import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'antd'
import { FinsDrawer } from '../../components/ui/FinsDrawer'
import { LinkWithIcon } from '../../components/ui/LinkWithIcon'

export const Documents = ({ history }) => {
  const { isMobile, currentProductPath } = useSelector((state) => state.global)
  const [isPreviousVersionsVisible, setIsPreviousVersionsVisible] =
    React.useState(false)

  const handlePreviousVersionsClick = () => {
    setIsPreviousVersionsVisible(true)
  }

  return (
    <FinsDrawer
      title="Документы"
      placement="right"
      width={isMobile ? '100%' : '439px'}
      bodyStyle={{ padding: '32px' }}
      isMobile={isMobile}
      routePathAfterClose={`${currentProductPath}/${history.location.search}`}
    >
      <LinkWithIcon
        text="Брокерская лицензия ФСФР N154-04434-100000 от 10.01.2001"
        link="https://fins.money/cdn/docs/license/Лицензия_брокера.jpg"
      />
      <LinkWithIcon
        text="Правила проведения маркетинговой (рекламной) акции «Пригласи друга»"
        link="https://fins.money/cdn/static/promo/invite_friend/Правила_проведения_акции_Пригласи_друга.pdf"
      />
      <LinkWithIcon
        text="Справка о принадлежности домена fins.money"
        link="https://fins.money/cdn/docs/tech/Принадлежность_домена_Финс_к_БКС.pdf"
      />
      <LinkWithIcon
        text="Правила проведения маркетинговой акции «Кэшбек 1000 рублей»"
        link="https://fins.money/cdn/static/promo/cashback_1000/Кэшбек_1000.pdf"
      />
      <LinkWithIcon
        text="Соглашение об организации корпоративной системы электронного документооборота Fins (редакция 6)"
        link="https://fins.money/cdn/docs/Соглашение_об_организации_ЭДО_Fins_6.pdf"
      />
      {isPreviousVersionsVisible ? (
        <>
          <LinkWithIcon
            text="Соглашение об организации корпоративной системы электронного документооборота Fins (редакция 5)"
            link="https://fins.money/cdn/docs/Соглашение_об_организации_ЭДО_Fins.pdf"
          />
          <LinkWithIcon
            text="Соглашение об организации корпоративной системы электронного документооборота Fins (редакция 4)"
            link="https://fins.money/cdn/docs/Соглашение_об_организации_ЭДО_Fins_4.pdf"
          />
        </>
      ) : (
        <>
          <Button block type="secondary" onClick={handlePreviousVersionsClick}>
            Прошлые версии
          </Button>
        </>
      )}
    </FinsDrawer>
  )
}

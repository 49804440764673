import React from 'react'
import { Divider } from 'antd'
import './index.less'

export const LinkWithIcon = ({ text, link }) => {
  return (
    <>
      <a
        className="link-with-icon"
        href={link}
        rel="noreferrer"
        target="_blank"
      >
        <div className="link-with-icon__content">{text}</div>
      </a>
      <Divider style={{ backgroundColor: '#D3DDF3', margin: '18px 0' }} />
    </>
  )
}

import React from 'react'
import '../index.less'

export const Section10 = ({ id }) => {
  return (
    <>
      <h1 id={id}>
        Производные финансовые инструменты: фьючерс, опцион — в чем разница
      </h1>
      <h2>
        Разберемся, что такое производные финансовые инструменты и срочный
        рынок.
      </h2>
      <p>
        <b>Биржа</b> — это площадка, на которой организованно ведутся торги
        различными активами. Виды активов бывают базовые и производные.
      </p>
      <p>
        Базовым активом могут выступать: акции, облигации, сырьевые товары,
        фондовые индексы, валюты и процентные ставки.
      </p>
      <p>
        Производными финансовыми инструментами (деривативами) называют контракт,
        по которому стороны получают право или обязуются исполнить оговоренные
        действия в отношении базового актива. Действия включают в себя:
        возможность покупки, продажи, предоставления, получения товара или
        ценных бумаг.
      </p>
      <p>Производные финансовые инструменты торгуются на срочном рынке.</p>
      <h2>Зачем инвестору деривативы</h2>
      <p>
        Попытаться снизить рыночные риски, возникающие при сделках с базовыми
        активами — то есть использовать стратегию хеджирования, при которой
        можно совершить сделку на заранее оговоренных условиях.
      </p>
      <p>
        Срочный рынок предоставляет широкие возможности для реализации различных
        торговых стратегий, в том числе спекулятивных.
      </p>
      <p>
        Есть здесь и дополнительные возможности: для совершения сделки не нужно
        оплачивать полную стоимость контракта, обеспечивая только размер
        гарантийного обеспечения (ГО). Например, это может быть около 10–20% от
        суммы сделки. Размер ГО устанавливается биржей.
      </p>
      <p>
        За счет блокировки только ГО появляется так называемый эффект плеча — по
        сравнению с покупкой базового актива на ту же сумму можно купить больше
        производных инструментов. Пропорционально увеличивается и риски и
        доходность.
      </p>
      <p>
        <b>Важно</b>: спекулятивные агрессивные стратегии влекут за собой
        повышенные риски. Стоит тщательно оценить долю капитала, которой
        инвестор готов рисковать. Важное условие, особенно для начинающего
        инвестора, это умение соотносить риски и управлять ими в рамках своей
        портфельной стратегии. Необходимо следить за достаточностью средств для
        ГО, чтобы брокер не закрыл позицию принудительно.
      </p>
      <p>
        С помощью инструментов срочного рынка можно не только спекулировать или
        хеджировать свои обязательства (например, валютный кредит), еще можно
        использовать стратегию арбитража. Арбитраж — это несколько логически
        связанных сделок с целью извлечь прибыль из разницы в ценах
        на одинаковые (или связанные) активы: в одно и то же время на разных
        рынках (пространственный арбитраж) или на одном и том же рынке в разные
        моменты времени (временной арбитраж).
      </p>
      <h2>Популярные деривативы</h2>
      <p>
        <b>Фьючерс</b> — это договоренность (контракт) между продавцом и
        покупателем о поставке базового актива через оговоренный период времени
        и по заранее определенной цене. Главное: фьючерс подразумевает
        обязательства для обеих сторон: продавец обязан продать, а покупатель
        купить выбранный актив на оговоренных условиях.
      </p>
      <p>
        <b>Опцион</b> — это договор, по которому покупатель опциона получает
        право купить/продать какой-либо актив (товар, ценная бумага, валюта и
        др.) в определенный момент времени по заранее обусловленной цене. 
      </p>
      <p>
        <b>Главное:</b> это обязательство одной стороны и право другой.
      </p>
      <p>
        Одним из организаторов торгов на срочном рынке является Московская
        биржа. Биржа выступает гарантом исполнения обязательств. Клиринг
        осуществляет АО «Национальный клиринговый центр».
      </p>
      <p>Кому подойдут производные финансовые инструменты</p>
      <p>
        Производные финансовые инструменты дают инвесторам множество
        возможностей:
      </p>
      <ul>
        <li>
          торговать «всем рынком» с помощью фьючерсов на индекс МосБиржи или РТС
        </li>
        <li>
          опосредованно инвестировать в товары, покупка и хранение которых
          связана с большими затратами (золото, нефть, пшеница и т.д.)
        </li>
        <li>отыгрывать мировые новости</li>
        <li>отыгрывать формирование цен на товары</li>
        <li>
          реализовывать арбитражные стратегии с мировыми товарными и фондовыми
          рынками
        </li>
        <li>хеджировать инвестиции в акции</li>
        <li>хеджировать валютные риски и др.</li>
      </ul>
      <h2>Как работают фьючерсы</h2>
      <p> Ключевые особенности фьючерсов</p>
      <p>
        Предмет фьючерсного контракта (что покупается/продается)
        называется базовый актив. По базовому активу выделяют:
      </p>
      <ul>
        <li>
          фьючерсы фондовой секции Мосбиржи: на акции, корзины облигации,
          индексы, инвестиционные паи;
        </li>
        <li>
          фьючерсы денежной секции Мосбиржи: на валютные пары, на процентные
          ставки;
        </li>
        <li>
          фьючерсы товарной секции Мосбиржи: на нефть марки Brent и Light Sweet
          Crude Oil, природный газ, золото, серебро, медь, платина, палладий и
          др.
        </li>
      </ul>
      <p>
        Статистика Московской биржи показывает, что больше 50% объема составляют
        торги фьючерсами на валютные базовые активы; остальной объем приходится
        на товарные и индексные — примерно поровну.
      </p>
      <p>
        Фьючерсные контракты имеют заранее оговоренные сроки исполнения. Момент
        исполнения фьючерса называется экспирация. На Срочном рынке Московской
        биржи торгуются месячные и квартальные контракты. Сделка накладывает на
        участников обязательство исполнить условия договора в день истечения
        срока фьючерса (экспирации).
      </p>
      <p>
        Основные условия обращения фьючерсного контракта стандартизированы
        биржей и закреплены в Спецификации фьючерса. Документ включает основные
        характеристики: дата исполнения контракта; количество базового актива,
        которое поставляется при исполнении поставочного фьючерса; размер
        комиссии биржи за покупку/продажу или при исполнении фьючерса; способы
        взаиморасчетов; валюта цены фьючерса и т.п.
      </p>
      <p>
        Стороны несут обязательства перед биржей вплоть до исполнения фьючерса
        или до закрытия позиций.
      </p>
      <p>
        Узнать актуальные котировки фьючерсов, дату исполнения, размер
        гарантийного обеспечения можно в Спецификации и в Параметрах инструмента
        на официальном сайте Московской биржи.
      </p>
      <h2>Классификация фьючерсов</h2>
      <p>По типу исполнения фьючерсы бывают поставочными и расчетными.</p>
      <p>
        <b>Поставочный фьючерс</b> — на дату исполнения контракта покупатель
        должен приобрести, а продавец продать установленное в спецификации
        количество базового актива. Поставка осуществляется после экспирации по
        расчетной цене, зафиксированной на последнюю дату торгов. Если закрыть
        позицию по фьючерсу до дня экспирации, поставки базового актива не
        произойдет.
      </p>
      <p>
        <b>Расчетный (беспоставочный) фьючерс</b> — между участниками
        производятся только денежные расчеты в сумме разницы между ценой
        контракта и расчетной ценой контракта на дату исполнения без физической
        поставки базового актива. То есть инвестору или начисляется прибыль, или
        списывается убыток.
      </p>
      <h2>Жизненный цикл фьючерса</h2>
      <p>
        Одновременно могут торговаться несколько фьючерсов на один актив —
        ближайшие и с более дальним сроком экспирации. По самым ликвидным
        фьючерсам может быть и 8 контрактов с квартальными экспирациями. По
        нефти Brent торгуется 12 месячных контрактов.
      </p>
      <p>
        Как правило, чем дальше дата экспирации, тем меньше ликвидность
        инструмента.
      </p>
      <h2>Как работают опционы</h2>
      <p>
        Ключевые особенности опционов схожи с фьючерсами. Поэтому для понимания
        разберемся, в чем главное отличие опциона от фьючерса.
      </p>
      <p>
        Это неравномерность обязательств сторон сделки. Для продавца опционов
        все идентично, как и по сделке во фьючерсах: это обязательство совершить
        сделку с базовым активом в будущем по заданной цене и на заданных
        условиях. А вот для покупателя — это право (не обязанность) в течение
        установленного в условиях срока купить либо продать на определенных
        условиях определенное количество базового актива. То есть покупатель
        может как согласиться, так и отказаться от этого права.
      </p>
      <p>
        Чтобы уравновесить позиции сторон, у опциона возникает дополнительная
        характеристика, которой нет у фьючерса — это цена опциона, или опционная
        премия. Она платится продавцу и остается у него, независимо от того,
        будет ли исполнен контракт.
      </p>
      <p>Опционная премия делится на два подвида:</p>
      <p>
        <b>Опцион call</b> — право купить для покупателя и обязательство продать
        для продавца
      </p>
      <p>
        <b>Опцион put</b> — право продать для покупателя опциона и обязательство
        купить для продавца.
      </p>
      <p>
        Эти опционы торгуются различно. Суть в том, что у них разная премия, а
        точнее — ее размер, рыночная стоимость.
      </p>
      <h2>Основные параметры опционов</h2>
      <p>
        Премия, или стоимость опциона — рыночная цена, по которой проходит
        сделка.
      </p>
      <p>
        Страйк, или цена исполнения — цена, по которой возможно исполнение прав
        и обязанностей по опциону.
      </p>
      <p>Дата экспирации, или дата погашения — срок до истечения контракта.</p>
      <p>Объем контракта измеряется в единицах базового актива.</p>
      <p>
        Премия опциона определяется в результате биржевых торгов. Формируется из
        двух основных частей: 
      </p>
      <p>
        <b>Внутренняя стоимость</b> — возникает тогда, когда цена базового
        актива опциона превышает цену страйк (в случае роста), и представляет
        собой разницу между этими двумя величинами.
      </p>
      <p>
        <b>Временная стоимость</b> — это ожидания изменения стоимости базового
        актива в будущем. Она зависит от волатильности базового актива и даты
        исполнения опциона.
      </p>
      <p>
        Опционы являются более гибким инструментом, чем фьючерсы и акции. Они
        дают возможность торгующим отразить абсолютно любой взгляд на рынок:
        рост, падение, ограниченный рост или падение, стагнация, рост
        волатильности (колебаний) цен на рынке без значимого конечного изменения
        цены, другие варианты поведения рынка.
      </p>
      <p>
        Итак, продавец обязан выполнить условия сделки. А покупателю важно
        «захотеть» ее реализовать. Рассмотрим случаи, в которых покупателю это
        будет выгодно.
      </p>
      <p>
        В зависимости от соотношения цены базового актива и цены страйк
        различают три вида опциона:
      </p>
      <ul>
        <li>
          Опцион <b>«в деньгах»</b> = биржа исполняет их автоматически
          <br />
          <b>для опциона Call:</b> когда текущая цена базового
          актива больше цены исполнения опциона (страйк);
          <br />
          <b>для опциона Put:</b> когда текущая цена базового актива меньше цены
          исполнения опциона (страйк).
        </li>
      </ul>
      <p>
        При покупке опционов убыток в данной ситуации будет ограничен размером
        премии — именно это и являлось предметом торга и стратегией при
        заключении опциона, повышая предсказуемость инвестиций. То есть
        финансовый результат продавца — ограниченная прибыль при потенциально
        неограниченном убытке (!).
      </p>
      <p>Опционы бывают месячные и квартальные.</p>
      <p>
        <b>Для чего нужны опционы</b>
      </p>
      <p>
        Приобретая опцион, обе стороны оценивают величину риска неблагоприятного
        изменения цены базового актива, которая закладывается в премию.
      </p>
      <p>
        Покупатели, как правило, используют опцион для хеджирования (сокращения)
        рисков или получения прибыли. Продавцы преследуют цель — заработать на
        его реализации. Для этого они устанавливают (или рассчитывают по
        определенной формуле) справедливую премию по опциону.
      </p>
      <p>Отличия опциона от прямой покупки (продажи) актива:</p>
      <ul>
        <li>
          ограниченные риски для покупателя (не более размера цены опциона);
        </li>
        <li>установленные сроки взаиморасчетов;</li>
        <li>более низкие издержки проведения операций на срочном рынке.</li>
      </ul>
    </>
  )
}

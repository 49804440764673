import React from 'react'
import '../index.less'

export const Section5 = ({ id }) => {
  return (
    <>
      <h1 id={id}>«Акции – базовые основы инвестирования»</h1>
      <h2>Риски использования сделок с неполным покрытием для инвестора</h2>
      <p>
        Акционерные общества являются эффективными системами современной мировой
        экономики. Соответственно, существуют две основные группы,
        заинтересованные в рынке акций. С одной стороны, это владельцы
        свободного капитала, или инвесторы. Их цель — инвестировать капитал с
        максимальной отдачей или доходностью для себя и своих клиентов. С другой
        — это акционеры, или владельцы акций. Они стремятся максимально
        увеличить стоимость или количество принадлежащих или доверенных им
        пакетов акций. Обе группы объединяет общий интерес — увеличение
        собственного капитала.
      </p>
      <p>
        Интересы инвесторов и акционеров встречаются и удовлетворяются на
        фондовом рынке, который обеспечивает свободное перемещение капиталов из
        одного предприятия в другое. Здесь присутствуют разные участники, каждый
        из который выполняет определённые функции:
      </p>
      <p>
        Эмитент – лицо (например: государство, местная администрация или
        акционерное общество), от имени которого выпущены ценные бумаги для
        развития и финансирования своей деятельности.
      </p>
      <p>
        Инвестор – это лицо или организация, размещающее капитал с целью
        последующего получения прибыли.
      </p>
      <p>К профессиональным участникам рынка ценных бумаг относятся:</p>
      <ul>
        <li>Брокеры</li>
        <li>Дилеры</li>
        <li>Форекс-дилеры</li>
        <li>Доверительные управляющие</li>
        <li>Инвестиционные консультанты</li>
        <li>Депозитарии</li>
        <li>Регистраторы</li>
      </ul>
      <p>
        Акции — это ценная бумага, которую выпускает (эмитирует) компания.
        Рассмотрим этот популярный инструмент инвестирования с точки зрения
        инвестора.
      </p>
      <p>
        Инвестор покупает акции с целью участия в капитале компании и получения
        дополнительного дохода. Существует два типа акций — обыкновенные и
        привилегированные: отличаются они набором прав, которые дают своему
        владельцу.
      </p>
      <p>
        Обыкновенные акции дают право голоса на собрании акционеров, возможность
        участия в распределении прибыли, но не гарантируют получение дивидендов.
      </p>
      <p>
        Привилегированные акции не дают право голоса на собрании акционеров, за
        исключением некоторых случаев, при принятии решений:
      </p>
      <ul>
        <li>о реорганизации и ликвидации акционерного общества,</li>
        <li>об изменении вида акционерного общества,</li>
        <li>
          о внесении в устав акционерного общества изменений и (или) дополнений,
          ограничивающих их права, в том числе в части дивидендных выплат.
        </li>
      </ul>
      <p>Важные характеристики акций:</p>
      <div>
        <div className="testing_box">
          <b>Ликвидность</b> — возможность быстро продать или купить по цене,
          близкой к рыночной.
        </div>
        <div className="testing_box">
          <b>Номинальная цена</b> — отражает размер уставного капитала компании.
        </div>
        <div className="testing_box">
          <b>Рыночная цена</b> — стоимость, устанавливаемая котировками на
          фондовой бирже на основе баланса спроса и предложения.
        </div>
        <div className="testing_box">
          <b>Доходность</b> — складывается из дивидендов (выплаты части прибыли
          компании) и/или прироста курсовой стоимости
        </div>
      </div>
      <p>
        Инвестору на старте формирования портфеля необходимо составить
        индивидуальную инвестиционную стратегию, чтобы понять в какие акции
        стоит или не стоит инвестировать. Прогнозируемые потери — это не
        страшно. Спонтанные необдуманные решения и отсутствие четкой
        выработанной стратегии — верный путь получить негативный инвестиционный
        результат.
      </p>
      <p>
        Необходимо поставить цель. Обезопасить накопления от инфляции или
        девальвации, увеличить капитал при незначительном уровне риска или
        попробовать быстро обогатиться? Какой объем потерь готов понести
        инвестор? Ответы на эти вопросы помогут определить подходы к выбору
        стратегии, допустимые позиции в портфеле и не позволят отвлекаться на
        краткосрочные спекулятивные соблазны.
      </p>
      <p>
        Инвестору стоит учитывать совокупность многих параметров: финансовые
        цели, толерантность к риску, желаемая доходность, временной горизонт
        инвестиций. Инвестор должен осознавать, что в случае необходимости
        возможность продать акцию ликвидно, то есть с минимальными потерями и в
        кратчайший срок, зависит от текущей рыночной ситуации, а не от его
        желания. То есть желание продать, не означает наличие покупателей.
        Иногда их может быть недостаточно, чтобы реализовать весь объем. Или,
        наоборот, слишком много, при покупке акций.
      </p>
      <h2>Особенности сделок с акциями</h2>
      <p>
        Торговать акциями можно, открыв счет в брокерской компании. Сделки
        совершаются в течение торговой сессии. Расписание и режим торгов
        публикуется на сайтах бирж.
      </p>
      <p>
        Пример: расчет и поставка по акциям на Московской бирже стандартно
        происходит на второй рабочий день (режим Т+2) — это следует учитывать
        при расчетах. Так, при закрытии реестра под дивиденды, для получения
        дивидендов акции нужно успеть приобрести за 2 рабочих дня до наступления
        события.
      </p>
      <h2>Зачем инвестору акции</h2>
      <ul>
        <li>
          Это потенциально более доходный инструмент, чем облигации или
          депозиты. Однако и более рискованный!
        </li>
        <li>
          Низкий порог входа: инвестор может приобрести 1 или несколько акций,
          имея небольшой капитал.
        </li>
        <li>
          В отличие от облигаций, дают возможность участия в управлении
          компанией.
        </li>
      </ul>
      <h2>Листинг ценных бумаг</h2>
      <p>
        <b>Листинг</b> — включение ценных бумаг организатором торговли в список
        допущенных к организованным торгам для заключения договоров
        купли-продажи, в том числе включение ценных бумаг биржей в котировальный
        список
      </p>
      <p>
        Листинг может осуществляется с включением и без включения в
        котировальные списки — Список ценных бумаг, допущенных к торгам на
        бирже.
      </p>
      <p>Список состоит из трех разделов:</p>
      <ul>
        <li>Первый уровень</li>
        <li>Второй уровень</li>
        <li>Третий уровень.</li>
      </ul>
      <p>
        Чтобы пройти процедуру листинга, ценная бумага и ее эмитент должны
        соответствовать требованиям, которые установлены Правилами листинга
        биржи.
      </p>
      <p>
        Очевидно, что компании отличаются друг от друга по многим параметрам. Их
        финансовые результаты, размер, отрасль и другие факторы создают
        различные риски при инвестировании. Благодаря стандартизированным
        требованиям биржи компания становится более прозрачной для частного
        инвестора. Разделение на уровни дает первичную информацию о том,
        насколько компания соответствует высоким требованиям биржи и помогает
        определить уровень риска для инвестиций. Это позволяет широкому кругу
        инвесторов обоснованно подойти к выбору ценных бумаг.
      </p>
      <p>
        Первый и Второй уровни являются котировальными списками. Третий уровень
        является некотировальной частью списка.
      </p>
      <p>
        <b>Первый уровень</b> — «высшая лига» — дает допуск к торгам бумагам
        известных эмитентов. На нем торгуются такие тяжеловесы, как Сбербанк,
        Роснефть, Лукойл и другие голубые фишки.
      </p>
      <p>
        Принципы для Первого и Второго уровней схожи, разница в масштабе
        требований:
      </p>
      <ul>
        <li>по капитализации</li>
        <li>рыночной цене акций в свободном обращении</li>
        <li>
          сроку существования компании отчетности по МСФО за последние 3
          календарных года
        </li>
        <li>по корпоративному управлению</li>
        <li>по раскрытию информации</li>
      </ul>
      <p>
        Требования Третьего уровня — это входной билет на биржу. Бумаги этого
        уровня не представлены в котировальном списке. Для листинга Третьего
        уровня биржа устанавливает для компаний минимальные требования:
      </p>
      <ul>
        <li>
          соответствие ценных бумаг требованиям законодательства РФ и
          нормативным актам Банка России;
        </li>
        <li>зарегистрировать проспект эмиссии ЦБ;</li>
        <li>
          раскрывать информацию в соответствии с Законом о ценных бумагах.
        </li>
      </ul>
      <p>
        Бумаги Третьего уровня, как правило, более волатильны и менее ликвидны,
        поскольку они более рискованные. Следовательно, количество совершаемых с
        ними сделок в течение дня значительно меньше в масштабах, чем с бумагами
        Первого и Второго уровней.
      </p>
      <p>
        Подробно ознакомиться с требованиями для включения в первый, второй или
        третий уровень, предъявляемыми Московской Биржей, можно по ссылке:{' '}
        <a href="https://www.moex.com/a2584" target="_blank" rel="noreferrer">
          https://www.moex.com/a2584
        </a>
      </p>

      <h2>Какие есть риски при инвестировании в акции</h2>
      <p>
        Один из главных постулатов при работе с рискованными активами, которыми
        являются акции, — доходы предыдущих периодов не гарантируют доходы в
        будущем. Именно поэтому инвестор, особенно начинающий, должен
        принципиально определить, какой уровень риска приемлем лично для него.
      </p>
      <p>
        Средства, размещаемые на брокерском счете, не подлежат страхованию, в
        отличие от банковских счетов и вкладов. Процент по вкладам известен,
        определенная сумма вклада защищена государством. Процент либо купон по
        облигациям также определен на входе в сделку. С акциями все иначе: чем
        больше доход, тем выше уровень риска. Рынки акций бывают крайне
        волатильны, особенно при изменении рыночной и экономической ситуации.
      </p>
      <p>
        Страновой риск, который может наступить в случае, когда действия
        суверенного правительства повлияют на способность должника, связанного с
        данной страной, исполнить свои обязательства.
      </p>
      <p>
        Чтобы минимизировать риски, стоит выбирать надежного брокера, имеющего
        подтвержденный опыт на профессиональном рынке ценных бумаг. Стоит
        использовать стратегии диверсификации активов, сочетая в своем портфеле
        акции различных компаний из разных сфер экономики. Расхожая фраза «не
        держите все яйца в одной корзине» наглядно отражает суть принципа
        сбалансированного распределения активов инвестора.
      </p>

      <h2>Особенности налогообложения</h2>
      <p>
        <b>Валютная переоценка</b>
      </p>
      <p>
        При расчете НДФЛ по операциям с ценными бумагами, номинированным в
        иностранной валюте, важным аспектом является курс Банка России,
        установленный для данной валюты к рублю на даты покупки/продажи
        (учитывается фактическая дата расчетов по сделке). Именно доход,
        пересчитанный в рублях по курсу Банка России, подлежит налогообложению.
      </p>
      <p>Особенно стоит обратить внимание:</p>
      <ul>
        <li>
          В ситуации, когда в связи со снижением курса доллара, установленного
          Банком России, доход, полученный физическим лицом в рублях —
          отрицательный, НДФЛ взиматься не будет.
        </li>
        <li>
          В ситуации, когда несмотря на то, что доход, полученный физическим
          лицом в долларах, отрицательный, доход в рублях (в связи с выросшим
          официальным курсом Банка России) —положительный. Положительный доход в
          рублях от реализации иностранных ценных бумаг облагается НДФЛ.
        </li>
      </ul>
      <p>
        Основание — НК РФ, Статья 210. Налоговая база 5. Доходы (расходы,
        принимаемые к вычету в соответствии со статьями 214.1, 214.3, 214.4,
        214.5, 218-221 настоящего Кодекса) налогоплательщика, выраженные
        (номинированные) в иностранной валюте, пересчитываются в рубли по
        официальному курсу Центрального банка Российской Федерации,
        установленному на дату фактического получения указанных доходов (дату
        фактического осуществления расходов), если иное не предусмотрено
        настоящей главой.
      </p>

      <h2>Оптимизация налогооблагаемой базы</h2>

      <p>
        А если убыток от совершения сделок с акциями все-таки получен? И здесь
        государство предусмотрело, как можно сгладить потери. Разумеется,
        компенсировать убытки никто не будет. Об этом стоит помнить, начиная
        сделку с любыми ценными бумагами, не только с акциями.
      </p>
      <p>
        Однако Налоговый кодекс РФ предусматривает для потерпевших неудачу на
        фондовом рынке некоторые особенности определения налоговой базы,
        исчисления и уплаты налога на доходы по операциям с ценными бумагами и
        по операциям с финансовыми инструментами срочных сделок.
      </p>
      <p>
        Так, можно уменьшить налогооблагаемую базу по акциям. Также можно
        просальдировать убытки и/или перенести убытки на будущее. Убыток,
        полученный в текущем налоговом периоде, может переноситься на будущее в
        течение 10 лет, следующих за тем налоговым периодом, в котором он был
        получен. При этом убыток, не перенесенный на ближайший год, может быть
        перенесен полностью или частично на следующий год из последующих девяти
        лет.
      </p>
      <p>
        <b>И так тоже бывает</b>
      </p>
      <p>
        А что делать, если брокерская компания, через которую обслуживался
        инвестор, обанкротилась или ликвидирована?
      </p>
      <p>
        Все ценные бумаги защищены на законодательном уровне. В этом случае
        инвестор может передать акции в депозитарий другого брокера или вывести
        их в реестр. Важно знать, что любые ценные бумаги, которые приобретает
        владелец счета, фиксируются в электронном регистре и без его участия их
        невозможно продать, перевести или «потерять».
      </p>
      <div className="testing_divider" />
    </>
  )
}

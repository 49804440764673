import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'antd'
import { push } from 'connected-react-router'
import { FinsDrawer } from '../../components/ui/FinsDrawer'

import './index.less'
import iconWarning from './icons/warning.svg'

export const AuthorizationStatusInfo = ({
  history,
  status = 'USER_BLOCKED',
}) => {
  const { otpRequire } = useSelector((state) => state.auth)
  const { isMobile, currentProductPath } = useSelector((state) => state.global)
  const dispatch = useDispatch()

  const statusText = {
    USER_BLOCKED: {
      title: 'Доступ ограничен',
      subTitle: 'Позвоните нам и наши специалисты поддержки помогут вам.',
      button: (
        <Button
          type="primary"
          block
          href="tel:88005001001"
          className="authorization-info__button"
        >
          8-800-500-10-01
        </Button>
      ),
      icon: iconWarning,
    },
    OTP_FAILED: {
      title: 'Введите телефон заново',
      subTitle:
        'Некоторое время вы оставались неактивны. В целях безопасности введите номер повторно',
      button: (
        <Button
          type="primary"
          block
          onClick={() => {
            dispatch(push(`${currentProductPath}/authorization`))
          }}
          className="authorization-info__button"
        >
          Продолжить
        </Button>
      ),
      icon: iconWarning,
    },
  }

  React.useEffect(() => {
    if (otpRequire) {
      history.push(
        `${currentProductPath}/authorization/otp/${history.location.search}`,
      )
    }
  }, [currentProductPath, history, otpRequire])

  return (
    <FinsDrawer
      title="Вход в Fins"
      placement="right"
      width={isMobile ? '100%' : '439px'}
      bodyStyle={{ padding: '32px' }}
      isMobile={isMobile}
      routePathAfterClose={`${currentProductPath}/${history.location.search}`}
    >
      <>
        <div className="authorization-info__wrap">
          <img src={statusText[status].icon} alt="status icon" />
          <div className="authorization-info__title">
            {statusText[status].title}
          </div>
          <div className="authorization-info__sub-title">
            {statusText[status].subTitle}
          </div>
          {statusText[status].button}
        </div>
      </>
    </FinsDrawer>
  )
}

import React from 'react'
import BcsImage from 'assets/bcs_logo.svg'
import { Link } from 'react-router-dom'
import { Divider, Button } from 'antd'
import FinsImage from 'assets/fins_logo.svg'
import { MainMenu } from 'components/MainMenu'
import classNames from 'classnames'
import './index.less'

const HeaderDesktop = ({ showMenu, handleLoginButton }) => {
  return (
    <>
      <div className={classNames('header')}>
        <div className="header__block">
          <Link to="/">
            <img
              src={FinsImage}
              className="header__fins-logo"
              alt="fins logo"
            />
          </Link>
          <Divider className="header__divider" type="vertical" />
          <img src={BcsImage} className="header__mi-logo" alt="bcs logo" />
          <div className="header__buttons">
            <Button onClick={handleLoginButton}>Войти</Button>
            <Button onClick={handleLoginButton} type="primary">
              Регистрация
            </Button>
          </div>
        </div>
        {showMenu && <MainMenu backgroudWhite isMobile={false} />}
      </div>
      <MainMenu isMobile={false} />
    </>
  )
}

export default HeaderDesktop

import React from 'react'
import { useSelector } from 'react-redux'
import { FinsDrawer } from '../../components/ui/FinsDrawer'
import { LinkWithIcon } from '../../components/ui/LinkWithIcon'
import LicenseBankImage from './assets/license_bank.jpg'
import LicenseBrokerImage from './assets/license_broker.jpg'

import './index.less'

export const Licenses = () => {
  const { isMobile, currentProductPath } = useSelector((state) => state.global)
  return (
    <FinsDrawer
      title="Лицензии ЦБ"
      placement="right"
      width={isMobile ? '100%' : '439px'}
      bodyStyle={{ padding: '32px' }}
      isMobile={isMobile}
      routePathAfterClose={currentProductPath}
    >
      <div className="licenses">
        <LinkWithIcon
          text="Лицензия брокера"
          link="https://fins.money/cdn/docs/license/Лицензия_брокера.jpg"
        />
        <img src={LicenseBrokerImage} alt="lincese broker" />
        <LinkWithIcon
          text="Лицензия банка"
          link="https://fins.money/cdn/docs/license/Лицензия_банка.pdf"
        />
        <img src={LicenseBankImage} alt="lincese bank" />
      </div>
    </FinsDrawer>
  )
}

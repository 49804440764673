import axios from 'axios'
import { notification } from 'antd'

import { ErrorCodes } from './errorCodes'
import { localize } from '../localize'

const codeMessage = {
  200: 'The server successfully returned the requested data.',
  201: 'New or modified data is successful.',
  202: 'A request has entered the background queue (asynchronous task).',
  204: 'The data was deleted successfully.',
  400: 'The request was made with an error and the server did not perform any operations to create or modify data.',
  401: 'User does not have permission (token, username, password is incorrect).',
  403: 'The user is authorized, but access is forbidden.',
  404: 'The request is made for a record that does not exist and the server does not operate.',
  406: 'The format of the request is not available.',
  410: 'The requested resource is permanently deleted and will not be retrieved.',
  422: 'A validation error occurred when creating an object.',
  500: 'An error occurred on the server. Please check the server.',
  502: 'Gateway error.',
  503: 'The service is unavailable and the server is temporarily overloaded or maintained.',
  504: 'The gateway timed out.',
}

const statusCodes = {
  successful: 200,
  noContent: 204,
  redirection: 300,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  unprocessableEntity: 422,
  internalServerError: 500,
  gatewayTimeout: 504,
}

// If status code !== 200
function checkStatus(response) {
  if (response?.data?.data?.errorCode) {
    const errortext = localize[ErrorCodes[response?.data?.data?.errorCode]]
    notification.error({
      message: errortext,
    })

    const reponseWithErrorMessage = {
      ...response?.data?.data,
      message: errortext,
    }

    return Promise.reject(reponseWithErrorMessage)
  }
  if (
    response.status >= statusCodes.successful &&
    response.status < statusCodes.redirection
  ) {
    return response
  }
  const errortext = codeMessage[response.status] || response.statusText
  notification.error({
    message: `Request error ${response.status}: ${response.url}`,
    description: errortext,
  })

  return Promise.reject(response)
}

function checkDataError(response) {
  const reAuthErrorList = [ErrorCodes.SESSION_NOT_VALID]
  if (
    response.data?.errorCode &&
    reAuthErrorList.includes(response.data.errorCode)
  ) {
    return response.data
  }

  if (response.error?.code && reAuthErrorList.includes(response.error.code)) {
    return response.error
  }

  return response.data
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export function request(url, options, withoutAuth = false) {
  const requestUrl = `${process.env.RAZZLE_API_URL}${url}`
  const defaultOptions = !withoutAuth
    ? {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('t')}`,
        },
      }
    : {}

  const newOptions = { ...defaultOptions, ...options }
  if (
    newOptions.method === 'POST' ||
    newOptions.method === 'PUT' ||
    newOptions.method === 'DELETE'
  ) {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...newOptions.headers,
      }
      newOptions.body = JSON.stringify(newOptions.body)
    } else {
      // newOptions.body is FormData
      newOptions.headers = {
        Accept: 'application/json',
        ...newOptions.headers,
      }
    }
  }

  return axios({
    ...newOptions,
    url: requestUrl,
  })
    .then(checkStatus)
    .then((response) => {
      if (
        newOptions.method === 'DELETE' ||
        response.status === statusCodes.noContent
      ) {
        return response.text()
      }
      return checkDataError(response)
    })
}

import { flatten, map, compose, keys, reduce } from 'ramda'
import { main } from './main'
import { agreement } from './agreement'
import { products } from './products'
import { partners } from './partners'
import { subRoutes } from './subRoots'

const getRoutes = compose(
  flatten,
  map((routes) => keys(routes)),
)

export const subRoutesExport = compose(
  reduce(
    (acc, mainRoute) =>
      acc.concat(
        getRoutes(subRoutes).map((subRoute) => `${mainRoute}${subRoute}`),
      ),
    [],
  ),
  getRoutes,
)([products])

export const routes = [products, partners, agreement, main]

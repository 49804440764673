import React from 'react'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import { useHistory } from 'react-router-dom'
import { Button } from 'antd'
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg'
import './index.less'

const chekeTrue = () => Cookies.get('agreeCookie') !== 'true'

export const Cookie = () => {
  const [isShow, setShow] = React.useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const { currentProductPath } = useSelector((state) => state.global)
  React.useEffect(() => {
    setShow(chekeTrue())
  }, [])

  const onClose = () => {
    Cookies.set('agreeCookie', 'true', { expires: 365, path: '' })
    setShow(false)
  }
  const handleOpenDrawer = React.useCallback(() => {
    dispatch(
      push(`${currentProductPath}/cookie-agreement?${history.location.search}`),
    )
  }, [dispatch, currentProductPath, history.location.search])

  if (!isShow) return null

  return (
    <div className="cookie-agreement">
      <div className="cookie-agreement__content">
        <div className="cookie-agreement__text">
          Fins использует{' '}
          <Button
            className="cookie-agreement__btn-link"
            onClick={handleOpenDrawer}
            type="link"
          >
            cookie
          </Button>{' '}
          для улучшения сайта и повышения удобства его использования. Мы
          ответственно подходим к вопросам защиты Ваших персональных данных.
          Узнайте больше в{' '}
          <a
            href="https://bcs-bank.com/f/personal_data_politics.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            политике конфиденциальности.
          </a>
        </div>
        <Button type="primary" onClick={onClose}>
          Отлично
        </Button>
      </div>

      <ArrowIcon onClick={onClose} className="cookie-agreement__arrow-icon" />
    </div>
  )
}

import React from 'react'
import { useDispatch } from 'react-redux'
import { SET_CURRENT_PRODUCT_PATH } from 'reducers/global'
import { MainHeader } from '../../sections/MainHeader'
import WhereToBegin from '../../sections/WhereToBegin'
import CalculateIncome from '../../sections/CalculateIncome'
import StartSaving from '../../sections/StartSaving'
import StartGettingIncome from '../../sections/StartGettingIncome'
import WritingAboutUs from '../../sections/WritingAboutUs'
import FAQ from '../../sections/FAQ'
import { DrawerRoute } from '../../components/DrawerRoute'

export const Main = () => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(SET_CURRENT_PRODUCT_PATH(''))
    if (typeof window !== 'undefined') window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <MainHeader />
      <WhereToBegin />
      <CalculateIncome />
      <StartSaving />
      <StartGettingIncome />
      <FAQ />
      <WritingAboutUs />
      <DrawerRoute />
    </>
  )
}

import { authorization } from './authorization'
import { registration } from './registration'
import { restorePassword } from './restore-password'
import { license } from './license'
import { documents } from './documents'

export const subRoutes = [
  registration,
  restorePassword,
  authorization,
  license,
  documents,
]

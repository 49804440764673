import React from 'react'
import { Alert } from 'antd'

export const RegistrationAlert = ({ message }) => {
  return (
    <Alert
      message="Ошибка регистрации."
      description={
        <div>
          {`${message} Попробуйте еще раз или позвоните нам по телефону`}
          <a href="tel:+78001007370">8-800-100-73-70</a>
        </div>
      }
      type="error"
    />
  )
}

export const validateRegexpField = (value, regexp) => {
  if (regexp.test(value)) {
    return true
  }

  return false
}

export const validateRequiredField = (value, validation, dependentValue) => {
  if (!value) {
    return Promise.reject(new Error('Обязательное поле для заполнения'))
  }
  return validation(value, dependentValue)
}

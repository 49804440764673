import React from 'react'
import { format } from 'date-fns'

export const SITEMAP = [
  {
    name: 'Партнёры',
    link: '/agents/',
  },
  {
    name: 'Документы',
    link: '/documents',
  },
  {
    name: 'Индивидуальный инвестиционный счет',
    link: '/individual-investment-account',
  },
  {
    name: 'Доверительное управление',
    link: '/trust-management',
  },
  {
    name: 'Паевый инвестиционный фонд',
    link: '/fund',
  },
  {
    name: 'Облигации',
    link: '/bonds',
  },
  {
    name: 'Квалифицированный инвестор',
    link: '/qualification',
  },
  {
    name: 'Структурные продукты',
    link: '/structural-products',
  },
  {
    name: 'Раскрытие информации',
    link: '/disclosure',
  },
]

export const FOOTER_TEXT = [
  <p>
    Наименование &laquo;Fins&raquo; (&laquo;Финс&raquo;) используется
    Акционерным обществом &laquo;БКС Банк&raquo; для обозначения корпоративной
    системы электронного документооборота &laquo;Fins&raquo; (система Fins).
    Система Fins организована Акционерным обществом &laquo;БКС Банк&raquo;,
    и&nbsp;предназначена для финансового и/или информационного электронного
    обслуживания клиентов. Она представляет собой совокупность программного,
    информационного и&nbsp;аппаратного обеспечения, которая реализует
    электронный документооборот в&nbsp;соответствии с&nbsp;Федеральным законом
    от&nbsp;06.04.2011 &#8470;&nbsp;63-ФЗ &laquo;Об&nbsp;электронной
    подписи&raquo;.
  </p>,
  <p>
    Брокерские услуги предоставляются Обществом с&nbsp;ограниченной
    ответственностью &laquo;Компания Брокеркредитсервис&raquo;, лицензия
    &#8470;&nbsp;154-04434-100000 от&nbsp;10.01.2001&nbsp;на осуществление
    брокерской деятельности. Выдана ФСФР без ограничения срока действия.
    Официальный сайт <a href="https://broker.ru/">broker.ru</a>.
  </p>,
  <p>
    Налоговый вычет по&nbsp;ИИС доступен только физлицам&nbsp;&mdash; налоговым
    резидентам&nbsp;РФ, которые получают доход, облагаемый НДФЛ
    по&nbsp;ставке&nbsp;13% (ст.&nbsp;219.1 Налогового кодекса РФ). Чтобы
    получить налоговый вычет, нужно заключить договор на&nbsp;открытие
    и&nbsp;ведение индивидуального инвестиционного счета (ИИС, договор ИИС)
    и&nbsp;выбрать вычет типа А&nbsp;&mdash; на&nbsp;внесённые деньги
    на&nbsp;ИИС. Вычет можно получить после истечения налогового периода.
    Налоговый период по&nbsp;ИИС&nbsp;&mdash; 1&nbsp;год. Минимальный срок
    инвестирования&nbsp;&mdash; 3&nbsp;года. Подробнее&nbsp;&mdash; Приложение
    15&nbsp;к Регламенту оказания услуг на&nbsp;рынке ценных бумаг ООО
    &laquo;Компания БКС&raquo;. Услугу по&nbsp;открытию и&nbsp;ведению
    индивидуального инвестиционного счета оказывает ООО &laquo;Компания
    БКС&raquo;. Клиент вправе иметь только один инвестиционный счет в&nbsp;одной
    финансовой организации; клиент не&nbsp;сможет снять со&nbsp;счета денежные
    средства в&nbsp;течение 3-х лет, если это произойдет&nbsp;&mdash;
    он&nbsp;теряет право на&nbsp;налоговые вычеты; пополнение счета возможно
    только в&nbsp;рублях на&nbsp;общую сумму не&nbsp;более 1&nbsp;млн
    в&nbsp;год, при этом налоговый вычет предоставляется только на&nbsp;сумму
    400&nbsp;тыс. руб.&nbsp;в&nbsp;год; денежные средства на&nbsp;ИИС
    не&nbsp;застрахованы АСВ.
  </p>,
  <p>
    Структурный продукт&nbsp;&mdash; срочный внебиржевой контракт. Доступен
    только квалифицированным инвесторам. Продукт не&nbsp;является банковским
    вкладом, на&nbsp;него не&nbsp;распространяется гарантия АСВ. Перед подачей
    поручения на&nbsp;заключение срочного внебиржевого контракта инвестору
    необходимо ознакомиться с&nbsp;рисками, связанными с&nbsp;осуществлением
    таких инвестиций, включая риски, описанные в&nbsp;Заявлении, раскрывающем
    риски, связанные с&nbsp;проведением операций на&nbsp;рынке фьючерсных
    контрактов, форвардных контрактов и&nbsp;опционов, а&nbsp;также
    в&nbsp;Декларации о&nbsp;рисках, связанных с&nbsp;приобретением иностранных
    ценных бумаг, и&nbsp;Декларации о&nbsp;рисках, связанных с&nbsp;заключением
    договоров, являющихся производными финансовыми инструментами, базисным
    активом которых являются ценные бумаги иностранных эмитентов или индексы,
    рассчитанные по&nbsp;таким ценным бумагам. Сохранность инвестиций возможна
    только при исполнении условий по&nbsp;продукту в&nbsp;полном объеме согласно
    его условиям в&nbsp;т.ч. срока инвестирования. При досрочном расторжении
    контракта клиент может понести убытки.
  </p>,
  <p>
    Услуги доверительного управления и&nbsp;по&nbsp;управлению инвестиционными
    фондами, паевыми инвестиционными фондами предоставляются
    АО&nbsp;УК&nbsp;&laquo;БКС&raquo;, Лицензия ФКЦБ России
    &#8470;&nbsp;21-000-1-00071 от&nbsp;25.06.2002&nbsp;на осуществление
    деятельности по&nbsp;управлению инвестиционными фондами, паевыми
    инвестиционными фондами и&nbsp;негосударственными пенсионными фондами, без
    ограничения срока действия. Лицензия ФСФР России
    &#8470;&nbsp;050-12750-001000
    от&nbsp;10.12.2009г.&nbsp;на&nbsp;осуществление деятельности
    по&nbsp;управлению ценными бумагами, без ограничения срока действия.
    Официальный сайт <a href="https://bcs.ru/am">bcs.ru/am</a>
  </p>,
  <p>
    Стоимость инвестиционных паёв может увеличиваться и&nbsp;уменьшаться,
    результаты инвестирования в&nbsp;прошлом не&nbsp;определяют
    и&nbsp;не&nbsp;гарантируют доходы в&nbsp;будущем. Доходность инвестиций
    в&nbsp;инвестиционных фондах не&nbsp;гарантирована. Прежде чем приобрести
    инвестиционный пай, следует внимательно ознакомиться с&nbsp;правилами
    доверительного управления паевым фондом. Результаты деятельности
    управляющего по&nbsp;управлению ценными бумагами в&nbsp;прошлом
    не&nbsp;определяют и&nbsp;не&nbsp;являются гарантией доходности учредителя
    управления в&nbsp;будущем. Управляющий не&nbsp;гарантирует достижения
    ожидаемой доходности, определенной в&nbsp;инвестиционном профиле&nbsp;и
    (или) в&nbsp;стандартном инвестиционном профиле учредителя управления.
  </p>,
  <p>
    Услуги по&nbsp;страхованию жизни предоставляются ООО СК&nbsp;&laquo;БКС
    Страхование жизни&raquo; <a href="http://bcslife.ru/">bcslife.ru</a>.
    Обязательства по&nbsp;договору несет ООО СК&nbsp;&laquo;БКС Страхование
    жизни&raquo;. Лицензии ЦБ&nbsp;РФ&nbsp;СЖ &#8470;&nbsp;4365, Лицензия
    ЦБ&nbsp;РФ&nbsp;СЛ &#8470;&nbsp;4365 от&nbsp;01.11.2018. Договор страхования
    жизни не&nbsp;является банковским вкладом, денежные средства по&nbsp;такому
    договору не&nbsp;застрахованы в&nbsp;соответствии с&nbsp;Федеральным законом
    &laquo;О&nbsp;страховании вкладов физических лиц в&nbsp;банках Российской
    Федерации&raquo;. Обязательства Страховщика, превышающие 1&nbsp;000&nbsp;000
    (один миллион) рублей на&nbsp;одного Застрахованного, перестрахованы
    в&nbsp;АО&nbsp;&laquo;Российская Национальная Перестраховочная
    Компания&raquo; (главный акционер компании&nbsp;&mdash; Банк России)
    на&nbsp;100%.
  </p>,
  <p>
    Материал не&nbsp;является индивидуальной инвестиционной рекомендацией.
    Упомянутые финансовые инструменты или операции могут не&nbsp;соответствовать
    вашему инвестиционному профилю. ООО &laquo;Компания БКС&raquo;,
    АО&nbsp;УК&nbsp;&laquo;БКС&raquo; и&nbsp;ООО СК&nbsp;&laquo;БКС Страхование
    жизни&raquo; не&nbsp;несет ответственности за&nbsp;возможные убытки
    инвестора в&nbsp;случае совершения операций или инвестирования
    в&nbsp;финансовые инструменты, упомянутые в&nbsp;данном материале.
  </p>,
  <p>
    Информация не&nbsp;может рассматриваться как публичная оферта, предложение
    или приглашение приобрести, или продать какие-либо ценные бумаги, иные
    финансовые инструменты, совершить с&nbsp;ними сделки. Информация
    не&nbsp;может рассматриваться в&nbsp;качестве гарантий или обещаний
    в&nbsp;будущем доходности вложений, уровня риска, размера издержек,
    безубыточности инвестиций. Результат инвестирования в&nbsp;прошлом
    не&nbsp;определяет дохода в&nbsp;будущем. Не&nbsp;является рекламой ценных
    бумаг. Перед принятием инвестиционного решения Инвестору необходимо
    самостоятельно оценить экономические риски и&nbsp;выгоды, налоговые,
    юридические, бухгалтерские последствия заключения сделки, свою готовность
    и&nbsp;возможность принять такие риски.
  </p>,
  <p>
    Информация действительна на&nbsp;{format(new Date(), 'dd.MM.yyyy')}{' '}
    и&nbsp;может измениться в&nbsp;зависимости от&nbsp;рыночной ситуации.
    Юридический адрес ООО &laquo;Компания БКС&raquo;: 129110, г. Москва, пр.
    Мира, 69, стр.1.
  </p>,
]

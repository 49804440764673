import React from 'react'
import { Form, Input as AntInput } from 'antd'
import classNames from 'classnames'
import { useHandleFocusInput, useHandleBlurInput } from '../../../utils/hooks'

const Input = (props) => {
  const {
    autoComplete = 'off',
    autoFocus = false,
    label,
    config,
    hasFeedback,
    onFocus = () => {},
    onBlur = () => {},
    onChange = () => {},
    type,
    password,
  } = props

  const [empty, setEmpty] = React.useState(true)
  const focusedInput = useHandleFocusInput(false)
  const bluredInput = useHandleBlurInput(false)
  // TODO: remove any
  const inputRef = React.useRef()

  // events
  const handleBlur = React.useCallback(
    (e) => {
      onBlur(e)
      focusedInput.onFocus(false)
      bluredInput.onBlur(true)
    },
    [bluredInput, focusedInput, onBlur],
  )

  const handleFocus = React.useCallback(
    (e) => {
      onFocus(e)
      focusedInput.onFocus(true)
      bluredInput.onBlur(false)
    },
    [bluredInput, focusedInput, onFocus],
  )

  const handleChange = React.useCallback(
    (e) => {
      onChange(e)
      setEmpty(e.target.value === '')
    },
    [onChange],
  )

  // effects
  React.useEffect(() => {
    if (window && inputRef && inputRef.current) {
      if (autoFocus) {
        inputRef.current.focus()
      }
    }
  }, [autoFocus])

  const inputProps = React.useMemo(() => {
    return {
      autoComplete,
      ref: inputRef,
      type,
      onChange: handleChange,
      onBlur: handleBlur,
      onFocus: handleFocus,
    }
  }, [handleChange, handleBlur, handleFocus, type, inputRef, autoComplete])

  return (
    <Form.Item>
      <Form.Item {...config} hasFeedback={hasFeedback && !focusedInput.value}>
        {password ? (
          <AntInput.Password
            autoComplete={autoComplete}
            ref={inputRef}
            type={type}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
        ) : (
          <AntInput
            {...props}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            ref={inputRef}
            type={type}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
        )}
      </Form.Item>
      <span
        className={classNames(
          'input-label',
          focusedInput.value && 'input-label__focus',
          !empty && 'input-label__not-empty',
        )}
      >
        {label}
      </span>
    </Form.Item>
  )
}

export default Input

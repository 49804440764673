import React from 'react'
import { ReactComponent as FundsIcon1 } from '../assets/product_tabs/funds_icon1.svg'
import { ReactComponent as FundsIcon2 } from '../assets/product_tabs/funds_icon2.svg'
import { ReactComponent as FundsIcon3 } from '../assets/product_tabs/funds_icon3.svg'
import { ReactComponent as TrustManagementIcon1 } from '../assets/product_tabs/trustManagement_icon1.svg'
import { ReactComponent as TrustManagementIcon2 } from '../assets/product_tabs/trustManagement_icon2.svg'
import { ReactComponent as TrustManagementIcon3 } from '../assets/product_tabs/trustManagement_icon3.svg'
import { ReactComponent as BondsIcon1 } from '../assets/product_tabs/bonds_icon1.svg'
import { ReactComponent as BondsIcon2 } from '../assets/product_tabs/bonds_icon2.svg'
import { ReactComponent as BondsIcon3 } from '../assets/product_tabs/bonds_icon3.svg'
import { ReactComponent as SpIcon1 } from '../assets/product_tabs/sp_icon1.svg'
import { ReactComponent as SpIcon2 } from '../assets/product_tabs/sp_icon2.svg'
import { ReactComponent as SpIcon3 } from '../assets/product_tabs/sp_icon3.svg'
import { ReactComponent as IiaIcon1 } from '../assets/product_tabs/iia_icon1.svg'
import { ReactComponent as IiaIcon2 } from '../assets/product_tabs/iia_icon2.svg'
import { ReactComponent as IiaIcon3 } from '../assets/product_tabs/iia_icon3.svg'
import { ReactComponent as QualifiedIcon1 } from '../assets/product_tabs/qualified_icon1.svg'
import { ReactComponent as QualifiedIcon2 } from '../assets/product_tabs/qualified_icon2.svg'
import { ReactComponent as QualifiedIcon3 } from '../assets/product_tabs/qualified_icon3.svg'

export const contentTypes = {
  title: 'title',
  paragraph: 'paragraph',
}

export const PRODUCT_TABS = {
  funds: [
    {
      key: 1,
      title: 'Гибкие условия',
      icon: <FundsIcon1 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Низкий порог входа',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value: 'Выбор подходящей стратегии доверительного управления',
        },
        {
          key: 3,
          type: contentTypes.title,
          value: 'Свободное распоряжение деньгами',
        },
        {
          key: 4,
          type: contentTypes.paragraph,
          value:
            'При желании вы можете докупать паи хоть каждый день и продать их в любой момент',
        },
      ],
    },
    {
      key: 2,
      title: 'Надёжная защита',
      icon: <FundsIcon2 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Под контролем Центрального Банка',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value:
            'Контроль за деятельностью управляющей компании осуществляет Центральный Банк России и специальный депозитарий',
        },
        {
          key: 3,
          type: contentTypes.title,
          value: 'Широкий список ценных бумаг',
        },
        {
          key: 4,
          type: contentTypes.paragraph,
          value:
            'ПИФ состоит из большого количества ценных бумаг крупных компаний, это позволяет снижать влияние отдельных компаний',
        },
      ],
    },
    {
      key: 3,
      title: 'Профессиональное управление',
      icon: <FundsIcon3 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Простые накопления',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value:
            'ПИФами управляет команда профессионалов финансового рынка. Они принимают решения о выборе самых перспективных ценных бумаг',
        },
        {
          key: 3,
          type: contentTypes.title,
          value: 'Высокий доход',
        },
        {
          key: 4,
          type: contentTypes.paragraph,
          value:
            'Широкий выбор стратегий, проверенные временем, нацелены не только на сохранение ваших денег, но и на увеличение вашей доходности',
        },
      ],
    },
  ],
  bonds: [
    {
      key: 1,
      title: 'Надёжность',
      icon: <BondsIcon1 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Крупные компании',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value:
            'В Fins представлены только облигации крупных и надежных компаний',
        },
        {
          key: 3,
          type: contentTypes.title,
          value: 'Понятный доход',
        },
        {
          key: 4,
          type: contentTypes.paragraph,
          value:
            'Ставка дохода по облигациям компаний зафиксирована и известна заранее',
        },
      ],
    },
    {
      key: 2,
      title: 'Гибкие условия',
      icon: <BondsIcon2 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Торговля на бирже',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value: 'Купите и продайте облигации в любое время на бирже.',
        },
        {
          key: 3,
          type: contentTypes.title,
          value: 'Удобный срок',
        },
        {
          key: 4,
          type: contentTypes.paragraph,
          value:
            'Приобретайте облигации на разный срок. Если вам понадобились деньги, облигацию можно продать в любой момент',
        },
      ],
    },
    {
      key: 3,
      title: 'Широкий выбор',
      icon: <BondsIcon3 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Различные эмитенты',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value:
            'В Fins представлены облигации крупных компаний из разных отраслей',
        },
        {
          key: 3,
          type: contentTypes.title,
          value: 'Государственные и корпоративные облигации',
        },
        {
          key: 4,
          type: contentTypes.paragraph,
          value:
            'Приобретайте облигации не только крупных компаний, но и выпущенные государством (ОФЗ)',
        },
      ],
    },
  ],
  sp: [
    {
      key: 1,
      title: 'Надёжно',
      icon: <SpIcon1 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Защита вложенных средств',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value:
            '100% от вложенных средств гарантированно возвращаются на ваш счёт в конце срока',
        },
      ],
    },
    {
      key: 2,
      title: 'Удобные условия',
      icon: <SpIcon2 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Фиксированная ставка дохода',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value:
            'В договоре заранее прописана гарантированная и повышенная ставка дохода, которую получит клиент',
        },
        {
          key: 3,
          type: contentTypes.title,
          value: 'Фиксированный срок',
        },
        {
          key: 4,
          type: contentTypes.paragraph,
          value:
            'Приобретайте структурный продукт на 3 месяца, 2, 3 или 5 лет в зависимости от ваших целей',
        },
      ],
    },
    {
      key: 3,
      title: 'Высокий доход',
      icon: <SpIcon3 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Гарантированный доход',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value:
            'Получайте гарантированный или повышенный доход, выше чем по вкладу',
        },
      ],
    },
  ],
  trustManagement: [
    {
      key: 1,
      title: 'Прозрачность',
      icon: <TrustManagementIcon1 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Индивидуальный подход',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value: 'Выбор подходящей стратегии доверительного управления',
        },
        {
          key: 3,
          type: contentTypes.title,
          value: 'Доступность',
        },
        {
          key: 4,
          type: contentTypes.paragraph,
          value:
            'Вы можете посмотреть в какие инструменты инвестируются ваши средства, и ежеквартально получать детальный отчёт',
        },
      ],
    },
    {
      key: 2,
      title: 'Возможности',
      icon: <TrustManagementIcon2 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Гибкое управление',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value: 'Пополнение и вывод средств без потери накопленного дохода',
        },
        {
          key: 3,
          type: contentTypes.title,
          value: 'Выгодные вложения',
        },
        {
          key: 4,
          type: contentTypes.paragraph,
          value:
            'Стратегии доверительного управления приносят доход рублях или в долларах',
        },
      ],
    },
    {
      key: 3,
      title: 'Надёжность',
      icon: <TrustManagementIcon3 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Защита вложенных средств',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value:
            'Действия управляющего стратегией доверительного управления регулируется Гражданским кодексом РФ и Центральным банком. Вся информация о владельцах ценных бумаг хранится в специальном депозитарии.',
        },
        {
          key: 3,
          type: contentTypes.paragraph,
          value:
            'Средства клиентов учитываются отдельно от средств управляющей компании',
        },
        {
          key: 4,
          type: contentTypes.title,
          value: 'Регулирование стратегий',
        },
        {
          key: 5,
          type: contentTypes.paragraph,
          value:
            'Состав и структура стратегии доверительного управления прописана в регламенте выбранной стратегии доверительного управления. За надлежащим управлением стратегией следит Центральный Банк РФ',
        },
      ],
    },
  ],
  iia: [
    {
      key: 1,
      title: 'Доступно',
      icon: <IiaIcon1 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Просто начать',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value:
            'Пользоваться ИИС так же просто, как и банковским вкладом, нужно только открыть и пополнить счёт',
        },
        {
          key: 3,
          type: contentTypes.title,
          value: 'Удобное пополнение',
        },
        {
          key: 4,
          type: contentTypes.paragraph,
          value:
            'Пополнять счёт можно в течение всего срока, частями или всю сумму сразу',
        },
      ],
    },
    {
      key: 2,
      title: 'Надёжно',
      icon: <IiaIcon2 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Государственная поддержка',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value:
            'ИИС запущен по программе Министерства финансов РФ в 2015 году, выплаты по ИИС производятся Федеральной налоговой службой',
        },
        {
          key: 3,
          type: contentTypes.title,
          value: 'Под надзором Центрального Банка',
        },
        {
          key: 4,
          type: contentTypes.paragraph,
          value:
            'Fins - сервис с банковской и брокерской лицензией ЦБ. Деятельность регулируется ФЗ "О рынке ценных бумаг" и "О защите прав и законных интересов инвесторов на рынке ценных бумаг"',
        },
      ],
    },
    {
      key: 3,
      title: 'Удобный счёт',
      icon: <IiaIcon3 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Начните с любой суммы',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value:
            'Пополнить счёт можно на любую желаемую сумму до 1 млн рублей. Для получения максимального налогового  вычета в 52 000 рублей нужно пополнить счёт на 400 000 рублей за год',
        },
        {
          key: 3,
          type: contentTypes.title,
          value: 'Закройте в любой момент',
        },
        {
          key: 4,
          type: contentTypes.paragraph,
          value:
            'Если вам срочно понадобятся деньги, их можно вернуть, досрочно закрыв ИИС. Мы поможем в этой ситуации. В случае досрочного закрытия ранее полученные вычеты придется вернуть',
        },
      ],
    },
  ],
  qualified: [
    {
      key: 1,
      title: 'Неограниченные возможности',
      icon: <QualifiedIcon1 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Доступ к продуктам',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value:
            'Приобретайте структурные продукты со 100% защитой вложенных средств',
        },
        {
          key: 3,
          type: contentTypes.title,
          value: 'Валютные стратегии',
        },
        {
          key: 4,
          type: contentTypes.paragraph,
          value: 'Получите доступ к торговле на крупных иностранных рынках',
        },
      ],
    },
    {
      key: 2,
      title: 'Высокий доход',
      icon: <QualifiedIcon2 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Размер активов',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value:
            'Сумма денежных средств или ценных бумаг и ПФИ на сумму не менее 6 млн рублей',
        },
        {
          key: 3,
          type: contentTypes.title,
          value: 'Объём торговых оборотов',
        },
        {
          key: 4,
          type: contentTypes.paragraph,
          value:
            'Сделки с ценными бумагами и ПФИ на сумму не менее 6 млн рублей',
        },
      ],
    },
    {
      key: 3,
      title: 'Критерии для получения статуса',
      icon: <QualifiedIcon3 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Образование',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value:
            'Экономическое образование в ВУЗе, квалификационный аттестат или международный сертификат',
        },
        {
          key: 3,
          type: contentTypes.title,
          value: 'Опыт работы',
        },
        {
          key: 4,
          type: contentTypes.paragraph,
          value:
            'Нужен опыт работы (от 2-х/3-х лет) в организации, которая заключала сделки с ценными бумагами и (или) ПФИ',
        },
      ],
    },
  ],
  partners: [
    {
      key: 1,
      title: 'Неограниченные возможности',
      icon: <QualifiedIcon1 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Доступ к продуктам',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value:
            'Приобретайте структурные продукты со 100% защитой вложенных средств',
        },
        {
          key: 3,
          type: contentTypes.title,
          value: 'Валютные стратегии',
        },
        {
          key: 4,
          type: contentTypes.paragraph,
          value: 'Получите доступ к торговле на крупных иностранных рынках',
        },
      ],
    },
    {
      key: 2,
      title: 'Высокий доход',
      icon: <QualifiedIcon2 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Размер активов',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value:
            'Сумма денежных средств или ценных бумаг и ПФИ на сумму не менее 6 млн рублей',
        },
        {
          key: 3,
          type: contentTypes.title,
          value: 'Объём торговых оборотов',
        },
        {
          key: 4,
          type: contentTypes.paragraph,
          value:
            'Сделки с ценными бумагами и ПФИ на сумму не менее 6 млн рублей',
        },
      ],
    },
    {
      key: 3,
      title: 'Критерии для получения статуса',
      icon: <QualifiedIcon3 />,
      content: [
        {
          key: 1,
          type: contentTypes.title,
          value: 'Образование',
        },
        {
          key: 2,
          type: contentTypes.paragraph,
          value:
            'Экономическое образование в ВУЗе, квалификационный аттестат или международный сертификат',
        },
        {
          key: 3,
          type: contentTypes.title,
          value: 'Опыт работы',
        },
        {
          key: 4,
          type: contentTypes.paragraph,
          value:
            'Нужен опыт работы (от 2-х/3-х лет) в организации, которая заключала сделки с ценными бумагами и (или) ПФИ',
        },
      ],
    },
  ],
}

import React from 'react'
import { Row, Col, Button, Tabs } from 'antd'
import { useRouteMatch } from 'react-router-dom'
import { push } from 'connected-react-router'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { PRODUCTS } from '../../constants/PRODUCTS'
import './index.less'

const ProductHeader = ({ type }) => {
  const dispatch = useDispatch()
  const { url } = useRouteMatch()
  const { currentProductPath } = useSelector((state) => state.global)

  const handleButton = React.useCallback(() => {
    dispatch(push(`${currentProductPath}/authorization`))
  }, [dispatch, currentProductPath])

  const product = PRODUCTS[type]
  return (
    <div className={classNames('product_header', `product_header__${type}`)}>
      <Row
        align="middle"
        style={{ backgroundImage: `url(${product?.productImage})` }}
        className="product_header__main"
      >
        <Col lg={14} sm={24}>
          <h1>{product?.title}</h1>
          <p>{product?.desc}</p>
          <Button block onClick={handleButton} type="primary">
            {product?.buttonText}
          </Button>
        </Col>
      </Row>
      {product.tabs ? (
        <Tabs centered className="product_header__tabs">
          {product.tabs.map((tab) => (
            <Tabs.TabPane tab={tab.name} key={tab.key}>
              <Row className="product_header__product product_header__product--tabs">
                <Col lg={9} md={9} className="product_header__product-img">
                  <img src={tab?.productInfoImage} alt="" />
                </Col>
                <Col
                  className="product_header__product-info"
                  lg={15}
                  md={15}
                  sm={24}
                >
                  <h2>{tab?.titleInfo}</h2>
                  <p>{tab?.descInfo}</p>
                </Col>
              </Row>
            </Tabs.TabPane>
          ))}
        </Tabs>
      ) : (
        <Row className="product_header__product">
          <Col lg={9} md={9} className="product_header__product-img">
            <img src={product?.productInfoImage} alt="" />
          </Col>
          <Col className="product_header__product-info" lg={15} md={15} sm={24}>
            <h2>{product?.titleInfo}</h2>
            <p>{product?.descInfo}</p>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default ProductHeader

import React from 'react'
import { useDispatch } from 'react-redux'
import { SET_CURRENT_PRODUCT_PATH } from 'reducers/global'
import { TESTING_PAGE_SECTIONS } from 'constants/TESTING_PAGE'
import { TestingPageSectionSelector } from 'components/TestingPageSectionSelector'
import { Header } from './components/Header'
import './index.less'

export const Testing = ({ match }) => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(SET_CURRENT_PRODUCT_PATH(match.url))
    if (typeof window !== 'undefined') window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header />
      <div className="testing">
        <div className="testing_content__main">
          <div className="testing_selector__wrap">
            <TestingPageSectionSelector visible />
          </div>
          <h1 style={{ marginBottom: '16px' }}>
            Тестирование неквалифицированных инвесторов
          </h1>
          <p>
            С 1 октября 2021 года Федеральным законом №39-ФЗ «О рынке ценных
            бумаг» введены ограничения по ряду финансовых инструментов и сделок.
            Для их совершения необходимо пройти тестирование. Предлагаем вам
            ознакомиться с подробными статьями, чтобы узнать больше о финансовых
            инструментах и повысить вероятность на успешное прохождение
            тестирования.
          </p>
          <div className="testing_divider" />
        </div>
        <div className="testing_content">
          {TESTING_PAGE_SECTIONS.map((section) => section.component)}
        </div>
      </div>
    </>
  )
}

import React from 'react'

export const MailIcon = () => {
  return (
    <svg
      width="50"
      height="44"
      viewBox="0 0 50 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.95 0H47.05C47.6998 0 48.3229 0.257539 48.7824 0.715961C49.2419 1.17438 49.5 1.79614 49.5 2.44444V41.5556C49.5 42.2039 49.2419 42.8256 48.7824 43.284C48.3229 43.7425 47.6998 44 47.05 44H2.95C2.30022 44 1.67705 43.7425 1.21759 43.284C0.758124 42.8256 0.5 42.2039 0.5 41.5556V2.44444C0.5 1.79614 0.758124 1.17438 1.21759 0.715961C1.67705 0.257539 2.30022 0 2.95 0ZM44.6 10.3596L25.1764 27.7151L5.4 10.3058V39.1111H44.6V10.3596ZM6.65195 4.88889L25.1495 21.1738L43.3799 4.88889H6.65195Z"
        fill="#42309E"
      />
    </svg>
  )
}

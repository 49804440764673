import { validateRegexpField, validateRequiredField } from './common'

const phoneNumberRegexp = new RegExp(/\+7\([9]\d{2}\)\s\d{3}-\d{2}-\d{2}/)

const minNameLength = 2
const maxNameLength = 30
const nameRegexp = new RegExp(
  /^[а-яА-ЯёЁ]([а-яА-ЯёЁ]|[-'\s]+(?=[а-яА-ЯёЁ]))[а-яА-ЯёЁ\s-']*$/,
)
const emailRegexp = new RegExp(
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s?$/,
)

export const phoneNumberValidation = (value) => {
  switch (true) {
    case validateRegexpField(value, phoneNumberRegexp):
      return Promise.resolve()
    default:
      return Promise.reject(
        new Error('Телефон должен быть в формате +7 (911) 111-11-11'),
      )
  }
}

export const passwordRules = {
  OnlyLatinSymbols: 'Только латиница',
  MinimumEightSymbols: 'Минимум 8 символов',
  ShouldBeUpperCaseLetters: 'Должны быть заглавные буквы',
  ShouldBeLowerCaseLetters: 'Должны быть прописные буквы',
  ShouldBeDigits: 'Должны быть цифры',
}

export const passwordValidationMessages = (value) => {
  const messages = []
  if (/[а-яА-ЯёЁ]+/i.test(value)) messages.push(passwordRules.OnlyLatinSymbols)
  if ((value || '').length < 8) messages.push(passwordRules.MinimumEightSymbols)
  if ((value || '').split('').filter((q) => /[A-Z]+/.test(q)).length === 0) {
    messages.push(passwordRules.ShouldBeUpperCaseLetters)
  }
  if ((value || '').split('').filter((q) => /[a-z]+/.test(q)).length === 0) {
    messages.push(passwordRules.ShouldBeLowerCaseLetters)
  }
  if (!/\d+/.test(value)) messages.push(passwordRules.ShouldBeDigits)

  return messages
}

export const passwordValidation = (value) => {
  const messages = passwordValidationMessages(value)

  if (messages.length > 0) {
    return Promise.reject(new Error(messages[0]))
  }
  return Promise.resolve()
}

export const nameValidation = (value) => {
  if (value.length < minNameLength) {
    return Promise.reject(
      new Error(`Минимальная длина составляет ${minNameLength} символа`),
    )
  }

  if (value.length > maxNameLength) {
    return Promise.reject(
      new Error(`Максимальная длина составляет ${maxNameLength} символов`),
    )
  }

  if (!nameRegexp.test(value)) {
    return Promise.reject(new Error('Только русские буквы'))
  }

  return Promise.resolve()
}

export const emailValidation = (value) => {
  if (!emailRegexp.test(value)) {
    return Promise.reject(
      new Error('Неверно введен email. Пример: example@gmail.com'),
    )
  }
  return Promise.resolve()
}

export const isPasswordValid = (value, confirmPasswordValue) => {
  return validateRequiredField(value, passwordValidation, confirmPasswordValue)
}

export const isPhoneNumberValid = (value) => {
  return validateRequiredField(value, phoneNumberValidation)
}

export const isNameValid = (value) => {
  return validateRequiredField(value, nameValidation)
}

export const isEmailValid = (value) => {
  return validateRequiredField(value, emailValidation)
}

import React from 'react'
import { Authorization } from '../pages/Authorization'
import { AuthorizationStatusInfo } from '../pages/AuthorizationStatusInfo'
import { Otp } from '../pages/Otp'
import { Password } from '../pages/Password'

export const authorization = {
  '/authorization': {
    component: (props) => <Authorization {...props} />,
    exact: true,
  },
  '/authorization/otp': {
    component: (props) => <Otp {...props} />,
    exact: true,
  },
  '/authorization/password': {
    component: (props) => <Password {...props} />,
    exact: true,
  },
  '/authorization/status': {
    component: (props) => <AuthorizationStatusInfo {...props} />,
    exact: true,
  },
  '/authorization/otp-failed': {
    component: (props) => (
      <AuthorizationStatusInfo {...props} status="OTP_FAILED" />
    ),
    exact: true,
  },
}

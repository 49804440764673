import { request } from '../request'

export const requestGetAuth = async (payload) => {
  return request('/gateway/api/security/v4/authentications', {
    method: 'POST',
    data: payload,
  })
}

export const requestRegister = async (payload) => {
  return request(
    `/gateway/api/security/v4/authentications/${payload.uuid}/register`,
    {
      method: 'POST',
      data: payload.data,
    },
  )
}

export const requestPasswordVerify = async (payload) => {
  return request(
    `/gateway/api/security/v4/authentications/${payload.uuid}/password`,
    {
      method: 'POST',
      data: payload,
    },
  )
}

export const requestCaptchaVerify = async (payload) => {
  return request(
    '/gateway/api/security/v4/authentications/{id}/captcha-verify',
    {
      method: 'POST',
      data: payload,
    },
  )
}

export const requestAuth = async (payload) => {
  return request(`/gateway/api/security/v4/authentications/${payload.uuid}`, {
    method: 'GET',
  })
}

export const requestSecretQuestions = async () => {
  return request('/gateway/api/security/v4/secret-questions', {
    method: 'GET',
  })
}

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'antd'
import { push } from 'connected-react-router'
import Cookies from 'js-cookie'

import { FinsDrawer } from '../../components/ui/FinsDrawer'
import './index.less'

export const CookieAgreement = ({ history }) => {
  const { isMobile, currentProductPath } = useSelector((state) => state.global)

  const dispatch = useDispatch()

  const handleCloseDrawer = React.useCallback(() => {
    Cookies.set('agreeCookie', 'true', { expires: 365, path: '' })
    dispatch(push(`${currentProductPath}/${history.location.search}`))
  }, [dispatch, currentProductPath, history.location.search])

  return (
    <FinsDrawer
      title="Согласие на обработку персональных данных для посетителей сайта"
      placement="right"
      width={isMobile ? '100%' : '439px'}
      bodyStyle={{ padding: '32px' }}
      isMobile={isMobile}
      routePathAfterClose={`/${history.location.search}`}
    >
      <div className="cookie-agreement__content-text">
        Продолжая работу на интернет-сайте (https://bcs-bank.com) я выражаю свое
        согласие АО «БКС Банк» (ОГРН 1055400000369, местонахождение: Российская
        Федерация, 129110, г. Москва, проспект Мира, д. 69, стр. 1) на
        автоматизированную обработку моих персональных данных (файлы cookie,
        сведения о действиях пользователя на сайте, сведения об оборудовании
        пользователя, дата и время сессии) в целях улучшения работы сайта,
        совершенствования продуктов и услуг АО «БКС Банк», определения
        предпочтений пользователя, предоставления целевой информации по
        продуктам и услугам АО «БКС Банк». Настоящее согласие действует с
        момента его предоставления и в течение всего периода использования
        сайта. В случае отказа от обработки вышеуказанных персональных данных я
        проинформирован о необходимости прекратить использование сайта или
        отключить файлы cookie в настройках браузера.
      </div>

      <Button block type="primary" onClick={handleCloseDrawer}>
        Принять
      </Button>
    </FinsDrawer>
  )
}

import React from 'react'
import { Slider } from 'antd'
import NumberFormat from 'react-number-format'

import './index.less'

const InputSlider = ({ min, max, defaultValue, handlerChange, isMobile }) => {
  const [amount, setAmount] = React.useState(defaultValue)

  const onChange = (value) => {
    setAmount(value > max ? max : value < min ? min : value)
  }

  const onValueChange = ({ value }) => {
    if (!isMobile) {
      setAmount(value)
    }
  }

  const onBlurHandler = (e, v) => {
    const value = Number(e.target.value.replace(/[ ₽]/g, ''))
    setAmount(value > max ? max : value < min ? min : value)
  }

  React.useEffect(() => setAmount(defaultValue), [defaultValue])

  React.useEffect(() => handlerChange(amount), [amount])
  return (
    <div className="input-slider">
      <div className="input-slider__input-wrap">
        <NumberFormat
          suffix=" ₽"
          id="input-slider"
          onValueChange={onValueChange}
          onBlur={onBlurHandler}
          maxLength={10}
          value={amount}
          thousandSeparator={' '}
          decimalSeparator="."
          decimalScale={2}
          className="input-slider__input"
          allowedDecimalSeparators={['.', ',']}
        />
        <label htmlFor="input-slider" className="input-slider__input-label">
          Я вложу
        </label>
        <Slider
          min={min}
          max={max}
          onChange={onChange}
          step={1000}
          value={amount}
          tooltipVisible={false}
        />
      </div>
    </div>
  )
}

export { InputSlider }

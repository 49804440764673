import React from 'react'
import NumberFormat from 'react-number-format'
import classNames from 'classnames'

import { CurrencyMapping } from 'constants/TRADING'

import '../index.less'

export const IncomeDetailsMobile = ({
  selectedProduct,
  contentBg,
  iWillGet,
  productIncome,
  iisIncome,
  loading,
}) => {
  return (
    selectedProduct && (
      <div className="calclulate-income__result-mobile">
        <div
          className={classNames('calclulate-income__result-mobile-total', {
            'calclulate-income__result-mobile-total--bg': contentBg,
          })}
        >
          <div className="calclulate-income__result-mobile-total__title">
            Я получу
          </div>

          <div className="calclulate-income__result-mobile-total__value">
            {!loading && selectedProduct?.product?.currency && (
              <NumberFormat
                suffix={` ${
                  CurrencyMapping[selectedProduct?.product?.currency]?.code
                }`}
                thousandSeparator={' '}
                decimalSeparator=","
                value={iWillGet}
                displayType="text"
              />
            )}
          </div>
        </div>
        <div className="calclulate-income__result-mobile__include">
          <div
            className={classNames(
              'calclulate-income__result-mobile__include-item',
              {
                'calclulate-income__result-mobile__include-item--bg': contentBg,
              },
            )}
          >
            Доход от продукта
            <div className="calclulate-income__result-mobile__include-item__amount green">
              {!loading && selectedProduct?.product?.currency && (
                <NumberFormat
                  suffix={` ${
                    CurrencyMapping[selectedProduct?.product?.currency]?.code
                  }`}
                  thousandSeparator={' '}
                  decimalSeparator=","
                  value={productIncome}
                  displayType="text"
                />
              )}
            </div>
          </div>
          {selectedProduct.checkedSwitch && (
            <div
              className={classNames(
                'calclulate-income__result-mobile__include-item',
                {
                  'calclulate-income__result-mobile__include-item--bg':
                    contentBg,
                },
              )}
            >
              13% от пополнения ИИС
              <div className="calclulate-income__result-mobile__include-item__amount green">
                {!loading && selectedProduct?.product?.currency && (
                  <NumberFormat
                    suffix={` ${
                      CurrencyMapping[selectedProduct?.product?.currency]?.code
                    }`}
                    thousandSeparator={' '}
                    decimalSeparator=","
                    value={iisIncome}
                    displayType="text"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  )
}

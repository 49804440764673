import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_PASSWORD_VERIFY } from '../../actions/constants/auth'
import { savePasswordData, setAuthLoading } from '../../reducers/auth'
import { requestPasswordVerify } from '../../api/v4/auth'

function* fetchPasswordVerify(action) {
  try {
    yield put(setAuthLoading(true))
    const passwordData = yield call(requestPasswordVerify, action.payload)

    yield put(savePasswordData(passwordData))
    yield put(setAuthLoading(false))
  } catch (e) {
    yield put(setAuthLoading(false))
    yield put(savePasswordData(e.message))
  }
}

function* passwordVerifySaga() {
  yield takeEvery(FETCH_PASSWORD_VERIFY, fetchPasswordVerify)
}

export default passwordVerifySaga

import { head } from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'
import { FinsIcon } from '../FinsIcon'

import icon from './assets/fins.svg'
import './index.less'

export const UserWelcomeBlock = () => {
  const { authenticationData } = useSelector((state) => state.auth)

  return (
    <div className="user-welcome-block">
      <FinsIcon icon={icon} width="45" height="45" />
      <div className="user-welcome-block__title">
        Здравствуйте, {authenticationData && authenticationData.firstName}
      </div>
    </div>
  )
}

import React from 'react'

import '../index.less'

export const Section3 = ({ id }) => {
  return (
    <>
      <h1 id={id}>Как инвестировать в облигации</h1>
      <p>
        Статья будет полезна при приобретении облигаций российских эмитентов,
        которым не присвоен кредитный рейтинг либо кредитный рейтинг которых
        ниже уровня, установленного Банком России.
      </p>
      <h2>Что такое облигация</h2>
      <p>
        Облигация — это долговая ценная бумага, которая в основном выпускается
        на ограниченный срок.
      </p>
      <p> В сделке присутствуют две стороны:</p>
      <p>
        <b>Эмитент</b> — структура, выпустившая эти ценные бумаги для
        привлечения дополнительного капитала. Эмитент обязан возвратить
        держателю облигации занятую у него сумму и выплатить определенный
        процент (купон), если это предусмотрено проспектом эмиссии.
      </p>
      <p>
        <b>Инвестор</b> — покупая облигации эмитента, по сути предоставляет
        собственные средства во временное пользование и получает за это доход.
      </p>
      <p>
        Инвестор выбирает комфортный для его инвестиционных целей срок обращения
        облигаций (краткосрочные, среднесрочные, долгосрочные). Конкретные
        условия, а именно номинальная стоимость бумаги, периодичность процентных
        перечислений (купон) и сроки, определяются во время эмиссии.
      </p>
      <h2>Как работает облигация</h2>
      <p>
        Облигация — это долговая ценная бумага, которая в основном выпускается
        на ограниченный срок.
      </p>
      <p>
        Для совершения операций с облигациями можно открыть брокерский счет и
        совершить сделку.
      </p>
      <p>По типу получения дохода облигации бывают процентные и дисконтные.</p>
      <p>
        Наиболее распространенный тип облигаций, обращающихся на российских
        биржах, процентные облигации — эмитент выплачивает периодический процент
        (купон) в течение срока обращения облигации. Купоны могут выплачиваются
        ежегодно, ежеквартально или ежемесячно в зависимости от условий бумаги.
      </p>
      <p>Процентные облигации могут быть:</p>
      <div>
        <div className="testing_box">
          С фиксированным купоном — определенный процент от номинальной
          стоимости выплачивается через установленные заранее промежутки
          времени. Процентная ставка по облигации известна с момента выпуска и,
          как правило, одинакова на весь срок обращения бумаги. Для такой бумаги
          можно составить график купонных выплат с точными суммами и однозначно
          рассчитать доходность к погашению.
        </div>
        <div className="testing_box">
          С переменным купоном — купон фиксируется до даты оферты, после которой
          процентная ставка меняется в зависимости от конъюнктуры рынка. До
          оферты новая процентная ставка неизвестна. Такой механизм позволяет
          эмитенту снизить процентный риск, особенно если выпуск размещается
          в период высоких процентных ставок с перспективой их понижения. А у
          инвестора появляется возможность досрочно погасить эти облигации
          по оферте. В промежутках между офертами такие бумаги ничем не
          отличаются от облигаций с фиксированным купоном с той лишь разницей,
          что доходность корректно считать не к дате погашения, а к дате
          ближайшей оферты.
        </div>
        <div className="testing_box">
          С плавающим купоном — процентная ставка привязана к изменению
          какого-либо индикативного финансового инструмента. Например, к
          ключевой ставке Банка России, индексу потребительских цен, ставке
          RUONIA, курсу доллара и др. Как правило, рассчитать размер купона
          по таким бумагам можно не дальше, чем на один купонный период. Формула
          расчета публикуется эмитентом и доступна всем инвесторам. Недостаток —
          будущую доходность можно лишь прогнозировать. Преимущество — позволяет
          застраховаться, например, от внезапного изменения рыночных процентных
          ставок.
        </div>
      </div>
      <p>
        Дисконтные (бескупонные) облигации — выплата купона не предусмотрена.
        Изначально размещаются ниже номинальной стоимости. Доход инвестора
        составляет разница между ценой покупки бумаги и ценой погашения/продажи.
      </p>
      <h2>Классификация по типу погашения номинала</h2>
      <div>
        <div className="testing_box">
          Погашение номинала полностью в конце срока. По умолчанию сумма
          номинала выплачивается инвестору в полном размере в дату погашения
          облигации. Такой тип погашения является самым распространенным среди
          бумаг на Московской бирже.
        </div>
        <div className="testing_box">
          Облигации с индексируемым номиналом. Иногда для облигации плавающим
          является не купон, а номинальная стоимость бумаги. Такой вид ОФЗ имеет
          преимущества в моменты высокой инфляции или как страховка от будущего
          ускорения роста цен.
        </div>
        <div className="testing_box">
          Облигации с амортизируемым долгом. Для большинства облигаций,
          обращающихся на Московской бирже, сумма номинала выплачивается
          инвестору в полном размере в дату погашения. Однако эмитенту может
          быть неудобна такая форма заимствований, при которой к дате погашения
          придется аккумулировать крупную сумму денег для погашения выпуска.
          Тогда он выпускает облигацию с амортизируемым долгом, номинал которой
          погашается частями вместе с купонными выплатами. Эмитенту это
          позволяет распределить выплату долга равномерно на весь период
          обращения. Часто такие бумаги встречаются среди муниципальных
          облигаций. Инвестору такой тип погашения менее выгоден: после
          получения части номинала следующие купоны начисляются на остаток
          стоимости бумаги, за счет чего суммарный доход получается меньше.
          Однако этот тип облигаций может быть удобен в периоды низких
          процентных ставок, когда ожидается их повышение. Тогда возвращенную
          часть номинала можно будет реинвестировать под более высокий процент.
        </div>
      </div>
      <h2>Виды рисков</h2>
      <p>
        Облигации не идентичны по уровню кредитного риска в зависимости от типа
        эмитента. На рынке обращаются государственные, субфедеральные, 
        муниципальные, корпоративные облигации.
      </p>
      <div>
        <div className="testing_box">
          <b>Государственные облигации</b> — эмитентом выступает государство в
          лице Министерства финансов РФ. Самые распространенные бумаги этой
          категории — это облигации федерального займа (ОФЗ): обычно обладают
          относительно высокой ликвидностью, широким выбором сроков
          инвестирования, низкой вероятностью дефолта эмитента. Из недостатков
          можно отметить невысокую доходность, которая является компенсацией
          за низкий кредитный риск.
        </div>
        <div className="testing_box">
          <b>Корпоративные облигации</b> — это долговые бумаги отдельных
          компаний. Их отличает большая, чем по ОФЗ, доходность. Часто купон
          по ним является переменным.
        </div>
        <div className="testing_box">
          <b>Субфедеральные и муниципальные облигации.</b> Эмитент — субъекты РФ
          (субфедеральные) и муниципальные образования (муниципальные). Рынок
          этих облигаций небольшой, что сказывается и на ликвидности. При этом
          доходность у бумаг немного выше за счет более низкой надежности.
          Покупать облигации регионов из-за более высокой доходности и считать
          их при этом равными по надежности государственным некорректно.
        </div>
      </div>
      <p>
        Цены облигаций могут изменяться в зависимости от макроэкономической
        ситуации и событий, влияющих на деятельность компании-эмитента.
        Инвестору следует помнить о возможных рисках при инвестировании:
      </p>
      <div>
        <div className="testing_box">
          <b>Риск дефолта.</b> Фиксируется в случае, когда эмитент не в
          состоянии выполнить финансовые обязательства по ценным бумагам и
          объявляет себя банкротом. В этом случае инвестор может потерять
          вложенные деньги: в отличие от депозитов они не защищены системой
          страхования вкладов.
        </div>
        <div className="testing_box">
          <b>Инфляционный риск.</b> Прибыль по ценным бумагам и фактический
          доход стоит корректировать на размер инфляции. При неблагоприятной
          экономической ситуации существует риск получения отрицательной
          реальной доходности, когда получаемый процент не покрывает инфляцию.
          Долгосрочные инвестиции больше подвержены инфляции, нежели
          краткосрочные.
        </div>
        <div className="testing_box">
          <b>Процентный риск.</b> Наблюдается при росте среднерыночной ставки по
          облигациям со схожими условиями. Облигации с процентом ниже рыночного
          снижают доход инвестора. На этот вид риска, как правило, влияет
          ключевая ставка ЦБ РФ. Чем длиннее облигация по сроку, тем больше
          ее цена зависит от изменения процентных ставок.
        </div>
        <div className="testing_box">
          <b>Риск реинвестирования.</b> Предполагает реинвестирование полученных
          доходов (купонов) по сниженной процентной ставке по сравнению с
          приобретенной. Этот риск проявляется при падении процентных ставок на
          протяжении длительного периода.
        </div>
        <div className="testing_box">
          <p>
            <b>Валютный риск. </b>Инвестирование может проходить как в
            российском рубле, так и в евро или долларах. Вложение в иностранные
            активы сильно зависит от колебаний курса валют.
          </p>
          <p>
            По законодательству все расчеты на территории РФ осуществляются
            в рублях. Подавляющее большинство облигаций, обращающихся
            на внутреннем рынке и доступных для частного инвестора, номинированы
            в национальной валюте. Торги проходят главным образом на Московской
            бирже.
          </p>
          Облигации, номинированные в иностранной валюте (в основном в евро
          и долларах США), называются еврооблигациями. Основные объемы торгов
          еврооблигаций проходят на внебиржевом рынке, некоторые бумаги доступны
          и на Московской бирже.
        </div>
        <div className="testing_box">
          <p>
            <b> Кредитный риск.</b> Важным показателем при анализе облигаций
            является кредитный рейтинг. Снижение платежеспособности эмитента
            отражается на его репутации на рынке ценных бумаг и цене облигации.
          </p>
          <p>
            <b>Кредитный рейтинг</b> — это оценка эмитента на основе
            комплексного анализа компании, учитывающего финансовое состояние,
            качество корпоративного управления и уровень бизнес-рисков.
            Агентство формирует мнение о кредитоспособности компании в целом или
            относительно ее конкретных обязательств. По сути, присваивая
            рейтинг, оценивается способность эмитента облигаций выполнять взятые
            на себя финансовые обязательства по этим облигациям, то есть
            погасить их вовремя и выплатить по ним доход.
          </p>
          <p>
            Разные выпуски облигаций могут иметь разный рейтинг, даже у одной
            и той же компании. А отсутствие кредитного рейтинга выпуска означает
            отсутствие мнения кредитного рейтингового агентства о кредитном
            риске данного выпуска облигаций.
          </p>
          <p>
            Рейтинговая шкала — это система рейтинговых категорий, отражающая
            мнение Агентства о вероятности дефолта. Выражаются в буквах и
            символах, от самого высокого уровня надежности (например ААА) до
            самого низкого (ССС).  Единой общемировой или даже общероссийской
            системы рейтингов не существует. Однако кредитные рейтинги разных
            агентств, особенно высокие, обычно сопоставимы друг с другом.
          </p>
          <p>
            Вместе с рейтингом агентства публикуют прогноз по нему: например,
            «стабильный», «позитивный» или «негативный». Он показывает,
            ожидают ли аналитики, что финансовое положение организации
            в ближайший год изменится и в какую сторону.
          </p>
          Агентство пересматривает рейтинг той или иной компании на регулярной
          основе.
        </div>
      </div>
      <h2>Ликвидность облигаций</h2>
      <p>
        <b>Ликвидность</b> — это способность актива быстро и без потерь в
        стоимости быть купленным или проданным по рыночной цене. При выборе
        облигации инвестору стоит обращать внимание на показатель ликвидности.
      </p>
      <p>
        Риск ликвидности заключается в вероятности не продать облигации раньше
        окончания срока действия по справедливой цене. Особенно, если инвестор
        принимает решение срочно продать принадлежащие ему облигации —
        покупателя может просто не найтись. Этот риск возможен при низкой
        известности эмитента и небольшом количестве сделок с его долговыми
        ценными бумагами.
      </p>
      <p>Облигации можно подразделить на:</p>
      <div>
        <div className="testing_box">
          <b>Высоколиквидные облигации</b> — это облигации, которые можно
          продать без снижения их цены;
        </div>
        <div className="testing_box">
          <b>Низколиквидные и неликвидные</b> — маловероятно продать за короткий
          промежуток времени, не снизив их стоимость.
        </div>
      </div>
      <p>На что еще стоит обращать внимание при оценке ликвидности:</p>
      <div>
        <div className="testing_box">
          <b>Среднедневной оборот торгов</b> — чем больше сделок по облигации
          проходит в течение дня, тем больший объем бумаг может реализовать
          инвестор в короткие сроки.
        </div>
        <div className="testing_box">
          <b>Оборот, включающий в себя только крупные сделки</b> — согласно
          некоторым теориям, оборот отдельно по категориям сделок может
          оказаться более показательным для оценки ликвидности, чем значение
          совокупного оборота.
        </div>
        <div className="testing_box">
          <b>Количество дней без сделок</b> — чем больше число таких дней, тем
          менее ликвидна облигация
        </div>
        <div className="testing_box">
          <b>Объем выпуска</b> — чем больше бумаг находится в обращении, тем
          больше вероятность реализовать необходимый объем через торги на бирже.
        </div>
        <div className="testing_box">
          <b>Кредитный риск</b> — зачастую более рискованные облигации
          высокодоходными в большей степени подвержены спекуляциям,
          соответственно, активность торгов по ним выше и выше ликвидность.
        </div>
        <div className="testing_box">
          <b>Волатильность</b> — повышенная волатильность также может позитивно
          повлиять на оборот торгов и ликвидность облигации.
        </div>
        <div className="testing_box">
          <b>Публичность эмитента</b> — чем больше компания раскрывает
          информации о своей деятельности, тем более привлекательными являются
          ее ценные бумаги.
        </div>
        <div className="testing_box">
          <b>Встроенные в облигацию опции</b> — такие особенности, как
          конвертируемость, наличие оферт put/call и другие опции также могут
          повлиять на ликвидность облигации.
        </div>
      </div>
      <h2>Какие налоги платит владелец облигаций</h2>
      <p>
        Налогообложение операций с ценными бумагами регламентируется статьей
        214.1 части 2 Налогового Кодекса РФ. Ставка налога на доходы физических
        лиц резидентов РФ составляет 13%.
      </p>
      <p>
        C 2021 г. купоны по всем облигациям без исключения, включая ОФЗ,
        муниципальные и корпоративные, независимо от даты выпуска и величины
        купонной ставки входят в налоговую базу и облагаются налогом по ставке
        13% (15% при превышении совокупного дохода 5 млн руб.). Налоговым
        агентом выступает брокер. 
      </p>
      <div className="testing_divider" />
    </>
  )
}

import React from 'react'
import { useSelector } from 'react-redux'
import MobileTabs from '../../components/MobileTabs'
import DesktopTabs from '../../components/DesktopTabs'
import { PRODUCT_TABS } from '../../constants/PRODUCT_TABS'

const ProductTabs = ({ type = 'funds' }) => {
  const { isMobile } = useSelector((state) => state.global)

  const productTabs = React.useMemo(() => PRODUCT_TABS[type], [type])

  return (
    <div className="content">
      <div className="content__block">
        {isMobile ? (
          <MobileTabs tabs={productTabs} />
        ) : (
          <DesktopTabs tabs={productTabs} />
        )}
      </div>
    </div>
  )
}

export default ProductTabs

import React from 'react'

export const BlockIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 5C20.6701 5 5 20.67 5 40C5 59.3299 20.6701 75 40 75C59.33 75 75 59.3299 75 40C75 20.67 59.33 5 40 5ZM0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40C80 62.0913 62.0914 80 40 80C17.9086 80 0 62.0913 0 40Z"
        fill="#F09C1E"
      />
      <path
        d="M39.5 20.625C34.0908 20.625 29.6761 25.1233 29.6761 30.635V33.495H26.8693C25.3186 33.495 24.0625 34.7748 24.0625 36.355V53.515C24.0625 55.0951 25.3186 56.375 26.8693 56.375H52.1307C53.6814 56.375 54.9375 55.0951 54.9375 53.515V36.355C54.9375 34.7748 53.6814 33.495 52.1307 33.495H49.3239V30.635C49.3239 25.3034 45.165 21.009 39.9989 20.7283C39.8401 20.6632 39.6711 20.6282 39.5 20.625ZM39.5 23.485C43.3926 23.485 46.517 26.6687 46.517 30.635V33.495H32.483V30.635C32.483 26.6687 35.6074 23.485 39.5 23.485Z"
        fill="#F09C1E"
      />
    </svg>
  )
}

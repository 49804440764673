import React from 'react'

import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { head, find, propEq } from 'ramda'
import { Row, Col, Select, Button, Switch } from 'antd'
import { FETCH_TOP_PRODUCTS } from 'actions/constants/calc'
import { InputSlider } from 'components/ui/InputSlider'
import { SelectSuffixIcon } from 'components/ui/SelectSuffixIcon'
import { PRODUCT_TYPES, MAX_IIS_INCOME_FROM, PRODUCTS } from 'constants/TRADING'

import { incomeInEndOfTerm } from 'utils/product'

import { CustomOption } from './components/CustomOption'
import { TypeButton } from './components/TypeButton'
import { IncomeDetailsMobile } from './components/IncomeDetailsMobile'
import { IncomeDetailsDesktop } from './components/IncomeDetailsDesktop'

import './index.less'

const CalculateIncome = ({ activeProductType = false, contentBg = false }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { isMobile, currentProductPath } = useSelector((state) => state.global)
  const { products, loading } = useSelector((state) => state?.topProducts)

  const [selectedType, setSelectedType] = React.useState(PRODUCT_TYPES.BOND)
  const [iWillGet, setIWillGet] = React.useState(0)
  const [productIncome, setProductIncome] = React.useState(0)
  const [iisIncome, setIisIncome] = React.useState(0)
  const [currentProductList, setCurrentProductList] = React.useState(null)
  const [selectedProduct, setSelectedProduct] = React.useState({
    product: null,
    checkedSwitch: true,
  })
  const [currentAmount, setCurrentAmount] = React.useState(
    PRODUCTS[selectedType]?.defaultAmount || 0,
  )

  React.useEffect(() => {
    dispatch({ type: FETCH_TOP_PRODUCTS })
  }, [dispatch])

  React.useEffect(() => {
    if (products) {
      setCurrentProductList(products[selectedType])
      const product = head(products[selectedType])

      setSelectedProduct({
        product,
        checkedSwitch: !(
          selectedType === PRODUCT_TYPES.TRUST_MANAGEMENT &&
          product.isIncludesIndividualInvestmentAccount === false
        ),
      })
      if (
        currentAmount < product?.minAmount ||
        currentAmount > product?.maxAmount
      ) {
        setCurrentAmount(PRODUCTS[selectedType]?.defaultAmount)
      }
    }
  }, [activeProductType, selectedType, products])

  const calculateAmount = () => {
    const { product, checkedSwitch } = selectedProduct
    const iis = !checkedSwitch
      ? 0
      : currentAmount > MAX_IIS_INCOME_FROM
      ? 52000
      : Math.floor(Number(currentAmount * 0.13))

    let income = 0

    if (selectedType === PRODUCT_TYPES.TRUST_MANAGEMENT) {
      income = incomeInEndOfTerm(product, Number(currentAmount))
    }
    if (selectedType === PRODUCT_TYPES.FUND) {
      income = Math.floor(Number(currentAmount) * product?.yield)
    }
    if (selectedType === PRODUCT_TYPES.BOND) {
      income = incomeInEndOfTerm(product, Number(currentAmount))
    }
    if (selectedType === PRODUCT_TYPES.STRUCTURED_PRODUCT) {
      income = incomeInEndOfTerm(product, Number(currentAmount))
    }

    setIWillGet(
      Number(income) +
        Number(currentAmount) +
        (selectedType === PRODUCT_TYPES.TRUST_MANAGEMENT ? 0 : iis),
    )
    setProductIncome(income)
    setIisIncome(iis)
  }

  React.useEffect(() => {
    calculateAmount()
  }, [selectedProduct.product, selectedProduct.checkedSwitch, currentAmount])

  React.useEffect(() => {
    if (activeProductType) setSelectedType(activeProductType)
  }, [activeProductType])

  const onTypeSelect = (type) => () => {
    setSelectedType(type)
  }

  const onIisSwitchChange = () => {
    setSelectedProduct({
      ...selectedProduct,
      checkedSwitch: !selectedProduct.checkedSwitch,
    })
  }

  const onAmountChange = (amount) => {
    setCurrentAmount(amount)
  }

  const onSelectChange = (name) => {
    const product = find(propEq('name', name))(currentProductList)
    setSelectedProduct({
      product,
      checkedSwitch: !(
        selectedType === PRODUCT_TYPES.TRUST_MANAGEMENT &&
        product.isIncludesIndividualInvestmentAccount === false
      ),
    })
  }

  const onBuyClickHandler = React.useCallback(() => {
    history.push(`${currentProductPath}/authorization`)
  }, [history, currentProductPath])

  return (
    <div
      className={classNames('content', 'content--more-pd', 'content--radius', {
        'content--bg': contentBg,
      })}
    >
      <div className="content__block">
        <h3 className={`${isMobile ? 'sub_title' : 'title'}`}>
          Рассчитайте доходность
        </h3>
        <div className="calclulate-income">
          <div className="calclulate-income__product-types">
            <div className="calclulate-income__product-types-title">
              Тип продукта:
            </div>
            <TypeButton
              title="Доверительное управление"
              type={PRODUCT_TYPES.TRUST_MANAGEMENT}
              selectedType={selectedType}
              eventHandler={onTypeSelect}
            />
            <TypeButton
              title="ПИФ"
              type={PRODUCT_TYPES.FUND}
              selectedType={selectedType}
              eventHandler={onTypeSelect}
            />
            <TypeButton
              title="Облигации"
              type={PRODUCT_TYPES.BOND}
              selectedType={selectedType}
              eventHandler={onTypeSelect}
            />
            <TypeButton
              title="Структурные продукты"
              type={PRODUCT_TYPES.STRUCTURED_PRODUCT}
              selectedType={selectedType}
              eventHandler={onTypeSelect}
            />
          </div>

          <Row gutter={30} className="calclulate-income__form">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <div className="calclulate-income__input-slider">
                <InputSlider
                  handlerChange={onAmountChange}
                  min={selectedProduct.product?.minAmount}
                  max={selectedProduct.product?.maxAmount}
                  defaultValue={Math.max(
                    PRODUCTS[selectedType]?.defaultAmount,
                    selectedProduct.product?.minAmount,
                  )}
                  isMobile={isMobile}
                />
              </div>
              <Select
                value={selectedProduct.product?.name}
                dropdownClassName="custom-selector"
                className="calclulate-income__select"
                optionLabelProp="label"
                suffixIcon={({ open }) => <SelectSuffixIcon open={open} />}
                onChange={onSelectChange}
              >
                {currentProductList?.map((item) => (
                  <Select.Option
                    key={item.name}
                    value={item.name}
                    label={
                      <CustomOption
                        product={item}
                        selectedType={selectedType}
                      />
                    }
                  >
                    <CustomOption product={item} selectedType={selectedType} />
                  </Select.Option>
                ))}
              </Select>
              <div
                className={classNames(
                  'calclulate-income__switch',
                  {
                    'calclulate-income__switch--bg': contentBg,
                  },
                  {
                    'calclulate-income__switch--disabled':
                      selectedType === PRODUCT_TYPES.TRUST_MANAGEMENT,
                  },
                )}
              >
                <span className="calclulate-income__switch-title">
                  {isMobile
                    ? selectedType === PRODUCT_TYPES.TRUST_MANAGEMENT &&
                      !selectedProduct.product
                        ?.isIncludesIndividualInvestmentAccount
                      ? 'ИИС не предусмотрен стратегией'
                      : 'ИИС'
                    : 'ИИС'}
                </span>
                <Switch
                  checked={selectedProduct.checkedSwitch}
                  disabled={selectedType === PRODUCT_TYPES.TRUST_MANAGEMENT}
                  onChange={onIisSwitchChange}
                />
              </div>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              {isMobile ? (
                <IncomeDetailsMobile
                  selectedProduct={selectedProduct}
                  selectedType={selectedType}
                  contentBg={contentBg}
                  iWillGet={iWillGet}
                  productIncome={productIncome}
                  iisIncome={iisIncome}
                  currentAmount={currentAmount}
                  loading={loading}
                />
              ) : (
                <IncomeDetailsDesktop
                  selectedProduct={selectedProduct}
                  selectedType={selectedType}
                  contentBg={contentBg}
                  iWillGet={iWillGet}
                  productIncome={productIncome}
                  iisIncome={iisIncome}
                  loading={loading}
                />
              )}
            </Col>
          </Row>
          <Row gutter={30} className="calclulate-income__button">
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Button
                type="primary"
                block
                className="calclulate-income__submit-button"
                onClick={onBuyClickHandler}
              >
                Купить
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default CalculateIncome

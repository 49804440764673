import { call, put, takeEvery, select } from 'redux-saga/effects'
import {
  RESTORE_RESEND_OTP,
  RESTORE_PROCESS,
  RESTORE_SUBMIT_PASSWORD,
  RESTORE_SUBMIT_OTP,
} from '../../actions/constants/security'

import {
  securityLoading,
  saveRestoreData,
  saveRestoreError,
  saveRestoreOTPData,
  saveRestoreOTPError,
} from '../../reducers/security'

import {
  requestTargetOtp,
  requestTargetOtpResend,
  requestTargetOtpVerify,
} from '../../api/v5/otp'
import {
  requestPasswordRestore,
  requestPasswordRestoreProcess,
} from '../../api/v5/passwordRestoration'

function* fetchPasswordRestoreProcess(action) {
  try {
    const restoreData = yield call(requestPasswordRestoreProcess, {
      ...action.payload,
    })

    yield put(saveRestoreData(restoreData))
  } catch (e) {
    yield put(securityLoading(false))
    yield put(saveRestoreError(e))
  }
}

function* submitNewPassword(action) {
  try {
    yield put(securityLoading(true))

    const restoreData = yield call(requestPasswordRestore, {
      ...action.payload,
    })

    const otpData = yield call(requestTargetOtp, {
      uuid: restoreData.id,
    })

    yield put(saveRestoreOTPData(otpData))
    yield put(securityLoading(false))
  } catch (e) {
    yield put(securityLoading(false))
    yield put(saveRestoreError(e))
  }
}

function* submitOtpCode(action) {
  try {
    const security = yield select((s) => s.security)
    const { id } = security.restore.data

    yield put(saveRestoreOTPData(null))
    yield put(securityLoading(true))

    const confirmData = yield call(requestTargetOtpVerify, {
      uuid: id,
      code: action.payload,
    })

    yield put(saveRestoreOTPData(confirmData))
    yield put(securityLoading(false))
  } catch (e) {
    yield put(securityLoading(false))
    yield put(saveRestoreOTPError(e))
  }
}

function* resendOtpCode() {
  try {
    const security = yield select((s) => s.security)

    const { id } = security.restore.data

    yield put(saveRestoreOTPData(null))
    yield put(securityLoading(true))

    const confirmData = yield call(requestTargetOtpResend, {
      uuid: id,
    })

    yield put(saveRestoreOTPData(confirmData))
    yield put(saveRestoreOTPError(null))
    yield put(securityLoading(false))
  } catch (e) {
    yield put(securityLoading(false))
    yield put(saveRestoreOTPError(e))
  }
}

function* fetchConfirmRestoreSaga() {
  yield takeEvery(RESTORE_SUBMIT_PASSWORD, submitNewPassword)
  yield takeEvery(RESTORE_SUBMIT_OTP, submitOtpCode)
  yield takeEvery(RESTORE_RESEND_OTP, resendOtpCode)
  yield takeEvery(RESTORE_PROCESS, fetchPasswordRestoreProcess)
}

export default fetchConfirmRestoreSaga

import { request } from '../request'

/**
 * Создание запроса на перезвон
 * @param {*} payload
 * @url http://dev.fins.t-global.bcs/gateway/monofins/swagger/swagger-ui/index.html?configUrl=%2Fgateway%2Fmonofins%2Fswagger%2Fswagger-ui%2Fconfig&urls.primaryName=security#/CallRequest/createCallRequest
 * @returns
 */
export const callRequest = async (payload) => {
  return request(`/gateway/api/security/v5/call-requests`, {
    method: 'POST',
    data: payload,
  })
    .then((response) => {
      return response
    })
    .catch((error) => ({ error }))
}

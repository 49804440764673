import React from 'react'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button } from 'antd'
import ArrowImage from './assets/arrow_left.svg'
import './index.less'

export const MainHeader = () => {
  const dispatch = useDispatch()
  const { isMobile } = useSelector((state) => state.global)

  const handleButtonClick = React.useCallback(() => {
    dispatch(push('/authorization'))
  }, [])

  return (
    <>
      {isMobile && (
        <Row type="flex" align="middle" className="main_header__line">
          <Col span={24}>
            <Link to="/licenses">
              Банковская и брокерская лицензия ЦБ{' '}
              <img src={ArrowImage} alt="arrow" />
            </Link>
          </Col>
        </Row>
      )}
      <div className="main_header">
        <Row align="middle" className="main_header__row">
          <Col lg={14} sm={24}>
            <h1>
              <b>Fins</b> — эксперт в мире инвестиций
            </h1>
            <p>
              Мы&nbsp;развиваем культуру накоплений и&nbsp;создаем готовые
              решения, чтобы инвестиции были доступны и&nbsp;понятны каждому
            </p>
            <Button onClick={handleButtonClick} block type="primary">
              Начать копить
            </Button>
          </Col>
        </Row>
      </div>
    </>
  )
}

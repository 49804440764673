import React from 'react'
import { Form, Select } from 'antd'
import Input from '../../ui/Input'

import './index.less'

export const SecretQuestionWithAnswer = ({ submit, questionList }) => {
  const [form] = Form.useForm()

  React.useEffect(() => {
    if (submit) {
      form.submit()
    }
  }, [form, submit])

  return (
    <Form form={form} name="secretQuestionWithAnswer">
      <Form.Item
        name="question"
        rules={[
          { required: true, message: 'Обязательное поле для заполнения' },
        ]}
      >
        <Select className="question" placeholder="Контрольный вопрос">
          {questionList.map((question) => (
            <Select.Option
              className="question__item"
              key={question.id}
              value={question.id}
            >
              {question.question}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Input
        label="Ответ"
        config={{
          validateTrigger: ['onFocus', 'onChange'],
          rules: [
            {
              required: true,
              message: 'Обязательное поле для заполнения',
            },
          ],
          name: 'answer',
        }}
      />
    </Form>
  )
}

import React from 'react'

export const localize = {
  Sell: 'Продать',
  IIA: 'ИИС',
  BondPeriodDescription:
    'Время, в течение которого компания будет пользоваться вашими деньгами (до возвращения долга). Срок погашения одинаковый для всех покупателей этой облигации.',
  BondDescription:
    'Долговая ценная бумага с фиксированным доходом. Выпуская облигации, компания берёт у вас деньги в долг и затем возвращает их с процентами.',
  YtmText:
    'Доход, который получит инвестор, если удержит облигацию до погашения и будет реинвестировать все полученные купоны.',
  BondCurrentYield:
    'Доходность облигации сейчас. Рассчитывается как сумма всех платежей по купону за год, разделённая на текущую цену облигации.',
  AtTheRate: 'по курсу',
  back: 'назад',
  status: 'статус',
  enter: 'войти',
  exit: 'Выйти',
  save: 'Сохранить',
  cancel: 'Отменить',
  Cancel: 'Отменить',
  Open: 'Открыть',
  Skip: 'Пропустить',
  OpenBrokerageAccount: 'Открытие брокерского счета',
  OpenAccountSingle: 'Открыть счёт',
  add: 'Добавить',
  Ending: 'Окончание',
  send: 'Отправить',
  close: 'закрыть',
  soon: 'скоро',
  today: 'сегодня',
  download: 'скачать',
  skip: 'пропустить',
  codeFromSMS: 'Код из смс',
  Additionaly: 'Дополнительно',
  Done: 'Готово',
  Start: 'Начать',
  Confirm: 'Подтвердить',
  Connect: 'Подключить',
  Buy: 'Купить',
  Title: 'Название',
  Price: 'Цена',
  Cost: 'Стоимость',
  Congratulation: 'Поздравляем',
  Confirmed: 'Подтверждено',
  Show: 'Показать',
  Description: 'Описание',
  StartEdit: 'Изменить',
  Sync: 'Синхронизация...',
  TransferMoney: 'Перевести',
  Continue: 'Продолжить',
  SpecialOffer: 'Уникальное предложение',
  annual: 'годовых',
  taxDeduction: 'налоговый вычет',
  SeeMoreBtn: 'Подробнее',
  WhatRisksDoIHave: 'Какие у меня есть риски?',
  CanILooseMoney: 'Могу ли я потерять деньги?',
  GuaranteeOfProtection:
    'Инвестиции защищены. Ваши деньги вернутся при любом сценарии.',
  GuaranteeOfProtectionWithIncome: `Ваш гарантированный доход.`,
  LearnMoreBtn: 'Узнать подробнее',

  MinAmount: 'Минимальная сумма',
  RecomendationSubmit: 'Оформить заявку',
  RecomendationComplete: 'Выполнить рекомендацию',

  TransferMessage: `Переводы в будние дни до 17:00 МСК <br/>
    отправляются в тот же день. <br/>
     Деньги, переведённые в нерабочее время, <br/>
     отправляются в ближайший рабочий день.`,
  TransferSendToProccesing: 'Перевод обрабатывается',
  Added: 'Добавленные',
  AssetType: 'Тип актива',

  OpenDate: 'Дата открытия',
  ExecutionDate: 'Дата закрытия',

  CouponDates: 'Купонные даты',
  CouponLongText:
    'В эти даты будет фиксироваться стоимость акций, чтобы рассчитать ваши выплаты по продукту.',

  InOrder: 'В процессе приобретения',

  StartDeposit: 'Выбрать',
  StartControl: 'Подключить активы',
  CompareAssets: 'Сравнивайте активы',
  Compare: 'Сравнить',
  CompareAssetsWithOtherOptions:
    'Сравните ваши активы с другими предложениями и узнайте о выгодных альтернативах',
  LinkYourCardsForIncreaseProfits:
    'Привяжите свои карты, счета и вклады, чтобы получать персональные рекомендации для увеличения дохода.',

  RecomendationDepositTitle: 'Рекомендованный вклад',
  RecomendationDepositsTitle: 'Рекомендованные вклады',
  RecomendationDepositSubTitle: 'Лучшая ставка',

  DepositIsOpen: 'Вклад открывается',
  DepositIsClose:
    'Мы получили вашу заявку на закрытие вклада, <br /> постараемся обработать её в ближайшее время.',
  InvestmentIsOpen: 'Приобретение портфеля инвестиций',

  StructuralProductIsOpen: 'Приобретение структурного продукта',
  BondOpen: 'Приобретение облигации',

  StructuralProductCountInfo: (count) =>
    `Осталось <b>${count} продуктов</b>. Успейте инвестировать с максимальной доходностью в безрисковый продукт БКС.`,
  // StructuralProductCountInfo: (count) =>
  //   `Осталось <b>${count} продуктов</b>. Успейте инвестировать с максимальной доходностью в безрисковый продукт БКС. <a rel="noopener noreferrer" target="_blank" href="${PromotionRules}">Правила акции</a>`,

  InvestIdeas: 'Инвестидея',
  Questions: 'Вопросы',

  SuccessAdded: 'Мы добавили его в монитор для удобного отслеживания статуса.',

  SuccessAddedStructur:
    'Покупка структурного продукта может занять некоторое время. Отслеживайте статус покупки в портфеле.',

  SuccessBondPurchase:
    'Покупка облигации может занять некоторое время. Отслеживайте статус покупки в портфеле.',

  SuccessAddedInvestPortfolio:
    'Покупка портфеля инвестиций может занять некоторое время. Отслеживайте статус покупки в портфеле.',

  Delete: 'Удалить',
  Edit: 'Редактировать',
  Update: 'Обновить',
  AreYouSureYouWantToDeleteCard: 'Вы действительно хотите удалить карту?',
  AreYouSureYouWantToAccount: 'Вы действительно хотите удалить счёт?',
  AreYouSureYouWantToDeposit: 'Вы действительно хотите удалить депозит?',
  ConfirmDelete: 'Подтвердите удаление',
  DeleteBankTitle: 'Удаление добавленного онлайн-банка',
  DeleteBankText:
    'Вы собираетесь удалить онлайн-банк. Деньги на картах, счетах и вкладах этого банка перестанут отображаться.',
  DeleteBankBtn: 'Удалить банк и активы',

  YieldSP:
    'Коэффициент (показатель) «ожидаемая доходность» («годовая доходность») используется исключительно для расчетов по данному контракту и не должен восприниматься в качестве заверения/гарантии того, что по данному контракту будет получена доходность в соответствующем данному коэффициенту (показателю) размере.',

  AccountPassport:
    'Введите свои данные в точности, как в паспорте, чтобы не пришлось заполнять анкету повторно. <br/> Обратите внимание на заглавные и строчные буквы, сокращения (гор.&nbsp;Москва или г. Москва), точки и букву ё.',

  AdditionalyDescription:
    'Прикрепите скан или фото паспорта, так мы обработаем анкету быстрее.',

  // Service status
  NotFound: 'Ничего не найдено',
  NotFoundBank: 'Нет банков для подключения',
  NotFoundDescription:
    'Попробуйте изменить параметры запроса <br /> или перезагрузить страницу.',

  NotFoundTransaction: 'Вы ещё не переводили деньги',
  NotFoundAccounts: 'Вы ещё не открывали счета',
  NotFoundBonds: 'Вы ещё не покупали облигации',
  NotFoundSP: 'Вы ещё не покупали структурные продукты',
  NotFoundAccountsDescription:
    'Когда вы откроете счета, они появятся в этом разделе.',
  NotFoundDeposits: 'Вы ещё не покупали вклады',
  NotFoundDepositsDescription:
    'Когда вы начнете покупать вклады, они появятся в этом разделе.',
  NotFoundBondsDescription:
    'Когда вы начнете покупать облигации, они появятся в этом разделе.',
  NotFoundDescriptionTransaction:
    'Когда начнёте делать переводы, они появятся в этом разделе.',

  NotFoundDescriptionSP:
    'Когда вы начнете покупать структурные продукты, они появятся в этом разделе.',

  Loading: 'Загрузка...',

  AllAssets: 'Все активы',
  PassiveIncome: 'Пассивный доход',

  AmountAtMaturity: 'Сумма в конце срока',

  DateAndTime: 'Дата и время',
  CardDetails: 'Реквизиты карты',

  Order: 'Заказ',
  ForMoneyTransfer: 'Для перевода средств',

  bond: 'Облигация',

  // ivnestmentProfileList
  in_order: 'в процессе покупки',
  InProcessPurchase: 'В процессе открытия',
  complete_purchase: 'куплен',
  processing_sale: 'в процессе продажи',
  sold: 'в процессе продажи',

  ConfirmDeleteAlertDeposit: 'Вы уверены, что хотите удалить вклад?',
  NoOpenDeposits: 'Нет открытых счетов',

  EnterCode: 'Введите код',
  AgreeTermOfUse:
    'Настоящим подтверждаю и гарантирую, что я действительно ознакомился(-ась) с текстом пользовательского соглашения и согласен(-на) с его условиями в полном объеме',
  NeedOTPConfirm: 'Необходимо будет ввести код из смс',

  // Transfer
  ToMyFinsAccount: 'На мои счета',
  MyFinsAccount: 'Мой счёт',
  AllTransfer: 'Все переводы',
  FastTransfers: 'Быстрые переводы',
  TransferTitle: 'Переводы',
  TransferInputTarget: 'Куда перевести, что пополнить?',
  TransferInputSource: 'Откуда перевести?',
  TransferToMyAccount: 'Перевод на счёт',
  TransferToMyOwnAccount: 'перевод себе на счёт',
  Transfer: 'Перевести',
  BetweenAccounts: 'Между счетами',
  NoAvailableAccount: 'Нет доступных для переводов банковских счетов',

  WithBrokerAccount: 'С брокерского счёта',
  ToBrokerAccount: 'На брокерский счёт',
  NoBrokerAccount: 'Нет брокерских счетов',

  FillUp: 'Пополнение',
  FillUpMoney: 'Пополнить',
  NoConnectBank: 'Нет подключенных банков',
  FillUpProcess: 'Пополнение в процессе',

  SignTheDocuments: 'Подпишите документы',
  SignedDocuments: 'Подписанные документы',
  SignedDocumentsNotFound: 'Нет документов для подписания',
  SignedDocumentsSuccess: 'Документы подписаны',

  MyDocuments: 'Мои документы',
  MyDocumentsGroup: 'Группа документов на подпись',
  TimeToSign: 'На подписание',
  Time: 'Время',
  TimeOfDelivery: 'Время доставки',
  SendSMS: 'Отправить смс-код',

  MyProfileNew: 'Мой новый профиль',
  MyProfile: 'Мой профиль',

  AllInformation: 'Общая информация',

  Purse: 'Кошелёк',
  Bank: 'Банк',

  Deposits: 'Вклады',
  Investments: 'Инвестиции',
  Cash: 'Наличные',
  FreeMoney: 'Свободные деньги',

  Rub: 'Рубли',
  Usd: 'Доллары США',
  UsdShort: 'Доллары',
  Eur: 'Евро',

  FinsAccount: 'Счета',

  WhyFins: 'Почему мы?',

  OpenDepositSumm: 'Открыть вклады на',
  OpenInvestmentSumm: 'Купить портфель инвестиций на',
  OpenStructuralSumm: 'Купить за',
  WithSumm: 'на сумму',

  HistoryOperation: 'История операций',
  MyAssets: 'Мои активы',
  AssetsStatistics: 'Статистика по активам',
  ChooseAssets:
    'Выберите тип актива и заполните все обязательные для добавления поля.',
  FinanceStatus: 'Финансовый статус',
  InvestmentsAndDeposits: 'Инвестиции и вклады',
  InvestWithProfitability: 'Выгодно вкладывайте',
  ConfirmCancel: 'Хотите отменить? Все несохранённые изменения будут потеряны',
  MyAssetsCompanyHelp: 'Поле получатель для внешнего перевода',
  NameOfRecipient: 'Наименование получателя',
  type: 'тип',
  Cards: 'Карты',
  Accounts: 'Счета',
  mmm: 'мес',
  name: 'наименование',
  countShort: 'количество',
  summ: 'Сумма',
  proportion: 'доля',
  issuers: 'Эмитенты',
  guaranteedYield: 'Гарантированная доходность',
  yield: 'Доходность, до',

  card: 'Карта',
  deposit: 'Депозит',
  account: 'Счёт',

  numberCard: 'Номер карты',
  transferFrom: 'Откуда',
  transferTo: 'Куда',
  expirationDate: 'Срок окончания',

  noResultsText: 'Ничего не найдено',
  SelectDataNull: 'Ничего не выбрано',
  RecomendationText: 'Рекомендации',
  TransferText: 'Переводы',
  MyActiveText: 'Мои активы',
  NewAttachText: 'Инвестиции <br /> и&nbsp;вклады',
  MenuStructuralProducts: 'Структурные<br />продукты',
  BondsIconText: 'Инвестиции и вклады',
  market: 'Маркет',
  HomeIconText: 'Главная',
  NotificationText: 'Уведомления',
  NoNotificationsText: 'У вас пока нет уведомлений',

  AddingAsset: 'Добавление актива',
  ByCategory: 'По категориям',

  registartion: 'Зарегистрироваться',
  resetPassword: 'Восстановить пароль',

  HasInvite: 'У вас есть приглашение?',

  profile: 'Профиль',
  settings: 'Настройки',
  profileSettings: 'Настройки профиля',
  help: 'Помощь',
  financialData: 'Финансовые данные',
  giftsToFriends: 'Подарки за друзей',
  Deposit: 'Вклад',
  Close: 'Закрыть',
  CloseDeposit: 'Закрыть вклад',
  ClosingDeposit: 'Закрытие вклада',
  PreCloseDeposit: 'Досрочное закрытие вклада',
  TodayYouCouldOpenDepositWithMoreRate:
    'При досрочном закрытии вклада проценты будут пересчитаны за срок фактического нахождения денежных средств в соответствии с условиями размещения депозитов.',
  onlineDeposit: 'Онлайн вклады',
  depositDuration: 'Срок вклада',
  InvestmentDuration: 'Срок инвестиций',
  ShortOther: 'др.',
  currency: 'Валюта',

  withCapitalization: 'С капитализацией',
  withCapitalizationToolTip:
    'Проценты дохода считаются от суммы (вклад+начисленный доход за предыдущие периоды)',

  partialWithdrawAllowed: 'Частичное снятие',
  chargeAllowed: 'Можно пополнять',
  insuranceOn: 'Застраховано',
  withAutoProlongation: 'Автоматическое продление',

  beneficialOption: 'Выгодное решение',

  DepositBankPartner: 'Вклады в банках-партнёрах',

  PaymentButtonMarket: (amount, term) => `Вложить ${amount} на ${term} дней`,
  PaymentButtonMarketInvest: (amount, term) =>
    `Инвестировать ${amount} на ${term} мес.`,

  onlineOpenAllowed: 'Вклады онлайн',
  onlineOpenTooltip: 'Можно открыть онлайн',
  OpeningOnline: 'Открытие онлайн',
  OpeningOffline: 'Открытие оффлайн',
  DepositInBankPartner: 'Все вклады',
  Period: 'Срок',
  DateOfClosing: 'Дата закрытия',
  Income: 'Доход',
  PossibleIncome: 'Возможный доход',
  PossibleGrowthOfStock: 'Если акции вырастут на',
  PossibleIncomeText:
    'Доход, который вы можете получить, выполняя наши рекомендации.',
  Distribution: 'Распределение',
  ProfitRate: 'Доходность',
  InterestRate: 'Ставка',
  // IncomeForecast: 'Прогноз дохода',
  Characteristics: 'Характеристики',

  WeWarnYouAboutSecuredLimits:
    'Вклады до 1 400 000 ₽ застрахованы государственной корпорацией АСВ (Агентством по страхованию вкладов). Если вы захотите вложить сумму больше застрахованной, мы предложим разложить деньги по разным банкам, чтобы сохранить выгодную ставку и уберечь вас от потерь.',
  WeWarnYouAboutSecuredLimitsToolTip: 'АСВ - Агентство по страхованию вкладов',

  // Checkout
  PortfelAndStructurProduct: 'Портфели и структурные продукты',
  ProfitRateTo: 'Доходность до',
  InvestmentInRUB: 'Инвестиции',
  ACBAlertBankAmount:
    'Сумма вложения в этом банке больше максимальной суммы, застрахованной АСВ (1,4 млн. ₽). Чтобы не потерять деньги, разложите их по разным банкам',

  // Purchase
  TotalAmountSum: 'Общая сумма вложения',
  ProductName: 'Название продукта',
  ProductType: 'Тип продукта',
  ExpectedYield: 'Ожидаемая доходность',

  // Investment Product Card
  currentAmount: 'Сумма вложения',
  currentAmountInvest: 'Инвестирование, от',
  currentMinSumInvest: 'Мин. сумма',
  FindConditions: 'Узнать условия',
  AvarageIIS: 'Доходность с ИИС',
  AvaragIIAInformer:
    'Бесплатный брокерский счёт, по которому вы можете получить налоговый вычет 13%. Так государство возвращает удержанный за год НДФЛ. Если в течение года пополнить ИИС на сумму до 400 000 ₽, то можно получить до 52 000 ₽ в виде вычета. Деньги с ИИС нельзя снимать три года, иначе вычет придётся вернуть.',
  returnAverage: 'Результат, до',
  // ResultWithIIS: `Результат с <a rel="noopener noreferrer" target="_blank" href="${IIS}">ИИС</a>, до`,
  average: 'Максимальная доходность',
  savePart: 'Защищённая часть',
  payoutsPerYear: 'Выплат в год',
  // ToggleTaxDeduction: `Включить налоговый вычет&nbsp;(<a rel="noopener noreferrer" target="_blank" href="${IIS}">ИИС</a>)`,
  GuaranteedYield: 'Гарантированная доходность',
  GuaranteedYieldShort: 'Гарантированная',
  ProjectedYield: 'Прогнозируемая доходность',
  ProjectedYieldShort: 'Прогнозируемая',
  InvestmentProtection: 'Защита инвестиций',

  ParticipationProtection: 'Защита вложения',

  InvestmentListTitle: 'Инвестиции, подходящие вашему финансовому темпераменту',

  invest_portfolio: 'Портфель',
  invest_account: 'Брокерский счёт',
  BrokerAccount: 'Брокерский счёт',
  BrokerAccountAndIIS: 'Брокерский счёт и ИИС',
  bank_account: 'Банковский счёт',
  BankAccounts: 'Банковские счета',
  structural_product: 'Структурный продукт',
  Bonds: 'Облигации',

  NominalRate: 'Номинальная ставка',
  InterestAccrualPeriod: 'Периодичность начисления %',
  InterestPaymentPeriodTitle: 'Период выплаты процентов',
  ChargeAllowed: 'Частичное пополнение',
  AutoProlongation: 'Автопродление',
  WithdrawAllowed: 'Частичное снятие',

  OtherDeposits: 'Другие вклады',
  Other: 'Другое',
  ShowOffers: 'Посмотреть предложения',
  plusSelect: '+ Выбрать',
  Select: 'Выбрать',
  Remove: 'Удалить',

  prefixDeposit: 'вклад',
  WereFound: 'Было найдено',

  MinimumEightSymbols: 'Минимум 8 символов',
  ShouldBeUpperCaseLetters: 'Должны быть заглавные буквы',
  ShouldBeLowerCaseLetters: 'Должны быть прописные буквы',
  ShouldBeDigits: 'Должны быть цифры',
  CalculateBondProfitability: 'Расчёт доходности облигаций',

  PasswordGood: 'Надёжный пароль',
  PasswordNormal: 'Ещё чуть-чуть, и пароль будет надёжным',
  PasswordEasy: 'Простой пароль',
  PasswordNo: 'Пароль',
  PasswordSuccess: 'Пароль успешно изменён',

  DoYouWantToCancelChanges:
    'Хотите отменить? Все несохранённые изменения будут потеряны',
  DocumentsSignature: 'Шаг 4: Ознакомьтесь с документами и отправьте заявку',
  Documents: 'Документы для ознакомления',
  DateOfAcknowledge: 'Дата ознакомления',
  DocumentsSignatureText:
    'Посмотрите документы, которые привезет наш представитель и ознакомьтесь со стоимостью обслуживания клиентов.',

  OpenAccount: 'Открытие счёта',
  OpenAccountInProgressTitle: 'Ваш брокерский счёт в процессе открытия',
  OpenAccountInCompleteButWithoutIIS:
    'У вас нет возможности купить данный продукт с ИИС',
  OpenAccountInCompleteButWithoutIISText:
    'Так как вы открыли счёт без ИИС, продукты с ИИС вам недоступны',
  OpenAccountInProgress:
    'Вы сможете купить этот продукт, после того, как получите смс об успешном открытии и пополните счёт.',
  OpenAccountComplete: 'Счета открыты',
  OpenAccountFailed: 'Заявка на открытие счёта отклонена',
  OpenAccountStep1Done: 'Перейти к паспортным данным',
  OpenAccountStep2Done: 'Выбрать время и адрес доставки документов',
  OpenAccountStep3Done: 'Ознакомиться с документами',
  OpenAccountPersonalData: 'Шаг 2: Укажите паспортные данные',
  OpenAccountContactsTitle: `Шаг 3: Выберите, где и когда вам будет удобно подписать&nbsp;документы`,
  OpenProfitableDepositsOnline: 'Вклады с онлайн-открытием',
  InvestProfitableAndSafelyFromSumm:
    'Инвестируйте <br /> с защитой вложений <br /> от 10 000 ₽',
  // RetrieveTaxesUpToSumm: `Получайте с <a rel="noopener noreferrer" target="_blank" href="${IIS}">ИИС</a> +52 000 ₽ в год`,
  OppenAccount: 'Открытие аккаунта',
  OpenAnAccount: 'Откройте счёт',
  WhatAccountOpened: 'Какие счета будут открыты',
  OpenAccountModalLoadFiles_title: 'Загрузите файлы еще раз!',
  OpenAccountModalLoadFiles_text:
    'После обновления страницы необходимо повторно загрузить файлы',

  Questionnarie: 'Шаг 1: Ответьте на несколько обязательных вопросов',
  Registration: 'Регистрация',
  AccountCreation: 'Создание аккаунта',
  OpenBill: 'Открытие счетов',
  Welcome: 'Добро пожаловать',
  Security: 'Безопасность',
  Enter: 'Вход',
  Password: 'Пароль',
  ChangePassword: 'Изменить пароль',
  EnterPhoneNumberToEnterFins: 'Введите номер телефона для входа',
  PhoneNumber: 'Номер телефона',
  SelectSourceOfIncome: 'Укажите источник дохода',
  Authorization: 'Авторизация',
  OTPCodeFromSMS: 'Код из смс',
  SendSMSOneMoreTime: 'Отправить смс ещё раз',
  OTPCodeIs4Digits: 'Введите код из смс',
  SMSDoesNotArrive: 'Код не приходит',
  Back: 'Назад',
  Next: 'Дальше',
  Send: 'Отправить',
  WeSendSMSwithCodeConfirmNumber:
    'Мы отправили смс с кодом подтверждения на ваш номер',
  WeSendSMSwithCodeConfirm:
    'Мы отправили смс с кодом подтверждения на ваш номер',
  FirstName: 'Имя',
  LastName: 'Фамилия',
  MiddleName: 'Отчество',
  Email: 'Электронная почта',
  ConcoctPassword: 'Придумайте пароль',
  RepeatPassword: 'Повторите пароль',
  EnterSamePassword: 'Введите одинаковые пароли',
  EnterFirstName: 'Введите имя',
  EnterLastName: 'Введите фамилию',
  IAgreeWith: 'Даю согласие на ',
  ConditionsTransfer: 'передачу и обработку персональных данных',
  AgreeWithDataProcessingRules:
    'Даю согласие на передачу и обработку персональных данных',
  AgreeToRules: 'Подтвердите согласие с правилами',
  OpenInvestBillText:
    'Заполните анкету и наш представитель приедет в удобное вам время и место для подписания документов. Счета будут открыты у нашего партнера БКС. Обслуживание всех счетов бесплатно.',
  OpenBankAccount: 'Открыть банковский счёт',

  AccountOpenViaBCSLong: [
    {
      key: 'bcs',
      title: 'Брокерский счёт для инвестирования',
      tooltip:
        'Услуги по открытию счета предоставляет ООО "Компания БКС" — лидер в рейтинге Московской Биржи. Счёт открывается и обслуживается бесплатно.',
      desription: '',
    },
    {
      key: 'bs',
      title: 'Банковский счёт для открытия онлайн-вкладов',
      tooltip:
        'Услуги по открытию банковских счетов предоставляет АО «БКС Банк». Вам бесплатно откроют три счёта: рублёвый, долларовый и евро. При открытии счетов также оформляется доверенность на АО «БКС Банк», чтобы вы могли открывать вклады в банках-партнёрах онлайн.',
      desription: '',
    },
    {
      key: 'iia',
      checkbox: true,
      // title: `Индивидуальный инвестиционный счёт (<a rel="noopener noreferrer" target="_blank" href="${IIS}">ИИС</a>) для получения налогового вычета до&nbsp;156&nbsp;000&nbsp;₽`,
      tooltip:
        'Бесплатный брокерский счёт, по которому вы можете получить налоговый вычет 13%. Существует два типа вычета: вычет типа А, когда вам возвращается часть уплаченного за год НДФЛ, и вычет типа В, когда вас освобождают от уплаты подоходного налога от инвестиций. Если в течение года пополнить ИИС на сумму до 400 000 ₽, то можно получить до 52 000 ₽ в виде вычета (тип А). За три года вы можете получить до 156 000 ₽. Деньги с ИИС нельзя снимать три года, иначе вычет придётся вернуть в полном объёме. По структурным продуктам можно получить только вычет типа А.',
      desription: '',
    },
  ],

  OpenBrokerAccount: 'Открыть брокерский счёт',
  PackageFINS: 'Пакет счетов',
  // OpenIIS: `Индивидуальный инвестиционный счёт — <a rel="noopener noreferrer" target="_blank" href="${IIS}">ИИС</a>`,
  ToBeAbleOpenDepositsPartnerBanks:
    'Хочу открывать вклады в банках-партнёрах онлайн',
  NoInternetConnection: 'Нет подключения к интернету',
  DepositMarket: 'Маркет вкладов',
  TheAmountOfInvestement: 'Сумма инвестиций',
  TermOfInvestement: 'Срок инвестиций',

  InvestmentMarket: 'Маркет инвестиций',
  Market: 'Маркет',
  Later: 'Позже',
  SomeAction: 'какое-то действие',
  Sort: 'Сортировать',
  ByProfitability: 'По доходности',
  ByBank: 'По банкам',
  NewInvestment: 'Новое вложение',
  StartAgain: 'Начать заново',
  MyChoiceText: 'Мой выбор',
  ConfirmationOfConditions: 'Подтверждение условий',
  OnlyRussianSymbols: 'Только русские буквы',
  OnlyLatinSymbols: 'Только латиница',
  PassportIssueDateShouldBe14YearsAfterBirth:
    'Паспорт выдаётся минимум с 14 лет',
  General: 'Общие',
  HowMuchIs: 'Сколько стоит',
  AboutService: 'О сервисе',
  OpenAndCloseAccountDocuments: 'Документы при открытии и закрытии счёта',

  ValidationPhoneNumber:
    'Телефон должен быть в формате <br /> +7 (111) 111-11-11',
  ValidationEmail: 'Введите корректный email',
  InBCS: 'В отделении банка',
  CallCourier: 'Отправить заявку',
  PersonalData: 'Паспортные данные',
  Phone: 'Телефон',
  EmailShort: 'email',
  City: 'Город',
  ValidationAddress: 'Начните вводить адрес и выберите нужный из списка',
  ValidationDeliveryAddress:
    'Сейчас мы доставляем документы только по Москве и Московской области',
  ValidationDeliveryAddressRussia:
    'Сейчас мы доставляем документы в городах: Москва и область, Санкт-Петербург, Екатеринбург, Краснодар, Нижний Новгород, Новосибирск, Челябинск. Если вашего города нет в списке, вы все равно можете оставить заявку на открытие счёта. Как только заявок накопится достаточное количество, наш представитель приедет в ваш город. Мы с вами свяжемся, чтобы уточнить время и место доставки.',

  PassportSeries: 'Серия',
  PassportNumber: 'Номер',
  PassportIssuer: 'Кем выдан',
  PassportIssueDate: 'Дата выдачи',
  PassportDepartmentCode: 'Код подразделения',
  BirthDate: 'Дата рождения',
  BirthPlace: 'Место рождения (как в паспорте)',
  NumberInn: 'Номер ИНН (заполняется по желанию)',
  Pick: 'Выберите',
  CodeWordForServiceInBank: 'Кодовое слово (кириллицей от 4 букв)',
  IsLivingAddressTheSameAsRegAddress:
    'Фактический адрес совпадает с регистрацией',
  IsDeliveryAddressTheSameAsRegAddress:
    'Адрес доставки совпадает с регистрацией',
  LivingAddress: 'Адрес проживания',
  PhotoPassport:
    'Скан или фотография вашего паспорта (заполняется по желанию, но так анкета обработается быстрее)',

  Conservative: 'Консервативный',
  conservative: 'консервативный',
  PositiveResult: 'Позитивный',
  NeutralResult: 'Нейтральный',

  Moderately_conservative: 'Умеренно консервативный',
  moderately_conservative: 'умеренно консервативный',

  Moderate: 'Рациональный',
  moderate: 'рациональный',

  Moderately_aggressive: 'Умеренно агрессивный',
  moderately_aggressive: 'умеренно агрессивный',

  Aggressive: 'Агрессивный',
  aggressive: 'агрессивный',

  Super_aggressive: 'Сверхагрессивный',
  super_aggressive: 'сверхагрессивный',

  Professional: 'Профессиональный',
  professional: 'профессиональный',

  RiskProfile: 'Финансовый темперамент',
  riskProfile: 'финансовый темперамент',

  RiskLevel: 'Уровень риска',

  InvestPortfolio: 'Портфели инвестиций',
  StructuralProduct: 'Структурные продукты',
  // IIS: `<a rel="noopener noreferrer" target="_blank" href="${IIS}">ИИС</a>`,
  Date: 'Дата',
  DateOfDelivery: 'Дата доставки',
  DeliveryNote: 'Комментарий',
  AgreeRules: 'Согласитесь с правилами',
  AddressAndTimeForDeliveryOfDocuments: 'Адрес и время доставки документов',
  AddressForDeliveryOfDocuments: 'Адрес доставки документов',

  Address: 'Адрес',
  AddressReg: 'Адрес регистрации',
  gender: 'Пол',
  MyInvestmentSelection: 'Мой выбор инвестиций',
  MyDepositSelection: 'Мой выбор вкладов',
  Total: 'Всего',
  GoToOpen: 'Перейти к открытию',
  ProtectedPart: 'Защищённая часть',
  SumOfInvestment: 'Сумма вложений',
  BankDepositName: 'Банк, название вклада',

  levelOfRisk: 'финансовый риск-профиль',
  LevelOfRisk: 'Финансовый риск-профиль',

  RefreshRiskLevel:
    'Обновить свой финансовый риск-профиль и пройти тест снова можно в личном кабинете',

  TitleRiskLevel: 'Какой вы инвестор?',
  FirstTextRiskLevel:
    'Это тест на финансовое риск-профилирование. Он нужен, чтобы мы могли узнать вашу склонность к риску и подобрать продукты, которые подходят именно вам.',

  CallToContactCenter:
    'Позвоните в Службу поддержки по номеру<br />+7 800 500 10 01<br />Мы поможем решить проблему.',

  ForgotPassword: 'Забыл пароль',
  AllSP: 'Все',
  ThresholdSP: 'Пороговый',
  threshold: 'Пороговый',
  ParticipationSP: 'Участие',
  participation: 'Участие',
  greatFenix: 'Корзина компаний',
  FuncWereFound: (text) => `${localize.WereFound} ${text}`,
  FuncDatesBetween: (dateMin, dateMax) =>
    `Даты должны быть в промежутке ${dateMin} -- ${dateMax}`,
  FuncDatesBetweenWithoutWeekends: (dateMin, dateMax) =>
    `Даты должны быть в промежутке ${dateMin} — ${dateMax} в рабочие дни`,
  WeSentOTPToPhoneNumber: (login) =>
    `Мы отправили смс с кодом подтверждения на номер ${login
      .split(' ')
      .join('\u00A0')}`,
  ResendSMSInSeconds: (seconds) => `Отправить повторно через ${seconds} секунд`,

  CommisionBond: (percent, min) => `${percent} от сделки, но не менее ${min}`,
  CommissionStructural: (per, min) =>
    `Сумма с учётом комиссии ${per} от суммы инвестиций (минимум ${min})`,

  totalAmount_deposit: 'Общая сумма вложения',
  totalAmount_investment: 'Сумма контракта',
  totalAmount_structuralProduct: 'Сумма контракта',

  INTERNAL_ERROR: 'Внутренняя ошибка сервера',
  VALIDATION_ERROR: 'Ошибка валидации',
  SEND_SMS_ERROR: 'Ошибка отправки смс',

  // SESSION
  SESSION_NOT_VALID: 'Неверная сессия',

  // AUTH
  BAD_CREDENTIALS: 'Неверный пароль',
  MAX_AUTH_ATTEMPTS_REACHED: 'Превышено количество попыток',
  TOKEN_HAS_NO_AUTH: 'Доступ запрещен',
  USER_BLOCKED:
    'Доступ ограничен. Позвоните нам и наши специалисты поддержки помогут вам.',
  BAD_SECRET_ANSWER:
    'Вы ввели неправльный ответ на контрольный вопрос, попробуйте ещё раз',
  MAX_ANSWER_ATTEMPTS_REACHED: 'Превышено количество попыток',
  PASSWORD_RESTORATION_TOKEN_WRONG: 'Ссылка на восстановление невалидна',

  // REGISTRATION
  LOGIN_ALREADY_HAS_REGISTRATION:
    'Пользователь с такими данными уже зарегистрирован.',
  NOT_IN_WHITE_LIST: 'Для введённого номера телефона регистрация недоступна.',

  // OTP
  BAD_OTP: 'Неверный код подтверждения',
  BAD_OTP_TOKEN: 'Невозможно совершить операцию',
  OTP_EXPIRED: 'Срок действия кода подтверждения истек',
  OTP_MAX_ATTEMPT_REACHED: 'Превышено максимальное кол-во попыток',

  // USER
  INCORRECT_OLD_PASSWORD: 'Старый пароль указан неверно',
  PASSWORD_ALREADY_USED: 'Пароль уже использовался. Выберите другой пароль',

  // PAYMENT
  INVALID_EXCHANGE_RATE: 'Курс конвертации изменился',
  INVALID_BANK_ACCOUNT: 'Неверный номер счёта',

  // EGGY
  EGGY_REQUEST_ERROR:
    'Ошибка сервиса автоматического получения финансовых данных',
  UNKNOWN_ERROR: 'Неизвестная ошибка',

  NOT_ENOUGH_MONEY: 'Недостаточно денег',
  BUY_ERROR: 'Ошибка при покупке',

  // MyDocuments
  SignInDocuments: 'Подписать документы',
  FIRST_STEP_INVEST:
    'Чтобы начать инвестировать, необходимо подписать документы',
  LAST_STEP_SMS_SEND: 'Подтвердите ваше согласие с помощью смс-кода.',

  TempLoadData: 'Загрузить тестовые значения',

  // assets
  SummLabel: 'Сумма',
  LoadMore: 'Загрузить ещё',
  CurrencyLabel: 'Валюта',
  Statement: 'Выписка',
  AccountNumberLabel: 'Номер счёта',
  AccountName: 'Название счёта',
  ExpireDateLabel: 'Срок действия',
  CardNumberLabel: 'Номер карты',
  IncorrectCardNumber: 'Неверный номер карты. Должно быть 13-19 цифр',
  IncorrectAccount: 'Неверный номер счёта. Должно быть 20 цифр',
  DepositOpenDateShouldBeGreaterThan:
    'Дата открытия вклада не должна быть больше текущей даты',
  IncorrectAmount: 'Это не число',
  OpenDateLabel: 'Дата открытия',
  TermLabel: 'Срок',
  BalanceLabel: 'Баланс',
  InterestRateWIthPercent: 'Ставка, %',
  CapitalizationLabel: 'Капитализация процентов',
  GoToMonitor: 'Перейти в Монитор',
  AddOtherAssets: 'Добавить другие активы',
  SuccessDeposit: 'Вклад добавлен',
  SuccessCard: 'Карта добавлена',
  SuccessAccount: 'Счёт добавлен',
  AllSuccess: (h) =>
    `${h} отобразится в ваших активах, там же вы сможете отредактировать или удалить актив.`,

  Choose: 'Подобрать',
  ChooseDate: 'Выберите дату',

  ConnectOtherBank: 'Подключить другой банк',

  // MARKET HEADER
  marketHeaderDepositInfo: 'до 10,5%',
  marketHeaderInvestmentInfo: 'до 16,5%',

  GoToMyAssets: 'Перейти в Мои активы',

  // PROFILE
  pluralBill: 'счёт',
  pluralInvestment: 'вклад',
  pluralCard: 'карт',
  resetAccess: 'Восстановить доступ',

  SetNowPassword: 'Введите текущий пароль',
  SetNewPassword: 'Введите новый пароль',
  ConfirmPassword: 'Повторите новый пароль',

  // NEW PROFILE
  PersonaData: 'Личные данные',
  Reporting: 'Отчетность',
  brokerReporting: 'Брокерские отчеты',
  depositReporting: 'Депозитарные отчеты',
  RegistersInstructions: 'Реестры поручений',
  TariffsAndDocs: 'Тарифы и документы',
  Tariffs: 'Тарифы',
  DocsSvyazBank: 'Документы по Связь-банку',

  //
  LoadMoreHistory: 'Загрузить операции',

  // LOGICOMPONENT FinsAsset
  NoTransactionBegining: 'У вас пока нет переводов, но то ли ещё будет',

  // InvestPortfolioAsset
  ItemsInProduct: 'Состав продукта',
  Ordering: 'Выписка',
  Closing: 'Закрытие',
  CloseProduct: 'Закрыть продукт',
  WhatIsYourInvestment: 'Какой вы инвестор?',
  outOf: 'из',
  completeFor: 'выполнено на',
  HowItsProductWork: 'Как работает продукт',
  Payouts: 'Выплаты',

  ReceiveReceipt: 'Получить квитанцию',
  RepeatOperation: 'Повторить операцию',

  ResendOPTCode: 'отправить смс повторно',
  OTPError: 'неправильный смс-код',

  investInstrumentPortfolioOrdering:
    'Портфель инвестиций в процессе покупки. Вы можете посмотреть подробную информацию о количестве купленных бумаг внутри продукта',

  StartPeriod: 'Начало периода',
  FinalPeriod: 'Конец периода',
  BankName: 'Название банка',
  BankNameOrBikName: 'Название банка или БИК',

  // InvestCard
  RemoveOnList: 'Удалить из подборки',

  // CART Checkout and Purchase
  BassisActive: 'Базисные активы',
  Tikker: 'Тиккер',

  WithCard: 'C карты',
  WithReqesits: 'По реквизитам',
  NotFoundRequsits: 'Нет информации по реквизитам',
  WithAnyBankAccount: 'С другого счёта',
  OperationSendTransfer: 'Операция отправлена в обработку',
  MoneyNoMoneyOoNoMoney: 'Недостаточно средств для осуществления перевода',

  YourBank: 'Ваш банк',
  DepositName: 'Название вклада',

  ChooseAccount: 'Выберите счёт',

  ConfirmConditinalProduct: 'Подтверждаю условия продукта',

  NoInvestItemInCartTitle: 'Выберите финансовые продукты.',
  NoInvestItemInCartDescription:
    'В этом разделе отображаются выбранные вами вклады и инвестиции. Здесь вы можете продолжить оформление продуктов или вернуться к ним позже.',

  // CART
  investment: 'портфель инвестиций',
  structuralproduct: 'структурные продукты',

  // INVESTMENT
  investNotFoundTitle: 'По вашему запросу ничего не найдено',
  investNotFoundDescription:
    'Попробуйте ввести другие поисковые данные или воспользуйтесь быстрой подстановкой рекомендуемых вам параметров.',

  SetRecomendationParams: 'Рекомендуемые параметры',

  WhereToStart: 'С чего начать?',

  ConnectingWithBank: 'Соединение с банком...',

  DropZoneScanPassport:
    'Развороты: главный, регистрация. <br /> Формат изображений jpeg, png, jpg не более 5 мб',
  DropZoneTitle: '<span>Перетащите или</span> загрузите файлы',
  DropZoneError: 'Недопустимый файл',
  DropZoneErrorMaxSize: 'Превышен размер файла',
  DropZoneScanPassportAfter: `Если не можете прикрепить  паспорт сейчас, пропустите это поле. В&nbsp;этом случае мы попросим вас продиктовать паспортные данные по&nbsp;телефону.`,
  ACB_text: {
    RUB: 'Вклады до 1 400 000 ₽ застрахованы государственной корпорацией АСВ (Агентством по страхованию вкладов). Если вы захотите вложить сумму больше застрахованной, мы предложим разложить деньги по разным банкам, чтобы вы их не потеряли.',
  },

  // VIDEO helpers
  video_invest_1: 'Что такое <br /> портфель <br /> инвестиций ?',
  video_invest_2: 'Что такое <br /> структурный <br /> продукт ?',
  video_deposit_1: 'Чем вклад отличается <br /> от инвестиции?',

  // SCRAPING MESSAGES
  scraping_success_message_vendor:
    'успешно подключен. <br /> Добавленные активы:',

  // TOOLTIP INFORMER
  // informer_tax_deduction_iis: `С <a rel="noopener noreferrer" target="_blank" href="${IIS}">ИИС</a> вы можете получить до 156 000 ₽ от государства дополнительно к своему доходу, если у вас белая зарплата. Пополните <a rel="noopener noreferrer" target="_blank" href="${IIS}">ИИС</a> три раза на сумму до 400 000 ₽ в год, и вам выплатят 13% от этой суммы — до 52 000 ₽ в год. С <a rel="noopener noreferrer" target="_blank" href="${IIS}">ИИС</a> нельзя снимать деньги в течение 3 лет, иначе полученный вычет придётся вернуть.`,
  informerSavePart:
    'Часть инвестированных денег, которые будут вам возвращены при неблагоприятном сценарии, без учета риска дефолта эмитента. Для портфелей неблагоприятный сценарий — это получение вложенных денег и дохода только по облигациям (а по акциям - нет). Название "защищённая часть" является условным и не может считаться гарантией возврата средств.',
  informerWithCapitalization:
    'Вклады, в которых доход считается не только от вложенной суммы, но и от начисленных процентов за предыдущие периоды.',
  informerWithdradAllowed:
    'Вклады с возможностью снять часть денег до окончания срока без потери начисленных процентов.',
  informerChargeAllowed:
    'Вклады с возможностью добавления денег к первоначальной сумме вложения.',

  // MONITOR
  ControlAssets: 'Подключение активов',
  monitorWelcomeMoneyHelp: 'Познакомьтесь с функциями и начните зарабатывать',
  SelectRightInvestments: 'Выберите подходящие инвестиции',
  SelectRightInvestmentsByRateAndRisk:
    'Выберите защищённые инвестиции или доходные вклады, которые вам подходят.',
  AllByCurrencyCB: 'Всего по курсу ЦБ',
  DistribtuinMoney: 'Распределение денег',
  TheAverageRate: 'Средняя ставка',
  AllActives: 'Общий баланс',
  FreeInvestMoney: 'Доступно для инвест.',
  Best: 'Лучшая',
  ScrappedCard: 'Привязанные карты',

  // 404 PAGE
  NotFoundPageHeader: 'Эта страница недоступна',
  NotFoundPageDescription:
    'Возможно, вы воспользовались <br /> недействительной ссылкой или страница была удалена.',
  NotFoundPageBackLink: 'Вернуться на стартовую страницу',

  // CHECKOUT NULL INVESTMENT
  investmentDescriptionTitle: 'Выгодно инвестируйте',
  investmentDescriptionNull:
    'Выберите инвестиции, которые подходят вам по доходности и финансовому риск-профилю',
  depositDescriptionNull:
    'Мы отобрали надёжные и выгодные вклады. Выберите подходящий именно вам.',

  SeeMore: 'Показать ещё',

  NoMoneyNoHoney: 'Недостаточно средств на счёте',
  // PAYMENT LINK
  linkBankAccountTransfer: 'На новую карту',
  linkInternalBankAccountTransfer: 'Клиенту',
  linkExternalBankAccountTransfer: 'На новый счёт',

  // PAYMENT TITLE
  ChargeDeposit: 'Пополнение вклада',
  BetweenAccountTrasnfer: 'Между счетами',
  TransferAnotherBank: 'Себе в другой банк',
  UpBrokerageAccount: 'Пополнить Брокерский счёт',
  TransferFinsClient: 'Перевод клиенту',
  own_bank_account_transfer: 'Перевод между своими счетами',
  invest_account_replenishment: 'Пополнение брокерского счёта',
  invest_account_withdraw: 'Вывод с брокерского счёта',
  internal_bank_account_transfer: 'Перевод клиенту БКС банка',
  external_bank_account_transfer: 'Перевод на счёт',
  external_own_bank_account_transfer: 'Перевод себе в другой банк',
  paymentLimits: (string) => `Вы можете переводить до ${string}`,
  transferAllAccountBill:
    'Выполнить перевод на банковский счёт в размере остатка',
  transferAllAccountBillWarning:
    'Поставьте галочку, если вы хотите вывести все деньги с брокерского счёта. Или вы сможете вывести не больше 85% от суммы на счёте.',
  transferAllAccountBillWarningTooltipText:
    'Согласно п. 20.12 <a rel="noopener noreferrer" target="_blank" href="https://broker.ru/regulations">«Регламента оказания услуг на рынке ценных бумаг ООО "Компания БКС"»</a>',

  Purpose: 'Назначение платежа',
  RequisitesReplenishment: 'Реквизиты для пополнения',
  ReceiverName: 'ФИО получателя',
  receiverBankAccountNumber: 'Номер счёта получателя',
  ThereIsNotEnoughMoneyToTransfer:
    'Не хватает денег для перевода. <br /> Пополните счёт списания или уменьшите сумму.',
  HowFundAccount: 'Как пополнить счёт?',
  FundAccountTooltipAnswer: (
    <div>
      1. Скопируйте реквизиты
      <br />
      и назначение платежа.
      <br />
      2. Откройте свой
      <br />
      онлайн-банк, выберите
      <br />
      перевод юридическому
      <br />
      лицу.
      <br />
      3. Вставьте скопированные
      <br />
      данные. Важно заполнить
      <br />
      назначение платежа в том
      <br />
      же виде, иначе деньги не
      <br />
      дойдут.
    </div>
  ),
  BrokerageDisclaimer: `
    Зачисление денег может занять до 3 рабочих дней. Если за 3 дня деньги не пришли, обратитесь в Службу поддержки: по бесплатному номеру 8 800 500 10 01; по почте <a href="mailto:help@mail.fins.money">help@mail.fins.money</a>.
    Мы отвечаем в будние дни с 9.30 до 19.00 МСК.
  `,

  //
  loginNewUserRegistration:
    'Создайте учетную запись, чтобы войти в сервис и начать зарабатывать больше.',

  // HELP CENTER
  helpPanel: {
    title: 'Справочный центр',
    placeholder: 'Поиск в справочном центре',
    emptySearch: 'Ничего не найдено, попробуйте изменить ваш вопрос',
    chooseCategory: 'Выбор категории',
    feedbackQuestion: 'Полезна ли вам эта статья?',
    feedbackIsSent: 'Спасибо за ваше мнение, оно важно для нас',
    feedbackYes: 'Да',
    feedbackNo: 'Нет',
  },

  feedbackPanel: {
    title: 'Сообщить о проблеме',
    general: {
      title: 'Что как по фичам?',
      desc: '---',
      message: 'Спасибо за отзыв!',
    },
  },

  // TRANSFER PROMO
  IfStockPricesRise: 'Если цены акций растут',
  IfStockPricesDown: 'Если цены акций снижаются',

  MakingAnAccount: 'Оформление счёта',

  DefaultStatusServiceTitle: 'Внутренняя ошибка сервера',
  DefaultStatusServiceDescription: 'Не беспокойтесь, скоро всё заработает.',

  ConnectErrorTitle: 'Мы не можем соединиться',
  ConnectErrorDescription:
    'Возможно, ваш компьютер находится в автономном режиме или сервер временно недоступен. Мы будем автоматически переподключаться.',
  ReconnectDescription:
    'Перезагрузите страницу и проверьте подключение к интернету',

  ProductOfTheMonth: 'Продукт месяца',
  SpecialOffers: 'Спецпредложения',
  ServiceAssistant: 'Ваш помощник',
  SelectionProduct: 'Подобрать продукт по параметрам',
  InvestAndDeposit: 'Инвестиции и вклады',
  BestProduct: 'Лучшие ставки по продуктам',
  selectionProduct: {
    Deposit: {
      welcome: 'Как меняются ставки в зависимости от срока',
      button: 'вклады',
    },
    Investment: {
      welcome: 'Как меняются ставки в зависимости от срока',
      button: 'инвестиции',
    },
  },
  revenueChart: 'График доходности',
  byGrowthOfStocks: 'От прироста акций',

  ExpandedSearch: 'Расширенный поиск',
  ExpandedSearchOff: 'Скрыть расширенный поиск',
  ChangeParamsMarketButtonFilter: 'Применить',
  ResetParamsMarketButtonFilter: 'Сбросить',

  DepositOpenRequestSent: 'В процессе покупки',
  DepositOpenFaield: 'Ошибка в процессе покупки вклада',
  DepositRequestClose: 'В процессе закрытия',
  DepositOpenWaitingForCharge: (date) => `Пополнить <br /> до ${date}`,

  interestPaymentPeriod: {
    daily: 'ежедневно',
    week: 'еженедельно',
    month: 'ежемесячно',
    quarter: 'ежеквартально',
    half_year: 'раз в полгода',
    year: 'раз в год',
    end: 'в конце срока',
    ahead: 'проценты вперёд',
  },

  interestPaymentPeriodNewScheme: {
    3: 'ежедневно',
    4: 'еженедельно',
    5: 'ежемесячно',
    6: 'ежеквартально',
    7: 'раз в полгода',
    8: 'раз в год',
    1: 'в конце срока',
    2: 'проценты вперёд',
  },

  // status text open account
  inProgressMarket:
    'Вы сможете завершить покупку, когда счета будут открыты. Нужно немного подождать.',
  inProgressFinsAsset: `Нужно немного подождать.`,
  inProgressTransfer:
    'Скоро вы сможете переводить деньги. Пожалуйста, дождитесь открытия счетов.',
  OpenRequestFailedPermament:
    'К сожалению, сейчас вы не можете открыть счета и пользоваться всеми возможностями сервиса.',
  OpenAccountFailedStatus:
    'Вам нужно ещё раз отправить заявку на открытие счетов.',
  OpenAccountInProgressStatus: 'В процессе открытия',
  NeedOpenAccount:
    'Понравился продукт? Чтобы купить его,  откройте и пополните брокерский счет.',
  closeDate: (value) => `До ${value}`,

  YouEarn: 'Вы заработаете',
  Details: 'Детали',
  ConditionalDeposit: 'Условия вклада',
  CurrencyDeposit: 'Валюта вклада',
  Requisites: 'Реквизиты',

  orderByTitle: {
    vendor: 'По банкам',
    currencty: 'По валюте',
    closeDate: 'Срок окончания',
    interest: 'По доходу',
  },

  ToHome: 'На главную',
  Learn: 'Узнать',
  StartTest: 'Пройти тест',

  IIATextHasOpen:
    'Чтобы получить <b>налоговый вычет</b> от государства, необходимо открыть Индивидуальный Инвестиционный Счет. Это быстро и <b>бесплатно</b>. &nbsp;',

  EmailConfirmationPage: 'Подтверждение почты',
  EmailConfirmationErorr: 'Ошибка подтверждения почты',
  EmailConfirmationSuccessTitle: 'Регистрация успешна завершена!',
  EmailConfirmationSuccessDescription:
    'Откройте счета онлайн, чтобы совершать выгодные вложения. Это быстро и бесплатно.',

  AnnualYield: 'Годовая доходность',
  participationRate: 'От прироста акций',
  RefundOfYourMoney: 'возврат ваших денег',
  Invest: 'Инвестиция',
  AmountInvest: 'Инвестирование',

  ShowMoreDeposit: 'Посмотреть больше вкладов',

  StructuralProductNote:
    'Готовое инвестиционное решение с оптимальным соотношением доходность/риск, разработанное финансовыми аналитиками. При покупке структурного продукта вы заключаете контракт, в котором фиксируется условие: в случае роста акций вы получаете повышенный доход, а в случае падения — защищены от потери своих денег. Деньги нельзя забрать до истечения срока контракта, иначе вы окажетесь в убытке.',

  SecretQuestion: 'Контрольный вопрос',
  Answer: 'Ответ',
  SecretQuestionText:
    'В целях безопасности вам необходимо задать контрольный вопрос и указать ответ. Это поможет быстро восстановить доступ к сервису в случае блокировки.',
  changeDeliveryDate: 'Выберите новую дату доставки',

  howReplenishAccounts: 'Как пополнить счета по реквизитам?',
  WarningDeliveryTime:
    'Если вы отправляете анкету позже 17.00 МСК, выберите дату доставки через один рабочий день.',
  WarningDeliveryTimeValid:
    'Анкеты, отправленные позже 17.00 МСК, мы обрабатываем на следующий рабочий день. Пожалуйста, выберите другую доступную дату доставки.',

  TransferValidMaxPersent:
    'Пожалуйста, введите сумму до 85% от остатка на счёте. Или выберите Вывести весь остаток со счёта.',

  KnowLevelOfRisk: 'Узнать свой финансовый риск-профиль',
  PerrsonalData: 'Личные данные',
  ContactData: 'Контактные данные',
  Adresses: 'Адреса',

  ScrappedInFinsBanks: 'Подключенные к банки',
  YouDontHaveScrappedBanks: 'У вас нет подключенных онлайн-банков',
  YouDontHaveScrappedBanksText:
    'Добавьте свои счета, карты и вклады, чтобы получать полезные рекомендации и видеть все деньги в одном месте. Это безопасно: ни одна денежная операция не совершается без вашего подтверждения по смс.',

  ConfirmEmail: 'Подтвердите email',
  EmailSentError: 'Возникла ошибка при отправке письма.',
  EmailSent: 'Письмо отправлено, проверьте ваш почтовый ящик.',
  SendEmail: (email) =>
    `Перейдите по ссылке из письма, которое мы отправили на <b>${email}</b>. Если вы не нашли письмо, проверьте папку спам, или отправьте ссылку повторно.`,
  ResendConfirmEmail: 'Отправить ссылку повторно',
  placeOfBirth: 'Место рождения',
  PassportIssuerShort: 'Выдан',
  ConfirmedMan: 'Подтвержден',

  GeneralAgreement: 'Генеральное соглашение',
  BeginningOfPeriod: 'Начало периода',
  FinishOfPeriod: 'Конец периода',
  Refreshed: 'Обновлено',

  // Promo
  HeaderTooltipParticipation:
    'Готовое решение с защитой вложений, разработанное финансовыми аналитиками. При покупке структурного продукта вы заключаете контракт, в котором фиксируются условие: в каком случае вы получаете повышенный доход.',
  HeaderTooltip:
    'Готовое решение с защитой вложений и гарантированным доходом, разработанное финансовыми аналитиками. При покупке структурного продукта вы заключаете контракт: в нём фиксируется условие, в каком случае вы получаете повышенный, а в каком – минимальный гарантированный доход.',
  HeaderTitle: `
    Инвестиции</br>
    с гарантированным</br>
    доходом <span>11%</span><small>годовых</small>
  `,

  HeaderTextFirst:
    'Продукт "Максимум дохода" разработан финансовыми аналитиками БКС.',
  HeaderTextSecond:
    'Это готовая инвестиционная стратегия, которая застрахует от колебаний курса рубля и позволит получить гарантированный высокий доход. Если курс доллара к рублю растет, ваш доход увеличивается.',
  AttachOnline: 'Вложить онлайн',
  PromotionRules: 'Правила акции',

  AboutProduct: 'О продукте',

  HowWorkTitle: 'Как работает продукт',
  HowWorkText:
    'Если курс доллара к рублю вырастет на 10% и более, то вы получите повышенный доход — 19% годовых + 100% вложенных денег в конце срока.',

  HowWorkCardFirstText: 'Гарантированно',
  HowWorkCardSecondText: 'Если курс $ к ₽ растет на 10 % и более',
  // HowWorkCardThirdText: `Если открыть <a rel="noopener noreferrer" target="_blank" href="${IIS}">ИИС</a>`,

  OptionsTitle: 'Варианты развития событий в течение срока вложения',

  ForecastTitleFirst: 'Позитивный сценарий',
  PriceOfStockArise: 'Цена акций выросла',
  PriceOfStockNegative: 'Цена акций не выросла или упала',
  ForecastTextFirst:
    'Если к концу срока продукта курс $ к ₽ вырастет больше, чем на 10%, то вы получите:',
  afterTreeMonth: 'через 3 мес',
  IncreasedRevenue: 'Повышенный доход',
  IncreasedRevenueTooltip:
    'Повышенный доход – максимально возможный доход в процентах годовых, который вы можете получить.',
  InvestedFunds: 'Вложенные средства',

  ForecastTitleSecond: 'Нейтральный сценарий',
  ForecastTextSecond:
    'Если к концу срока продукта курс $ к ₽ упадёт или вырастет меньше, чем на 10%, то вы получите:',
  endOfTerm: 'в конце срока',
  GuaranteedIncome: 'Гарантированный доход',
  GuaranteedIncomeTooltip:
    'Гарантированный доход – минимальный доход в процентах годовых, который вы получите в любом случае.',

  UsersAlertTitle:
    'Продукт доступен только новым клиентам: тем, кто никогда раньше не открывал счета и не инвестировал в БКС.',

  WhyItsSafe: 'Почему это безопасно',
  FirstCardPlitText:
    'Все условия продукта фиксируются в договоре с инвестиционной компанией БКС',
  SecondCardPlitText:
    'БКС — лидер российского рынка ценных бумаг с 23-летним опытом и 300 000 клиентов',
  ThirdCardPlitText:
    'В 2017 году агентство RAEX присвоило БКС рейтинг кредитоспособности ruBBB+ со стабильным прогнозом',
  FourthCardPlitText:
    'Каждая третья  сделка на российском фондовом рынке принадлежит БКС',

  AccountPackageTitle:
    'Чтобы инвестировать в «Максимум дохода» онлайн, откройте бесплатный пакет счетов.',
  MinimumAmountInvestment: 'Минимальная сумма вложения',
  MinimumAmountInvestmentCost: '10 000 ₽',
  PeriodCost: '3 месяца',
  ReferenceInformationTitle: 'Если остались вопросы',
  ReferenceInformationText: `
    Звоните по бесплатному номеру</br>
    8 800 500 10 01 или пишите в чат.</br>
    Мы ответим вам в будние дни</br>
    с 9.30 до 19.00 МСК`,

  // Lifehack
  Lifehack: 'Лайфхак',
  LifehackSubtitle: '60 секунд, 3 шага',
  HowToYours: 'Как к своим',
  Deopsits: 'Вложения',
  ToGet: 'получить',
  ByYear: 'за год',
  WithGovernmentParticipation: 'Государство доплатит!',
  LetsGo: 'Погнали',
  OpenInvestmentAccount: 'Откройте инвестиционный счёт',
  LifehackOpenAccount: 'Открыть счёт',
  OpenInvestmentAccountDescription:
    'Индивидуальный инвестиционный счёт&nbsp;&mdash; это особый брокерский счёт, по&nbsp;которому вы&nbsp;можете получать налоговые вычеты в&nbsp;размере&nbsp;13% от&nbsp;суммы инвестирования.',
  OpenInvestmentAccountCaption: 'Открытие и обслуживание ИИС <b>бесплатны</b>.',
  IncomePerYear: 'доход с вложений за год',
  IncomePerThreeYears: 'доход с вложений за 3 года',

  InvestIntoProduct: (product) =>
    `Инвестируйте с&nbsp;ИИС в&nbsp;&laquo;${product}&raquo; на&nbsp;полгода`,
  InvestIntoProductDescription: (product, minRate) =>
    `&laquo;${product}&raquo;&nbsp;&mdash; структурный продукт c&nbsp;наиболее высоким гарантированным доходом&nbsp;&mdash; ${minRate}%.`,
  InvestProductCaption: (product, minRate) =>
    `В&nbsp;&laquo;${product}&raquo; предусмотрена <b>100% защита ваших денег</b>. При любом сценарии деньги вернутся обратно + минимум&nbsp;${minRate}% дохода.`,
  InvestmentIncome: 'доход с вложений',
  GetMoney: 'Получите ещё больше денег',
  GetMoneyDescription: (increaseRate, minRate, incomeRate, totalRate) =>
    `Если курс доллара вырастет за&nbsp;6&nbsp;месяцев на&nbsp;${increaseRate}% или больше, то&nbsp;к&nbsp;гарантированным&nbsp;${minRate}% вы&nbsp;получите ещё&nbsp;${incomeRate}% в&nbsp;виде повышенного дохода. Итого&nbsp;&mdash; ${totalRate}% годовых!`,
  InTotal: 'Итого',
  OpenInvestmentAccountOnline: 'Открыть ИИС онлайн',
  GetMoneyCaption:
    '<b>Внимание</b>: деньги с&nbsp;ИИС нельзя снимать три года, но&nbsp;вычеты можно получить раньше. Если вы&nbsp;открыли ИИС в&nbsp;декабре, вычеты по&nbsp;нему можно получить уже в&nbsp;январе. А&nbsp;чтобы не&nbsp;замораживать деньги&nbsp;&mdash; продолжайте инвестировать в&nbsp;другие продукты.',

  WhatToSeeBeforeProductSelection: 'Подходит ли вам покупка продуктов на ИИС?',
  SuitsYouIf: 'Да, если',
  LifehackYes1: 'Вы хотите доходность выше, чем по вкладу',
  LifehackYes2: 'Вы платите НДФЛ, и можете рассчитывать на налоговые вычеты',
  LifehackYes3: 'Вы готовы инвестировать деньги на три года',
  NotSuitsYouIf: 'Нет, если',
  LifehackNo1:
    'Вы хотите иметь возможность снимать деньги в любое время без потери дохода ',
  IIAManualt: 'Руководство по ИИС',
  BondPurchaseInfoAboutCommissionBuy:
    'Мы увеличили цену в заявке на 0,3% чтобы повысить вероятность исполнения сделки.',
  BondPurchaseInfoAboutCommissionSell:
    'Мы уменьшили цену в заявке на 0,3% чтобы повысить вероятность исполнения сделки.',
  BondPurchaseInfoAboutCommissionTooltip:
    'Цены на бирже постоянно меняются, иногда по несколько раз в секунду. Чтобы не отменять сделку из-за незначительных колебаний, мы добавляем дополнительные 0,3% к цене. Если цена на выбранные бумаги не изменится, или уменьшится, с вашего счета будет списана меньшая сумма.',

  PurchaseBondsTitle: 'Покупка облигаций',
  SoldBondsTitle: 'Облигации успешно проданы',
  BuySuccessBondsTitle: 'Облигации успешно куплены',
  ReturnToAssets: 'Вернуться в активы',
  SellBondsTitle: 'Продажа облигаций',
  NKD: 'НКД',
  TransactionFee: 'Комиссия за сделку',
  TotalAmount: 'Общая сумма',
  BondPriceChanged: 'Стоимость облигации изменилась',
  ChangesBondPortfolio:
    'Изменения в портфеле отразятся в течение нескольких минут',
  AmountOneLotTitle: 'Стоимость (1 лот)',
  TradeFloorClosed: 'В данный момент биржа не работает',

  Online: 'Онлайн',
  Offline: 'Оффлайн',

  GoToMainPage: 'Перейти на главную',
  GoToMarket: 'Перейти в маркет',
  Company: 'Компания',
  Protection: 'Защита',
  incomeSourceDisclaimerRest: `Заключение генерального соглашения и депозитарного
  договора осуществляется мною лично, без участия представителя. От моего имени не будет
  действовать представитель при совершении операций после открытия счёта в ООО “Компания БКС”. Я
  не действую и не буду действовать к выгоде другого лица при проведении сделок и иных операций.
  У меня нет бенефициарного владельца - стороннего физического лица. Я не являюсь должностным
  лицом, указанным в ст. 7.3 Федерального закона № 115-ФЗ от 07.08.2001 г., и/или супругом(-ой),
  и/или близким родственником вышеуказанного должностного лица. Мои финансовое положение и
  деловая репутация положительны.`,
  incomeSourceSalary: 'Заработная плата',
  incomeSourceBusiness:
    'Доход от осуществления предпринимательской деятельности и (или) от участия в уставном (складочном) капитале коммерческой организации',
  incomeSourceStocks:
    'Доход от операций с ценными бумагами и (или) иностранными финансовыми инструментами, неквалифицированными в соответствии с законодательством РФ в качастве ценных бумаг',
  incomeSourceDerivatives:
    'Доход от операций с производными финансовыми инструментами',
  incomeSourceForex:
    'Доход от операций с иностранной валютой на организованных торгах и (или) на внебиржевом рынке (Forex)',
  incomeSourceInheritance: 'Получение наследства',
  incomeSourceDonation: 'Получение активов по договору дарения',
  incomeSourceLoan: 'Заем, кредит',
  incomeSourceSelfEmployer: 'Профессиональный доход самозанятого лица',
  incomeSourceAnother: 'Иное',

  dropzoneFileTooBig: 'Файл слишком большой',
  dropzoneLimit: 'Загрузите файлы формата jpg или png не более 15 мб',
  dropzoneFirstPage: 'Первая страница паспорта',
  dropzoneSecondPage: 'Страница с регистрацией',
  dropzoneLoading: 'Загружаем файл...',
  dropzoneAnalyzing: 'Анализируем паспортные данные',
  dropzoneAccepted: 'Успешно подтвердили',
  dropzoneFailure: 'Не удалось считать данные',

  flowSelectionScans: 'Использовать фото паспорта',
  flowSelectionManual: 'Ввести паспортные данные в ручном режиме',
  enterPromoCode: 'Ввести промокод',

  passportData: 'Паспортные данные',

  yourIncomeSource: 'Источник ваших доходов',
  change: 'изменить',
  openAccountSingle: 'Открыть счёт',
  promocodeApplied: 'Промокод применен',
  passportDataAccepting: 'Паспортные данные подтверждаются',
  fastProcessTwoMinutes:
    'Это быстрый процесс, через 1-2 минуты все будет готово.',
  brokerageAccountOpening: 'Открываем брокерский счёт',
  brokerageAccountOpeningDesc:
    'Данные подтверждены и документы подписаны. Вам придет смс, когда брокерский счёт будет открыт.',
  brokerageAccountOpened: (withIIA) =>
    withIIA
      ? 'Поздравляем, ваш ИИС и брокерский счёт открыт'
      : 'Брокерский счёт открыт',
  brokerageAccountOpenedDesc: (withIIA) =>
    withIIA
      ? 'Теперь вы можете пополнить счёт и покупать инвестиционные продукты.'
      : 'Теперь вы можете пополнить счёт и покупать инвестиционные продукты.',
  passportDataProcessing: 'Паспортные данные обрабатываются',
  fastProcessFiveMinutes:
    'Через несколько минут проверка будет закончена. Вы сможете подписать документы на открытие счёта',
  topUpAccount: 'Пополнить счёт',
  goToMarket: 'Выбрать продукты',
  documentsSigning: 'Подпишите документы',
  readAndSignDocuments:
    'Прочтите и подпишите сформированные документы для заключения договоров на брокерское и депозитарное обслуживание с ООО “Компания БКС”.',
  sign: 'Подписать',
  passportDataConfirmation: 'Подтвердите паспортные данные',
  addressesMatch:
    'Фактический адрес проживания совпадает с адресом регистрации',
  expand: 'Развернуть...',
  promoCode: 'Промокод',
  apply: 'Применить',

  syncStatus: 'Синхронизируем статус',
  syncStatusDesc: 'Обновляем статус заявки открытия счёта.',

  sendingOTP: 'Отправляем смс',
  sendingOTPPhoneDesc:
    'Для открытия счёта необходимо подтвердить номер телефона по смс. Оно придет через 5-10 секунд.',
  sendingOTPDocsDesc:
    'Подготавливаем смс с кодом для подписания документов. Оно придет через 5-10 секунд.',

  enterOTP: 'Введите код',
  enterOTPPhoneDesc: (phone) =>
    `Мы отправили смс с кодом подтверждения паспортных данных на ваш телефон ${phone
      .split(' ')
      .join('\u00A0')}.`,
  enterOTPDocsDesc: (phone) =>
    `Мы отправили смс с кодом для подписания документов на ваш телефон ${phone
      .split(' ')
      .join('\u00A0')}`,
  OTPCode: 'Код из смс',
  resendSMS: 'Отправить смс ещё раз',
  OTPLimitExceeded: 'Превышен лимит отправки смс. Обновляем сессию.',
  whatIsInn: 'Что такое ИНН и где его взять?',
  innFieldDescription:
    'Это поле поможет ускорить процесс проверки паспорта. Пропустите, если не знаете.',
}

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  products: null,
  productsLoadingError: null,
}

const topProducts = createSlice({
  name: 'topProducts',
  initialState,
  reducers: {
    saveTopProducts: (state, action) => {
      state.products = action.payload
    },
    topProductsLoading: (state, action) => {
      state.loading = action.payload
    },
    saveTopProductsError: (state, action) => {
      state.productsLoadingError = action.payload
    },
  },
})

const { reducer, actions } = topProducts

export const { saveTopProducts, topProductsLoading, saveTopProductsError } =
  actions

export default reducer

import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { Registration } from '../../components/forms/Registration'
import { STAGES } from '../../constants/START_GETTING_INCOME'
import SuccessImage from './assets/success.svg'
import './index.less'

const StartGettingIncome = () => {
  const { isMobile } = useSelector((state) => state.global)
  const { result } = useSelector((state) => state.security.invite)

  return (
    <div className="content content--more-pd content--radius">
      <div className="content__block">
        {isMobile && (
          <h3 className="start_getting_income__title">
            Начните получать доход с Fins
          </h3>
        )}
        <div className="start_getting_income">
          <div className="start_getting_income__stages">
            {STAGES.map((stage) => (
              <div className="start_getting_income__stage" key={stage.key}>
                <span>{stage.key}</span>
                <span>{stage.text}</span>
              </div>
            ))}
          </div>
          <div
            className={classNames(
              'start_getting_income__form',
              result && 'start_getting_income__success',
            )}
          >
            {!isMobile && !result && <h3>Начните получать доход с Fins</h3>}
            {!result && <Registration />}
            {result && (
              <div className="start_getting_income__success-info">
                <img src={SuccessImage} alt="success" />
                <p>
                  Спасибо!
                  <br />В ближайшее время вам перезвонит наш финансовый
                  консультант
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StartGettingIncome

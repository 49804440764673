import { request } from '../request'

/**
 * Получения списка топ продуктов, разделенных по категориям
 * @url https://fins.money/gateway/api/docs/swagger-private/swagger-ui/index.html?configUrl=%2Fgateway%2Fapi%2Fdocs%2Fswagger-private%2Fswagger-ui%2Fconfig&urls.primaryName=market#/TopProducts
 * @returns
 */
export const fetchTopProducts = async () => {
  return request(
    `/gateway/api/market/v6/products/top`,
    {
      method: 'GET',
    },
    true,
  )
    .then((response) => {
      return response
    })
    .catch((error) => ({ error }))
}

import React from 'react'
import { Registration } from '../pages/Registration'
import { SuccessRegistration } from '../pages/SuccessRegistration'

export const registration = {
  '/registration': {
    component: (props) => <Registration {...props} />,
    exact: true,
  },
  '/registration/success': {
    component: (props) => <SuccessRegistration {...props} />,
    exact: true,
  },
}

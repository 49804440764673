import React from 'react'
import { useSelector } from 'react-redux'
import { Divider } from 'antd'
import { TestingPageSectionSelector } from 'components/TestingPageSectionSelector'

import BcsImage from 'assets/bcs_logo.svg'
import FinsImage from 'assets/fins_logo.svg'

import '../index.less'

export const Header = () => {
  const { isMobile } = useSelector((state) => state.global)
  const [showAfterScroll, setShowAfterScroll] = React.useState(false)

  const changeMenuVisible = React.useCallback(() => {
    const y = window && window?.scrollY

    const testingBlock = document.querySelector('.testing_content__main')
    const element = testingBlock
    const { height: blockHeight } = element.getBoundingClientRect()
    setShowAfterScroll(y > blockHeight)
  }, [])

  React.useEffect(() => {
    window.addEventListener('scroll', changeMenuVisible)
    return () => window.removeEventListener('scroll', changeMenuVisible)
  }, [changeMenuVisible])

  return (
    <>
      <div className="testing-header">
        <div className="testing-header__block">
          <img
            src={FinsImage}
            className="testing-header__fins-logo"
            alt="fins logo"
          />

          <Divider className="testing-header__divider" type="vertical" />
          <img
            src={BcsImage}
            className="testing-header__mi-logo"
            alt="bcs logo"
          />
        </div>
      </div>
      <TestingPageSectionSelector afterScroll={showAfterScroll} />
    </>
  )
}

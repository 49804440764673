import React from 'react'
import '../index.less'

export const Section8 = ({ id }) => {
  return (
    <>
      <h1 id={id}>Главное о ETF</h1>
      <h2>Разберемся в основных принципах инвестирования в ETF.</h2>
      <p>
        ETF (Exchange Traded Funds, торгуемый на бирже фонд) — это биржевой
        фонд, который инвестирует в определенные группы активов. Механизм работы
        ETF достаточно прост. Компания (эмитент ETF) имеет определенный актив
        (например, акции из индекса или золото) и на этот актив выпускает ценные
        бумаги, динамика цены которых зависит от динамики цены базового актива.
      </p>
      <p>
        ETF как биржевой инструмент зародились в конце 70-х годов. Тогда первый
        ETF был выпущен компанией The Vanguard Group. Но свою популярность ETF
        приобрели только в 90-х годах. Сейчас это один из самых бурно
        развивающихся инструментов. По последним данным компании ETF Global,
        больше половины всех ETF приходится на рынок США. Именно там расположены
        штаб-квартиры самых крупных эмитентов: State Street Global Advisers,
        BlackRock Inc, The Vanguard Group.
      </p>
      <ul>
        <li>
          Погашение номинала полностью в конце срока. По умолчанию сумма
          номинала выплачивается инвестору в полном размере в дату погашения
          облигации. Такой тип погашения является самым распространенным среди
          бумаг на Московской бирже.
        </li>
      </ul>
      <h2>Виды ETF</h2>
      <p>
        ETF можно классифицировать по типу актива, на который они выпущены. ETF
        позволяет инвестировать в:
      </p>
      <ul>
        <li>Фондовые рынки стран</li>
        <li>Отдельные сектора экономики</li>
        <li>Валюты</li>
        <li>Индексы</li>
        <li>Еврооблигации</li>
        <li>Биржевые товары (золото, нефть, серебро и т.д.)</li>
        <li>другие активы.</li>
      </ul>
      <p>
        Существует еще ряд ETF с более сложной структурой управления. Такие ETF
        называют экзотическими или специфическими. Среди них можно выделить:
      </p>
      <ul>
        <li>
          Перевернутые или реверсивные ETF —  динамика цены таких ETF обратно
          пропорциональна цене базового актива. Такие ETF, попросту говоря,
          «шортят» базовый актив.
        </li>
        <li>
          Маржинальные ETF — противоположны реверсивным, они используют механизм
          длинной позиции, чтобы показать большую доходность, чем базовый актив.
          Например, при росте цены базового актива на 1%, цена ETF вырастет на
          2%.
        </li>
        <li>
          ETF на волатильность (VIX) — ETF, в основе которого лежит индекс
          волатильности или, как его еще называют, индекс страха.
        </li>
      </ul>
      <h2>Механизм формирования цены</h2>
      <p>
        Цена акции индексного фонда будет повторять динамику цен на активы,
        которые входят в основу фонда.
      </p>
      <p>
        Цена одной акции фонда — стоимость чистых активов фонда, приходящаяся на
        одну акцию. Это расчетная (справедливая) стоимость акции — некий
        ориентир цены ETF. Расчетная цена акций фонда публикуется на сайте фонда
        или информационных агентств.
      </p>
      <p>
        При этом рыночная цена акции фонда на бирже может отличаться от
        расчетной. На это может повлиять множество факторов: курс валюты,
        разница между спросом и предложением. Чтобы рыночная цена на бирже была
        максимально приближена к расчетной, кроме обычных покупателей и
        продавцов, на бирже есть маркетмейкеры — они ставят цены, близкие к
        «справедливым», то есть расчетным. Маркетмейкер должен участвовать в
        торгах не менее 7 часов в течение дня. Таким образом, цена акции фонда
        на бирже стремится к расчетной.
      </p>
      <h2>Особенности работы с ETF</h2>
      <p>
        ETF — это биржевой инструмент, акции которого можно очень просто купить.
        Ликвидность ETF достаточно высокая, поэтому купить и продать акции фонда
        можно в любой момент во время торговой сессии.  Для этого вам необходим
        обычный брокерский счет или ИИС (для работы с ETF на российских биржах).
      </p>
      <p>
        Наибольшее количество ETF торгуются на площадке NYSE Arca. Данная
        площадка недоступна для торговли неквалифицированным клиентам.
        Центральный Банк РФ не классифицирует часть иностранных ETF (например,
        на волатильность) как ценные бумаги, поэтому на данный момент нет
        возможности купить их на российский брокерский счет.
      </p>
      <p>
        Многие ETF, кроме всего прочего, выплачивают держателям своих акций
        дивиденды. Дивиденды обеспечивают инвесторам регулярный доход, который в
        дальнейшем можно реинвестировать в новые активы, положить на хранение
        или потратить на свое усмотрение. Дивидендный доход от ETF отличается от
        того, который выплачивается по акциям. Базовым активом по ETF могут
        выступать сотни акций, по каждой из которых выплачиваются дивиденды. По
        ETF дивиденды рассчитываются и распределяются на пропорциональной
        основе. Минусом такой системы может стать тот факт, что ставка
        дивидендной доходности может часто меняться. С другой стороны, дивиденды
        поступают от диверсифицированной группы акций, что снижает риск и может
        принести большую доходность.
      </p>
      <p>
        Еще одна особенность, на которую стоит обратить внимание — это комиссия
        за управление фондом. Однако это не та комиссия, которую взимает брокер
        или биржа, и она не взимается дополнительно. Эта комиссия уже включена в
        стоимость фонда. Все ETF (как и ПИФ) взимают со своих акционеров плату
        за управление фондом (Expense rates). Эмитент фонда в течение всего
        действия ETF имеет операционные расходы (налоги, административные
        расходы, маркетинг и т.д.). Эта комиссия выражается как процент от
        средних чистых активов фонда.
      </p>
      <h2>Риски инвестирования</h2>
      <p>
        <b>Во-первых</b>, вы можете столкнуться с фактом отставания цены ETF от
        динамики цены базового актива, однако в некоторых случаях это может
        сыграть на руку. Например, в случае, если на рынке произошла коррекция,
        вы можете успеть выйти из позиции, пока ваш ETF не последовал вниз за
        базовым активом.
      </p>
      <p>
        <b>Во-вторых</b>, в случае если вы работаете со сложными ETF, может
        возникнуть необходимость дополнительного анализа структуры и рисков
        этого инструмента. Например, перед покупкой реверсивного ETF необходимо
        проанализировать размер плеча и соответствие динамике цены базового
        актива.
      </p>
      <p>
        <b>В-третьих</b>, существует риск закрытия странового индекса для
        соответствующих ETF. Преобладающее количество ETF в мире выпущены на
        акции, а точнее на индексы, например S&P 500. Но далеко не все ETF имеют
        привязку к известным индексам, так как обслуживание таких индексов
        дорогое. Поэтому для создания ETF чаще всего используют малоизвестные
        индексы.
      </p>
      <p>
        <b>В-четвертых</b>, не стоит забывать и о риске делистинга инструмента.
      </p>
      <h2>Причины, по которым стоит рассматривать ETF</h2>
      <p>
        Сравнительно низкая стоимость. Этот инструмент позволяет инвестировать
        небольшие деньги в крупные компании. Если покупать эти же акции
        напрямую, то придется вложить больше. Например, акции Apple и Facebook
        стоят сотни долларов, а некоторые ETF кратно дешевле, хотя включают в
        себя акции и Apple, и Facebook.
      </p>
      <p>
        Диверсификация. ETF позволяют вкладывать деньги не только в отдельные
        компании, но и в целые отрасли, страны или рынки, что существенно
        снижает риск потери денег.
      </p>
      <p>Ликвидность. ETF можно быстро продать или купить.</p>
      <p>
        Прозрачность. Структура и другая информация о ETF находится в открытом
        доступе и посмотреть ее может любой инвестор.
      </p>
      <div className="testing_divider" />
    </>
  )
}

import React from 'react'
import { Carousel } from 'antd'

import { contentTypes } from '../../constants/PRODUCT_TABS'

import './index.less'

const MobileTabs = ({ tabs }) => {
  return (
    <div className="mobile-tabs">
      <Carousel dots={{ className: 'mobile-tabs__dots' }} adaptiveHeight>
        {tabs.map((tab) => (
          <div key={tab.key} className="mobile-tabs__tab-wrap">
            <div className="mobile-tabs__tab">
              <div className="mobile-tabs__tab-icon">{tab.icon}</div>
              <div className="mobile-tabs__tab-title">{tab.title}</div>
            </div>
            <div className="mobile-tabs__content">
              {tab.content?.map((content) => (
                <div>
                  {content.type === contentTypes.title && (
                    <h4 className="mobile-tabs__content-title">
                      {content.value}
                    </h4>
                  )}
                  {content.type === contentTypes.paragraph && (
                    <p className="mobile-tabs__content-paragraph">
                      {content.value}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export default MobileTabs

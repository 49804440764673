import React from 'react'

import { Section1 } from 'pages/Testing/components/Section1'
import { Section3 } from 'pages/Testing/components/Section3'
import { Section4 } from 'pages/Testing/components/Section4'
import { Section5 } from 'pages/Testing/components/Section5'
import { Section6 } from 'pages/Testing/components/Section6'
import { Section7 } from 'pages/Testing/components/Section7'
import { Section8 } from 'pages/Testing/components/Section8'
import { Section9 } from 'pages/Testing/components/Section9'
import { Section10 } from 'pages/Testing/components/Section10'

export const TESTING_PAGE_SECTIONS = [
  {
    key: 1,
    value: 'Облигации со структурным доходом',
    component: <Section1 id="section1" />,
  },
  {
    key: 3,
    value: 'Как инвестировать в облигации',
    component: <Section3 id="section3" />,
  },
  {
    key: 4,
    value: 'Сделки с неполным покрытием — можно, но осторожно',
    component: <Section4 id="section4" />,
  },
  {
    key: 5,
    value: '«Акции – базовые основы инвестирования»',
    component: <Section5 id="section5" />,
  },
  {
    key: 6,
    value: 'Сделки РЕПО — инструмент получения ликвидности на финансовом рынке',
    component: <Section6 id="section6" />,
  },
  {
    key: 7,
    value: 'Как инвестировать в облигации иностранных эмитентов',
    component: <Section7 id="section7" />,
  },
  {
    key: 8,
    value: 'Главное о ETF',
    component: <Section8 id="section8" />,
  },
  {
    key: 9,
    value: 'ПИФ и ЗПИФ — в чем разница?',
    component: <Section9 id="section9" />,
  },
  {
    key: 10,
    value:
      'Производные финансовые инструменты: фьючерс, опцион — в чем разница',
    component: <Section10 id="section10" />,
  },
]

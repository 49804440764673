import React from 'react'
import { Collapse } from 'antd'
import classNames from 'classnames'
import { FAQS, PRODUCT_FAQ } from '../../constants/FAQ'
import ArrowImage from './assets/arrow.svg'
import './index.less'

const FAQ = ({ type }) => {
  const faqs = type ? PRODUCT_FAQ[type] : FAQS
  return (
    <div className="content content--bg">
      <div className="content__block">
        <h3 className="sub_title">Частые вопросы</h3>
        <div className="faq__list">
          <Collapse
            expandIcon={({ isActive }) => (
              <img
                className={classNames(
                  'faq__icon',
                  isActive && 'faq__icon-revert',
                )}
                src={ArrowImage}
                alt=""
              />
            )}
            bordered={false}
            expandIconPosition="right"
          >
            {faqs.map((item) => (
              <Collapse.Panel key={item.key} header={item.question}>
                <div dangerouslySetInnerHTML={{ __html: item.answer }} />
              </Collapse.Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </div>
  )
}

export default FAQ

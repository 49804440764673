import React from 'react'
import { Select } from 'antd'
import { find, propEq } from 'ramda'
import { scroller } from 'react-scroll'
import classNames from 'classnames'

import { useSelector, useDispatch } from 'react-redux'

import { TESTING_PAGE_SECTIONS } from 'constants/TESTING_PAGE'
import { SelectSuffixIcon } from 'components/ui/SelectSuffixIcon'

import { TESTING_PAGE_SET_CURRENT_SECTION } from 'reducers/testingPage'
import { CustomOption } from './CustomOption'

export const TestingPageSectionSelector = ({ afterScroll, visible, id }) => {
  const { selectedSection } = useSelector((state) => state.testingPage)
  const { isMobile } = useSelector((state) => state.global)
  const testingRef = React.createRef()
  const dispatch = useDispatch()

  const progressWidth = React.useMemo(
    () => (selectedSection?.key || 1) * (100 / TESTING_PAGE_SECTIONS.length),
    [selectedSection],
  )

  const onSelectChange = (sectionName) => {
    const section = find(propEq('value', sectionName))(TESTING_PAGE_SECTIONS)
    dispatch(TESTING_PAGE_SET_CURRENT_SECTION(section))

    scroller.scrollTo(`section${section.key}`, {
      smooth: false,
      offset: isMobile ? -145 : -240,
    })
  }

  return (
    <div
      ref={testingRef}
      className={classNames('testing-select', {
        'testing-select__mobile': isMobile,
        'testing-select__after-scroll': afterScroll,
        'testing-select__visible': visible,
      })}
    >
      <div>
        <Select
          value={
            selectedSection
              ? selectedSection.value
              : TESTING_PAGE_SECTIONS[0].value
          }
          className="testing-select__field"
          getPopupContainer={() => testingRef.current}
          dropdownClassName={classNames('testing-select__dropdown', {
            'testing-select__dropdown-mobile': isMobile,
          })}
          optionLabelProp="label"
          suffixIcon={({ open }) => <SelectSuffixIcon open={open} />}
          onChange={onSelectChange}
        >
          {TESTING_PAGE_SECTIONS.map((item) => (
            <Select.Option
              key={item.key}
              value={item.value}
              label={<CustomOption item={item} />}
            >
              <CustomOption item={item} />
            </Select.Option>
          ))}
        </Select>
        <div
          className="testing-select__progress-bar"
          style={{ width: `${progressWidth}%` }}
        />
      </div>
    </div>
  )
}

import createSagaMiddleware from 'redux-saga'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import rootSaga from './sagas/root'
import createRootReducer from './reducers'

export const history =
  typeof window === 'undefined'
    ? null
    : createBrowserHistory({
        basename: '/',
      })

const sagaMiddleware = createSagaMiddleware()

const middleware = [
  routerMiddleware(history),
  ...getDefaultMiddleware({ thunk: false }),
  sagaMiddleware,
]

export const store = configureStore({
  reducer: createRootReducer(history),
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
})

sagaMiddleware.run(rootSaga)

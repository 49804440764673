import { combineReducers } from '@reduxjs/toolkit'
import { connectRouter } from 'connected-react-router'
import global from './global'
import security from './security'
import auth from './auth'
import otp from './otp'
import registration from './registration'
import testingPage from './testingPage'
import topProducts from './topProducts'

const createRootReducer = (history) =>
  combineReducers({
    router: history ? connectRouter(history) : {},
    global,
    security,
    auth,
    otp,
    registration,
    testingPage,
    topProducts,
  })

export default createRootReducer

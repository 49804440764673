import React from 'react'
import '../index.less'

export const Section9 = ({ id }) => {
  return (
    <>
      <h1 id={id}>ПИФ и ЗПИФ — в чем разница?</h1>
      <h2>
        Разберемся, что такое паевые инвестиционные фонды и как они работают.
      </h2>
      <h3>Что такое ПИФ</h3>
      <p>
        Паевой инвестиционный фонд (ПИФ) — обособленный имущественный комплекс,
        состоящий из имущества, переданного в доверительное управление
        управляющей компании учредителем (учредителями) доверительного
        управления с условием объединения этого имущества с имуществом иных
        учредителей доверительного управления, и из имущества, полученного в
        процессе такого управления, доля в праве собственности на которое
        удостоверяется ценной бумагой, выдаваемой управляющей компанией.
      </p>
      <p>Паевой инвестиционный фонд не является юридическим лицом.</p>
      <p>
        (Статья 10 п. 1. Федеральный закон от 29.11.2001 № 156-ФЗ (ред. от
        02.07.2021) «Об инвестиционных фондах» (с изм. и доп., вступ. в силу с
        01.10.2021).
      </p>
      <h2>Простыми словами</h2>
      <p>
        ПИФ — форма доверительного управления, при которой каждый пайщик
        получает долю своей прибыли в зависимости от объема средств, внесенных
        им в фонд.
      </p>
      <p>
        Участник ПИФа получает инвестиционный пай — именную ценную бумагу,
        удостоверяющую долю его владельца в праве собственности на часть
        имущества фонда.
      </p>
      <p>
        Фондом управляет профессиональный участник рынка ценных бумаг —
        управляющая компания (УК). Она имеет лицензию на проведение финансовых
        операций и управляет ПИФом в интересах инвесторов.
      </p>
      <p>
        Управляющая компания инвестирует деньги пайщиков, например, в облигации
        или акции, недвижимость, валюту, с целью получения дохода от этих
        инвестиций. При увеличении стоимости ценных бумаг, входящих в состав
        паевого фонда, растет и стоимость одного инвестиционного пая, а
        следовательно, и потенциальный доход пайщиков. За профессиональное
        управление капиталом УК удерживает плату — вознаграждение за свои
        услуги.
      </p>
      <h2>Какие ПИФы бывают?</h2>
      <p>
        <b>Открытые</b> — когда операции с паями, например, их выдачу и
        погашение в управляющей компании, можно проводить в любой рабочий день.
      </p>
      <p>
        <b>Интервальные</b> — для операций с паями доступен только определенный
        период времени. Например, четыре раза в год по две недели. Компенсация
        за неудобства — более высокая доходность, поскольку УК может планировать
        инвестиции без оглядки на то, что пайщики внезапно выведут средства.
      </p>
      <p>
        <b>Закрытые</b> — купить паи такого фонда можно только в момент его
        формирования. Чаще всего фонд создается под конкретный проект. Сроки
        работы ПИФа оговорены заранее. По окончании времени инвестиционные паи
        погашаются, инвесторы получают денежные средства от реализации
        инвестиционных паев и расходятся. До окончания срока договора забрать
        свою долю нельзя, если иное не предусмотрено ПДУ фонда.
      </p>
      <h2>Подробнее о ЗПИФ</h2>
      <p>
        ЗПИФ ориентирован на привлечение инвестиций на определенный, как
        правило, долгосрочный период. Срок его действия фиксируется в Правилах
        фонда и составляет от 3 до 15 лет с возможностью досрочного завершения
        или пролонгации.
      </p>
      <p>
        В доверительное управление могут передаваться денежные средства (за
        исключением наличных денежных средств), ценные бумаги (акции,
        облигации), производные финансовые инструменты (фьючерсы, опционы), а
        также иное имущество, предусмотренное инвестиционной декларацией фонда и
        действующим законодательством.
      </p>
      <h2>Категории ЗПИФов</h2>
      <p>
        Формирование закрытого паевого инвестиционного фонда происходит под
        определенную инвестиционную стратегию (следовательно, и под определенную
        инвестиционную декларацию). В зависимости от вида закрытого паевого
        фонда, средства пайщиков инвестируются в акции, векселя, облигации,
        недвижимость, ипотечные закладные.
      </p>
      <p>Виды фондов глобально можно разделить на две большие группы:</p>
      <ul>
        <li>
          Фонды для квалифицированных инвесторов можно разбить на три категории:
        </li>
        <li>
          Фонды для квалифицированных инвесторов можно разбить на три категории:
          <ul>
            <li>фонд финансовых инструментов,</li>
            <li>фонд недвижимости,</li>
            <li>комбинированный фонд.</li>
          </ul>
        </li>
        <li>
          Фонды для широкого круга лиц — когда квалификация не требуется — можно
          разбить на две категории:
          <ul>
            <li>фонд рыночных финансовых инструментов,</li>
            <li>фонд недвижимости.</li>
          </ul>
        </li>
      </ul>
      <p>
        Каждая категория фондов рассматривает инвестирование в строго
        определенные активы, и инвестиционная декларация фонда составляется в
        зависимости от его категории.
      </p>
      <h2>Как работает ЗПИФ?</h2>
      <p>
        Особенностью закрытого фонда является то, что список владельцев и
        количество выданных паев определяется при формировании фонда, а далее
        для всех важных событий по фонду (дополнительный выпуск, изменение
        инвестиционной декларации) нужно проводить общее собрание пайщиков, на
        котором все решения по фонду определяются путём голосования, в котором
        могут участвуют владельцы не менее 10% общего количества паев.
      </p>
      <p>
        Процесс сбора средств в паевой фонд называется формированием фонда. Фонд
        начинает действовать при достижении фиксированной в Правилах фонда
        стоимости активов, то есть, когда набрана сумма средств, достаточная для
        финансирования того проекта или объекта, под который формируется фонд.
        Покупка клиентами инвестиционных паев закрытого фонда в момент их
        выпуска (в момент формирования фонда) называется первичным обращением.
      </p>
      <p>
        Как правило, после формирования ЗПИФ «закрывается» на выпуск новых паев
        и на прием новых пайщиков. В правилах закрытого фонда может быть
        предусмотрен дополнительный выпуск инвестиционных паев после завершения
        формирования фонда, при этом владельцы инвестиционных паев имеют
        преимущественное право на приобретение дополнительно выпущенных паев.
      </p>
      <p>
        Новые инвесторы могут присоединиться к проекту только с согласия
        участников закрытого фонда также путем выпуска дополнительных паев. По
        истечении срока действия фонд расформировывается, а паи погашаются по
        текущей стоимости активов.
      </p>
      <p>
        Вторичное обращение (покупка/продажа ранее выпущенных паев) закрытого
        паевого инвестиционного фонда возможно, если это предусмотрено в
        правилах доверительного управления фондом. Клиент вправе продавать,
        дарить, завещать свои паи другому лицу.
      </p>
      <p>
        Дополнительной особенностью Закрытого паевого инвестиционного фонда
        является возможность получения периодических выплат, если Правилами
        фонда предусмотрена выплата инвестиционного дохода, а не его
        реинвестирование в активы Фонда. Это достаточно распространенная
        практика для закрытых фондов недвижимости, где полученный доход не
        всегда получается инвестировать в целевой объект финансирования: на
        арендные платежи от сдачи коммерческой недвижимости не всегда можно
        купить еще один объект недвижимости. Поэтому инвесторы фонда получают
        выплаты в течение срока работы фонда при наличии такого дохода.
      </p>
      <p>
        В ЗПИФ для квалифицированных инвесторов может быть также предусмотрен
        инвестиционный комитет (ИК). ИК может одобрять сделки за счет имущества
        ЗПИФ и действия УК как акционера/участника АО/ООО, чьи акции или доли
        входят в имущество фонда. В ИК входят пайщики либо их представители,
        однако сама УК, ее работники и представители входить в инвесткомитет не
        могут. Компетенция ИК описывается в правилах доверительного управления
        фонда.
      </p>
      <h2>Что нужно знать при работе со ЗПИФами?</h2>
      <p> Налогообложение фонда</p>
      <p>
        Налог на прибыль. Доходы, поступающие в состав фонда, не облагаются
        налогом на прибыль.{' '}
      </p>
      <p>Налогообложение инвесторов закрытого фонда</p>
      <p>Налог на прибыль / НДФЛ</p>
      <p>Доходы у пайщика возникают в трех случаях:</p>
      <ul>
        <li>при погашении паев фонда, например при его прекращении,</li>
        <li>
          при реализации паев на вторичном рынке, например при их продаже,
        </li>
        <li>
          при получении промежуточного дохода по паям (если такие выплаты
          предусмотрены правилами фонда).
        </li>
      </ul>
      <p>
        Операции с паями и владение ими не облагаются НДС и имущественными
        налогами. 
      </p>
      <p>
        Таким образом, наиболее эффективное использование ЗПИФа — это
        реинвестирование полученной фондом прибыли в новые активы.
      </p>
      <p>Преимущества владения паями ЗПИФа:</p>
      <ul>
        <li>
          Сальдирование всех прибылей и убытков внутри ЗПИФа, что позволяет
          оптимизировать налогообложение на протяжении срока владения фондом.
        </li>
        <li>
          Отсутствие двойного налогообложения. ЗПИФ финансовых инструментов не
          платит налоги на прирост активов, инвестор фонда выплачивает налог
          только при погашении пая и получении дохода.
        </li>
        <li>
          Возможность использовать ЗПИФ как траст и распределить доли между
          родственниками, партнерами по бизнесу и т.д.
        </li>
        <li>
          При прекращении деятельности УК (лишение лицензии, банкротство и пр.)
          право долевой собственности на имущество ЗПИФ остается у пайщиков,
          требования кредиторов к УК не распространяются на имущество ЗПИФа.
        </li>
        <li>
          Возможность создавать фонд под цели одного или группы инвесторов:
          например, у кого-то из клиентов есть потребность профинансировать
          конкретный проект: производство фильма, строительство многоквартирного
          дома и т.д.
        </li>
        <li>
          Конфиденциальность: информация о владельцах паев не раскрывается и
          доступна ограниченному кругу лиц.
        </li>
        <li>
          Отсрочка по уплате налога на прибыль внутри ЗПИФа по операциям с
          активами фонда (в отсутствие выплат промежуточного инвестиционного
          дохода).
        </li>
      </ul>
      <h2>Риски инвестирования</h2>
      <p>
        Риски связаны с инвестиционной стратегией ЗПИФ, отраженной в правилах
        доверительного управления фонда, и работой управляющей компании.
      </p>
      <div className="testing_divider" />
    </>
  )
}

import React from 'react'
import Ticker from 'react-ticker'
import CompaniesImage from './assets/companies.png'
import './index.less'

const WritingAboutUs = () => {
  return (
    <div className="writing_about_us">
      <div className="writing_about_us__carousel">
        <span>О нас пишут:</span>
        <Ticker>{() => <img src={CompaniesImage} alt="companies" />}</Ticker>
      </div>
    </div>
  )
}

export default WritingAboutUs

import React from 'react'
import NumberFormat from 'react-number-format'
import { percentFormatter } from '../../../utils/helpers'

import { PRODUCT_TYPES } from '../../../constants/TRADING'
import tmIcon from '../assets/trust-management-icon.svg'
import '../index.less'

export const CustomOption = ({ product, selectedType }) => {
  const handleOnError = React.useCallback(
    (el) => {
      el.target.src =
        selectedType === PRODUCT_TYPES.TRUST_MANAGEMENT
          ? tmIcon
          : 'https://fins.money/cdn/static/icon/trading-instrument/default_product.svg'
    },
    [selectedType],
  )

  return (
    <div className="select-item">
      <div className="select-item__icon">
        <img
          src={product?.iconLink}
          alt="Иконка продукта"
          width={40}
          height={40}
          onError={handleOnError}
        />
      </div>
      <div className="select-item__content">
        <div className="select-item__content-title">{product?.name}</div>
        <div className="select-item__content-income">
          <span className="select-item__content-income-rate">
            <NumberFormat
              suffix="%"
              thousandSeparator={' '}
              decimalSeparator=","
              value={percentFormatter(product?.yield, 2)}
              displayType="text"
            />
          </span>{' '}
          {/* <span className="select-item__content-income-desc">
            - доход в год
          </span> */}
        </div>
      </div>
    </div>
  )
}

import { call, put, takeEvery, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { FETCH_TARGET_OTP_VERIFY } from '../../actions/constants/otp'
import {
  saveOtpData,
  setOtpError,
  setOtpVerify,
  setOtpLoading,
  clearOtpData,
} from '../../reducers/otp'
import { requestTargetOtpVerify } from '../../api/v5/otp'
import { wordsDeclensions, declensions } from '../../utils/helpers'
import { clearAuthenticationData, setAuthLoading } from '../../reducers/auth'

function* fetchTargetOtpVerify(action) {
  try {
    yield put(setOtpLoading(true))
    const otpData = yield call(requestTargetOtpVerify, action.payload)
    if (otpData.verified) {
      yield put(
        setOtpError({
          error: false,
          message: '',
        }),
      )
      yield put(setOtpVerify(true))
    } else {
      yield put(
        setOtpError({
          error: true,
          message: `Неверный код подтверждения. Осталось ${
            otpData.verificationAttemptsLeft
          } ${declensions(otpData.verificationAttemptsLeft, wordsDeclensions)}`,
        }),
      )
      yield put(setOtpVerify(false))
    }

    yield put(saveOtpData(otpData))
    yield put(setOtpLoading(false))
  } catch (e) {
    const { currentProductPath } = yield select((s) => s.global)
    const { message } = e

    switch (message) {
      case 'Request failed with status code 422':
        yield put({ type: 'CANCEL_STATUS_POLLING' })
        yield put(setAuthLoading(false))
        yield put(setOtpLoading(false))
        yield put(clearAuthenticationData())
        yield put(clearOtpData())
        yield put(push(`${currentProductPath}/authorization/otp-failed`))
        break
      default:
        yield put({ type: 'CANCEL_STATUS_POLLING' })
        yield put(setAuthLoading(false))
        yield put(setOtpLoading(false))
        yield put(clearAuthenticationData())
        yield put(clearOtpData())
        yield put(push(`${currentProductPath}/authorization/otp-failed`))
        break
    }
  }
}

function* targetOtpVerifySaga() {
  yield takeEvery(FETCH_TARGET_OTP_VERIFY, fetchTargetOtpVerify)
}

export default targetOtpVerifySaga

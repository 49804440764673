import React from 'react'

import { Header } from '../../components/Header'
import { Footer } from '../../components/Footer'
import { Cookie } from '../../components/Cookie'

export const BasicLayout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
      <Cookie />
    </>
  )
}

import React from 'react'

export const useHandleFocusInput = (initialValue) => {
  const [value, setValue] = React.useState(initialValue)

  const handleFocus = (v) => {
    setValue(v)
  }

  return {
    value,
    onFocus: (v) => {
      handleFocus(v)
    },
  }
}

export const useHandleBlurInput = (initialValue) => {
  const [value, setValue] = React.useState(initialValue)

  const handleBlur = (v) => {
    setValue(v)
  }

  return {
    value,
    onBlur: (v) => {
      handleBlur(v)
    },
  }
}

export const useHandleSteps = (initialValue) => {
  const [value, setValue] = React.useState(initialValue)

  function handleChange(v) {
    setValue(v)
  }

  return {
    value,
    onChange: handleChange,
  }
}

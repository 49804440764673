import { call, put, takeEvery, select } from 'redux-saga/effects'
import { RESTORE } from '../../actions/constants/security'
import { CANCEL_STATUS_POLLING } from '../../actions/constants/auth'
import {
  saveRestoreData,
  securityLoading,
  saveRestoreError,
  saveRestoreSuccess,
} from '../../reducers/security'

import { requestPasswordRestoreEmail } from '../../api/v5/passwordRestoration'

function* fetchRestore(action) {
  try {
    const authId = yield select((s) => s.auth.authenticationData.id)
    yield put({ type: CANCEL_STATUS_POLLING })

    yield put(saveRestoreError(null))
    yield put(securityLoading(true))

    const restoreData = yield call(requestPasswordRestoreEmail, {
      authenticationId: authId,
    })
    yield put(saveRestoreData(restoreData))
    if (!restoreData || restoreData?.code) {
      yield put(saveRestoreSuccess(false))
    } else {
      yield put(saveRestoreSuccess(true))
    }
    yield put(securityLoading(false))
  } catch (e) {
    yield put(securityLoading(false))
    yield put(saveRestoreError(e))
    yield put(saveRestoreSuccess(false))
  }
}

function* fetchRestoreSaga() {
  yield takeEvery(RESTORE, fetchRestore)
}

export default fetchRestoreSaga

import {
  call,
  put,
  select,
  delay,
  take,
  takeEvery,
  race,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import {
  FETCH_CHECK_AUTH,
  CANCEL_STATUS_POLLING,
} from '../../actions/constants/auth'
import {
  saveAuthenticationData,
  setAuthLoading,
  setPasswordRequired,
  setOtpRequired,
  saveSessionToken,
  saveRefreshToken,
  setRegistrationRequired,
  clearAuthenticationData,
} from '../../reducers/auth'
import { clearOtpData } from '../../reducers/otp'
import { requestAuth } from '../../api/v4/auth'

const POLLING_DELAY = 800

export function* checkAuthPollingWorker() {
  yield put(setAuthLoading(true))
  while (true) {
    try {
      const { authenticationData } = yield select((s) => s.auth)
      const { currentProductPath } = yield select((s) => s.global)
      const authData = yield call(requestAuth, { uuid: authenticationData.id })

      yield put(saveAuthenticationData(authData))
      switch (authData.status) {
        case 'NEW':
          yield delay(POLLING_DELAY)
          break
        case 'CAPTCHA_REQUIRED':
          yield delay(POLLING_DELAY)
          break
        case 'CAPTCHA_FAILED':
          yield delay(POLLING_DELAY)
          break
        case 'PENDING_OTP_VERIFICATION':
          yield delay(POLLING_DELAY)
          yield put(setOtpRequired(true))
          break
        case 'OTP_FAILED':
          yield put(clearAuthenticationData())
          yield put(setAuthLoading(false))
          yield put(clearOtpData())
          yield put(push(`${currentProductPath}/authorization/otp-failed`))
          yield put({ type: CANCEL_STATUS_POLLING })
          break
        case 'USER_BLOCKED':
          yield put(clearAuthenticationData())
          yield put(setAuthLoading(false))
          yield put(clearOtpData())
          yield put(push(`${currentProductPath}/authorization/status`))
          yield put({ type: CANCEL_STATUS_POLLING })
          break
        case 'PASSWORD_REQUIRED':
          yield delay(POLLING_DELAY)
          yield put(setPasswordRequired(true))
          break
        case 'REGISTRATION_REQUIRED':
          yield delay(POLLING_DELAY)
          yield put(setRegistrationRequired(true))
          break
        case 'AUTHENTICATION_SUCCEED':
          yield put(saveSessionToken(authData.result.accessToken))
          yield put(saveRefreshToken(authData.result.refreshToken))
          yield put(setAuthLoading(false))
          yield put({ type: CANCEL_STATUS_POLLING })
          break
        case 'AUTHENTICATION_FAILED':
          yield put(setAuthLoading(false))
          yield put({ type: CANCEL_STATUS_POLLING })
          yield put(clearAuthenticationData())
          yield put(clearOtpData())
          break
        default:
          yield put({ type: CANCEL_STATUS_POLLING })
          break
      }
    } catch (e) {
      yield put({ type: CANCEL_STATUS_POLLING })
      yield put(setAuthLoading(false))
      yield put(saveAuthenticationData(e.message))
    }
  }
}

export function* checkAuthWatchWorker() {
  yield race({
    task: call(checkAuthPollingWorker),
    cancel: take(CANCEL_STATUS_POLLING),
  })
}

export function* checkAuth() {
  try {
    yield call(checkAuthWatchWorker)
  } catch (e) {
    yield put(saveAuthenticationData(e.message))
  }
}

function* checkAuthSaga() {
  yield takeEvery(FETCH_CHECK_AUTH, checkAuth)
}

export default checkAuthSaga

import { scroller } from 'react-scroll'

import HomeImage from '../assets/product_carousel/funds_home.png'
import HandsImage from '../assets/product_carousel/funds_hands.png'
import MobileImage from '../assets/product_carousel/funds_mobile.png'
import PeoplesImage from '../assets/product_carousel/funds_peoples.png'

import BondSearchImage from '../assets/product_carousel/bond_search.png'
import BondBagImage from '../assets/product_carousel/bond_bag.png'

import SpHouseImage from '../assets/product_carousel/sp_house.png'

import TrustAnchievImage from '../assets/product_carousel/trust_achiev.png'

import IiaComputerImage from '../assets/product_carousel/iia_computer.png'
import IiaMoneyBoxImage from '../assets/product_carousel/iia_money_box.png'

import QualifiedDocImage from '../assets/product_carousel/qualified_doc.png'
import QualifiedFaxImage from '../assets/product_carousel/qualified_fax.png'
import QualifiedCertImage from '../assets/product_carousel/qualified_cert.png'
import QualifiedMoneyImage from '../assets/product_carousel/qualified_money.png'

import PartnersImage1 from '../assets/product_carousel/partners_image1.png'
import PartnersImage2 from '../assets/product_carousel/partners_image2.png'
import PartnersImage3 from '../assets/product_carousel/partners_image3.png'

export const PRODUCT_SLIDES = {
  funds: [
    {
      key: 1,
      text: 'Управляющая компания создает фонд',
      desc: 'Он состоит из акций, облигаций и иных финансовых активов',
      label: 'Как работают ПИФы?',
      image: HomeImage,
    },
    {
      key: 2,
      text: 'Вы выбираете для себя подходящее предложение',
      desc: 'Это можно сделать онлайн',
      label: 'Как работают ПИФы?',
      image: MobileImage,
    },
    {
      key: 3,
      text: 'Эксперты занимаются управлением',
      desc: 'Под наблюдением ЦБ',
      label: 'Как работают ПИФы?',
      image: PeoplesImage,
    },
    {
      key: 4,
      text: 'Вы получаете доход ',
      desc: 'Доход образуется в случае роста стоимости пая ',
      label: 'Как работают ПИФы?',
      image: HandsImage,
    },
  ],
  bonds: [
    {
      key: 1,
      text: 'Клиент выбирает облигацию',
      desc: 'Аналитики Fins выбирают только облигации крупных надежных компаний',
      label: 'Как работают облигации?',
      image: BondSearchImage,
    },
    {
      key: 2,
      text: 'Получение купонного дохода',
      desc: 'За владение облигацией клиент получает купонный доход каждые 3/6 или 12 месяцев',
      label: 'Как работают облигации?',
      image: BondBagImage,
    },
  ],
  sp: [
    {
      key: 1,
      text: 'Клиент выбирает структурный продукт',
      desc: 'Выберите продукт на удобный вам срок - от 3-х месяцев до 5 лет',
      label: 'Как работают структурные продукты?',
      image: BondSearchImage,
    },
    {
      key: 2,
      text: 'Защита средств',
      desc: 'Если акции компаний не вырастут до конца срока - ваши средства будут защищены и вернуться на ваш счет ',
      label: 'Как работают структурные продукты?',
      image: SpHouseImage,
    },
    {
      key: 3,
      text: 'Получение дохода',
      desc: 'Если акции компаний вырастут, то вы получите фиксированный доход в конце срока',
      label: 'Как работают структурные продукты?',
      image: BondBagImage,
    },
  ],
  qualified: [
    {
      key: 1,
      text: 'Выберите критерий для признания статуса',
      desc: 'Получите статус квалифицированного инвестора - достаточно соответствовать хотя бы одному критерию',
      label: 'Как получить статус квалифицированного инвестора?',
      image: QualifiedDocImage,
    },
    {
      key: 2,
      text: 'Загрузите необходимые документы',
      desc: 'Загрузите документы в личном кабинете Fins  онлайн',
      label: 'Как получить статус квалифицированного инвестора?',
      image: QualifiedFaxImage,
    },
    {
      key: 3,
      text: 'Дождитесь присвоения статуса',
      desc: 'Присвоение статуса квалифицированного инвестора занимает не более одного дня',
      label: 'Как получить статус квалифицированного инвестора?',
      image: QualifiedCertImage,
    },
    {
      key: 4,
      text: 'Воспользуйтесь новыми предложениями',
      desc: 'Оформить структурные продукты со 100% защитой вложенных средств можно в личном кабинете Fins',
      label: 'Как получить статус квалифицированного инвестора?',
      image: QualifiedMoneyImage,
    },
  ],
  iia: [
    {
      key: 1,
      text: 'Зарегистрируйтесь на сайте',
      desc: 'Откройте счет в Fins бесплатно, за 5 минут!',
      label: 'Как работает ИИС?',
      image: IiaComputerImage,
    },
    {
      key: 2,
      text: 'Пополните счет',
      desc: 'Пополняйте счет удобным способом: по реквизитам, с карты или через систему быстрых платежей (СБП)',
      label: 'Как работает ИИС?',
      image: IiaMoneyBoxImage,
    },
    {
      key: 3,
      text: 'Получите налоговый вычет до 52 000 рублей',
      desc: 'Получить налоговый вычет можно за пару кликов на сайте Федеральной налоговой службы (ФНС)',
      label: 'Как работает ИИС?',
      image: BondBagImage,
    },
  ],
  trustManagement: [
    {
      key: 1,
      text: 'Вы выбираете стратегию доверительного управления',
      desc: 'Копите вместе с российскими или американскими акциями',
      label: 'Как работает доверительное управление?',
      image: BondSearchImage,
    },
    {
      key: 2,
      text: 'Профессионал управляет стратегией',
      desc: 'Профессиональный управляющий ежедневно анализирует рынок и вкладывает ваши средства в выгодные акции и облигации',
      label: 'Как работает доверительное управление?',
      image: TrustAnchievImage,
    },
    {
      key: 3,
      text: 'Вы получаете доход',
      desc: 'В конце срока вы получаете доход и вложенные средства',
      label: 'Как работает доверительное управление?',
      image: BondBagImage,
    },
  ],
  partners: [
    {
      key: 1,
      text: 'Стать агентом с нами легко',
      desc: 'Пройдите регистрацию и дождитесь звонка',
      label: 'О нашем сервисе',
      image: PartnersImage1,
      button: {
        text: 'Стать агентом',
        type: 'default',
        onClickHandler: () => {
          scroller.scrollTo('partnerStepList', {
            smooth: true,
            offset: -126,
          })
        },
      },
    },
    {
      key: 2,
      text: 'Самый надежный партнер - Брокер №1 в России',
      desc: 'Финансовая группа БКС работает более 26 лет и имеет наивысший уровень надежности компании (AAA)',
      label: 'О нашем сервисе',
      image: PartnersImage2,
    },
    {
      key: 3,
      text: 'Полная поддержка агентов Fins',
      desc: 'Агентам Fins доступны обучающие курсы по финансовой грамотности, постоянная поддержка профессионалов и возможность развития карьеры',
      label: 'О нашем сервисе',
      image: PartnersImage3,
    },
  ],
}

import { request } from '../request'

export const requestPasswordRestoreEmail = async (payload) => {
  return request('/gateway/api/security/v5/password-restorations/send-email', {
    method: 'POST',
    data: payload,
  })
}

export const requestPasswordRestore = async (payload) => {
  return request('/gateway/api/security/v5/password-restorations', {
    method: 'POST',
    data: payload,
  })
}

export const requestPasswordRestoreProcess = async (payload) => {
  return request(
    `/gateway/api/security/v5/password-restorations/${payload.id}`,
    {
      method: 'GET',
      data: payload,
    },
  )
}

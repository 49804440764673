import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'antd'
import { FinsDrawer } from '../../components/ui/FinsDrawer'

import './index.less'

export const Disclosure = ({ history }) => {
  const { isMobile, currentProductPath } = useSelector((state) => state.global)
  return (
    <FinsDrawer
      title="Раскрытие информации"
      placement="right"
      width={isMobile ? '100%' : '439px'}
      bodyStyle={{ padding: '32px' }}
      isMobile={isMobile}
      routePathAfterClose={`${currentProductPath}/${history.location.search}`}
    >
      <div className="disclosure">
        <div className="disclosure-info__wrap">
          <div className="disclosure-info__title">Удаление аккаунта</div>
          <div className="disclosure-info__sub-title">
            Удаление аккаунта возможно при закрытии всех счетов. Для подачи
            заявки необходимо обратиться в&nbsp;службу поддержки.
            Мы&nbsp;расскажем дальнейшие шаги и&nbsp;поможем с&nbsp;документами
          </div>
          <Button
            type="primary"
            block
            href="tel:88005001001"
            className="disclosure-info__button"
          >
            8 (800) 500-10-01
          </Button>
        </div>
        <div className="disclosure-info__wrap">
          <div className="disclosure-info__title">
            После удаления аккаунта, бессрочно хранятся типы данных:
          </div>
          <ul className="disclosure-info__list">
            <li>
              <div className="disclosure-info__sub-title">
                Личная информация: имя, электронный адрес, идентификаторы
                пользователей, адрес, номер телефона;
              </div>
            </li>
            <li>
              <div className="disclosure-info__sub-title">
                Финансовая информация;
              </div>
            </li>
            <li>
              <div className="disclosure-info__sub-title">
                Идентификаторы устройства;
              </div>
            </li>
            <li>
              <div className="disclosure-info__sub-title">
                Отчёты об&nbsp;ошибках.
              </div>
            </li>
          </ul>
        </div>
        <div className="disclosure-info__wrap">
          <div className="disclosure-info__title">
            Временные данные, которые не&nbsp;хранятся:
          </div>
          <ul className="disclosure-info__list">
            <li>
              <div className="disclosure-info__sub-title">
                Местоположение: приблизительное местоположение, точное
                местоположение;
              </div>
            </li>
            <li>
              <div className="disclosure-info__sub-title">Фотографии;</div>
            </li>
            <li>
              <div className="disclosure-info__sub-title">
                Файлы и&nbsp;документы;
              </div>
            </li>
            <li>
              <div className="disclosure-info__sub-title">
                История действий в&nbsp;приложении.
              </div>
            </li>
          </ul>
        </div>
      </div>
    </FinsDrawer>
  )
}

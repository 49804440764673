import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_AUTH, FETCH_CHECK_AUTH } from '../../actions/constants/auth'
import { saveAuthenticationData, setAuthLoading } from '../../reducers/auth'
import { getPlatformAndUserData } from '../../utils/helpers'
import { normalizePhoneNumber } from '../../utils/normalize'
import { requestGetAuth } from '../../api/v4/auth'

function* fetchAuth(action) {
  try {
    yield put(setAuthLoading(true))

    const source = localStorage.getItem('source')
      ? localStorage.getItem('source').toUpperCase()
      : 'FINS'
    const requestAuthData = {
      ...getPlatformAndUserData(navigator, source),
      login: normalizePhoneNumber(action.payload),
    }

    const authData = yield call(requestGetAuth, requestAuthData)

    yield put(saveAuthenticationData(authData))

    if (authData.status === 'NEW') {
      yield put({ type: FETCH_CHECK_AUTH, payload: { uuid: authData.id } })
    }
  } catch (e) {
    yield put(setAuthLoading(false))
    yield put(saveAuthenticationData(e.message))
  }
}

function* authSaga() {
  yield takeEvery(FETCH_AUTH, fetchAuth)
}

export default authSaga

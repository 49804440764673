import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  registrationData: null,
  secretQuestionsData: [],
  registrationLoading: false,
  registrationStatus: false,
  registrationError: {
    error: false,
    message: '',
  },
}

const registration = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    saveRegistrationData: (state, action) => {
      state.registrationData = action.payload
    },
    setRegistrationLoading: (state, action) => {
      state.registrationLoading = action.payload
    },
    setRegistrationStatus: (state, action) => {
      state.registrationStatus = action.payload
    },
    setRegistrationError: (state, action) => {
      state.registrationError = action.payload
    },
    saveSecretQuestionsData: (state, action) => {
      state.secretQuestionsData = action.payload
    },
    clearRegistrationData: (state, action) => {
      state.registrationData = initialState.registrationData
    },
  },
})

const { reducer, actions } = registration

export const {
  saveRegistrationData,
  setRegistrationLoading,
  saveSecretQuestionsData,
  clearRegistrationData,
  setRegistrationStatus,
  setRegistrationError,
} = actions

export default reducer

import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_TOP_PRODUCTS } from '../../actions/constants/calc'

import {
  saveTopProducts,
  topProductsLoading,
  saveTopProductsError,
} from '../../reducers/topProducts'

import { fetchTopProducts } from '../../api/v6/topProducts'

function* getTopProducts() {
  try {
    yield put(topProductsLoading(true))
    const topProducts = yield call(fetchTopProducts)

    yield put(saveTopProducts(topProducts))
    yield put(topProductsLoading(false))
  } catch (e) {
    yield put(topProductsLoading(false))
    yield put(saveTopProductsError(e))
  }
}

function* fetchTopProductsSaga() {
  yield takeEvery(FETCH_TOP_PRODUCTS, getTopProducts)
}

export default fetchTopProductsSaga

export const TITLE = 'Fins — сервис с банковской и брокерской лицензией ЦБ'

export const META = [
  {
    name: 'description',
    content:
      'Инвестиции с Fins - это доступные и надежные вложения для каждого',
  },
  { property: 'og:image', content: 'https://fins.money/images/mail-img.png' },
  { property: 'og:type', content: 'website' },
  { property: 'og:sitename', content: 'fins.money' },
  { property: 'og:locale', content: 'ru_Ru' },
  { property: 'og:url', content: 'https://fins.money/' },
  {
    property: 'og:title',
    content: 'Fins — сервис с банковской и брокерской лицензией ЦБ',
  },
  {
    property: 'og:description',
    content: 'Выгодные накопления со 100% защитой вложений',
  },
  {
    property: 'og:image:width',
    content: '1200',
  },
  {
    property: 'og:image:height',
    content: '630',
  },
  {
    property: 'og:image:type',
    content: 'image/png',
  },
  {
    name: 'twitter:card',
    content: 'summary_large_image',
  },
  {
    name: 'twitter:url',
    content: 'https://fins.money/',
  },
  {
    name: 'twitter:domain',
    content: 'https://fins.money/',
  },
  {
    name: 'twitter:title',
    content: 'Fins — сервис с банковской и брокерской лицензией ЦБ',
  },
  {
    name: 'twitter:description',
    content: 'Выгодные накопления со 100% защитой вложений',
  },
  {
    name: 'google-site-verification',
    content: 'wPNtn5ffau_bFiy1C64M-C_oIktTlDUzfWmejLDAfK4',
  },
  {
    name: 'mailru-verification',
    content: 'abac1147cd98cb8b',
  },
  {
    name: 'mailru-verification',
    content: '0ca4099283ed1369',
  },
  {
    name: 'yandex-verification',
    content: 'b9e2ff1994070d75',
  },
  {
    name: 'yandex-verification',
    content: '87591bcd8dabb389',
  },
]

import React from 'react'
import { Documents } from '../pages/Documents'
import { CookieAgreement } from '../pages/CookieAgreement'
import { Disclosure } from '../pages/Disclosure'

export const documents = {
  '/documents': {
    component: (props) => <Documents {...props} />,
    exact: true,
  },
  '/disclosure': {
    component: (props) => <Disclosure {...props} />,
    exact: true,
  },
  '/cookie-agreement': {
    component: (props) => <CookieAgreement {...props} />,
    exact: true,
  },
}

import numeral from 'numeral'
import {
  formatDuration,
  add,
  differenceInYears,
  differenceInDays,
  differenceInMonths,
} from 'date-fns'
import { ru } from 'date-fns/locale'

import { PRODUCT_TYPES } from 'constants/TRADING'

numeral.register('locale', 'ru', {
  delimiters: { thousands: ' ', decimal: ',' },
  abbreviations: {
    thousand: 'тыс.',
    million: 'млн.',
  },
})

numeral.locale('ru')

export const wordsDeclensions = ['попытка', 'попытки', 'попыток']

export const declensions = (number, titles) => {
  const cases = [2, 0, 1, 1, 1, 2]

  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ]
}
export const getPlatformAndUserData = (nav, source) => {
  return {
    realm: 'FINS',
    partnerCode: 'FINS',
    source,
    device: {
      platform: 'WEB', // MacIntel
      language: nav.language,
      userAgent: nav.userAgent,
      cookieEnabled: true,
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    },
  }
}

const numeralDefaultFraction = (number) =>
  numeral(parseFloat(number)).format('0,0[.]0[00]')

const percentDefaultFraction = (number) =>
  numeral(parseFloat(number)).format('0,0.00')

export const percentFormatter = (value, fixed = 2, removeZeros = false) => {
  const currentValue = value === null ? 0 : value
  const valueToPercents = currentValue * 100

  const toFixedValue =
    fixed === 0 &&
    parseInt(valueToPercents, 10).toFixed(1) !== valueToPercents.toFixed(1)
      ? 1
      : fixed

  const num = valueToPercents.toFixed(toFixedValue)

  const result = !removeZeros
    ? percentDefaultFraction(num)
    : numeralDefaultFraction(num)

  return `${result}%`
}

const formatDurationFNS = (years, months, days) => {
  return formatDuration(
    {
      years,
      months,
      days,
    },
    { format: ['years', 'months', 'days'], locale: ru },
  )
}

export const formatTerm = (type, term) => {
  if (type === PRODUCT_TYPES.TRUST_MANAGEMENT) {
    const years = Math.floor(term / 365)
    const months = Math.floor((term % 365) / 30)
    const days = Math.floor((term % 365) % 30)
    return formatDurationFNS(years, months, days)
  }
  if (type === PRODUCT_TYPES.STRUCTURED_PRODUCT) {
    const dateNow = new Date()
    const newDate = add(dateNow, { days: term })

    const termInYears = differenceInYears(newDate, dateNow)

    const fullYears = termInYears
    const fullMonths = differenceInMonths(
      newDate,
      add(dateNow, { years: fullYears }),
    )
    const fullDays = differenceInDays(
      newDate,
      add(dateNow, { years: fullYears, months: fullMonths }),
    )

    const monthsForSp =
      fullDays >= 15
        ? fullMonths === 11
          ? 0
          : fullMonths + Number(1)
        : fullMonths

    const yearsForSp =
      fullDays >= 15
        ? fullMonths === 11
          ? fullYears + Number(1)
          : fullYears
        : fullYears

    const daysForSp = 0

    return formatDurationFNS(yearsForSp, monthsForSp, daysForSp)
  }
  const termInMonths = Math.floor(term / 30)
  const remainderOfMonths = Math.floor(termInMonths % 12)
  const remainderOfDays = term % 30
  const years = Math.floor(termInMonths / 12)
  const months = remainderOfMonths + Math.floor((termInMonths % 12) / 30)
  const days = remainderOfDays === 0 ? 0 : remainderOfDays
  return formatDurationFNS(years, months, days)
}

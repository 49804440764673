import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  otpData: null,
  otpLoading: false,
  isOtpVerfiy: false,
  otpError: {
    error: false,
    message: '',
    attemptsReached: false,
  },
}

const otp = createSlice({
  name: 'otp',
  initialState,
  reducers: {
    saveOtpData: (state, action) => {
      const data = action.payload
      state.otpData = data
    },
    setOtpLoading: (state, action) => {
      state.otpLoading = action.payload
    },
    setOtpVerify: (state, action) => {
      state.isOtpVerfiy = action.payload
    },
    setOtpError: (state, action) => {
      state.otpError = action.payload
    },
    clearOtpData: (state, action) => {
      state.otpData = initialState.otpData
      state.otpError = initialState.otpError
    },
  },
})

const { reducer, actions } = otp

export const {
  saveOtpData,
  setOtpLoading,
  setOtpVerify,
  setOtpError,
  clearOtpData,
} = actions

export default reducer

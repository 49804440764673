import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_SECRET_QUESTIONS } from '../../actions/constants/auth'
import {
  saveSecretQuestionsData,
  setRegistrationLoading,
} from '../../reducers/registration'
import { requestSecretQuestions } from '../../api/v4/auth'

function* fetchSecretQuestions(action) {
  try {
    yield put(setRegistrationLoading(true))
    const authData = yield call(requestSecretQuestions, action.payload)

    yield put(saveSecretQuestionsData(authData))
    yield put(setRegistrationLoading(false))
  } catch (e) {
    yield put(setRegistrationLoading(false))
    yield put(saveSecretQuestionsData(e.message))
  }
}

function* secretQuestionsSaga() {
  yield takeEvery(FETCH_SECRET_QUESTIONS, fetchSecretQuestions)
}

export default secretQuestionsSaga

import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { getYear } from 'date-fns'
import './intex.less'
import { SITEMAP, FOOTER_TEXT } from '../../constants/FOOTER_TEXT'

export const Footer = () => {
  const [full, setFull] = React.useState()
  const { currentProductPath } = useSelector((state) => state.global)

  const { isMobile } = useSelector((state) => state.global)

  const year = React.useMemo(() => getYear(new Date()), [])

  const handleClickFull = React.useCallback(
    (e) => {
      e.preventDefault()
      setFull(!full)
    },
    [full],
  )
  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__copyright">© Fins. {year}</div>
        <Row className="footer__sitemap">
          <Col sm={24} md={7} lg={7} xl={5} className="footer__sitemap-item">
            <div className="footer__sitemap-sub_item">
              <span className="footer__sub_title">Служба поддержки</span>
              <a href="tel:88005001001">8 (800) 500-10-01</a>
              <a href="mailto:help@mail.fins.money">help@mail.fins.money</a>
            </div>
          </Col>
          <Col sm={24} md={9} lg={9} xl={8} className="footer__sitemap-item">
            <div className="footer__sitemap-sub_item footer__sitemap-menu">
              <span className="footer__sub_title">Подробнее</span>
              {SITEMAP.map((ref) => (
                <Link
                  key={ref.name}
                  to={
                    ['/documents', '/disclosure'].includes(ref.link)
                      ? `${currentProductPath}${ref.link}`
                      : ref.link
                  }
                  target={ref.link === '/agents/' ? '_blank' : '_self'}
                >
                  {ref.name}
                </Link>
              ))}
            </div>
          </Col>
          <Col sm={24} md={8} lg={8} xl={7} className="footer__sitemap-item">
            <div className="footer__sitemap-sub_item footer__sitemap-menu">
              <span className="footer__sub_title">Мобильное приложение</span>
              <div className="footer__markets">
                <a
                  className="footer__apple"
                  href="https://apps.apple.com/ru/app/fins/id1453184963"
                />
                <a
                  className="footer__google"
                  href="https://play.google.com/store/apps/details?id=money.fins"
                />
              </div>
            </div>
            <div className="footer__sitemap-sub_item">
              <span className="footer__sub_title">Социальные сети</span>
              <div className="footer__socials">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://vk.com/fins.money"
                  className="footer__social footer__social-vk"
                />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://t.me/finsmoney"
                  className="footer__social footer__social-telegram"
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className="footer__footer-text">
          {!isMobile ? (
            FOOTER_TEXT.map((i) => i)
          ) : (
            <>
              {FOOTER_TEXT.filter((_el, index) =>
                full ? true : index < 1,
              ).map((i) => i)}
              <a
                className="footer__more-button"
                href=""
                onClick={handleClickFull}
              >
                {full ? ' Свернуть' : ' Развернуть...'}
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

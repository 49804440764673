import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { subRoutes } from '../../routes/subRoots'

export const DrawerRoute = () => {
  const match = useRouteMatch()
  const url = match.path !== '/' ? match.path : ''
  return (
    <Switch>
      {subRoutes.map((routes) =>
        Object.keys(routes).map((path) => {
          return (
            <Route
              key={path}
              render={(props) => routes[path].component(props)}
              path={`${url}${path}`}
              exact={routes[path].exact}
            />
          )
        }),
      )}
    </Switch>
  )
}

import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import HeaderMobile from './components/HeaderMobile'
import HeaderDesktop from './components/HeaderDesktop'
import './index.less'

export const Header = () => {
  const [showMenu, setShowMenu] = React.useState(false)

  const { isMobile, currentProductPath } = useSelector((state) => state.global)
  const history = useHistory()

  const handleLoginButton = React.useCallback(() => {
    history.push(
      `${currentProductPath}/authorization${history.location.search}`,
    )
  }, [history, currentProductPath])

  const changeMenuVisible = React.useCallback(() => {
    const y = window && window?.scrollY
    const block = document.querySelector('.main_header')
    const productBlock = document.querySelector('.product_header__main')
    const blockHeader = document.querySelector('.agreement__text')
    const element = productBlock || block || blockHeader
    const { height: blockHeight } = element.getBoundingClientRect()
    setShowMenu(y > blockHeight)
  }, [])

  React.useEffect(() => {
    window.addEventListener('scroll', changeMenuVisible)
    return () => window.removeEventListener('scroll', changeMenuVisible)
  }, [])

  return isMobile ? (
    <HeaderMobile showMenu={showMenu} handleLoginButton={handleLoginButton} />
  ) : (
    <HeaderDesktop showMenu={showMenu} handleLoginButton={handleLoginButton} />
  )
}

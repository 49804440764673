import React from 'react'
import classNames from 'classnames'

import '../index.less'

export const TypeButton = ({ title, type, selectedType, eventHandler }) => (
  <button
    type="button"
    className={classNames('calclulate-income__product-types-item', {
      active: selectedType === type,
    })}
    onClick={eventHandler(type)}
  >
    {title}
  </button>
)

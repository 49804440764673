import React from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { enquireScreen } from 'enquire-js'
import queryString from 'query-string'
import { Layout } from 'antd'
import { isClient } from 'utils'
import { Helmet } from 'react-helmet'
import TagManager from 'react-gtm-module'
import { META, TITLE } from 'constants/META'
import { history } from './store'
import { SET_IS_MOBILE } from './reducers/global'
import { NotFound } from './pages/NotFound'
import { BasicLayout } from './layouts/basicLayout'
import { Testing } from './pages/Testing'
import { routes as routesList } from './routes/root'
import './styles/index.less'

const tagManagerArgs = {
  gtmId: 'GTM-TVZ6JV6',
}

const App = () => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      TagManager.initialize(tagManagerArgs)
    }

    if (typeof dataLayer !== 'undefined') {
      window.dataLayer.push({
        event: 'pageview',
        page: {
          url: window.location.pathname,
          title: document.title,
        },
      })

      history.listen((location) => {
        window.dataLayer.push({
          event: 'pageview',
          page: {
            url: location.pathname,
            title: document.title,
          },
        })
      })
    }
  }, [])

  React.useEffect(() => {
    if (isClient) {
      enquireScreen((m) => {
        dispatch(SET_IS_MOBILE(!!m))
      })
    }
    const query = queryString.parse(history.location.search)
    if (query.agentCode || query.fn) {
      localStorage.setItem('agentCode', query.agentCode || query.fn)
    }
    if (query.referrerCode) {
      localStorage.setItem('referrerCode', query.referrerCode)
    }
    if (query.sub_id) {
        localStorage.setItem('sub_id', query.sub_id)
    }
    if (query.utm_source) {
      localStorage.setItem('source', query.utm_source)
    }
  }, [dispatch])

  React.useEffect(() => {
    localStorage.removeItem('t')
  }, [])

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
        {META.map((props) => {
          return <meta key={props.name || props.property} {...props} />
        })}
      </Helmet>

      <Switch>
        <Route
          path="/testing"
          render={(props) => {
            return (
              <Layout.Content>
                <Testing {...props} />
              </Layout.Content>
            )
          }}
          exact
        />
        <Route
          path="/not-found/"
          render={(props) => (
            <Layout.Content
              style={{
                minHeight: '80vh',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <NotFound
                style={{ minHeight: '600px', height: '100%' }}
                {...props}
              />
            </Layout.Content>
          )}
        />
        {routesList.map((routes) =>
          Object.keys(routes).map((key) => {
            return (
              <Route
                path={key}
                key={key}
                render={(props) => {
                  return (
                    <BasicLayout>
                      <Layout.Content>
                        {routes[key].component(props)}
                      </Layout.Content>
                    </BasicLayout>
                  )
                }}
                exact={routes[key].exact}
              />
            )
          }),
        )}
      </Switch>
    </>
  )
}

export default App

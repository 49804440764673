export const FAQS = [
  {
    key: 1,
    question: 'Что такое Fins?',
    answer: `<p>Fins - помощник для выгодных накоплений. Мы развиваем культуру накоплений, 
    исключая устаревшие решения прошлого, чтобы инвестиции были доступны и понятны для каждого.</p>`,
  },
  {
    key: 2,
    question: 'Почему нам доверяют?',
    answer: `<ul>
      <li>Наш партнёр — БКС, брокер №1 в России по рейтингу Московской биржи;</li>
      <li>Мы сервис с <a target="_blank" href="https://fins.money/cdn/docs/license/Лицензия_банка.pdf">банковской</a> 
      и <a target="_blank" href="https://fins.money/cdn/docs/license/Лицензия_брокера.jpg">брокерской</a> лицензией ЦБ;</li>
      <li>Ваши данные в безопасности - многоуровневая система защиты информации;</li>
    </ul>`,
  },
  {
    key: 3,
    question: 'Причины выбрать Fins',
    answer: `<ul>
      <li>Fins помогает выгодно копить с надежными продуктами, подобранными профессионалами финансового рынка;</li>
      <li>Приобрести продукты в Fins - просто. Воспользуйтесь мобильным приложением на <a target="_blank" href="https://play.google.com/store/apps/details?id=money.fins">Android</a> 
      или <a href="https://apps.apple.com/ru/app/fins/id1453184963" target="_blank">iOS</a></li>
      <li>Ваш финансовый советник предложит Вам подходящее решение. Позвоните по телефону 8 (800) 500-10-01 или напишите в чат в личном кабинете.</li>
      <li>Открыть счет в Fins - это быстро и бесплатно.</li>
    </ul>`,
  },
  {
    key: 4,
    question: 'Сколько стоит обслуживание в Fins?',
    answer: `
    <p>Открытие, обслуживание и вывод со счета денег в рублях - бесплатно. Вы платите комиссию только при покупке продуктов.</p>
    <p><a href="https://fins.money/cdn/docs/tariff/Тариф_Fins.pdf" target="_blank">Полное описание тарифов</a></p>
    `,
  },
  {
    key: 5,
    question: 'Что такое брокерский счёт?',
    answer: `<p>Брокерский счет - это специальный счет для торговли ценными бумагами и валютой. 
    С брокерским счетом Fins вы сможете зарабатывать вместе с облигациями, паевыми инвестиционными фондами (ПИФ), доверительным управлением и структурными продуктами. Открыть брокерский счет можно онлайн за 5 минут - это бесплатно.</p>`,
  },
]

export const PRODUCT_FAQ = {
  funds: [
    {
      key: 1,
      question: 'Управляющая компания',
      answer: `<p>Управляющая компания БКС - одна из крупнейших компаний для частных и институциональных клиентов. Компания занимается управлением активов клиентов уже более 20 лет и насчитывает более 28 000 клиентов.</p> 
      <p>Национальное рейтинговое агентство оценивает качество компании на высоком уровне AA- [ru.am]</p>`,
    },
    {
      key: 2,
      question: 'Что такое ПИФ?',
      answer: `<p>Паевой инвестиционный фонд (ПИФ) - это фонд, состоящий из ценных бумаг (акций, облигаций, фондов и других бумаг) под управлением профессионала рынка с целью получения дохода.</p>`,
    },
    {
      key: 3,
      question: 'Как это работает?',
      answer: `<p>Клиент выбирает подходящий ему паевой инвестиционный фонд состоящий из акций или облигаций. Выбранным фондом управляет профессионал с целью получения дохода клиентом.</p>`,
    },
    {
      key: 4,
      question: 'Можно ли вывести часть денег?',
      answer: `<p>Купить или продать паи паевого фонда можно в любой момент.</p>`,
    },
    {
      key: 5,
      question: 'Сколько стоит обслуживание в Fins?',
      answer: `<p>Открытие, обслуживание и вывод со счета денег в рублях - бесплатно. Вы платите комиссию только при покупке продуктов. <a href="https://fins.money/cdn/docs/tariff/Тариф_Fins.pdf" target="_blank">Полное описание тарифов</a></p>`,
    },
  ],
  trustManagement: [
    {
      key: 1,
      question: 'Управляющая компания',
      answer: `<p>Управляющая компания БКС - одна из крупнейших компаний для частных и институциональных клиентов. Компания занимается управлением активов клиентов уже более 20 лет и насчитывает более 28 000 клиентов.</p>
      <p>Национальное рейтинговое агентство оценивает качество компании на высоком уровне AA- [ru.am]</p>`,
    },
    {
      key: 2,
      question: 'Что такое Доверительное управление (ДУ)?',
      answer: `<p>Доверительное управление (ДУ) - это договор между клиентом и управляющей компанией, по которому профессиональный управляющий обязуется осуществлять управление активами на рынке ценных бумаг в интересах клиента.</p>`,
    },
    {
      key: 3,
      question: 'Как это работает?',
      answer: `<p>Клиент выбирает подходящую стратегию доверительного управления и пополняет счет. Управляющий вместе со своей командой собирает аналитические отчеты и распределяет деньги клиента в наиболее доходные активы (это могут быть фонды, акции или облигации). При изменении рынка управляющий изменяет структуру портфеля, продавая невыгодные ценные бумаги и заменяя их на более доходные инструменты.</p>`,
    },
    {
      key: 4,
      question: 'Можно ли вывести часть денег?',
      answer: `<p>Стратегия доверительного предусматривает досрочное расторжение договора в течение 10-20 дней.</p>`,
    },
  ],
  bonds: [
    {
      key: 1,
      question: 'Что такое Fins?',
      answer: `<p>Fins - помощник для выгодных накоплений. Мы развиваем культуру накоплений, 
      исключая устаревшие решения прошлого, чтобы инвестиции были доступны и понятны для каждого.</p>`,
    },
    {
      key: 2,
      question: 'Почему нам доверяют?',
      answer: `<ul>
        <li>Наш партнёр — БКС, брокер №1 в России по рейтингу Московской биржи;</li>
        <li>Мы сервис с <a target="_blank" href="https://fins.money/cdn/docs/license/Лицензия_банка.pdf">банковской</a> 
        и <a target="_blank" href="https://fins.money/cdn/docs/license/Лицензия_брокера.jpg">брокерской</a> лицензией ЦБ;</li>
        <li>Ваши данные в безопасности - многоуровневая система защиты информации;</li>
      </ul>`,
    },
    {
      key: 3,
      question: 'Что такое облигации?',
      answer: `<p>Облигация - это ценная бумага, которая позволяет вам дать в долг государству или компании и получать за это доход.</p>`,
    },
    {
      key: 4,
      question: 'Сколько стоит обслуживание в Fins?',
      answer: `<p>Открытие, обслуживание и вывод со счета денег в рублях - бесплатно. Вы платите комиссию только при покупке продуктов. <a href="https://fins.money/cdn/docs/tariff/Тариф_Fins.pdf" target="_blank">Полное описание тарифов</a></p>`,
    },
  ],
  sp: [
    {
      key: 1,
      question: 'Что такое Fins?',
      answer: `<p>Fins - помощник для выгодных накоплений. Мы развиваем культуру накоплений, 
      исключая устаревшие решения прошлого, чтобы инвестиции были доступны и понятны для каждого.</p>`,
    },
    {
      key: 2,
      question: 'Что такое структурный продукт?',
      answer: `<p>Структурный продукт - это готовое финансовое решение со 100% защитой вложенных средств.</p>`,
    },
    {
      key: 3,
      question: 'Кто может купить структурный продукт?',
      answer: `<p>Для покупки структурного продукта требуется статус квалицированного инвестора.</p>`,
    },
    {
      key: 4,
      question: 'Сколько стоит обслуживание в Fins?',
      answer: `<p>Открытие, обслуживание и вывод со счета денег в рублях - бесплатно. Вы платите комиссию только при покупке продуктов. <a href="https://fins.money/cdn/docs/tariff/Тариф_Fins.pdf" target="_blank">Полное описание тарифов</a></p>`,
    },
  ],
  iia: [
    {
      key: 1,
      question: 'Что такое Fins?',
      answer: `<p>Fins - помощник для выгодных накоплений. Мы развиваем культуру накоплений, 
      исключая устаревшие решения прошлого, чтобы инвестиции были доступны и понятны для каждого.</p>`,
    },
    {
      key: 2,
      question: 'Что такое ИИС?',
      answer: `<p>ИИС (Индивидуальный инвестиционный счет) - это особый вид брокерского счета, с помощью которого можно получать до 52 000 рублей от суммы пополнения в год в виде налогового вычета</p>`,
    },
    {
      key: 3,
      question: 'Обязательно ли закрывать ИИС через 3 года?',
      answer: `<p>ИИС можно не закрывать через 3 года, а продолжить получать налоговый вычет в размере до 52 000 рублей ежегодно.</p>`,
    },
    {
      key: 4,
      question: 'Можно ли вывести часть денег с ИИС?',
      answer: `<p>Нет, с ИИС можно вывести только всю сумму сразу, после чего ваш счет будет закрыт. Вы сможете открыть его повторно, с этим поможет наша служба поддержки</p>`,
    },
    {
      key: 5,
      question: 'Сколько стоит обслуживание в Fins?',
      answer: `<p>Открытие, обслуживание и вывод со счета денег в рублях - бесплатно. Вы платите комиссию только при покупке продуктов. <a href="https://fins.money/cdn/docs/tariff/Тариф_Fins.pdf" target="_blank">Полное описание тарифов</a></p>`,
    },
  ],
  qualified: [
    {
      key: 1,
      question: 'Что такое Fins?',
      answer: `<p>Fins - помощник для выгодных накоплений. Мы развиваем культуру накоплений, 
      исключая устаревшие решения прошлого, чтобы инвестиции были доступны и понятны для каждого.</p>`,
    },
    {
      key: 2,
      question: 'Квалифицированный инвестор - это',
      answer: `<p>Квалифицированный инвестор - это статус, который позволяет приобретать специально выпущенные финансовые инструменты, структурные продукты и иностранные акции.</p>`,
    },
    {
      key: 3,
      question: 'Сколько стоит обслуживание в Fins?',
      answer: `<p>Открытие, обслуживание и вывод со счета денег в рублях - бесплатно. Вы платите комиссию только при покупке продуктов. <a href="https://fins.money/cdn/docs/tariff/Тариф_Fins.pdf" target="_blank">Полное описание тарифов</a></p>`,
    },
  ],
}

import React, { useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import queryString from 'query-string'
import { Button } from 'antd'
import { FinalSection } from '../../FinalSection'
import { MailIcon } from './assets/mail'
import './index.less'
import { NewPassword } from '../NewPassword'

export const PasswordRestore = ({ setDrawerTitle }) => {
  const [restoreStep, setRestoreStep] = useState('forgot')
  const history = useHistory()
  const dispatch = useDispatch()

  const { isOtpVerfiy } = useSelector((state) => state.otp)
  const {
    restore: { success },
  } = useSelector((state) => state.security)

  const handleButton = useCallback(() => {
    dispatch({
      type: 'RESTORE',
    })
    setRestoreStep('email')
  }, [])

  useEffect(() => {
    const query = queryString.parse(history.location.search)
    if (query.hash) {
      setRestoreStep('newPassword')
    } else if (!isOtpVerfiy) {
      history.push('/authorization')
    }
  }, [])

  useEffect(() => {
    if (restoreStep === 'forgot') setDrawerTitle('Вход в Fins')
  }, [restoreStep])

  return (
    <>
      {restoreStep === 'forgot' && (
        <div className="password-restore">
          <div className="password-restore__icon">
            <MailIcon />
          </div>
          <p className="password-restore__title">Забыли пароль?</p>
          <p className="password-restore__text">
            Отправьте ссылку для восстановления пароля на вашу почту. Или
            позвоните нам и специалисты поддержки помогут вам
          </p>

          <Button
            style={{ marginBottom: '26px' }}
            onClick={handleButton}
            type="primary"
            block
          >
            Отправить ссылку на email
          </Button>
          <p className="password-restore__phone">
            <a href="tel:8-800-500-10-01">8 (800) 500-10-01</a>
          </p>
        </div>
      )}
      {restoreStep === 'email' && (
        <FinalSection type={success ? 'restorePassword' : 'mailNotFound'} />
      )}
      {restoreStep === 'newPassword' && <NewPassword />}
    </>
  )
}

import React from 'react'
import { Form, Input } from 'antd'
import Mask from 'inputmask'
import classNames from 'classnames'
import { useHandleFocusInput, useHandleBlurInput } from '../../../utils/hooks'
// import { dynamic, isBrowser } from 'umi';

const InputMask = (props) => {
  const {
    autoComplete = 'off',
    autoFocus = false,
    label,
    config,
    hasFeedback,
    onFocus = () => {},
    onBlur = () => {},
    onChange = () => {},
    mask,
    showMaskOnHover,
  } = props

  const [empty, setEmpty] = React.useState(true)
  const focusedInput = useHandleFocusInput(false)
  const bluredInput = useHandleBlurInput(false)

  const im = React.useMemo(() => {
    return new Mask({
      mask,
      showMaskOnHover,
    })
  }, [mask, showMaskOnHover])

  // TODO - remove any
  const inputRef = React.useRef()

  // events
  const handleBlur = React.useCallback(
    (e) => {
      onBlur(e)
      focusedInput.onFocus(false)
      bluredInput.onBlur(true)
    },
    [bluredInput, focusedInput, onBlur],
  )

  const handleFocus = React.useCallback(
    (e) => {
      onFocus(e)
      focusedInput.onFocus(true)
      bluredInput.onBlur(false)
    },
    [bluredInput, focusedInput, onFocus],
  )

  const handleChange = React.useCallback(
    (e) => {
      im.mask(e.target)
      onChange(e)
      setEmpty(e.target.value === '')
    },
    [onChange, im],
  )

  // effects
  React.useEffect(() => {
    if (inputRef && inputRef.current) {
      if (autoFocus) {
        inputRef.current.focus()
      }
    }
  }, [autoFocus, inputRef])

  React.useEffect(() => {
    if (inputRef && inputRef.current && inputRef.current.props?.value) {
      setEmpty(false)
    }
  }, [])

  return (
    <Form.Item>
      <Form.Item {...config} hasFeedback={hasFeedback && !focusedInput.value}>
        <Input
          ref={inputRef}
          {...props}
          onChange={handleChange}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </Form.Item>
      <span
        className={classNames(
          'input-label',
          focusedInput.value && 'input-label__focus',
          !empty && 'input-label__not-empty',
        )}
      >
        {label}
      </span>
    </Form.Item>
  )
}

export default InputMask

import React from 'react'
import { Form, Checkbox, Button } from 'antd'
import { isNameValid, isPhoneNumberValid } from 'utils/validators'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { INVITE } from '../../../actions/constants/security'
import Input from '../../ui/Input'
import InputMask from '../../ui/InputMask'
import { FETCH_AUTH } from '../../../actions/constants/auth'
import {
  setLogin,
  setAuthLoading,
  setOtpRequired,
} from '../../../reducers/auth'
import './index.less'

export const Registration = () => {
  const [form] = Form.useForm()
  const [personalData, setPesonalData] = React.useState(true)

  const history = useHistory()
  const dispatch = useDispatch()
  const { currentProductPath } = useSelector((state) => state.global)
  const { loading } = useSelector((state) => state.security.invite)

  const handleChangeName = React.useCallback((e) => {
    const { value } = e.target
    dispatch({
      type: 'security/setRegistrationFormField',
      payload: {
        key: 'name',
        value,
      },
    })
  }, [])

  const handleChangePhone = React.useCallback((e) => {
    const { value } = e.target
    dispatch({
      type: 'security/setRegistrationFormField',
      payload: {
        key: 'phone',
        value,
      },
    })
  }, [])

  const handleChangePesonalData = React.useCallback((e) => {
    const { checked } = e.target
    setPesonalData(checked)
  }, [])

  const handleClickRegistartion = React.useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        const { phone } = values
        dispatch({ type: FETCH_AUTH, payload: phone })
        dispatch(setLogin(phone))
        dispatch(setAuthLoading(true))
        dispatch(setOtpRequired(true))
        history.push(
          `${currentProductPath}/authorization/otp/${history.location.search}`,
        )
      })
      .catch(() => {})
  }, [form, dispatch, currentProductPath, history])

  const onFinish = React.useCallback(() => {
    dispatch({
      type: INVITE,
    })
  }, [])

  return (
    <Form name="registartion" onFinish={onFinish} form={form}>
      <Input
        label="Ваше имя"
        onChange={handleChangeName}
        config={{
          validateTrigger: ['onFocus', 'onChange'],
          rules: [
            ({ getFieldError }) => ({
              validator(_, value) {
                return isNameValid(value)
              },
            }),
          ],
          name: 'name',
        }}
      />
      <InputMask
        label="Телефон"
        mask="+7(999) 999-99-99"
        autocomplete="tel"
        inputMode="numeric"
        showMaskOnHover={false}
        onChange={handleChangePhone}
        config={{
          validateTrigger: ['onFocus', 'onChange'],
          rules: [
            ({ getFieldError }) => ({
              validator(_, value) {
                return isPhoneNumberValid(value)
              },
            }),
          ],
          name: 'phone',
        }}
      />
      <Form.Item
        name="personalData"
        className="registration_form__item-checkbox"
      >
        <Checkbox
          onChange={handleChangePesonalData}
          checked={personalData}
          className="registration_form__checkbox"
          value="B"
        >
          Даю согласие на передачу и обработку{' '}
          <a
            target="_blank"
            href="https://fins.money/cdn/docs/Согласие_на_обработку_персональных_данных.pdf"
            rel="noreferrer noopener"
          >
            персональных данных
          </a>
        </Checkbox>
      </Form.Item>
      <Button
        loading={loading}
        disabled={!personalData}
        type="primary"
        onClick={handleClickRegistartion}
        block
        className="registration_form__button"
      >
        Зарегистрироваться
      </Button>

      <Button
        loading={loading}
        disabled={!personalData}
        htmlType="submit"
        block
      >
        Получить консультацию
      </Button>
    </Form>
  )
}

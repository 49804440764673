export const FETCH_TRY_LOGIN = 'FETCH_TRY_LOGIN'
export const FETCH_FINALIZE_SIGNUP = 'FETCH_FINALIZE_SIGNUP'
export const HELLO = 'HELLO'
export const CONFIRM_OTP = 'CONFIRM_OTP'
export const FINALIZE = 'FINALIZE'
export const REGISTRATION_FORM_CLEANUP = 'REGISTRATION_FORM_CLEANUP'
export const RESTORE = 'RESTORE'
export const CONFIRM_RESTORE = 'CONFIRM_RESTORE'
export const INVITE = 'INVITE'
export const RESTORE_SUBMIT_PASSWORD = 'RESTORE_SUBMIT_PASSWORD'
export const RESTORE_SUBMIT_OTP = 'RESTORE_SUBMIT_OTP'
export const RESTORE_RESEND_OTP = 'RESTORE_RESEND_OTP'
export const RESTORE_PROCESS = 'RESTORE_CONFIRM_OTP'

import React from 'react'
import classNames from 'classnames'
import { formatSlideNumber } from '../../utils/carousel'
import styles from './index.module.less'

const startIndex = 0

const DesktopCarousel = ({ slides, isProduct, type }) => {
  const [slideIndex, setSlideIndex] = React.useState(startIndex)
  const [animation, setAnimation] = React.useState(false)

  const startAnimation = React.useCallback(() => {
    setAnimation(true)
    setTimeout(() => setAnimation(false), 500)
  }, [])

  const next = React.useCallback(() => {
    const index =
      slideIndex + 1 > slides.length - 1 ? slides.length - 1 : slideIndex + 1
    setSlideIndex(index)
    startAnimation()
  }, [slideIndex, slides, startAnimation])

  const prev = React.useCallback(() => {
    const index = slideIndex - 1 < 0 ? 0 : slideIndex - 1
    setSlideIndex(index)
    startAnimation()
  }, [slideIndex, startAnimation])

  return (
    <div
      className={classNames(
        styles.carousel,
        isProduct && styles.carousel__product,
        type && styles[`carousel__${type}`],
      )}
    >
      <div className={styles.slide} key={slideIndex}>
        <div
          className={classNames(
            styles.slide__left,
            animation && styles.animation,
          )}
        >
          <div className={styles.slide__label}>{slides[slideIndex].label}</div>
          <p>{slides[slideIndex].text}</p>
          <span>{slides[slideIndex].desc}</span>
          {slideIndex === 0 && !isProduct && (
            <div className={styles.markets}>
              <a
                className={styles.apple}
                href="https://apps.apple.com/ru/app/fins/id1453184963"
              />
              <a
                className={styles.google}
                href="https://play.google.com/store/apps/details?id=money.fins"
              />
            </div>
          )}
        </div>
        <div
          className={classNames(
            styles.slide__right,
            animation && styles.animation,
          )}
        >
          <img
            src={slides[slideIndex].image}
            alt=""
            className={classNames(slideIndex === 1 && styles.slide_index_1)}
          />
        </div>
      </div>
      <div className={styles.buttons}>
        <button
          disabled={animation || slideIndex === 0}
          onClick={prev}
          className={classNames(
            styles.prev,
            slideIndex === 0 && styles.disabled,
          )}
          type="button"
        />
        <button
          disabled={animation || slideIndex === slides.length - 1}
          onClick={next}
          className={classNames(
            styles.next,
            slideIndex === slides.length - 1 && styles.disabled,
          )}
          type="button"
        />
      </div>
      <div className={styles.counter}>{`${formatSlideNumber(
        slideIndex + 1,
      )} / ${formatSlideNumber(slides.length)}`}</div>
    </div>
  )
}

export default DesktopCarousel

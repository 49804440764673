import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { push } from 'connected-react-router'
import { Form, Button } from 'antd'
import { declensions } from '../../../utils/helpers'
import { UserWelcomeBlock } from '../../UserWelcomeBlock'
import { FETCH_PASSWORD_VERIFY } from '../../../actions/constants/auth'
import { clearFinalizeData } from '../../../reducers/security'
import {
  saveRefreshToken,
  clearAuthenticationData,
} from '../../../reducers/auth'
import { clearOtpData } from '../../../reducers/otp'
import Input from '../../ui/Input'
import './index.less'

export const PasswordForm = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const { currentProductPath } = useSelector((state) => state.global)
  const { authenticationData } = useSelector((state) => state.auth)
  const { loading } = useSelector((state) => state.auth)
  const { passwordData } = useSelector((state) => state.auth)
  const { isOtpVerfiy } = useSelector((state) => state.otp)

  const wordsDeclensions = ['попытка', 'попытки', 'попыток']

  const handleOnFinish = React.useCallback(
    (values) => {
      const { password } = values
      dispatch({
        type: FETCH_PASSWORD_VERIFY,
        payload: { password, uuid: authenticationData.id },
      })
    },
    [dispatch, authenticationData],
  )

  const handleLinkButton = React.useCallback(() => {
    dispatch(push(`${currentProductPath}/password-restore`))
  }, [currentProductPath, dispatch])

  React.useEffect(() => {
    if (!isOtpVerfiy) {
      dispatch(push(`${currentProductPath}/authorization`))
    }
  }, [currentProductPath, dispatch, history, isOtpVerfiy])

  React.useEffect(() => {
    if (passwordData && !passwordData.isValid) {
      if (passwordData?.attemptsLeft === 0) {
        dispatch(clearOtpData())
        dispatch(clearAuthenticationData())
        dispatch(push(`${currentProductPath}/authorization`))
      }
      form.setFields([
        {
          name: 'password',
          value: '',
          errors: ['Неверный пароль'],
        },
      ])
    }
  }, [currentProductPath, dispatch, form, passwordData])

  React.useEffect(() => {
    if (passwordData && passwordData.isValid) {
      dispatch(saveRefreshToken(passwordData?.result?.refreshToken))
      window.location.href = `/icab/?sessionToken=${passwordData?.result.accessToken}`
    }
  }, [passwordData, dispatch])

  React.useEffect(() => {
    return () => {
      dispatch(clearFinalizeData())
    }
  }, [])

  return (
    <Form
      className="password-form"
      name="password-form"
      form={form}
      onFinish={handleOnFinish}
    >
      <UserWelcomeBlock />
      <Input
        label="Введите пароль"
        password
        config={{
          validateTrigger: ['onFocus', 'onChange'],
          rules: [
            {
              required: true,
              message: 'Обязательное поле для заполнения',
            },
          ],
          name: 'password',
        }}
      />
      <Button
        onClick={handleLinkButton}
        className="password-form__link-button"
        type="link"
      >
        Забыли пароль?
      </Button>
      {passwordData && !passwordData.isValid && (
        <div className="password-form__errors">
          <div className="password-form__errors-icon" />
          <div className="password-form__errors-desc">
            {!passwordData.attemptsLeft
              ? `Неверный пароль`
              : `Осталось ${passwordData.attemptsLeft} ${declensions(
                  passwordData.attemptsLeft,
                  wordsDeclensions,
                )}`}
          </div>
        </div>
      )}
      <Button
        className="password-form__button"
        loading={loading}
        type="primary"
        block
        htmlType="submit"
      >
        Продолжить
      </Button>
    </Form>
  )
}

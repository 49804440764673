import React from 'react'
import { MenuOutlined } from '@ant-design/icons'
import BcsImage from 'assets/bcs_logo.svg'
import { Link, useHistory } from 'react-router-dom'
import { Divider, Button } from 'antd'
import FinsImage from 'assets/fins_logo.svg'
import { MainMenu } from 'components/MainMenu'
import classNames from 'classnames'
import './index.less'

const HeaderMobile = ({ showMenu, handleLoginButton }) => {
  const [collapsed, setCollapsed] = React.useState(false)
  const history = useHistory()
  const handleOpenMenu = React.useCallback(() => {
    setCollapsed(!collapsed)
  }, [collapsed])

  const isPartnersPage = React.useMemo(
    () => /partners/.test(history.location.pathname),
    [history.location.pathname],
  )

  return (
    <>
      <div className="header-mobile">
        <div className="header-mobile__block">
          <button
            data-type="burger"
            type="button"
            className="header-mobile__button-burger"
            onClick={handleOpenMenu}
          >
            <MenuOutlined />
          </button>

          <Link to="/">
            <img
              src={FinsImage}
              className="header-mobile__fins-logo"
              alt="fins logo"
            />
          </Link>
          <Divider className="header-mobile__divider" type="vertical" />
          <img
            src={BcsImage}
            className="header-mobile__mi-logo"
            alt="bcs logo"
          />
        </div>
      </div>
      <MainMenu handleOpenMenu={handleOpenMenu} show={collapsed} isMobile />
      {!isPartnersPage && (
        <div
          className={classNames(
            'header-mobile__buttons',
            showMenu && 'header-mobile__buttons--show',
          )}
        >
          <Button onClick={handleLoginButton} type="primary">
            Регистрация
          </Button>
          <Button onClick={handleLoginButton}>Вход</Button>
        </div>
      )}
    </>
  )
}

export default HeaderMobile

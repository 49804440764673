import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FinsDrawer } from '../../components/ui/FinsDrawer'
import { FETCH_SECRET_QUESTIONS } from '../../actions/constants/auth'
import { FETCH_TARGET_OTP } from '../../actions/constants/otp'
import { AuthForm } from '../../components/forms/AuthForm'

import './index.less'
import { clearOtpData } from '../../reducers/otp'

export const Authorization = ({ history }) => {
  const { authenticationData } = useSelector((state) => state.auth)
  const { otpRequire } = useSelector((state) => state.auth)
  const { otpData } = useSelector((state) => state.otp)
  const { isMobile, currentProductPath } = useSelector((state) => state.global)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (otpRequire && authenticationData && authenticationData?.id) {
      dispatch({
        type: FETCH_TARGET_OTP,
        payload: { uuid: authenticationData.id },
      })
    }
  }, [authenticationData, otpRequire, dispatch])

  React.useEffect(() => {
    if (otpRequire && otpData && otpData?.codeLength) {
      history.push(
        `${currentProductPath}/authorization/otp/${history.location.search}`,
      )
    }
  }, [otpRequire, otpData, currentProductPath, history])

  React.useEffect(() => {
    dispatch({ type: FETCH_SECRET_QUESTIONS })
  }, [])

  React.useEffect(() => {
    dispatch(clearOtpData())
  }, [])

  return (
    <FinsDrawer
      title="Вход в Fins"
      placement="right"
      width={isMobile ? '100%' : '439px'}
      bodyStyle={{ padding: '32px' }}
      isMobile={isMobile}
      routePathAfterClose={`${currentProductPath}/${history.location.search}`}
    >
      <>
        <p>
          <b>Используйте номер мобильного телефона для входа или регистрации</b>
        </p>
        <p className="authorization__desc">
          Мы не спамим навязчивыми сообщениями. Ваш телефон нужен для
          безопасного входа с смс-подтверждением
        </p>
        <AuthForm />
      </>
    </FinsDrawer>
  )
}

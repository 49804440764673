export const isOtpValid = (value) => {
  switch (true) {
    case value && value.replace(new RegExp(/\) |-|_/g), '').length !== 4:
      return Promise.reject(new Error('Введите код'))
    case !value:
      return Promise.reject(new Error('Обязательное поле для заполнения'))
    default:
      return Promise.resolve()
  }
}

import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_TARGET_OTP } from '../../actions/constants/otp'
import { saveOtpData, setOtpError, setOtpLoading } from '../../reducers/otp'
import { requestTargetOtp } from '../../api/v5/otp'

function* fetchOtp(action) {
  try {
    yield put(setOtpLoading(true))
    const otpData = yield call(requestTargetOtp, action.payload)

    yield put(saveOtpData(otpData))
    yield put(setOtpLoading(false))
  } catch (e) {
    yield put(setOtpLoading(false))
    yield put(setOtpError(e.message))
  }
}

function* getActiveOtpSaga() {
  yield takeEvery(FETCH_TARGET_OTP, fetchOtp)
}

export default getActiveOtpSaga

export const STAGES = [
  {
    key: 1,
    text: 'Зарегистрируйтесь и получите доступ ко всем продуктам сервиса',
  },
  {
    key: 2,
    text: 'Откройте бесплатный счёт',
  },
  {
    key: 3,
    text: 'Пополните счёт и начните получать доход',
  },
]

export const ErrorCodes = {
  '-1': 'NETWORK_ERROR',
  10: 'INTERNAL_ERROR',
  100: 'MAINTENANCE',
  30: 'VALIDATION_ERROR',

  // Security
  1100: 'SESSION_NOT_VALID',
  1120: 'BAD_CREDENTIALS',
  1121: 'MAX_AUTH_ATTEMPTS_REACHED',
  1130: 'TOKEN_HAS_NO_AUTH',
  1140: 'USER_BLOCKED',
  1300: 'LOGIN_ALREADY_HAS_REGISTRATION',
  1302: 'REGISTRATION_NOT_ALLOWED',
  1400: 'BAD_OTP',
  1401: 'BAD_OTP_TOKEN',
  1402: 'OTP_EXPIRED',
  1403: 'OTP_MAX_ATTEMPT_REACHED',
  1500: 'INCORRECT_OLD_PASSWORD',

  // INVITE
  30000: 'INVITE_REQUEST_ALREADY_EXISTS',
  INVITE_TOKEN_INVALID: 30001,

  // USER
  EMAIL_CONFIRMATION_TOKEN_INVALID: 2100,
  EMPTY_RISK_PROFILE: 2200,

  // INVEST
  SP_MIN_AMOUNT_ERROR: 1600,

  // DEPOSIT
  DEPOSIT_NOT_ALLOWED_DUE_ATTORNEY_DATE: 5102,

  // PAYMENT
  INVALID_EXCHANGE_RATE: 6000,
  INVALID_BANK_ACCOUNT: 6001,
  NOT_ENOUGH_MONEY: 6002,
  WRONG_BCS_ACCOUNT_NUMBER: 6006,
  LIMIT_EXCEEDED_SINGLE: 6102,
  LIMIT_EXCEEDED_DAILY: 6103,
  LIMIT_AMOUNT_TOO_SMALL: 6104,
  CANT_WITHDROW_IIS: 6105,

  LIMIT_SIZE_IMG_AVATAR: 'Размер файла не должен превышать 5Мб',

  IMPOSIBLE_TO_BUY: 9990,

  SCRAPING_INTERNAL_ERROR: 10000,
  SCRAPING_OPERATION_ALREADY_INPROGRESS: 10001,

  // SECRET QUEST
  1600: 'SECRET_QUESTION_NOT_FOUND',
  1613: 'MAX_SECRET_ANSWER_COUNT_REACHED',
  4700: 'GA_NOT_FITS_FOR_SP_PURCHASING',
  1612: 'BAD_SECRET_ANSWER',
  1615: 'MAX_ANSWER_ATTEMPTS_REACHED',
  1502: 'PASSWORD_RESTORE_SESSION_EXPIRED',
  1503: 'PASSWORD_RESTORATION_TOKEN_WRONG',
  1504: 'PASSWORD_RESTORATION_SESSION_ALREADY_USED',
  1501: 'PASSWORD_ALREADY_USED',
}

// TODO: убить это гавно
const ErrorCodesInline = {
  BAD_CREDENTIALS: true,
  BAD_OTP: true,
  OTP_EXPIRED: true,
  BAD_OTP_TOKEN: true,
  OTP_MAX_ATTEMPT_REACHED: true,
  MAX_AUTH_ATTEMPTS_REACHED: true,

  NOT_ENOUGH_MONEY: true,
}

import React, { useEffect, useState } from 'react'
import { Form, Button } from 'antd'
import { keys, head } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { push } from 'connected-react-router'
import queryString from 'query-string'

import { FinalSection } from '../../FinalSection'
import useInterval from '../../../hooks/useInterval'

import {
  CONFIRM_RESTORE,
  RESTORE_RESEND_OTP,
  RESTORE_CONFIRM_OTP,
  RESTORE_PROCESS,
  RESTORE_SUMBIT_PASSWORD,
  RESTORE_SUBMIT_PASSWORD,
  RESTORE_SUBMIT_OTP,
} from '../../../actions/constants/security'
import {
  passwordRules,
  passwordValidationMessages,
} from '../../../utils/validators/user-data'
import { isPasswordValid, isOtpValid } from '../../../utils/validators'
import Input from '../../ui/Input'
import InputMask from '../../ui/InputMask'

import './index.less'

export function NewPassword({ nested, labelFirstPassword, submit }) {
  const [useOtpCode, setOtpCode] = useState('')
  const [useTimeleft, setTimeleft] = useState(60)
  const [usePasswordValidErrors, setPasswordValidErrors] = useState('')
  const [form] = Form.useForm()
  const { getFieldValue } = form
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    restore: { success, error, data, otp },
  } = useSelector((state) => state.security)
  const { loading: securityLoading } = useSelector((state) => state.security)

  const query = queryString.parse(history.location.search)

  const [useStep, setStep] = useState('password')
  const [usePolling, setPolling] = useState(1000)

  useInterval(
    async () => {
      if (query?.hash) {
        dispatch({
          type: RESTORE_PROCESS,
          payload: {
            id: query?.hash,
          },
        })
      }
    },
    usePolling ? 1000 : null,
  )

  const handleSubmitPassword = (values) => {
    dispatch({
      type: RESTORE_SUBMIT_PASSWORD,
      payload: {
        id: query?.hash,
        password: values.password,
      },
    })
    setPolling(true)
  }
  const handleSubmitOTP = (e) => {
    if (e.target.value.replace(/\_/g, '').length === 4) {
      dispatch({
        type: RESTORE_SUBMIT_OTP,
        payload: getFieldValue('otpCode'),
      })
      setPolling(true)
    }
  }

  const handleResendOTP = () => {
    dispatch({
      type: RESTORE_RESEND_OTP,
      payload: null,
    })
    setTimeleft(60)
  }

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTimeleft(useTimeleft - 1)
    }, 1000)
    if (useTimeleft === 0) {
      clearTimeout(timer)
    }
    return () => clearTimeout(timer)
  }, [useTimeleft])

  useEffect(() => {
    if (data) {
      setPolling(false)
      switch (data.status) {
        case 'NEW':
          setStep('password')
          break
        case 'PENDING_OTP_VERIFICATION':
          setStep('sms')
          break
        case 'CHANGED':
          setStep('success')
          break
        case 'FAILED':
          setStep('failed')
          break
        default:
        // setPolling(true)
      }
    }
  }, [data])

  return (
    <>
      {useStep === 'password' && (
        <Form
          className="new-password-form"
          name="newPassword"
          form={form}
          onFieldsChange={(fieldData) => {
            const name = head(fieldData)?.name[0]
            const value = head(fieldData)?.value
            if (name === 'password') {
              setPasswordValidErrors(passwordValidationMessages(value))
            }
          }}
          onFinish={handleSubmitPassword}
        >
          <p className="new-password-form__title">Придумайте новый пароль</p>
          <Input
            label={labelFirstPassword || 'Пароль'}
            password
            config={{
              shouldUpdate: (prevValues, curValues) =>
                prevValues.password !== curValues.password,
              validateTrigger: ['onFocus', 'onChange'],
              rules: [
                () => ({
                  validator(_, value) {
                    return isPasswordValid(value)
                  },
                }),
              ],
              name: 'password',
            }}
          />
          <Input
            label="Повторите пароль"
            password
            config={{
              dependencies: ['password'],
              validateTrigger: ['onFocus', 'onChange'],
              rules: [
                ({ validateFields }) => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject(
                        new Error('Обязательное поле для заполнения'),
                      )
                    }
                    if (getFieldValue('password') === value) {
                      return Promise.resolve()
                    }

                    return Promise.reject(new Error('Пароли не совпадают'))
                  },
                }),
              ],
              name: 'passwordRepeat',
            }}
          />
          <Form.Item shouldUpdate>
            {() => {
              return (
                <ul className="new-password-form__validator-list">
                  {keys(passwordRules).map((i) => {
                    return (
                      <li
                        key={i}
                        className={
                          usePasswordValidErrors.includes(passwordRules[i])
                            ? ''
                            : getFieldValue('password') && 'valid'
                        }
                      >
                        {passwordRules[i]}
                      </li>
                    )
                  })}
                </ul>
              )
            }}
          </Form.Item>
          {!nested && (
            <Button
              htmlType="submit"
              type="primary"
              block
              loading={securityLoading}
              disabled={
                !getFieldValue('password') ||
                !getFieldValue('passwordRepeat') ||
                getFieldValue('password') !== getFieldValue('passwordRepeat')
              }
            >
              Продолжить
            </Button>
          )}
        </Form>
      )}
      {useStep === 'sms' && (
        <>
          <p>
            <b>Для подтверждения смены пароля введите код из сообщения</b>
          </p>
          <p className="authorization__desc">
            Мы отправили СМС с кодом на ваш телефон {otp?.data?.phone}
          </p>
          <Form name="otp-form" form={form}>
            <InputMask
              label="Код из смс"
              mask="9999"
              showMaskOnHover={false}
              onChange={handleSubmitOTP}
              config={{
                validateTrigger: ['onFocus', 'onChange'],
                rules: [
                  ({ _getFieldValue }) => ({
                    validator(_, value) {
                      return isOtpValid(value)
                    },
                  }),
                ],
                name: 'otpCode',
              }}
            />
            <Button
              disabled={useTimeleft > 0}
              onClick={handleResendOTP}
              type="primary"
              loading={securityLoading}
              block
              htmlType="submit"
            >
              {useTimeleft > 0
                ? `Повторно запросить код через ${useTimeleft}с.`
                : 'Запросить код'}
            </Button>
          </Form>
        </>
      )}
      {useStep === 'success' && <FinalSection type="restorePasswordSuccess" />}
      {useStep === 'failed' && <FinalSection type="restorePasswordFailed" />}
    </>
  )
}

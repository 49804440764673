import React from 'react'
import { useSelector } from 'react-redux'
import MobileCarousel from '../../components/MobileCarousel'
import DesktopCarousel from '../../components/DesktopCarousel'
import { SLIDES } from '../../constants/START_SAVING'
import './index.less'

const StartSaving = () => {
  const { isMobile } = useSelector((state) => state.global)

  return (
    <div className="content content--bg">
      <div className="content__block">
        <h3 className="sub_title">Начать копить с Fins - удобно</h3>
        <p className="sub_desc">
          Приобретайте выгодные предложения с помощью мобильного приложении
          Fins. Зарегистрируйтесь и получите доступ ко всем продуктам. Ваш
          финансовый консультант поможет выбрать подходящий именно вам продукт
        </p>
      </div>
      <div className="start-saving">
        {!isMobile ? (
          <DesktopCarousel slides={SLIDES} />
        ) : (
          <MobileCarousel slides={SLIDES} />
        )}
      </div>
    </div>
  )
}

export default StartSaving

import React from 'react'
import { Fund } from '../pages/Fund'
import { TrustManagement } from '../pages/TrustManagement'
import { Bonds } from '../pages/Bonds'
import { StructuralProducts } from '../pages/StructuralProducts'
import { Qualification } from '../pages/Qualification'
import { IndividualInvestmentAccount } from '../pages/IndividualInvestmentAccount'
import { Testing } from '../pages/Testing'

export const products = {
  '/fund': {
    component: (props) => <Fund {...props} />,
  },
  '/trust-management': {
    component: (props) => <TrustManagement {...props} />,
  },
  '/bonds': {
    component: (props) => <Bonds {...props} />,
  },
  '/structural-products': {
    component: (props) => <StructuralProducts {...props} />,
  },
  '/qualification': {
    component: (props) => <Qualification {...props} />,
  },
  '/individual-investment-account': {
    component: (props) => <IndividualInvestmentAccount {...props} />,
  },
}

import React from 'react'
import { Button } from 'antd'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import './index.less'
import { SuccessIcon } from './assets/success'
import { NotFoundIcon } from './assets/notFound'
import { BlockIcon } from './assets/block'

const mapTypeToSection = {
  restorePassword: {
    icon: <SuccessIcon />,
    title: 'Проверьте вашу почту',
    desc: 'Ссылка для восстановления пароля отправлена на ',
    feedback: '. Если письмо не приходит, позвоните нам',
    showPhone: true,
    showButton: true,
    buttons: [{ link: '/', text: 'Хорошо' }],
  },
  successRegistration: {
    icon: <SuccessIcon />,
    title: 'Регистрация в Fins успешно завершена!',
    desc: 'Откройте счета Fins, чтобы совершать выгодные вложения',
    showPhone: false,
    showButton: true,
    buttons: [
      {
        redirect: true,
        link: `https://fins.money/icab/`,
        text: 'Открыть счет',
      },
      { link: '/', text: 'Позже' },
    ],
  },
  blockingPassword: {
    icon: <BlockIcon />,
    title: 'Мы вас заблокировали',
    desc: 'Это случилось, потому что вы ввели 3 раза неправильный пароль. Мы перезвоним вам в ближайшее время для уточнения личности. Если вы — это вы, мы вас разблокируем, но нужно будет придумать новый пароль',
    showPhone: false,
    showButton: true,
    buttons: [{ link: '/', text: 'Хорошо' }],
  },
  mailNotFound: {
    icon: <NotFoundIcon />,
    title: 'Почта не найдена',
    desc: 'Позвоните нам и наши специалисты поддержки помогут вам',
    showPhone: true,
    showButton: false,
  },
  restorePasswordSuccess: {
    icon: <SuccessIcon />,
    title: 'Новый пароль успешно сохранен!',
    desc: 'Используйте его для входа в личный кабинет',
    showPhone: false,
    showButton: true,
    buttons: [{ link: '/authorization', text: 'Продолжить' }],
  },
  restorePasswordFailed: {
    icon: <BlockIcon />,
    title: 'Доступ ограничен!',
    desc: 'Позвоните нам и наши специалисты поддержки помогут вам.',
    showPhone: true,
    showButton: true,
    buttons: [{ link: '/authorization', text: 'Продолжить' }],
  },
}

export const FinalSection = ({ type, email }) => {
  const dispatch = useDispatch()
  const { restore } = useSelector((state) => state.security)

  const handleButton = React.useCallback((link, redirect) => {
    return () => {
      if (redirect) {
        window.location.href = `${link}?sessionToken=${localStorage.getItem(
          't',
        )}`
      } else {
        dispatch(push(link))
      }
    }
  }, [])

  return (
    <div className="final-section">
      <div className="final-section__icon">{mapTypeToSection[type]?.icon}</div>
      <p className="final-section__title">{mapTypeToSection[type]?.title}</p>
      <p className="final-section__text">
        {mapTypeToSection[type]?.desc}{' '}
        {restore?.data?.email && restore?.data?.email}
        {mapTypeToSection[type]?.feedback}
      </p>

      {mapTypeToSection[type].showButton &&
        mapTypeToSection[type].buttons.map((b) => {
          return (
            <Button
              style={{ marginBottom: '26px' }}
              onClick={handleButton(b.link, b.redirect)}
              type="primary"
              block
            >
              {b.text}
            </Button>
          )
        })}
      {mapTypeToSection[type]?.showPhone &&
        (type === 'mailNotFound' ? (
          <Button style={{ marginBottom: '0' }} type="primary" block>
            <a href="tel:8-800-500-10-01">8 (800) 500-10-01</a>
          </Button>
        ) : (
          <p className="final-section__phone">
            <a href="tel:8-800-500-10-01">8 (800) 500-10-01</a>
          </p>
        ))}
    </div>
  )
}

import React from 'react'
import { Modal } from 'antd'
import { Link } from 'react-router-dom'
import './index.less'

const WhereToBegin = () => {
  const videoRef = React.useRef(null)

  const [isModalVisible, setIsModalVisible] = React.useState(false)

  const showModal = React.useCallback(() => {
    setIsModalVisible(true)
  }, [])

  const handleOk = React.useCallback(() => {
    setIsModalVisible(false)
  }, [])

  const handleCancel = React.useCallback(() => {
    setIsModalVisible(false)
  }, [])

  React.useEffect(() => {
    if (videoRef && videoRef.current) {
      if (!isModalVisible) videoRef.current.pause()
      if (isModalVisible) videoRef.current.play()
    }
  }, [isModalVisible])

  return (
    <div className="content content--bg">
      <div className="content__block">
        <h3 className="sub_title">С чего начать</h3>
        <div className="where_to_begin__video-button">
          <button onClick={showModal} type="button" />
        </div>
        <Modal
          className="where_to_begin__modal"
          visible={isModalVisible}
          centered
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <video
            className="where_to_begin__video"
            controls
            ref={videoRef}
            autoPlay
            title="Video about Fins"
            src="https://fins.money/welcome.mp4"
          />
        </Modal>
        <h3 className="sub_title">Получать доход - просто</h3>
        <p className="sub_desc">
          Узнайте подробнее о выгодных способах накопления
        </p>
        <div className="where_to_begin__cards">
          <div className="where_to_begin__img-money" />
          <div className="where_to_begin__cards-one">
            <Link
              to="/individual-investment-account"
              className="where_to_begin__card"
            >
              <h5>Индивидуальный инвестиционный счет</h5>
              <p>
                Получите до 52 000 ₽ в год в виде налогового вычета по
                государственной программе
              </p>
            </Link>
          </div>
          <div className="where_to_begin__cards-two">
            <Link
              to="/trust-management"
              className="where_to_begin__card where_to_begin__card--blue where_to_begin__card--bg-circle"
            >
              <h5>Доверительное управление</h5>
              <p>
                Профессионалы финансового рынка управляют вашими активами - вы
                получаете высокий доход
              </p>
            </Link>
            <Link to="/fund" className="where_to_begin__card">
              <h5>Паевой инвестиционный фонд</h5>
              <p>
                Станьте совладельцем крупных компаний - получайте доход вместе с
                лидерами рынка
              </p>
            </Link>
          </div>
          <div className="where_to_begin__cards-two">
            <Link
              to="/bonds"
              className="where_to_begin__card where_to_begin__card--blue where_to_begin__card--bg-plus"
            >
              <h5>Облигации</h5>
              <p>
                Одолжите деньги государству или надежной компании и получите до
                10% годовых
              </p>
            </Link>
            <Link
              to="/structural-products"
              className="where_to_begin__card where_to_begin__card--bg-bottom"
            >
              <h5>Структурный продукт</h5>
              <p>Получайте доход со 100% защитой вложенных денег</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhereToBegin

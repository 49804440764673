import React from 'react'
import './index.less'
import { Divider, } from 'antd'

export const Agreement = () => {
  return (
    <div className="agreement">
      <h2 className="agreement__title">
        СОГЛАСИЕ НА&nbsp;ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ СУБЪЕКТА ПЕРСОНАЛЬНЫХ
        ДАННЫХ (далее&nbsp;&mdash; Согласие)
      </h2>

      <div className="agreement__content">
        <p className="agreement__text">
          Посредством совершения действий по&nbsp;указанию/предоставлению
          <sup className="agreement__text-footnote">1</sup>
          &nbsp;на сайте{' '}
          <a
            href="https://fins.money/"
            target="_blank"
            rel="noreferrer"
            className="agreement__link"
          >
            https://fins.money
          </a>{' '}
          и&nbsp;его подразделах/в мобильном приложении fins или на&nbsp;ином
          сайте, правообладателем которого является АО&nbsp;&laquo;БКС
          Банк&raquo; (ОГРН: 1055400000369), своих персональных данных
          <sup className="agreement__text-footnote">2</sup> (далее&nbsp;&mdash;
          персональные данные) Я
          <sup className="agreement__text-footnote">3</sup>&nbsp;в соответствии
          с&nbsp;Федеральным законом от&nbsp;27.07.2006 &#8470;&nbsp;152-ФЗ
          &laquo;О&nbsp;персональных данных&raquo; предоставляю каждому
          из&nbsp;указанных ниже в&nbsp;таблице юридических лиц (далее каждый
          в&nbsp;отдельности именуется&nbsp;&mdash; Оператор,
          а&nbsp;совместно&nbsp;&mdash; Операторы) право (согласие)
          на&nbsp;обработку моих персональных данных как самостоятельно, так
          и&nbsp;совместно с&nbsp;другими Операторами, в&nbsp;том числе,
          но&nbsp;не&nbsp;ограничиваясь, на&nbsp;передачу персональных данных
          (копий/скан-образов документов, содержащих персональные данные) между
          Операторами:
        </p>
        <ul className="agreement__operators-list">
          <li>
            Общество с&nbsp;ограниченной ответственностью &laquo;Компания
            Брокеркредитсервис&raquo;
            <p>
              ОГРН: 1025402459334, место нахождения: 630099, г. Новосибирск, ул.
              Советская д.&nbsp;37
            </p>
          </li>
          <li>
            Акционерное общество &laquo;БКС Банк&raquo;
            <p>
              ОГРН: 1055400000369, место нахождения: 129110, г. Москва, проспект
              Мира, д.&nbsp;69, стр.&nbsp;1
            </p>
          </li>
          <li>
            Акционерное общество Управляющая Компания
            &laquo;Брокеркредитсервис&raquo;
            <p>
              ОГРН: 1025403200020, место нахождения: 630099, г. Новосибирск,
              Советская, д.&nbsp;37
            </p>
          </li>
          <li>
            BrokerCreditService(Cyprus)Limited&nbsp;/ БРОКЕРКРЕДИТСЕРВИС (КИПР)
            ЛИМИТЕД
            <p>
              регистрационный номер НЕ&nbsp;154856, место нахождения: Меса
              Геитония, пр-т Спиру Киприану ул. 1-го Октября, д.&nbsp;1,
              &laquo;ВАШИОТИС КАЛАНДЕ ОФИСЕС&raquo;, 1-ый этаж, Лимасол, Кипр
            </p>
          </li>
          <li>
            BCS Prime Brokerage Limited&nbsp;/ БИ-СИ-ЭС ПРАЙМ БРОКЕРИДЖ ЛИМИТЕД
            <p>
              регистрационный номер 8040031, место нахождения:
              99&nbsp;Бишопсгейт, Лондон, EC2M 3XD
            </p>
          </li>
          <li>
            BCS Global Markets (Europe) Limited
            <p>
              регистрационный номер НЕ&nbsp;396590, место нахождения: Меса
              Геитония, пр-т Спиру Киприану ул. 1-го Октября, д.&nbsp;1,
              &laquo;ВАШИОТИС КАЛАНДЕ ОФИСЕС&raquo;, 3-ый этаж, офис 3.3.,
              Лимасол, Кипр
            </p>
          </li>
          <li>
            BROKERCREDITSERVICE STRUCTURED PRODUCTS PLC&nbsp;/
            БРОКЕРКРЕДИТСЕРВИС СТРАКЧЭРД ПРОДАКТС ПиЭлСи
            <p>
              регистрационный номер НЕ&nbsp;158664, место нахождения: Агиа Зони,
              12, АГИА ЗОНИ СЕНТЕР, офис 103, 3027, Лимассол, Кипри, 12, АГИА
              ЗОНИ СЕНТЕР, офис 103, 3027, Лимассол, Кипр
            </p>
          </li>
          <li>
            Общество с&nbsp;ограниченной ответственностью
            &laquo;Консультационный центр БКС&raquo;
            <p>
              ОГРН: 5087746668610, место нахождения: 129110, г. Москва, проспект
              Мира, 69, стр.&nbsp;1
            </p>
          </li>
          <li>
            Общество с&nbsp;ограниченной ответственностью
            &laquo;Консультационный центр БКС&raquo;
            <p>
              ОГРН: 5087746668610, место нахождения: 129110, г. Москва, проспект
              Мира, 69, стр.&nbsp;1
            </p>
          </li>
          <li>
            Общество с&nbsp;ограниченной ответственностью &laquo;Удостоверяющий
            Центр БКС&raquo;
            <p>
              ОГРН: 1065407148685, место нахождения: 630099, г. Новосибирск, ул.
              Советская, д.&nbsp;37
            </p>
          </li>
          <li>
            Общество с&nbsp;ограниченной ответственностью Страховая компания
            &laquo;БКС Страхование жизни&raquo;
            <p>
              ОГРН: 5177746400607, место нахождения: 129110, г. Москва, проспект
              Мира, д.&nbsp;69, стр.&nbsp;1, этаж&nbsp;9, помещ. XV, ком.1
            </p>
          </li>
          <li>
            Общество с&nbsp;ограниченной ответственностью
            &laquo;БКС-Технологии&raquo;
            <p>
              ОГРН: 1125476124729, место нахождения: 630099, г. Новосибирск, ул.
              Советская, 37
            </p>
          </li>
          <li>
            Общество с&nbsp;ограниченной ответственностью
            &laquo;Интеллектуальные торговые системы&raquo;
            <p>
              ОГРН: 1195476005471, место нахождения: 630099, г. Новосибирск, ул.
              Советская, д.&nbsp;37, оф.308
            </p>
          </li>
          <li>
            Закрытое акционерное общество &laquo;Первый специализированный
            депозитарий&raquo;
            <p>
              ОГРН: 1027700373678, место нахождения: 125167, г. Москва,
              ул.&nbsp;8 Марта 4-я, д. 6А
            </p>
          </li>
          <li>
            Акционерное общество &laquo;Специализированный депозитарий
            &laquo;ИНФИНИТУМ&raquo;
            <p>
              ОГРН: 1027739039283, место нахождения: 115162, г. Москва, ул.
              Шаболовка, д.&nbsp;31, корп. Б
            </p>
          </li>
          <li>
            Общество с&nbsp;ограниченной ответственностью
            &laquo;Специализированное финансовое общество БКС Структурные
            Ноты&raquo;
            <p>
              ОГРН: 1197746248963; место нахождения: 125171, г. Москва,
              Ленинградское шоссе, дом 16А, строение&nbsp;1, этаж&nbsp;8,
              ком.&nbsp;24
            </p>
          </li>
          <li>
            Общество с&nbsp;ограниченной ответственностью &laquo;БИОРГ&raquo;
            <p>
              ОГРН: 1097746762618, место нахождения: 117105, г. Москва,
              Варшавское шоссе, д.&nbsp;35, стр.&nbsp;1
            </p>
          </li>
          <li>
            Общество с&nbsp;ограниченной ответственностью &laquo;БКС
            Финтех&raquo;
            <p>
              ОГРН: 1197746523259, место нахождения: 121205, г. Москва,
              территория Сколково инновационного центра, бульвар Большой, дом
              40, пом. XXVIII, ком. 138-149,155
            </p>
          </li>
          <li>
            Акционерное общество &laquo;Управляющая компания УРАЛСИБ&raquo;
            <p>
              ОГРН: 1027739003489, место нахождения: 129110, г. Москва, проспект
              Мира, д.&nbsp;69, стр.&nbsp;1, этаж.&nbsp;3, пом. 3-07
            </p>
          </li>
          <li>
            Общество с&nbsp;ограниченной ответственностью
            &laquo;АМБЕРДАТА&raquo;
            <p>
              ОГРН: 1157746157194, место нахождения: 127521, г. Москва, Проезд
              12-й Марьиной рощи, д.&nbsp;9, корп.&nbsp;1, пом. VI, ком.&nbsp;1
            </p>
          </li>
          <li>
            Общество с&nbsp;ограниченной ответственностью
            &laquo;М&nbsp;Дата&raquo;
            <p>
              ОГРН: 1137746590288, место нахождения: 115280, г. Москва, ул.
              Ленинская Слобода, д.19, ком. 21Б1
            </p>
          </li>
        </ul>

        <Divider style={{ margin: '40px 0' }} />

        <h3 className="agreement__subtitle">
          Предоставляя Согласие, я&nbsp;ЗАВЕРЯЮ И&nbsp;ПОДТВЕРЖДАЮ, что:
        </h3>

        <ul className="agreement__confirm-list">
          <li>
            <p className="agreement__confirm-list_goal-title">
              Я&nbsp;ознакомлен с&nbsp;целями обработки Операторами персональных
              данных, включающих в&nbsp;себя:
            </p>
            <ol className="agreement__goal-list">
              <li>
                Совершение операций и&nbsp;проведение сделок, оказание
                финансовых услуг;
              </li>
              <li>
                Подготовка к&nbsp;заключению, заключение и&nbsp;исполнение
                договоров, соглашений и&nbsp;приложений к&nbsp;ним;
              </li>

              <li>
                Предоставление информации (отказ в&nbsp;предоставлении
                информации);
              </li>
              <li>
                Предложение мне услуг (в&nbsp;том числе путем осуществления
                со&nbsp;мной прямых контактов с&nbsp;помощью средств связи),
                предоставляемых Оператором и/или третьими лицами, как
                в&nbsp;отдельности, так и совместно;
              </li>
              <li>
                Информирование меня о&nbsp;проводимых Оператором рекламных или
                маркетинговых акциях;
              </li>

              <li>
                Проведение в&nbsp;отношении меня опросов/анкетирования,
                рекламных и маркетинговых и&nbsp;иных исследований, связанных
                с&nbsp;деятельностью Оператора и&nbsp;предоставляемых
                им&nbsp;услуг;
              </li>

              <li>
                Построение моделей машинного обучения по&nbsp;конвертации
                пользователей;
              </li>
              <li>
                Приглашение меня на&nbsp;семинары (курсы, лекции, обучение)
                и&nbsp;иные мероприятия, связанные с&nbsp;деятельностью
                Оператора;
              </li>

              <li>
                Проведение упрощенной идентификации, в&nbsp;том числе через
                Систему межведомственного электронного взаимодействия (СМЭВ),
                единую систему идентификации и&nbsp;аутентификации (ЕСИА);
              </li>
              <li>
                Обработку персональных данных в&nbsp;целях функционирования
                сайтов, мобильных приложений, в&nbsp;том числе для
                их&nbsp;улучшения, анализа использования, сбора статистической
                информации (в&nbsp;том числе сбора технических файлов cookie),
                учета предпочтений пользователей.
              </li>
            </ol>
          </li>
          <li>
            Согласие предоставляется на&nbsp;осуществление Оператором любых
            действий в&nbsp;отношении персональных данных, осуществление которых
            необходимо и желательно для достижения целей их&nbsp;обработки,
            в&nbsp;том числе на&nbsp;их сбор, запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу, предоставление, доступ, трансграничную
            передачу, обезличивание, блокирование, удаление и&nbsp;уничтожение.
          </li>
          <li>
            Уведомлен, о&nbsp;том, что обработка персональных данных
            осуществляется Операторами любым способом, в&nbsp;том числе как
            с&nbsp;использованием средств автоматизации
            <sup className="agreement__text-footnote">4</sup>, так и&nbsp;без
            использования средств автоматизации (с использованием различных
            материальных носителей, включая бумажные носители).
          </li>
          <li>
            Ознакомился в&nbsp;полном объеме с&nbsp;Политиками ООО
            &laquo;Компания БКС&raquo; и&nbsp;АО &laquo;БКС Банк&raquo;
            в&nbsp;отношении обработки персональных данных, размещенных на:{' '}
            <a
              href="https://broker.ru/f/company/policy-personal-data.pdf"
              target="_blank"
              rel="noreferrer"
              className="agreement__link"
            >
              https://broker.ru/f/company/policy-personal-data.pdf
            </a>
            ,{' '}
            <a
              href="https://bcsbank.com/f/personal_data_politics.pdf?v2"
              target="_blank"
              rel="noreferrer"
              className="agreement__link"
            >
              https://bcsbank.com/f/personal_data_politics.pdf?v2
            </a>
            , и&nbsp;согласен с&nbsp;их содержанием в&nbsp;полном объеме.
          </li>
          <li>
            Я&nbsp;также даю согласие на&nbsp;обработку моих персональных данных
            и&nbsp;иной информации (в&nbsp;том числе технических файлов cookie,
            данных об оказанных мне услугах), полученных в&nbsp;сети Интернет
            от&nbsp;третьих лиц, а также данных из&nbsp;социальных сетей.
          </li>
          <li>
            Обработка персональных данных может осуществляться как Оператором,
            так и&nbsp;третьими лицами, заключившими с&nbsp;Оператором
            соглашение на условиях конфиденциальности и&nbsp;ответственности
            за&nbsp;разглашение персональных данных при условии соблюдения
            требований действующего законодательства Российской Федерации.
          </li>
          <li>
            Настоящим, действуя своей волей и&nbsp;в&nbsp;своем интересе,
            заявляю в соответствии с&nbsp;положениями Федерального закона
            от&nbsp;13.03.2006 &#8470;&nbsp;38-ФЗ &laquo;О&nbsp;рекламе&raquo;
            и&nbsp;положениями Федерального закона от&nbsp;07.07.2003
            &#8470;&nbsp;126-ФЗ &laquo;О&nbsp;связи&raquo; о&nbsp;своем согласии
            на&nbsp;получение от&nbsp;любого из&nbsp;Операторов по указанным
            мной номеру телефона, адресу электронной почты, иным средствам связи
            (в&nbsp;том числе в&nbsp;мобильных приложениях) каких-либо рассылок
            (включая, смс-уведомления), которые могут носить в&nbsp;том числе
            рекламный характер, направляемых в&nbsp;целях, аналогичных целям
            обработки персональных данных. Указанный номер телефона может
            использоваться Оператором в&nbsp;том числе для осуществления прямых
            контактов со&nbsp;мной для достижения указанных в&nbsp;настоящем
            абзаце целей.
          </li>
          <li>
            Я&nbsp;ознакомлен с&nbsp;тем, что в&nbsp;любой момент могу
            отказаться от&nbsp;получения рекламных рассылок (включая,
            смсуведомления) путем обращения по телефону в&nbsp;контактный центр
            Оператора.
          </li>
          <li>
            Настоящее Согласие действует до&nbsp;достижения всех указанных целей
            обработки персональных данных за&nbsp;исключением случаев, когда
            более длительный срок их&nbsp;обработки предусмотрен действующим
            законодательством Российской Федерации. Настоящее Согласие может
            быть отозвано, если иное не&nbsp;установлено действующим
            законодательством Российской Федерации, путем направления мной
            письменного уведомления (запроса) в&nbsp;адрес Оператора, которому
            передаются персональные данные, по&nbsp;указанным выше адресам.
            Настоящее Согласие считается отозванным по&nbsp;истечении&nbsp;30
            (тридцати)<sup className="agreement__text-footnote">5</sup>{' '}
            календарных дней с&nbsp;момента получения Оператором письменного
            уведомления (запроса) о&nbsp;его отзыве. При этом, в&nbsp;случае
            отзыва Согласия у&nbsp;любого из&nbsp;Операторов обработка
            персональных данных может осуществляться иными Операторами,
            у&nbsp;которых Согласие не&nbsp;отозвано.
          </li>
          <li>
            В&nbsp;случае отзыва мной согласия на&nbsp;обработку своих
            персональных данных Оператор вправе продолжить их&nbsp;обработку без
            согласия субъекта персональных данных с&nbsp;целью осуществления
            и&nbsp;выполнения функций, полномочий и обязанностей, возложенных
            на&nbsp;Оператора действующим законодательством Российской
            Федерации. Кроме того, Оператор вправе продолжить обработку
            персональных данных, если она необходима для исполнения договора,
            стороной которого либо выгодоприобретателем или поручителем
            по&nbsp;которому является субъект персональных данных, а также для
            заключения договора по&nbsp;инициативе субъекта персональных данных
            или договора, по&nbsp;которому субъект персональных данных будет
            являться выгодоприобретателем или поручителем.
          </li>
        </ul>
        <Divider style={{ margin: '40px 0' }} />

        <div className="agreement__footnotes">
          <p>
            <sup className="agreement__text-footnote">1</sup>&nbsp;Например,
            посредством ввода, подтверждения, предоставления скан-образов/копий
            документов, содержащих персональные данные.
          </p>
          <p>
            <sup className="agreement__text-footnote">2</sup>&nbsp;В том числе,
            но&nbsp;не&nbsp;ограничиваясь: фамилии, имя, отчество, номер
            телефона, адрес электронной почты, адрес места регистрации, адрес
            места жительства, ИНН, реквизиты документа, удостоверяющего
            личность, и&nbsp;иные данные, содержащиеся в&nbsp;нем.&nbsp;
          </p>
          <p>
            <sup className="agreement__text-footnote">3</sup>&nbsp;Субъект
            персональных данных.
          </p>
          <p>
            <sup className="agreement__text-footnote">4</sup>&nbsp;В частности,
            с&nbsp;использованием информационных технологий и&nbsp;технических
            средств, включая средства вычислительной техники,
            информационно-технические комплексы,
            информационно-телекоммуникационные сети, средства и&nbsp;системы
            передачи, приема и&nbsp;обработки персональных данных, программные
            средства (программное обеспечение, операционные системы, системы
            управления базами данных и&nbsp;т.п.
          </p>
          <p>
            <sup className="agreement__text-footnote">5</sup>&nbsp;День
            получения уведомления (запроса) не включается в&nbsp;тридцатидневный
            срок.
          </p>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import { Drawer } from 'antd'
import { useHistory } from 'react-router'
import { FinsIcon } from '../../FinsIcon'
import icon from './assets/close-drawer-icon.svg'

export const FinsDrawer = ({
  children,
  isMobile,
  routePathAfterClose,
  callbackAfterClose,
  title,
  onCloseHandler = () => {},
  iconClose = icon,
}) => {
  const [visible, setVisible] = React.useState(false)
  const history = useHistory()

  const handleCloseDrawer = React.useCallback(() => {
    setVisible(false)
    if (callbackAfterClose) {
      callbackAfterClose()
    }
  }, [callbackAfterClose])

  const handleAfterVisibleChange = React.useCallback(() => {
    if (!visible) {
      onCloseHandler()
      history.push(routePathAfterClose)
    }
  }, [visible, history, routePathAfterClose, onCloseHandler])

  React.useEffect(() => {
    setVisible(true)
  }, [])

  return (
    <Drawer
      title={title}
      placement="right"
      onClose={handleCloseDrawer}
      afterVisibleChange={handleAfterVisibleChange}
      closeIcon={<FinsIcon icon={iconClose} width="15" height="15" />}
      width={isMobile ? '100%' : '439px'}
      headerStyle={{ padding: '33px 24px 0' }}
      bodyStyle={{ padding: '32px' }}
      visible={visible}
    >
      {children}
    </Drawer>
  )
}

import React from 'react'
import NumberFormat from 'react-number-format'
import classNames from 'classnames'
import { formatTerm } from 'utils/helpers'

import { PRODUCT_TYPES, CurrencyMapping } from 'constants/TRADING'

import '../index.less'

export const IncomeDetailsDesktop = ({
  selectedProduct,
  selectedType,
  contentBg,
  iWillGet,
  productIncome,
  iisIncome,
  loading,
}) => {
  return (
    selectedProduct && (
      <div>
        <div
          className={classNames('calclulate-income__result-item', {
            'calclulate-income__result-item--bg': contentBg,
          })}
        >
          <div className="calclulate-income__result-item-title">Я получу</div>
          <div className="calclulate-income__result-item-value primary">
            {!loading && selectedProduct?.product?.currency && (
              <NumberFormat
                suffix={` ${
                  CurrencyMapping[selectedProduct?.product?.currency]?.code
                }`}
                thousandSeparator={' '}
                decimalSeparator=","
                value={iWillGet}
                displayType="text"
              />
            )}
          </div>
        </div>
        <div
          className={classNames('calclulate-income__result-item', {
            'calclulate-income__result-item--bg': contentBg,
          })}
        >
          <div className="calclulate-income__result-item-title">
            Доход от продукта
            <span>
              {selectedProduct.product?.term
                ? `За срок ${formatTerm(
                    selectedType,
                    selectedProduct.product?.term,
                  )}`
                : 'Срок не ограничен'}
            </span>
          </div>
          <div className="calclulate-income__result-item-value">
            {!loading && selectedProduct?.product?.currency && (
              <NumberFormat
                suffix={` ${
                  CurrencyMapping[selectedProduct?.product?.currency]?.code
                }`}
                thousandSeparator={' '}
                decimalSeparator=","
                value={productIncome}
                displayType="text"
              />
            )}
          </div>
        </div>

        <div
          className={classNames('calclulate-income__result-item', {
            'calclulate-income__result-item--bg': contentBg,
          })}
        >
          {selectedType === PRODUCT_TYPES.TRUST_MANAGEMENT &&
            !selectedProduct.product?.isIncludesIndividualInvestmentAccount && (
              <div className="calclulate-income__result-item-title--disabled">
                ИИС не предусмотрен стратегией
              </div>
            )}
          {(selectedType !== PRODUCT_TYPES.TRUST_MANAGEMENT ||
            selectedProduct.product?.isIncludesIndividualInvestmentAccount) && (
            <>
              <div className="calclulate-income__result-item-title">
                13% от пополнения ИИС
              </div>
              <div className="calclulate-income__result-item-value">
                {!loading && selectedProduct?.product?.currency && (
                  <NumberFormat
                    suffix={` ${
                      CurrencyMapping[selectedProduct?.product?.currency]
                        ?.code || selectedProduct?.product?.currency
                    }`}
                    thousandSeparator={' '}
                    decimalSeparator=","
                    value={iisIncome}
                    displayType="text"
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    )
  )
}

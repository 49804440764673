import React from 'react'

import './index.less'

export const CustomOption = ({ item }) => {
  return (
    <div className="testing-select__option">
      <div className="testing-select__option-content">
        <div className="testing-select__option-content-title">{item.value}</div>
      </div>
    </div>
  )
}

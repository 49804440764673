import React from 'react'
import { SET_CURRENT_PRODUCT_PATH } from 'reducers/global'
import { useDispatch } from 'react-redux'
import ProductHeader from '../../sections/ProductHeader'
import FAQ from '../../sections/FAQ'
import StartGettingIncome from '../../sections/StartGettingIncome'
import ProductCarousel from '../../sections/ProductCarousel'
import CalculateIncome from '../../sections/CalculateIncome'
import ProductTabs from '../../sections/ProductTabs'
import { DrawerRoute } from '../../components/DrawerRoute'

export const Qualification = ({ match }) => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(SET_CURRENT_PRODUCT_PATH(match.url))
    if (typeof window !== 'undefined') window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <ProductHeader type="qualified" />
      <ProductTabs type="qualified" />
      <CalculateIncome contentBg />
      <ProductCarousel type="qualified" />
      <StartGettingIncome />
      <FAQ type="qualified" />
      <DrawerRoute />
    </>
  )
}

export const MAX_IIS_INCOME_FROM = 400000

export const PRODUCT_TYPES = {
  CURRENCY: 'currency',
  FUND: 'funds',
  STRUCTURED_PRODUCT: 'structuredProducts',
  BOND_OTC: 'bond_otc',
  BOND: 'bonds',
  TRUST_MANAGEMENT: 'trustManagementStrategies',
}

export const CurrencyMapping = {
  RUB: {
    name: 'RUB',
    code: '₽',
    html: '&#36;',
    localeName: 'Рубль РФ',
  },
  USD: {
    name: 'USD',
    code: '$',
    html: '&#36;',
    localeName: 'Доллар США',
  },
  EUR: {
    name: 'EUR',
    code: '€',
    html: '&#8364;',
    localeName: 'Евро',
  },
  CNY: {
    name: 'CNY',
    code: '¥',
    html: '&#165;',
    localeName: 'Юань',
  },
}

export const PRODUCTS = {
  [PRODUCT_TYPES.TRUST_MANAGEMENT]: {
    type: PRODUCT_TYPES.TRUST_MANAGEMENT,
    defaultAmount: 100000,
    descriptionHeading: 'Описание стратегии:',
  },
  [PRODUCT_TYPES.FUND]: {
    type: PRODUCT_TYPES.FUND,
    defaultAmount: 100000,
    descriptionHeading: 'Описание фонда:',
  },
  [PRODUCT_TYPES.STRUCTURED_PRODUCT]: {
    type: PRODUCT_TYPES.STRUCTURED_PRODUCT,
    defaultAmount: 100000,
    descriptionHeading: 'Как работает продукт:',
  },
  [PRODUCT_TYPES.BOND]: {
    type: PRODUCT_TYPES.BOND,
    defaultAmount: 100000,
    descriptionHeading: 'Описание продукта:',
  },
}

import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_TARGET_OTP_RESEND } from '../../actions/constants/otp'
import { saveOtpData, setOtpLoading } from '../../reducers/otp'
import { requestTargetOtpResend } from '../../api/v5/otp'

function* fetchOtp(action) {
  try {
    yield put(setOtpLoading(true))
    const authData = yield call(requestTargetOtpResend, action.payload)

    yield put(saveOtpData(authData))
    yield put(setOtpLoading(false))
  } catch (e) {
    yield put(setOtpLoading(false))
    yield put(saveOtpData(e.message))
  }
}

function* resendOtpSaga() {
  yield takeEvery(FETCH_TARGET_OTP_RESEND, fetchOtp)
}

export default resendOtpSaga

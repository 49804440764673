import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { FinsDrawer } from '../../components/ui/FinsDrawer'
import { PasswordRestore } from '../../components/forms/PasswordRestore'
import arrowLeft from './assets/arrow-left.svg'

export const RestorePassword = () => {
  const { isMobile, currentProductPath } = useSelector((state) => state.global)
  const [useTitle, setTitle] = useState('Восстановление пароля')

  return (
    <FinsDrawer
      title={useTitle}
      placement="right"
      width={isMobile ? '100%' : '439px'}
      bodyStyle={{ padding: '32px' }}
      isMobile={isMobile}
      routePathAfterClose={`${currentProductPath}/authorization`}
      iconClose={arrowLeft}
    >
      <PasswordRestore
        setDrawerTitle={setTitle}
        labelFirstPassword="Новый пароль"
      />
    </FinsDrawer>
  )
}

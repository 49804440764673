import { all, call } from 'redux-saga/effects'
import fetchRestoreSaga from './security/restore'
import fetchConfirmRestoreSaga from './security/confirmRestore'
import inviteSaga from './security/invite'

import checkAuthSaga from './auth/checkAuth'
import authSaga from './auth/fetchAuth'
import passwordVerifySaga from './auth/passwordVerify'
import registrationSaga from './auth/registration'
import secretQuestionsSaga from './auth/secretQuestions'
import getActiveOtp from './otp/getActiveOtp'
import resendOtp from './otp/resendOtp'
import fetchTargetOtpVerify from './otp/targetOtpVerify'
import fetchTopProductsSaga from './calc/fetchTopProducts'

export default function* rootSaga() {
  yield all([
    call(authSaga),
    call(fetchTopProductsSaga),
    call(fetchRestoreSaga),
    call(fetchConfirmRestoreSaga),
    call(inviteSaga),
    call(fetchTargetOtpVerify),
    call(secretQuestionsSaga),
    call(registrationSaga),
    call(checkAuthSaga),
    call(passwordVerifySaga),
    call(resendOtp),
    call(getActiveOtp),
  ])
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button } from 'antd'
import InputMask from '../../ui/InputMask'
import { isPhoneNumberValid } from '../../../utils/validators'
import { FETCH_AUTH } from '../../../actions/constants/auth'
import './index.less'
import { setLogin } from '../../../reducers/auth'

export const AuthForm = () => {
  const [form] = Form.useForm()
  const { authLoading } = useSelector((state) => state.auth)
  const { otpLoading } = useSelector((state) => state.otp)

  const [phoneState, setPhoneState] = React.useState(null)

  const dispatch = useDispatch()

  const handleOnFinish = React.useCallback(
    (values) => {
      const { phone } = values
      dispatch({ type: FETCH_AUTH, payload: phone })
      dispatch(setLogin(phone))
    },
    [dispatch],
  )

  return (
    <Form
      name="authorization-form"
      form={form}
      onFinish={handleOnFinish}
      initialValues={{ remember: true, phone: '' }}
    >
      <InputMask
        autoFocus
        autoComplete="tel"
        label="Телефон"
        mask="+7(999) 999-99-99"
        inputMode="numeric"
        id="phone"
        showMaskOnHover={false}
        onChange={(e) => setPhoneState(e.target.value)}
        config={{
          validateTrigger: ['onFocus', 'onChange', 'onInput'],
          rules: [
            ({ getFieldError }) => ({
              validator(_, value) {
                return isPhoneNumberValid(phoneState)
              },
            }),
          ],
          name: 'phone',
        }}
      />
      <Form.Item shouldUpdate>
        {() => (
          <Button
            disabled={
              !form.getFieldsValue().phone ||
              form.getFieldsValue()?.phone?.length === 0 ||
              !!form.getFieldsError().filter(({ errors }) => errors.length)
                .length
            }
            type="primary"
            block
            htmlType="submit"
            loading={authLoading || otpLoading}
          >
            Продолжить
          </Button>
        )}
      </Form.Item>
    </Form>
  )
}

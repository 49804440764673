import { request } from '../request'

export const requestTargetOtp = async (payload) => {
  return request(`/gateway/api/otp/v5/business-key/${payload.uuid}`, {
    method: 'GET',
  })
}

export const requestTargetOtpResend = async (payload) => {
  return request(`/gateway/api/otp/v5/business-key/${payload.uuid}/resend`, {
    method: 'POST',
  })
}

export const requestTargetOtpVerify = async (payload) => {
  return request(`/gateway/api/otp/v5/business-key/${payload.uuid}/verify`, {
    method: 'POST',
    data: {
      code: payload.code,
    },
  })
}

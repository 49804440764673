import React from 'react'

export const NotFoundIcon = () => {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.1667 53.3333C12.4387 53.3333 0.5 41.3947 0.5 26.6667C0.5 11.9387 12.4387 0 27.1667 0C41.8947 0 53.8333 11.9387 53.8333 26.6667C53.8333 41.3947 41.8947 53.3333 27.1667 53.3333ZM27.1667 48C32.8246 48 38.2508 45.7524 42.2516 41.7516C46.2524 37.7508 48.5 32.3246 48.5 26.6667C48.5 21.0087 46.2524 15.5825 42.2516 11.5817C38.2508 7.58095 32.8246 5.33333 27.1667 5.33333C21.5087 5.33333 16.0825 7.58095 12.0817 11.5817C8.08095 15.5825 5.83333 21.0087 5.83333 26.6667C5.83333 32.3246 8.08095 37.7508 12.0817 41.7516C16.0825 45.7524 21.5087 48 27.1667 48ZM24.5 34.6667H29.8333V40H24.5V34.6667ZM24.5 13.3333H29.8333V29.3333H24.5V13.3333Z"
        fill="#E8972D"
      />
    </svg>
  )
}

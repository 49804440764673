import React from 'react'
import '../index.less'

export const Section4 = ({ id }) => {
  return (
    <>
      <h1 id={id}>Сделки с неполным покрытием — можно, но осторожно</h1>
      <p>
        Рассказываем инвесторам-новичкам о возможностях и нюансах необеспеченных
        сделок.
      </p>
      <p>
        Совершение необеспеченных сделок дает возможность  многократно увеличить
        доход инвестора — эту магическую фразу слышали многие новички от
        инвесторов-старожилов.
      </p>
      <p>
        Сделки с неполным обеспечением не рекомендованы неквалифицированным
        инвесторам, поэтому стоит приходить к таким инструментам по мере
        изучения теории и накопления опыта торговли.
      </p>
      <p>
        Чтобы понять, насколько эффективен этот продукт и стоит ли его
        использовать, начнем с азов: развеем или, наоборот, внесем сомнения.
      </p>
      <h2>
        Что такое необеспеченные сделки и чем они могут быть полезны инвестору?
      </h2>
      <p>
        При работе на валютном и фондовых рынках брокер дает своим клиентам
        возможность проводить операции под обеспечение собственных активов
        (денежных средств и ценных бумаг), а именно:
      </p>
      <div>
        <div className="testing_box">
          Совершать сделки, имея на счету только часть суммы для расчетов
        </div>
        <div className="testing_box">
          Оставшуюся часть денег вносить после расчетов по сделке
        </div>
        <div className="testing_box">
          Покупать ценные бумаги или валюту и получать доход в случае роста их
          стоимости
        </div>
        <div className="testing_box">
          Открывать короткие позиции (шорт) и использовать снижение стоимости
          актива для получения дохода
        </div>
        <div className="testing_box">
          Выводить часть средств, не продавая ценные бумаги и не дожидаясь
          расчетов по сделкам
        </div>
      </div>
      <h2>Как это работает</h2>
      <p>
        Многие брокеры включают услугу «Сделки с неполным обеспечением»
        в стандартный договор на брокерское обслуживание. За использование
        услуги инвестор заплатит комиссию.
      </p>
      <p>Плата устанавливается в процентах годовых. Ставка зависит от:</p>
      <div>
        <div className="testing_box">
          актива, с которым возникла необеспеченная позиция (рубли, валюта,
          российские ценные бумаги, иностранные ценные бумаги),
        </div>
        <div className="testing_box">направления сделки (лонг, шорт),</div>
        <div className="testing_box">
          от размера открытой необеспеченной позиции по всем брокерским счетам
          инвестора или от объема активов на брокерских счетах,
        </div>
        <div className="testing_box">других возможных условий брокера.</div>
      </div>
      <p>Разумеется, стоит заранее выяснить все параметры сделки.</p>
      <p>
        Соотношение собственных средств и необеспеченной позиции позволит
        инвестору приумножить потенциальную прибыль. Следует помнить и о другой
        стороне медали — это пропорционально повысит риски, поэтому и потери
        могут вырасти соответственно.
      </p>
      <h2>На какой объем можно открыть сделку с неполным обеспечением</h2>
      <p>
        Отметим, что единых условий ни на фондовом, ни на валютном рынке не
        предусмотрено.
      </p>
      <p>
        Существуют некоторые параметры, на которые обращает внимание брокер:
      </p>
      <div>
        <div className="testing_box">
          <p>
            <b>Направление сделки: лонг или шорт.</b>
          </p>
          <p>
            При открытии сделки с неполным покрытием на счете инвестора
            возникает задолженность — по рублям (лонг), валюте (лонг/шорт) или
            по бумагам (шорт), которая переносится путем заключения специальных
            сделок:{' '}
          </p>
          <p>
            на фондовом рынке — автоРЕПО, <br /> на валютном рынке — автоСВОП.{' '}
          </p>
          Приставка «авто» означает, что сделки заключаются автоматически. В
          брокерских отчетах такие сделки обозначаются просто РЕПО или СВОП.
        </div>
        <div className="testing_box">
          <p>
            <b>
              Брокер рассчитывает категорию риска и учитывает историю
              взаимоотношений с клиентом.
            </b>
          </p>
          <p>
            Брокеры стандартно разбивают клиентов на категории по уровню риска:
          </p>
          <ul>
            <li>со стандартным уровнем риска (КСУР),</li>
            <li>с повышенным уровнем риска (КПУР),</li>
            <li>
              с особым уровнем риска (КОУР) — для клиентов-юридических лиц.
            </li>
          </ul>
          <br />
          Как правило, сразу после заключения договора инвестор имеет
          статус КСУР.
        </div>
        <div className="testing_box">
          <p>
            <b>Качество активов для обеспечения.</b>
          </p>
          Брокер устанавливает определенную шкалу риска. По инструментам,
          которые считаются более надежными, можно получить больше денег. При
          этом не по всем инструментам установлена ставка риска.
        </div>
        <div className="testing_box">
          <b>Ставка риска</b> — это дисконт, с которым брокер оценивает
          залоговые инструменты.
        </div>
      </div>
      <p>
        Брокер контролирует риски и рассчитывает Начальные ставки риска в случае
        длинной позиции (Dlong) и в случае короткой позиции (Dshort)
      </p>
      <p>
        Применяемые брокером ставки риска, как правило, публикуются в интернете
        на сайте брокера.
      </p>
      <p>При расчете начальных ставок риска учитывается:</p>
      <div>
        <div className="testing_box">
          <b>Волатильность рынка в целом.</b> Чем рынок волатильнее, тем выше
          риск изменения стоимости ценной бумаги или валюты, а значит, выше
          и ставка риска.
        </div>
        <div className="testing_box">
          <b>
            Ликвидность (волатильность) конкретной ценной бумаги или валюты.
          </b>
          Известно, что крупные заявки на покупку/продажу меньше влияют на цены
          высоколиквидных инструментов, чем на цены низколиквидных.
          Следовательно, чем менее ликвиден финансовый инструмент, тем
          волатильнее его цена, а значит, выше и ставка риска.
        </div>
        <div className="testing_box">
          <b>Ставки вышестоящих клиринговых организаций.</b> Брокер не может
          устанавливать ставки ниже, но вправе устанавливать выше с учетом
          собственных оценок риска.
        </div>
      </div>
      <p>
        При снижении стоимости портфеля до уровня Минимальной маржи брокер может
        принудительно закрывать позиции клиентов с разным уровнем риска либо
        менять параметры так называемого уровня достаточности средств.
      </p>
      <h2>Кому подойдут сделки с неполным обеспечением</h2>
      <p>
        Тому инвестору, который вышел из статуса новичка, познал вкус побед и
        поражений и хотел бы широко использовать возможности торговли на рынке
        ценных бумаг.
      </p>
      <p>Какие возможности получит инвестор:</p>
      <div>
        <div className="testing_box">
          <p>
            <b>Спекулятивные</b>
          </p>
          <ul>
            <li>
              Покупать больше ценных бумаг или валюты, чем есть на то свободных
              средств, и заработать на росте котировок, нарастив позицию —
              длинная позиция Лонг.
            </li>
            <li>
              Заработать на снижении котировок ценных бумаг, открыв короткую
              позицию Шорт.
            </li>
            <li>
              Продавать ценные бумаги или валюту, которую не покупал ранее.
            </li>
            <li>
              Торговать на одной площадке под обеспечение маржинальных активов
              на другой площадке, при этом не распродавая их.
            </li>
            <li>
              Торговать иностранными бумагами под залог российских и наоборот.
            </li>
          </ul>
        </div>
        <div className="testing_box">
          <p>
            <b>Технические</b>
          </p>
          <p>
            В технических целях (типа технический овердрафт): довнести деньги
            после совершения сделки, но до расчетов по ней.
          </p>
          <p>
            На фондовом рынке предусмотрен принцип отложенного исполнения
            (Т+..., где Т — это день заключения сделки). Это означает, что при
            заключении сделки поставка актива и списание средств происходит не
            моментально, а на следующий рабочий день (Т+1), или через день
            (Т+2), в зависимости от режима расчетов. Так, все акции (российские
            и иностранные) торгуются в режиме Т+2.
          </p>
          <p>
            Благодаря этому механизму возникает пространство для маневра: на
            момент заключения сделки инвестор должен обеспечить только
            минимальную требуемую сумму, остальное можно довнести позднее,
            до фактических расчетов по сделке.
          </p>
          В этом случае задолженности по счету не возникнет и клиенту не нужно
          будет платить за полученную услугу.
        </div>
      </div>
      <p>
        Для приобретения опыта на срочном рынке инвестору, пожалуй, лучше
        начинать с подобных операций, то есть в технических целях на короткий
        срок. Поняв механизм торговли и осознав возникающие риски, далее
        инвестор может использовать все возможности рынка в спекулятивных целях.
      </p>
      <h2>Как действует брокер, чтобы минимизировать риски</h2>
      <p>
        Брокер в определенной мере контролирует возможные убытки инвестора. Если
        начальная маржа превысит стоимость портфеля клиента, то инвестор
        не сможет открывать новые позиции, направленные на увеличение маржи.
      </p>
      <p>
        Брокер направляет инвестору Margin call — уведомление о необходимости
        довнесения денежных средств на счет или частичном закрытии позиций.
      </p>
      <p>
        Оценить риск удержания текущей позиции позволяет Минимальная маржа. Она
        рассчитывается как Начальная маржа* коэффициент k_min, который
        определяется в зависимости от Уровня риска клиента и публикуется на
        сайте брокера.
      </p>
      <p>
        Если минимальная маржа превысит стоимость портфеля клиента, то брокер
        осуществляет принудительное закрытие до разного значения
        параметра Уровня достаточности средств (УДС) в зависимости от уровня
        риска клиента (КСУР, КПУР, КОУР).
      </p>
      <p>
        При этом в зависимости от рыночной ситуации брокер оставляет за собой
        право по клиентам КПУР и КОУР менять значение параметра УДС, до которого
        будет осуществляться принудительное закрытие позиций. Актуальное
        значение раскрывается на сайте.
      </p>
      <p>Брокер дополнительно удержит комиссию за принудительное закрытие.</p>
      <h2>Риски использования сделок с неполным покрытием для инвестора</h2>
      <p>
        Риск — благородное дело. Использование необеспеченных сделок,
        действительно, предоставляет много возможностей. Однако всегда стоит
        помнить об эффективном сочетании выбранной стратегии и сопутствующих
        рисках.
      </p>
      <p>
        Правила здесь не отличаются от базовых рекомендаций при инвестировании
        на рынке ценных бумаг:
      </p>
      <div>
        <div className="testing_box">
          Составьте индивидуальную стратегию инвестирования и следуйте ей.
        </div>
        <div className="testing_box">
          Диверсифицируйте портфель, комбинируя бумаги из разных отраслей,
          регионов, валют.
        </div>
        <div className="testing_box">
          Исходя из поставленных целей, определите готовность к риску и
          «комфортный» уровень возможных потерь.
        </div>
        <div className="testing_box">
          Соотнесите параметры риск/доходность — стройте план, исходя из
          риск-профиля, волатильности рынка и горизонта инвестирования.
        </div>
        <div className="testing_box">
          Поставьте стоп-лосс — уровень входа и выхода из позиции — оптимальный
          риск, что позволит ограничить возможные убытки или, наоборот, закрыть
          позицию при достижении определенного уровня прибыли. Помните, что без
          этого действия размер возможных убытков не ограничен.
        </div>
      </div>
      <div className="testing_divider" />
    </>
  )
}

import FundImage from '../assets/products/fund.png'
import FundInfoImage from '../assets/products/fund_info.png'

import BondImage from '../assets/products/bond.png'
import BondInfoImage from '../assets/products/bond_info.png'

import SpImage from '../assets/products/sp.png'
import SpInfoImage from '../assets/products/sp_info.png'

import TrustImage from '../assets/products/trust.png'
import TrustInfoImage from '../assets/products/trust_info.png'
import TrustInfoIIsImage from '../assets/products/trust_iis_info.png'

import IiaImage from '../assets/products/iia.png'
import IiaInfoImage from '../assets/products/iia_info.png'

import QualifiedImage from '../assets/products/qualified.png'
import QualifiedInfoImage from '../assets/products/qualified_info.png'

export const PRODUCTS = {
  funds: {
    title: 'Паевой инвестиционный фонд (ПИФ)',
    desc: 'Станьте совладельцем крупных компаний - получайте доход вместе с лидерами рынка',
    buttonText: 'Выбрать ПИФ',
    titleInfo: 'Что такое ПИФ?',
    descInfo:
      'Паевой инвестиционный фонд (ПИФ) – это простой и доступный способ вложения денег с помощью профессиональных управляющих в ценные бумаги с возможностью получения большего дохода, чем по вкладам.',
    productImage: FundImage,
    productInfoImage: FundInfoImage,
  },
  trustManagement: {
    title: 'Доверительное управление',
    desc: 'Профессионалы финансового рынка управляют вашими активами - вы получаете высокий доход',
    buttonText: 'Выбрать стратегию',
    productImage: TrustImage,
    tabs: [
      {
        key: 1,
        name: 'Доверительное управление',
        titleInfo: 'Что такое Доверительное управление?',
        productInfoImage: TrustInfoImage,
        descInfo:
          'Доверительное управление (ДУ) - это услуга, при которой профессиональный управляющий распоряжается вашими деньгами. Он действует от вашего лица и приобретает для вас ценные бумаги, которые приносят доход.',
      },
      {
        key: 2,
        name: 'Доверительное управление с ИИС',
        titleInfo: 'Дополнительный доход ИИС ДУ',
        productInfoImage: TrustInfoIIsImage,
        descInfo:
          'ДУ ИИС (Индивидуальный инвестиционный счет) - это счет доверительного управления, с помощью которого можно получать до 52 000 рублей от суммы пополнения в год в виде налогового вычета.',
      },
    ],
  },
  bonds: {
    title: 'Облигации',
    desc: 'Одолжите деньги государству или надежной компании и получайте за это доход',
    buttonText: 'Выбрать облигации',
    titleInfo: 'Что такое облигации?',
    descInfo:
      'Облигация - это ценная бумага, которая позволяет вам дать в долг государству или компании и получать за это доход.',
    productImage: BondImage,
    productInfoImage: BondInfoImage,
  },
  sp: {
    title: 'Структурные продукты',
    desc: 'Готовые финансовые решения со 100% защитой вложенных средств доступные только квалифицированным инвесторам',
    buttonText: 'Выбрать продукт',
    titleInfo: 'Что такое структурные продукты?',
    descInfo:
      'Структурный продукт - это готовый продукт, который состоит из двух частей - защитной и доходной. Защитная часть обеспечивает 100% защиту вложенных средств, а доходная - получение дохода',
    productImage: SpImage,
    productInfoImage: SpInfoImage,
  },
  iia: {
    title: 'Индивидуальный инвестиционный счет',
    desc: 'Получите до 52 000 ₽ в год в виде налогового вычета по государственной программе',
    buttonText: 'Открыть ИИС',
    titleInfo: 'Что такое ИИС?',
    descInfo:
      'ИИС (Индивидуальный инвестиционный счет) - это особый вид брокерского счета, с помощью которого можно получать до 52 000 рублей от суммы пополнения в год в виде налогового вычета',
    productImage: IiaImage,
    productInfoImage: IiaInfoImage,
  },
  qualified: {
    title: 'Квалифицированный инвестор',
    desc: 'Получите статус квалифицированного инвестора для доступа к предложениям со 100% защитой денег',
    buttonText: 'Получить консультацию',
    titleInfo: 'Кто такой квалифицированный инвестор',
    descInfo:
      'Квалифицированный инвестор - это статус, который позволяет приобретать специально выпущенные финансовые инструменты, структурные продукты и иностранные акции.',
    productImage: QualifiedImage,
    productInfoImage: QualifiedInfoImage,
  },
}

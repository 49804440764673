import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isMobile: false,
  currentProductPath: '',
}

const global = createSlice({
  name: 'global',
  initialState,
  reducers: {
    SET_IS_MOBILE: (state, action) => {
      state.isMobile = action.payload
    },
    SET_CURRENT_PRODUCT_PATH: (state, { payload }) => {
      state.currentProductPath = payload.replace(/\/$/, '')
    },
  },
})

const { reducer, actions } = global

export const { SET_IS_MOBILE, SET_CURRENT_PRODUCT_PATH } = actions

export default reducer

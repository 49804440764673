import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { Row, Col, Button } from 'antd'
import { Element } from 'react-scroll'
import './index.less'

const PartnerStepList = ({ contentBg }) => {
  const { isMobile } = useSelector((state) => state.global)

  return (
    <Element name="partnerStepList" id="partnerStepList">
      <div className={classNames('content', { 'content--bg': contentBg })}>
        <div className="content__block">
          <div className="partner_step_list">
            <div className="partner_step_list__item">
              <div className="partner_step_list__item-number">1</div>
              <div className="partner_step_list__content">
                <div className="partner_step_list__item-title">
                  Свяжитесь с нами удобным способом
                </div>
                {isMobile && (
                  <div className="partner_step_list__item-sub-title">
                    Получите до 52 000 ₽ в год в виде налогового вычета по
                    государственной программе
                  </div>
                )}
                <Row align="middle" gutter={{ sm: 0, md: 17 }}>
                  <Col xs={24} sm={24} md={12}>
                    <div className="partner_step_list__item-sub-description">
                      Позвонить на телефон
                    </div>
                    <Button block href="tel:88005001001">
                      8 800 500 10 01
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <div className="partner_step_list__item-sub-description">
                      Написать в Whats App
                    </div>
                    <Button
                      block
                      href="https://wa.me/89661831651"
                      target="_blank"
                    >
                      8 966 183 16 51
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="partner_step_list__item">
              <div className="partner_step_list__item-number">2</div>
              <div className="partner_step_list__content">
                <div className="partner_step_list__item-title">
                  Выберите модель сотрудничества
                </div>
                <div className="partner_step_list__item-description">
                  Выбирайте модели привлечения клиентов подходящие именно Вам
                </div>
              </div>
            </div>
            <div className="partner_step_list__item">
              <div className="partner_step_list__item-number">3</div>
              <div className="partner_step_list__content">
                <div className="partner_step_list__item-title">
                  Подпишите соглашение о присоединении
                </div>
                <div className="partner_step_list__item-description">
                  <a
                    href="https://fins.money/cdn/static/promo/invite_friend/Договор-оферта_партнера.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Договор возмездного оказания услуг для партнеров и агентов
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Element>
  )
}

export default PartnerStepList

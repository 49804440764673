import React from 'react'
import { Form, Divider, Button, Alert } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { trim } from 'ramda'
import Input from 'components/ui/Input'
import { isNameValid, isEmailValid } from 'utils/validators'
import { FETCH_REGISTRATION } from 'actions/constants/auth'

import { NewPassword } from '../NewPassword'
import { SecretQuestionWithAnswer } from '../SecretQuestionWithAnswer'
import { RegistrationAlert } from '../../RegistrationAlert'
import './index.less'

export const LongRegistration = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [sumbit, setSubmit] = React.useState(false)
  const { authenticationData } = useSelector((state) => state.auth)
  const { secretQuestionsData } = useSelector((state) => state.registration)
  const { registrationLoading } = useSelector((state) => state.registration)
  const { registrationError } = useSelector((state) => state.registration)

  const handleFormProvider = React.useCallback(
    (name, { values, forms }) => {
      if (name === 'long-registration') {
        const { newPassword, secretQuestionWithAnswer } = forms
        const secretQuestionHasErrors = secretQuestionWithAnswer
          .getFieldsError()
          .some((item) => item.errors.length > 0)
        const newPasswordHasErrors = newPassword
          .getFieldsError()
          .some((item) => item.errors.length > 0)

        if (!secretQuestionHasErrors && !newPasswordHasErrors) {
          // no errors, continue
          const formData = {
            data: {
              password: newPassword.getFieldValue('password'),
              firstName: values.name,
              email: trim(values.email),
              secretQuestionId:
                secretQuestionWithAnswer.getFieldValue('question'),
              secretAnswer: secretQuestionWithAnswer.getFieldValue('answer'),
              referral: {
                agentCode: localStorage.getItem('agentCode') || '',
                referrerCode: localStorage.getItem('referrerCode') || '',
                ad: localStorage.getItem('sub_id') || '',
              },
            },
            uuid: authenticationData.id,
          }

          dispatch({
            type: FETCH_REGISTRATION,
            payload: formData,
          })
        }
      }
    },
    [authenticationData, dispatch],
  )

  const handleSubmit = React.useCallback(() => {
    setSubmit(true)
  }, [])

  React.useEffect(() => {
    if (sumbit) {
      form.submit()
      setSubmit(false)
    }
  }, [sumbit, form])

  return (
    <Form.Provider onFormFinish={handleFormProvider}>
      <Form
        className="long-registration-form"
        name="long-registration"
        form={form}
      >
        <div className="long-registration-form__title">
          1. Ваши контактные данные
        </div>
        <Input
          label="Как вас зовут?"
          config={{
            validateTrigger: ['onFocus', 'onChange'],
            rules: [
              ({ getFieldError }) => ({
                validator(_, value) {
                  return isNameValid(value)
                },
              }),
            ],
            name: 'name',
          }}
        />
        <Input
          label="Ваш email"
          config={{
            validateTrigger: ['onFocus', 'onChange'],
            rules: [
              ({ getFieldError }) => ({
                validator(_, value) {
                  return isEmailValid(value)
                },
              }),
            ],
            name: 'email',
          }}
        />
      </Form>
      <Divider />
      <div className="long-registration-form__title">2. Придумайте пароль</div>
      <NewPassword nested submit={sumbit} />
      <Divider />
      <div className="long-registration-form__title">
        3. Для восстановления доступа выберите контрольный вопрос и укажите
        ответ
      </div>
      <SecretQuestionWithAnswer
        questionList={secretQuestionsData}
        submit={sumbit}
      />
      {registrationError.error && (
        <RegistrationAlert message={registrationError.message} />
      )}
      <Button
        style={{ marginTop: '20px' }}
        onClick={handleSubmit}
        block
        type="primary"
        loading={registrationLoading}
        disabled={registrationLoading}
      >
        Зарегистрироваться
      </Button>
    </Form.Provider>
  )
}

import React from 'react'
import { useSelector } from 'react-redux'
import MobileCarousel from '../../components/MobileCarousel'
import DesktopCarousel from '../../components/DesktopCarousel'
import { PRODUCT_SLIDES } from '../../constants/PRODUCT_SLIDES'
import './index.less'

const ProductCarousel = ({ type }) => {
  const { isMobile } = useSelector((state) => state.global)
  const slides = PRODUCT_SLIDES[type]
  return (
    <div className="content">
      <div className="product-carousel">
        {!isMobile ? (
          <DesktopCarousel isProduct type={type} slides={slides} />
        ) : (
          <MobileCarousel isProduct type={type} slides={slides} />
        )}
      </div>
    </div>
  )
}

export default ProductCarousel

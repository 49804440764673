import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FinsDrawer } from '../../components/ui/FinsDrawer'
import { LongRegistration } from '../../components/forms/LongRegistration'
import { clearRegistrationData } from '../../reducers/registration'
import { clearAuthenticationData } from '../../reducers/auth'
import { CANCEL_STATUS_POLLING } from '../../actions/constants/auth'

export const Registration = ({ history }) => {
  const dispatch = useDispatch()
  const { authenticationData } = useSelector((state) => state.auth)
  const { registrationStatus } = useSelector((state) => state.registration)
  const { isMobile, currentProductPath } = useSelector((state) => state.global)

  const onCloseDrawer = () => {
    dispatch(clearRegistrationData())
    localStorage.removeItem('t')
  }
  const handleCallbackAfterClose = React.useCallback(() => {
    dispatch(clearAuthenticationData(false))
    dispatch({ type: CANCEL_STATUS_POLLING })
  }, [dispatch])

  React.useEffect(() => {
    if (!authenticationData?.id) {
      dispatch(clearRegistrationData())
      localStorage.removeItem('t')
      history.push('/authorization')
    }

    return () => {
      dispatch(clearRegistrationData())
    }
  }, [authenticationData, dispatch, history])

  React.useEffect(() => {
    if (registrationStatus) {
      history.push('/registration/success')
    }
  }, [authenticationData, dispatch, history, registrationStatus])

  return (
    <FinsDrawer
      title="Регистрация в Fins"
      placement="right"
      width={isMobile ? '100%' : '439px'}
      bodyStyle={{ padding: '32px' }}
      isMobile={isMobile}
      onCloseHandler={onCloseDrawer}
      routePathAfterClose={`${currentProductPath}/authorization/${history.location.search}`}
      callbackAfterClose={handleCallbackAfterClose}
    >
      <LongRegistration />
    </FinsDrawer>
  )
}

import React from 'react'
import { Carousel } from 'antd'
import classNames from 'classnames'
import styles from './index.module.less'

const MobileCarousel = ({ slides, isProduct, type }) => {
  return (
    <div
      className={classNames(
        styles.mobile_carousel,
        isProduct && styles.mobile_carousel__product,
        type && styles[`mobile_carousel__${type}`],
      )}
    >
      <Carousel dots={{ className: styles.dots }}>
        {slides.map((slide) => (
          <div key={slide.key}>
            <div className={styles.mobile_carousel_slide}>
              <h5>{slide.text}</h5>
              {slide.desc && <p>{slide.desc}</p>}
              {slide.key === 1 && !isProduct && (
                <div className={styles.mobile_carousel_markets}>
                  <a
                    className={styles.mobile_carousel_apple}
                    href="https://apps.apple.com/ru/app/fins/id1453184963"
                  />
                  <a
                    className={styles.mobile_carousel_google}
                    href="https://play.google.com/store/apps/details?id=money.fins"
                  />
                </div>
              )}
              <img
                src={slide.image}
                alt="images"
                className={classNames(slide.key === 2 && styles.slide_index_1)}
              />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export default MobileCarousel

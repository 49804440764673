import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button } from 'antd'
import { push } from 'connected-react-router'
import Input from '../../ui/Input'
import { isOtpValid } from '../../../utils/validators'
import { clearOtpData } from '../../../reducers/otp'
import { clearAuthenticationData } from '../../../reducers/auth'
import {
  FETCH_TARGET_OTP_RESEND,
  FETCH_TARGET_OTP_VERIFY,
} from '../../../actions/constants/otp'

import './index.less'

const timeLeftValue = 60

export const OtpForm = () => {
  const [form] = Form.useForm()
  const { getFieldValue } = form
  const dispatch = useDispatch()
  const { authenticationData } = useSelector((state) => state.auth)
  const { otpLoading } = useSelector((state) => state.otp)
  const { otpError } = useSelector((state) => state.otp)
  const { otpData } = useSelector((state) => state.otp)
  const { currentProductPath } = useSelector((state) => state.global)
  const [timeLeft, setTimeLeft] = React.useState(timeLeftValue)
  const { attemptsReached } = otpError

  const handleChangeInput = React.useCallback(
    (e) => {
      form.setFields([
        {
          name: 'otpCode',
          value: e.target.value.replace(/[^0-9]/g, ''),
        },
      ])
      if (
        /^\d+$/.test(e.target.value.replace(/[^0-9]/g, '')) &&
        e.target.value.replace(/[^0-9]/g, '').length === 4
      ) {
        dispatch({
          type: FETCH_TARGET_OTP_VERIFY,
          payload: {
            code: getFieldValue('otpCode'),
            uuid: authenticationData.id,
          },
        })
      }
    },
    [authenticationData, dispatch, form, getFieldValue],
  )

  const handleClickButton = React.useCallback(() => {
    if (attemptsReached) {
      dispatch(clearOtpData())
      dispatch(clearAuthenticationData())
      dispatch(push(`${currentProductPath}/authorization`))
    } else {
      dispatch({
        type: FETCH_TARGET_OTP_RESEND,
        payload: { uuid: authenticationData.id },
      })
      setTimeLeft(timeLeftValue)
    }
  }, [attemptsReached, authenticationData, currentProductPath, dispatch])

  React.useEffect(() => {
    if (!authenticationData) {
      dispatch(clearOtpData())
      dispatch(clearAuthenticationData())
      dispatch(push(`${currentProductPath}/authorization`))
    }
  }, [authenticationData, currentProductPath, dispatch])

  React.useEffect(() => {
    if (otpError.error) {
      form.setFields([
        {
          name: 'otpCode',
          value: '',
          errors: [otpError.message],
        },
      ])
    }
  }, [otpError, form, otpData])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)
    if (timeLeft === 0) {
      clearTimeout(timer)
    }
    return () => clearTimeout(timer)
  }, [timeLeft])

  return (
    <Form name="otp-form" form={form}>
      <Input
        autoFocus
        autoComplete="one-time-code"
        inputMode="numeric"
        disabled={attemptsReached}
        label="Код из смс"
        id="otpCode"
        onChange={handleChangeInput}
        config={{
          validateTrigger: ['onFocus', 'onChange', 'onInput'],
          rules: [
            ({ _getFieldError }) => ({
              validator(_, value) {
                return isOtpValid(value)
              },
            }),
          ],
          name: 'otpCode',
        }}
        maxLength={otpData?.codeLength}
      />
      {attemptsReached ? (
        <Button
          onClick={handleClickButton}
          type="primary"
          loading={otpLoading}
          block
          htmlType="submit"
        >
          Авторизоваться
        </Button>
      ) : (
        <Button
          disabled={timeLeft > 0 || attemptsReached}
          onClick={handleClickButton}
          type="primary"
          loading={otpLoading}
          block
          htmlType="submit"
        >
          {timeLeft > 0
            ? `Повторно запросить код через ${timeLeft}с.`
            : 'Запросить код'}
        </Button>
      )}
    </Form>
  )
}

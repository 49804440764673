export const menuItems = [
  {
    key: 'individual-investment-account',
    label: 'ИИС',
  },
  {
    key: 'trust-management',
    label: 'Доверительное управление',
  },
  {
    key: 'fund',
    label: 'ПИФ',
  },
  {
    key: 'bonds',
    label: 'Облигации',
  },
  {
    key: 'structural-products',
    label: 'Структурный продукт',
  },
  {
    key: 'qualification',
    label: 'Квалифицированный инвестор',
  },
]

export const licenceUrls = {
  bank: 'https://fins.money/cdn/docs/license/Лицензия_банка.pdf',
  broker: 'https://fins.money/cdn/docs/license/Лицензия_брокера.jpg',
}

import { select, call, put, takeEvery } from 'redux-saga/effects'
import { INVITE } from '../../actions/constants/security'
import { callRequest } from '../../api/v5/callRequest'
import {
  securityInviteError,
  securityInviteLoading,
  securityInviteResult,
} from '../../reducers/security'
import { normalizePhoneNumber } from '../../utils/normalize'

function* fetchInvite(_action) {
  try {
    yield put(securityInviteLoading(true))

    const security = yield select((s) => s.security)
    const { name, phone } = security.registrationData.form
    const agentCode = localStorage.getItem('agentCode')

    const response = yield call(callRequest, {
      name,
      phone: normalizePhoneNumber(phone),
      source: 'Client_general',
      agentCode,
    })
    yield put(securityInviteResult(true))
    yield put(securityInviteLoading(false))
  } catch (e) {
    yield put(securityInviteLoading(false))
    yield put(securityInviteError(true))
  }
}

function* inviteSaga() {
  yield takeEvery(INVITE, fetchInvite)
}

export default inviteSaga

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedSection: null,
}

const testingPage = createSlice({
  name: 'testingPage',
  initialState,
  reducers: {
    TESTING_PAGE_SET_CURRENT_SECTION: (state, action) => {
      state.selectedSection = action.payload
    },
  },
})

const { reducer, actions } = testingPage

export const { TESTING_PAGE_SET_CURRENT_SECTION } = actions

export default reducer

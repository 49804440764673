import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FinsDrawer } from '../../components/ui/FinsDrawer'
import { PasswordForm } from '../../components/forms/PasswordForm'
import { clearAuthenticationData } from '../../reducers/auth'
import { clearOtpData } from '../../reducers/otp'
import { CANCEL_STATUS_POLLING } from '../../actions/constants/auth'

export const Password = () => {
  const { isMobile, currentProductPath } = useSelector((state) => state.global)
  const dispatch = useDispatch()

  const handleCallbackAfterClose = React.useCallback(() => {
    dispatch(clearAuthenticationData(false))
    dispatch(clearOtpData())
    dispatch({ type: CANCEL_STATUS_POLLING })
  }, [dispatch])

  React.useEffect(() => {
    return () => {
      localStorage.removeItem('t')
    }
  }, [])

  return (
    <FinsDrawer
      title="Вход в Fins"
      placement="right"
      width={isMobile ? '100%' : '439px'}
      bodyStyle={{ padding: '32px' }}
      isMobile={isMobile}
      routePathAfterClose={currentProductPath}
      callbackAfterClose={handleCallbackAfterClose}
    >
      <PasswordForm />
    </FinsDrawer>
  )
}

import React from 'react'
import classNames from 'classnames'
import { find, propEq } from 'ramda'
import { Tabs } from 'antd'
import './index.less'

import { contentTypes } from '../../constants/PRODUCT_TABS'

const { TabPane } = Tabs

const DesktopTabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = React.useState(1)

  const onTabPaneClick = React.useCallback(
    (tabKey) => () => {
      setActiveTab(tabKey)
    },
    [],
  )

  const tab = React.useMemo(() => {
    return find(propEq('key', activeTab))(tabs)
  }, [activeTab])
  return (
    <div className="desktop-tabs">
      <div className="desktop-tabs__pane">
        {tabs.map((item) => (
          <button
            type="button"
            key={item.key}
            onClick={onTabPaneClick(item.key)}
            className={classNames(
              'desktop-tabs__pane-item',
              {
                active: activeTab === item.key,
              },
              { 'hide-border-right': item.key === 2 && activeTab === 3 },
              { 'hide-border-left': item.key === 2 && activeTab === 1 },
            )}
          >
            <div className="desktop-tabs__pane-icon">{item.icon}</div>
            <div className="desktop-tabs__pane-title">{item.title}</div>
          </button>
        ))}
      </div>
      <div className="desktop-tabs__content">
        {tabs.map((item) => (
          <div
            key={item.key}
            className={classNames('desktop-tabs__content-item', {
              active: activeTab === item.key,
            })}
          >
            {item.content?.map((content) => (
              <div key={content.key}>
                {content.type === contentTypes.title && (
                  <h4 className="desktop-tabs__content-title">
                    {content.value}
                  </h4>
                )}
                {content.type === contentTypes.paragraph && (
                  <p className="desktop-tabs__content-paragraph">
                    {content.value}
                  </p>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default DesktopTabs

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { FinsDrawer } from '../../components/ui/FinsDrawer'
import { OtpForm } from '../../components/forms/OtpForm'
import { clearAuthenticationData } from '../../reducers/auth'
import { clearOtpData } from '../../reducers/otp'
import { CANCEL_STATUS_POLLING } from '../../actions/constants/auth'

export const Otp = ({ history }) => {
  const { authenticationData } = useSelector((state) => state.auth)
  const { authLoading } = useSelector((state) => state.auth)
  const { registrationRequire } = useSelector((state) => state.auth)
  const { passwordRequire } = useSelector((state) => state.auth)
  const { isMobile, currentProductPath } = useSelector((state) => state.global)
  const { login } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const handleCallbackAfterClose = React.useCallback(() => {
    dispatch(clearAuthenticationData())
    dispatch(clearOtpData())
    dispatch({ type: CANCEL_STATUS_POLLING })
  }, [dispatch])

  React.useEffect(() => {
    if (registrationRequire) {
      dispatch(
        push(`${currentProductPath}/registration/${history.location.search}`),
      )
    }
  }, [currentProductPath, dispatch, history, registrationRequire])

  React.useEffect(() => {
    if (passwordRequire) {
      dispatch(push(`${currentProductPath}/authorization/password`))
    }
  }, [currentProductPath, dispatch, history, passwordRequire])

  React.useEffect(() => {
    if (!authenticationData && !authLoading) {
      dispatch(
        push(`${currentProductPath}/authorization/${history.location.search}`),
      )
    }
  }, [authLoading, authenticationData, currentProductPath, dispatch, history])

  return (
    <FinsDrawer
      title="Вход в Fins"
      placement="right"
      width={isMobile ? '100%' : '439px'}
      bodyStyle={{ padding: '32px' }}
      isMobile={isMobile}
      routePathAfterClose={`${currentProductPath}/authorization/${history.location.search}`}
      callbackAfterClose={handleCallbackAfterClose}
    >
      <>
        <p>
          <b>Введите код из сообщения</b>
        </p>
        <p className="authorization__desc">
          Мы отправили СМС с кодом на ваш телефон <br />
          +7 (***) ***-{login && login.substring(12, login.length)}
        </p>
        <OtpForm />
      </>
    </FinsDrawer>
  )
}

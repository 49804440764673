import React from 'react'
import { Row, Col } from 'antd'
import '../index.less'

export const Section1 = ({ id }) => {
  return (
    <>
      <h1 id={id}>Облигации со структурным доходом</h1>
      <p>
        Рассмотрим особенности облигаций со структурным доходом, их риски и
        преимущества.
      </p>
      <p>
        Облигации со структурным доходом, по сути, выступают одним из вариантов
        структурных облигаций. Однако они отличаются от этого более широкого
        понятия.
      </p>
      <h2>Особенности облигаций со структурным доходом</h2>
      <div>
        <div className="testing_box">
          Позволяют инвестору получать регулярный доход в каждый отчетный период
          и дополнительный доход при выполнении определенных условий.
        </div>
        <div className="testing_box">
          Условия напрямую связаны с базисным активом облигации, которым могут
          выступать:
          <ul>
            <li>уровень инфляции,</li>
            <li>цены фьючерсов на драгметаллы, нефть,</li>
            <li>
              цены акций или облигаций, торгуемых на российской бирже (за
              исключением бумаг, предназначенных только для квалифицированных
              инвесторов),
            </li>
            <li>курс валюты, установленный Банком России,</li>
            <li>биржевой индекс (например, Индекс ММВБ).</li>
          </ul>
        </div>
        <div className="testing_box">
          Цена облигации со структурным доходом и доход по ним (купон) зависят
          от изменений стоимости базисных активов. То есть при приобретении
          облигации со структурным доходом инвестор не может предсказать с
          полной точностью, каким будет его доход. Он знает только, как
          рассчитывается потенциальный доход, но размер будет зависеть от
          стоимости базисного актива в конкретный период (также определенный в
          условиях продукта).
        </div>
        <div className="testing_box">
          На саму стоимость облигации со структурным доходом также влияет целый
          набор факторов, в связи с чем нельзя предсказать, как изменение
          стоимости базисного актива повлияет на непосредственную стоимость
          облигации.
        </div>
      </div>
      <h3>
        Например, в основе продукта акции ПАО «СберБанк». Они выросли за три
        месяца на 15%, но это не значит, что стоимость облигации также вырастет
        на 15%. Ее изменение предсказать невозможно.
      </h3>

      <h2>Как работает облигация со структурным доходом?</h2>
      <p>
        У облигации со структурным доходом есть срок жизни и параметры,
        определяющие периодичность и потенциальный объем выплат. Базисный актив
        (БА) также определен заранее.
      </p>
      <p style={{ marginBottom: 0 }}>
        Например, облигация со структурным доходом, отслеживающая обыкновенные
        акции ПАО «СберБанк». Срок — 3 года. Выплата регулярного дохода в
        размере 0,01% от номинала — каждый год. В конце срока действия возможно
        получение дополнительного дохода, размер которого будет считаться так:
      </p>
      <Row>
        <Col sm={24} md={8}>
          <div className="testing_box">
            <div>
              <b>% x коэффициент участия</b>
            </div>
            <small>прироста стоимости акций</small>
          </div>
        </Col>
      </Row>
      <p>
        Коэффициент участия определяет, сколько вы получите от роста котировок
        БА. В нашем примере пусть будет так:
      </p>
      <Row>
        <Col sm={24} md={8}>
          <div className="testing_box">
            <div>
              <b>15% x 1,1 (110%) = 16,5% за 3 года</b>
            </div>
            <small>рост котировок Сбербанка</small>
          </div>
        </Col>
      </Row>
      <h3>Риски инвестирования</h3>
      <div>
        <div className="testing_box">
          Риски невыплаты или ненадлежащего исполнения эмитентов обязательств по
          выплате купонов / дохода.
        </div>
        <div className="testing_box">Риск банкротства эмитента облигации.</div>
        <div className="testing_box">
          Если базисный актив не растет в цене, инвестор не получает
          дополнительный доход в конце срока действия продукта.
        </div>
      </div>
      <h3>Преимущества облигаций со структурным доходом</h3>
      <p>В конце срока обращения:</p>
      <div>
        <div className="testing_box">
          Инвестору возвращается номинал облигации
        </div>
        <div className="testing_box">
          Если цены на базовый актив выросли по сравнению с изначальными,
          выплачивается дополнительный доход.
        </div>
      </div>
      <div className="testing_divider" />
    </>
  )
}

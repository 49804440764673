import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_REGISTRATION } from '../../actions/constants/auth'
import {
  saveRegistrationData,
  setRegistrationError,
  setRegistrationLoading,
  setRegistrationStatus,
} from '../../reducers/registration'
import { requestRegister } from '../../api/v4/auth'

function* fetchRegistration(action) {
  try {
    yield put(setRegistrationLoading(true))
    const registrationData = yield call(requestRegister, action.payload)

    if (registrationData.accessToken) {
      yield put(setRegistrationStatus(true))
      localStorage.setItem('t', registrationData.accessToken)
      yield put(
        setRegistrationError({
          error: false,
          message: '',
        }),
      )
    }
    yield put(saveRegistrationData(registrationData))
    yield put(setRegistrationLoading(false))
  } catch (e) {
    const { response } = e
    const { data } = response
    if (data.code) {
      yield put(setRegistrationStatus(false))
      yield put(
        setRegistrationError({
          error: true,
          message: 'Ошибка регистрации',
        }),
      )
    }
    yield put(setRegistrationLoading(false))
    yield put(saveRegistrationData(e.message))
  }
}

function* registrationSaga() {
  yield takeEvery(FETCH_REGISTRATION, fetchRegistration)
}

export default registrationSaga

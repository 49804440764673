import React from 'react'
import { push } from 'connected-react-router'
import { useSelector, useDispatch } from 'react-redux'
import { FinsDrawer } from '../../components/ui/FinsDrawer'
import { FinalSection } from '../../components/FinalSection'

export const SuccessRegistration = () => {
  const dispatch = useDispatch()
  const { isMobile } = useSelector((state) => state.global)
  const { registrationStatus } = useSelector((state) => state.registration)

  React.useEffect(() => {
    if (!registrationStatus) {
      dispatch(push(`/`))
    }
  }, [])

  return (
    <FinsDrawer
      title="Регистрация в Fins"
      placement="right"
      width={isMobile ? '100%' : '439px'}
      bodyStyle={{ padding: '32px' }}
      isMobile={isMobile}
      routePathAfterClose="/"
    >
      <FinalSection type="successRegistration" />
    </FinsDrawer>
  )
}

import React from 'react'
import '../index.less'

export const Section7 = ({ id }) => {
  return (
    <>
      <h1 id={id}>Как инвестировать в облигации иностранных эмитентов</h1>
      <h2>
        Разберемся, что такое еврооблигации или евробонды, каких видов они
        бывают, и что нужно знать про них инвестору.
      </h2>

      <p>
        Еврооблигации или евробонды (Eurobonds) — это облигации, преимущественно
        номинированные в иностранной валюте. Эмитенты выпускают облигации, чтобы
        профинансировать текущую деятельность и новые проекты компании.
      </p>
      <p>
        Приставка «евро» — отсылка к прошлому, так как родиной этого инструмента
        является Европа.
      </p>
      <p>
        Еврооблигации могут быть выпущены и в рублях, например, биржевые ноты от
        БКС № 31, 34, 42. Это связано с тем, что в стране выпуска евробонда
        национальной валютой является евро, а номинальная стоимость облигаций
        выражена в рублях, то есть в валюте, отличной от национальной.
      </p>
      <p>
        Наибольшей популярностью в России традиционно пользуются еврооблигации,
        номинированные в долларах США и евро. Этот инструмент представляет
        интерес для тех инвесторов, кто ставит целью получение доходности
        в иностранной валюте, либо страхует себя от рисков снижения рубля
      </p>

      <h2>Как работает облигация</h2>

      <p>
        Для совершения операций с облигациями можно открыть брокерский счет и
        совершить сделку.
      </p>
      <p>По типу получения дохода облигации бывают процентные и дисконтные.</p>
      <p>
        Наиболее распространенный тип облигаций, обращающихся на российских
        биржах, — процентные облигации. Эмитент выплачивает периодический
        процент (купон) в течение срока обращения облигации. Купоны могут
        выплачиваются ежегодно, ежеквартально или ежемесячно в зависимости от
        условий бумаги.
      </p>
      <p>Процентные облигации могут быть:</p>
      <ul>
        <li>
          С фиксированным купоном — определенный процент от номинальной
          стоимости выплачивается через установленные заранее промежутки
          времени. Процентная ставка по облигации известна с момента выпуска и,
          как правило, одинакова на весь срок обращения бумаги. Для такой бумаги
          можно составить график купонных выплат с точными суммами и однозначно
          рассчитать доходность к погашению.
        </li>
        <li>
          С переменным купоном — купон фиксируется до даты оферты, после которой
          процентная ставка меняется в зависимости от конъюнктуры рынка. До
          оферты новая процентная ставка неизвестна. Такой механизм позволяет
          эмитенту снизить процентный риск, особенно если выпуск размещается в
          период высоких процентных ставок с перспективой их понижения. А у
          инвестора появляется возможность досрочно погасить эти облигации по
          оферте. В промежутках между офертами такие бумаги ничем не отличаются
          от облигаций с фиксированным купоном с той лишь разницей, что
          доходность корректно считать не к дате погашения, а к дате ближайшей
          оферты.
        </li>
        <li>
          С плавающим купоном — процентная ставка привязана к изменению
          какого-либо индикативного финансового инструмента. Например, к
          ключевой ставке Банка России, индексу потребительских цен, ставке
          RUONIA, курсу доллара и др. Как правило, рассчитать размер купона по
          таким бумагам можно не дальше, чем на один купонный период. Формула
          расчета публикуется эмитентом и доступна всем инвесторам. Недостаток —
          будущую доходность можно лишь прогнозировать. Преимущество — позволяет
          застраховаться, например, от внезапного изменения рыночных процентных
          ставок.
        </li>
      </ul>
      <p>
        Дисконтные (бескупонные) облигации — выплата купона не предусмотрена.
        Изначально размещаются ниже номинальной стоимости. Доход инвестора
        составляет разница между ценой покупки бумаги и ценой погашения/продажи.
      </p>

      <h2>Классификация по типу погашения номинала</h2>
      <ul>
        <li>
          Погашение номинала полностью в конце срока. По умолчанию сумма
          номинала выплачивается инвестору в полном размере в дату погашения
          облигации. Такой тип погашения является самым распространенным среди
          бумаг на Московской бирже.
        </li>
        <li>
          Облигации с амортизируемым долгом. Для большинства облигаций,
          обращающихся на Московской бирже, сумма номинала выплачивается
          инвестору в полном размере в дату погашения. Однако эмитенту может
          быть неудобна такая форма заимствований, при которой к дате погашения
          придется аккумулировать крупную сумму денег для погашения выпуска.
          Тогда он выпускает облигацию с амортизируемым долгом, номинал которой
          погашается частями вместе с купонными выплатами. Эмитенту это
          позволяет распределить выплату долга равномерно на весь период
          обращения. Часто такие бумаги встречаются среди муниципальных
          облигаций. Инвестору такой тип погашения менее выгоден: после
          получения части номинала следующие купоны начисляются на остаток
          стоимости бумаги, за счет чего суммарный доход получается меньше.
          Однако этот тип облигаций может быть удобен в периоды низких
          процентных ставок, когда ожидается их повышение. Тогда возвращенную
          часть номинала можно будет реинвестировать под более высокий процент
        </li>
      </ul>

      <h2>Виды рисков</h2>
      <p>
        Цены облигаций могут изменяться в зависимости от макроэкономической
        ситуации и событий, влияющих на деятельность компании-эмитента.
        Инвестору следует помнить о возможных рисках при инвестировании:
      </p>
      <div>
        <div className="testing_box">
          <p>
            <b>Риск дефолта.</b> Фиксируется в случае, когда эмитент не в
            состоянии выполнить финансовые обязательства по ценным бумагам и
            объявляет себя банкротом. В этом случае инвестор может потерять
            вложенные деньги: в отличие от депозитов, они не защищены системой
            страхования вкладов.
          </p>
          <p>
            Облигации не идентичны по уровню кредитного риска в зависимости от
            типа эмитента. На рынке обращаются государственные, субфедеральные,
            муниципальные, корпоративные облигации.
          </p>
          <ul>
            <li>
              Государственные облигации — эмитентом выступает государство в лице
              Министерства финансов РФ. Самые распространенные бумаги этой
              категории — облигации федерального займа (ОФЗ): обычно обладают
              относительно высокой ликвидностью, широким выбором сроков
              инвестирования, низкой вероятностью дефолта эмитента. Из
              недостатков можно отметить невысокую доходность, которая является
              компенсацией за низкий кредитный риск.
            </li>
            <li>
              Корпоративные облигации — это долговые бумаги отдельных компаний.
              Их отличает большая, чем по ОФЗ, доходность. Часто купон по ним
              является переменным.
            </li>
          </ul>
        </div>
        <div className="testing_box">
          <b>Инфляционный риск.</b> Прибыль по ценным бумагам и фактический
          доход стоит корректировать на размер инфляции. При неблагоприятной
          экономической ситуации существует риск получения отрицательной
          реальной доходности, когда получаемый процент не покрывает инфляцию.
          Долгосрочные инвестиции больше подвержены инфляции, нежели
          краткосрочные.
        </div>
        <div className="testing_box">
          <b>Процентный риск.</b> Наблюдается при росте среднерыночной ставки по
          облигациям со схожими условиями. Облигации с процентом ниже рыночного
          снижают доход инвестора. На этот вид риска, как правило, влияет
          ключевая ставка ЦБ РФ. Чем длиннее облигация по сроку, тем больше ее
          цена зависит от изменения процентных ставок.
        </div>
        <div className="testing_box">
          <b>Риск реинвестирования.</b> Предполагает реинвестирование полученных
          доходов (купонов) по сниженной процентной ставке по сравнению с
          приобретенной. Этот риск проявляется при падении процентных ставок на
          протяжении длительного периода.
        </div>
        <div className="testing_box">
          <p>
            <b>Валютный риск.</b> Инвестирование может проходить как в
            российском рубле, так и в евро или долларах. Вложение в иностранные
            активы сильно зависит от колебаний курса валют.
          </p>
          <p>
            По законодательству все расчеты на территории РФ осуществляются в
            рублях. Подавляющее большинство облигаций, обращающихся на
            внутреннем рынке и доступных для частного инвестора, номинированы в
            национальной валюте. Торги проходят главным образом на Московской
            бирже.
          </p>
          Облигации, номинированные в иностранной валюте (в основном в евро и
          долларах США), называются еврооблигациями. Основные объемы торгов
          еврооблигаций проходят на внебиржевом рынке, некоторые бумаги доступны
          и на Московской бирже.
        </div>
        <div className="testing_box">
          <p>
            <b>Кредитный риск.</b> Важным показателем при анализе облигаций
            является кредитный рейтинг. Снижение платежеспособности эмитента
            отражается на его репутации на рынке ценных бумаг и цене облигации.
          </p>
          <p>
            <b>Кредитный рейтинг</b> — это оценка эмитента на основе
            комплексного анализа компании, учитывающего финансовое состояние,
            качество корпоративного управления и уровень бизнес-рисков.
            Агентство формирует мнение о кредитоспособности компании в целом или
            относительно ее конкретных обязательств. По сути, присваивая
            рейтинг, оценивается способность эмитента облигаций выполнять взятые
            на себя финансовые обязательства по этим облигациям, то есть
            погасить их вовремя и выплатить по ним доход.
          </p>
          <p>
            Разные выпуски облигаций могут иметь разный рейтинг, даже у одной и
            той же компании. А отсутствие кредитного рейтинга выпуска означает
            отсутствие мнения кредитного рейтингового агентства о кредитном
            риске данного выпуска облигаций.
          </p>
          <p>
            <b>Рейтинговая шкала</b> — это система рейтинговых категорий,
            отражающая мнение агентства о вероятности дефолта. Выражаются в
            буквах и символах, от самого высокого уровня надежности (например,
            ААА) до самого низкого (ССС).  Единой общемировой или даже
            общероссийской системы рейтингов не существует. Однако кредитные
            рейтинги разных агентств, особенно высокие, обычно сопоставимы друг
            с другом.
          </p>
          <p>
            Вместе с рейтингом агентства публикуют прогноз по нему: например,
            «стабильный», «позитивный» или «негативный». Он показывает, ожидают
            ли аналитики, что финансовое положение организации в ближайший год
            изменится и в какую сторону.
          </p>
          Агентство пересматривает рейтинг той или иной компании на регулярной
          основе.
        </div>
      </div>
      <h2>Ликвидность облигаций</h2>
      <p>
        <b>Ликвидность</b> — это способность актива быстро и без потерь в
        стоимости быть купленным или проданным по рыночной цене. При выборе
        облигации инвестору стоит обращать внимание на показатель ликвидности.
      </p>
      <p>
        Риск ликвидности заключается в вероятности не продать облигации раньше
        окончания срока действия по справедливой цене. Особенно, если инвестор
        принимает решение срочно продать принадлежащие ему облигации —
        покупателя может просто не найтись. Этот риск возможен при низкой
        известности эмитента и небольшом количестве сделок с его долговыми
        ценными бумагами.
      </p>
      <p> Облигации можно подразделить на:</p>
      <ul>
        <li>
          Высоколиквидные облигации — это облигации, которые можно продать без
          снижения их цены.
        </li>
        <li>
          Низколиквидные и неликвидные — маловероятно продать за короткий
          промежуток времени, не снизив их стоимость.
        </li>
      </ul>

      <p>На что еще стоит обращать внимание при оценке ликвидности:</p>
      <ul>
        <li>
          Среднедневной оборот торгов — чем больше сделок по облигации проходит
          в течение дня, тем больший объем бумаг может реализовать инвестор в
          короткие сроки.
        </li>
        <li>
          Оборот, включающий в себя только крупные сделки — согласно некоторым
          теориям, оборот отдельно по категориям сделок может оказаться более
          показательным для оценки ликвидности, чем значение совокупного
          оборота.
        </li>
        <li>
          Количество дней без сделок — чем больше число таких дней, тем менее
          ликвидна облигация.
        </li>
        <li>
          Объем выпуска — чем больше бумаг находится в обращении, тем больше
          вероятность реализовать необходимый объем через торги на бирже.
        </li>
        <li>
          Кредитный риск — зачастую более рискованные облигации высокодоходными
          в большей степени подвержены спекуляциям, соответственно, активность
          торгов по ним выше и выше ликвидность.
        </li>
        <li>
          Волатильность — повышенная волатильность также может позитивно
          повлиять на оборот торгов и ликвидность облигации.
        </li>
        <li>
          Публичность эмитента — чем больше компания раскрывает информации о
          своей деятельности, тем более привлекательными являются ее ценные
          бумаги.
        </li>
        <li>
          Встроенные в облигацию опции — такие особенности, как
          конвертируемость, наличие оферт put/call и другие опции также могут
          повлиять на ликвидность облигации.
        </li>
      </ul>

      <h2>Какие налоги платит владелец еврооблигаций</h2>
      <p>
        Налогообложение операций с ценными бумагами регламентируется статьей
        214.1 части 2 Налогового Кодекса РФ. Ставка налога на доходы физических
        лиц резидентов РФ составляет 13%.
      </p>
      <p>При этом важно помнить о валютной переоценке.</p>
      <p>
        При расчете НДФЛ по операциям с ценными бумагами, номинированным в
        иностранной валюте, важным аспектом является курс Банка России,
        установленный для данной валюты к рублю на даты покупки/продажи
        (учитывается фактическая дата расчетов по сделке). Именно доход,
        пересчитанный в рублях по курсу Банка России, подлежит налогообложению.
      </p>
      <p>Особенно стоит обратить внимание:</p>
      <ul>
        <li>
          В ситуации, когда связи со снижением курса доллара, установленного
          Банком России, доход, полученный физическим лицом в рублях —
          отрицательный. НДФЛ взиматься не будет.
        </li>
        <li>
          В ситуации, когда несмотря на то, что доход, полученный физическим
          лицом в долларах, отрицательный, доход в рублях (в связи с выросшим
          официальным курсом Банка России) — положительный. Положительный доход
          в рублях от реализации иностранных ценных бумаг облагается НДФЛ.
        </li>
      </ul>

      <p>
        Основание — НК РФ, Статья 210. Налоговая база 5. Доходы (расходы,
        принимаемые к вычету в соответствии со статьями 214.1, 214.3, 214.4,
        214.5, 218 - 221 настоящего Кодекса) налогоплательщика, выраженные
        (номинированные) в иностранной валюте, пересчитываются в рубли по
        официальному курсу Центрального банка Российской Федерации,
        установленному на дату фактического получения указанных доходов (дату
        фактического осуществления расходов), если иное не предусмотрено
        настоящей главой.
      </p>
      <p>
        От валютной переоценки освобождаются еврооблигации Минфина. Для расчета
        налоговой базы в рублях, для определения и расходов, и доходов от
        реализации бумаги берется один курс ЦБ — на дату продажи ценной бумаги.
        Таким образом, после определения финансового результата в валюте, он
        пересчитывается в рубли РФ по курсу Центрального Банка на дату продажи
        бумаг. За счет этого изменение курса рубля на итоговый финансовый
        результат клиента в рублях не влияет (на основании Федерального закона
        от 19.07.2018 № 200-ФЗ «О внесении изменений в статьи 210 и 214-1 части
        второй Налогового кодекса Российской Федерации»).
      </p>
      <p>
        C 2021 г. купоны по всем облигациям без исключения, независимо от даты
        выпуска и величины купонной ставки, входят в налоговую базу и облагаются
        налогом по ставке 13% (15% при превышении совокупного дохода 5 млн
        руб.). Налоговым агентом выступает брокер.
      </p>

      <div className="testing_divider" />
    </>
  )
}

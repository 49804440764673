import React from 'react'
import { useDispatch } from 'react-redux'
import { SET_CURRENT_PRODUCT_PATH } from 'reducers/global'
import { PartnersHeader } from '../../sections/PartnersHeader'

import ProductCarousel from '../../sections/ProductCarousel'
import PartnerStepList from '../../sections/PartnerStepList'

import { DrawerRoute } from '../../components/DrawerRoute'
import WritingAboutUs from '../../sections/WritingAboutUs'

export const Partners = ({ match }) => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(SET_CURRENT_PRODUCT_PATH(match.url))
    if (typeof window !== 'undefined') window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PartnersHeader />
      <PartnerStepList contentBg />
      <ProductCarousel type="partners" />
      <WritingAboutUs />
      <DrawerRoute />
    </>
  )
}

import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Menu } from 'antd'
import classNames from 'classnames'
import { menuItems, licenceUrls } from '../../constants/MAIN_MENU'

import './index.less'

export const MainMenu = ({
  isMobile,
  show = false,
  handleOpenMenu,
  backgroudWhite,
}) => {
  const [showMenu, setShowMenu] = React.useState(false)
  const [activeKey, setActiveKey] = React.useState([])

  const history = useHistory()
  const { pathname } = useLocation()
  const { currentProductPath } = useSelector((state) => state.global)

  const licenceUrl = React.useMemo(() => {
    return currentProductPath
      ? `${currentProductPath.replace('/', '')}/licenses`
      : 'licenses'
  }, [currentProductPath])

  const handleMenuClick = React.useCallback(
    ({ _item, key, _keyPath, _domEvent }) => {
      if (isMobile) handleOpenMenu()
      return ['bank', 'broker'].includes(key)
        ? window.open(licenceUrls[key], '_blank')
        : history.push(`/${key}`)
    },
    [history, show, isMobile],
  )

  React.useEffect(() => {
    if (isMobile) {
      if (showMenu && !show) {
        setTimeout(() => setShowMenu(show), 300)
      } else {
        setShowMenu(show)
      }
    }
  }, [show, isMobile])

  React.useEffect(() => {
    const active = menuItems.reduce((acc, menu) => {
      return pathname.includes(menu.key) ? menu.key : acc
    }, [])
    setActiveKey(active)
  }, [pathname])

  if (!showMenu && isMobile) return null

  return (
    <>
      <div
        className={classNames(
          'main-menu__container',
          backgroudWhite && 'main-menu__container--white',
        )}
      >
        <div className="main-menu__content">
          <Menu
            selectedKeys={activeKey}
            onClick={handleMenuClick}
            className={classNames(
              'main-menu',
              isMobile && 'main-menu__mobile',
              isMobile && !show && showMenu && 'main-menu__mobile-close',
            )}
            mode={isMobile ? 'vertical' : 'horizontal'}
          >
            {menuItems.map((menu, index) => (
              <Menu.Item
                className={classNames(
                  'main-menu__item',
                  index === 0 && 'main-menu__item--first',
                )}
                key={menu.key}
              >
                {menu.label}
              </Menu.Item>
            ))}
            {!isMobile && (
              <Menu.Item className="main-menu__item--last" key={licenceUrl}>
                Лицензии ЦБ
              </Menu.Item>
            )}
          </Menu>
        </div>
      </div>
    </>
  )
}

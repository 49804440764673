import React from 'react'
import classNames from 'classnames'

import './index.less'
import ARROW_ICON from './arrow-icon.svg'

export const SelectSuffixIcon = ({ open }) => (
  <img
    src={ARROW_ICON}
    className={classNames('select-suffix-icon', {
      'select-suffix-icon--open': open,
    })}
    alt="arrow icon"
  />
)

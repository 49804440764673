import React from 'react'
import '../index.less'

export const Section6 = ({ id }) => {
  return (
    <>
      <h1 id={id}>
        Сделки РЕПО — инструмент получения ликвидности на финансовом рынке
      </h1>
      <h2>
        Сделки РЕПО — это один из важнейших инструментов рынка ценных бумаг.
      </h2>

      <p>
        РЕПО (от англ. repurchase agreement, repo) — это финансовая сделка, в
        рамках которой каждая из сторон сделки заключает две встречные сделки
        купли-продажи ценных бумаг. По первой сделке одна из сторон покупает или
        продает ценные бумаги. По второй части она обязана продать или купить их
        обратно.
      </p>
      <p>
        Сделки РЕПО — это один из важнейших инструментов рынка ценных бумаг. С
        помощью таких сделок привлекаются денежные средства или ценные бумаги.
        Также эти сделки используются и для размещения денежных средств или
        ценных бумаг. Предметом сделки РЕПО обычно являются акции или облигации.
      </p>
      <p>
        Сделка РЕПО подразумевает переход права собственности на предмет сделки.
        Таким образом, в период действия договора (сделки) РЕПО право
        собственности на ценные бумаги переходит от их продавца к покупателю.
        Сделки РЕПО позволяют быстро привлекать или размещать денежные средства,
        тем самым обеспечивая ликвидность.
      </p>
      <p>Основные классификации сделок РЕПО</p>
      <p>
        По сроку действия: «интрадэй» — внутридневные, «овернайт» — через ночь,
        на один рабочий день, «срочные» — на определенное количество дней, с
        открытой датой.
      </p>
      <p>
        По типу операций: прямое РЕПО — продажа ценных бумаг по первой части
        сделки, обратное РЕПО — покупка ценных бумаг по первой части сделки.
      </p>
      <p>По валюте расчетов: рубли, иностранная валюта.</p>
      <p>
        По месту и способу заключения: биржевые — заключаются на организованном
        рынке рынке ценных бумаг, OTC — на внебиржевом рынке ценных бумаг.
      </p>
      <br />
      <p>
        <b>Пример 1.1.</b>
      </p>
      <p>
        Брокер «ABC» и его клиент Алексеев Борис Сергеевич 23.09.2021 заключили
        срочную сделку РЕПО. По первой части сделки РЕПО клиент Алексеев Борис
        Сергеевич продал брокеру «ABC» 1000 акций АО «Газпром» и получил за них
        300000 руб. Цена первой части РЕПО составила 300 руб. за одну акцию. По
        второй части сделки РЕПО клиент Алексеев Борис Сергеевич обязан
        25.10.2021 выкупить у брокера «ABC» 1000 акций АО «Газпром» за 303160
        руб. Расчетная цена второй части РЕПО составила 303,16 руб. за одну
        акцию.
      </p>
      <p>
        Таким образом, Борис Сергеевич должен будет заплатить брокеру 3160 руб.
        процентов за пользование денежными средствами или 12% годовых,
        начисленных на сумму первой части сделки РЕПО за фактический срок
        пользования денежными средствами. Для клиента Алексеева данная сделка
        является сделкой прямого РЕПО.
      </p>
      <h2>Риски, связанные с заключением сделок РЕПО</h2>
      <p>
        Основным риском при совершении сделок РЕПО является неисполнение
        сторонами второй части таких сделок. Неисполнение может наступить в
        случае, если:
      </p>
      <ul>
        <li>
          у продавца ценных бумаг не будет денежных средств для их обратного
          выкупа у контрагента по сделке,
        </li>
        <li>
          у покупателя ценных бумаг не будет их в наличии для возврата
          контрагенту по сделке,
        </li>
        <li>отказ от выполнения обязательств любой из сторон.</li>
      </ul>
      <p>
        Главным фактором риска, влияющим на исполнение сторонами своих
        обязательств по сделкам РЕПО, является изменение стоимости ценных бумаг
        на рынке (волатильность).
      </p>

      <h2>Инструменты управления рисками при заключении сделок РЕПО</h2>

      <p>
        Переоценка обязательств по договору РЕПО может быть организована при
        определенных в договоре РЕПО условиях, на тот случай, когда стоимость
        ценных бумаг, являющихся предметом сделки РЕПО, поднялась или опустилась
        существенно для обеих сторон. Цель переоценки — создание условий для
        выполнения второй части сделки РЕПО.
      </p>
      <p>
        Существует верхняя и нижняя переоценки. Продавец ценных бумаг по первой
        части сделки РЕПО может провести верхнюю переоценку, если стоимость этих
        бумаг поднялась выше определенного уровня. Нижнюю переоценку проводит
        покупатель, если цена бумаг опустилась ниже установленного уровня. В
        процессе заключения сделки РЕПО стороны могут договориться об основаниях
        и порядке проведения переоценки.
      </p>
      <p>
        Переоценка по договору РЕПО может быть проведена следующими способами:
      </p>
      <ul>
        <li>досрочное исполнение сделки РЕПО,</li>
        <li>
          досрочное исполнение сделки РЕПО и заключение новой сделки РЕПО
          (переоткрытие сделки),
        </li>
        <li>маржинальный взнос (как деньгами, так и ценными бумагами),</li>
        <li>компенсационный взнос (как деньгами, так и ценными бумагами).</li>
      </ul>
      <p>
        Для наглядности рассмотрим сделку прямого РЕПО и случай нижней
        переоценки.
      </p>
      <p>
        Начальный дисконт — разница между рыночной стоимостью ценных бумаг и
        привлекаемыми денежными средствами. Как правило, продавец в таких
        сделках получает за свои ценные бумаги сумму меньше их рыночной
        стоимости. Для покупателя ценных бумаг по первой части РЕПО это будет
        являться дополнительной гарантией того, что продавец ценных бумаг по
        первой части РЕПО захочет выкупить свои бумаги обратно и, таким образом,
        выполнит условия договора.
      </p>
      <br />
      <p>
        <b>Пример 1.2.</b>
      </p>
      <p>
        В примере 1.1. рыночная стоимость 1000 акций АО «Газпром» составляла
        375000 руб., таким образом, начальный дисконт составил 20% (375000 * 20%
        = 75 000).
      </p>
      <p>
        <b>Компенсационный взнос</b> — это деньги или ценные бумаги, которые
        продавец обязан передать покупателю по первой части сделки РЕПО в
        случае, если рыночная стоимость ценных бумаг упала ниже оговоренного
        сторонами уровня. Внесенные дополнительно деньги уменьшают задолженности
        продавца бумаг по первой части сделки РЕПО; внесенные дополнительно
        бумаги увеличивают размер обеспечения по первой части сделки РЕПО. Таким
        образом, внесенные активы изменяют размер обязательств по второй части
        сделки РЕПО.
      </p>
      <br />
      <p>
        <b>Пример 1.3.</b>
      </p>
      <p>
        Согласно условиям примера 1.1., 23.09.2021 по первой части сделки РЕПО
        клиентом было продано 1000 акций АО «Газпром», оцененных с 20% дисконтом
        к их рыночной стоимости. Напоминаем, сделка заключена на срок до
        25.10.2021. С 24.09.2021 котировки ценных бумаг стали снижаться, и
        28.09.2021 рыночная стоимость 1000 акций АО «Газпром» составила 309 000
        руб. (одна акция стала стоить 309 руб.).
      </p>
      <p>
        Таким образом, текущий дисконт (разница между рыночной стоимостью ценных
        бумаг и суммой сделки РЕПО) составил всего 9 000 руб., или 3%
        (9000/300000* 100%). Условиями сделки РЕПО предусмотрен компенсационный
        взнос. С помощью этого взноса продавец бумаг по первой части сделки РЕПО
        должен восстановить первоначально оговоренный уровень дисконта. Это
        можно сделать или деньгами (частичной оплатой — уменьшением
        задолженности) или довнесением (дозаводом) дополнительного количества
        акций АО «Газпром». 309000 * 0,8 % = 247 200, 300 000 – 247 200 =
        52 800.
      </p>
      <p>
        Таким образом, в первом случае, продавец ЦБ по 1 ч. РЕПО должен
        обеспечить наличие на своем брокерском счете или же предоставить
        контрагенту сумму в 52 800 руб. Другой вариант урегулирования ситуации —
        завод дополнительных 214 акций АО «Газпром» (1000 + 214)*309 = 375126,
         375126 * 0,8 = 300 100.
      </p>
      <p>
        <b>Маржинальный взнос</b> — это деньги или ценные бумаги, которые
        продавец обязан передать покупателю по первой части РЕПО в случае, если
        рыночная стоимость ценных бумаг упала ниже оговоренного сторонами
        уровня. Внесенные активы передаются на срочной и возвратной основе. В
        качестве маржинального взноса могут быть зачислены ценные бумаги другого
        вида или другого эмитента, отличные от ценных бумаг, являющихся
        предметом самой сделки РЕПО.
      </p>
      <p>
        Также в качестве денежного взноса могут быть использованы денежные
        средства в валюте, отличной от валюты самой сделки РЕПО. Эти взносы
        являются дополнительной гарантией исполнения обязательств со стороны
        продавца ценных бумаг по первой части сделки РЕПО. После исполнения
        сторонами обязательств по второй части сделки РЕПО маржинальный взнос
        возвращается в полном объеме.
      </p>
      <br />
      <p>
        <b>Пример 1.4.</b>
      </p>
      <p>
        Согласно условий примеров 1.1. и 1.2., 23.09.2021 по первой части сделки
        РЕПО клиентом было продано 1000 акций АО «Газпром», оцененных с 20%
        дисконтом к их рыночной стоимости. Напоминаем, сделка заключена на срок
        до 25.10.2021. С 24.09.2021 котировки ценных бумаг стали снижаться, и
        28.09.2021 рыночная стоимость 1000 акций АО «Газпром» составила 309 000
        руб. (одна акция стоит 309 руб.).
      </p>
      <p>
        Таким образом, текущий дисконт (разница между рыночной стоимостью ценных
        бумаг и суммой сделки РЕПО) составил всего 9000 руб., или 3%. Условиями
        сделки РЕПО предусмотрен маржинальный взнос. С помощью этого взноса
        продавец бумаг по первой части сделки РЕПО должен восстановить размер
        стоимостной оценки его активов согласно первоначально оговоренному
        уровню дисконта. Это можно сделать или деньгами, или довнесением
        (дозаводом) ценных бумаг. 309 000 * 0,8% = 247 200, 300 000 – 247 200 =
        52 800.
      </p>
      <p>
        Таким образом, в первом случае, продавец ЦБ по первой части сделки РЕПО
        должен обеспечить маржинальный взнос в размере 52 800 руб. на своем
        брокерском счету или же предоставить контрагенту другие валюты,
        составляющие рублевый эквивалент суммы в 52 800 руб. Например, это могут
        быть 600 евро (на 28.09.2021 курс евро к рублю, установленный Банком
        России составил 88 руб. за евро). 600*88 = 52 800. Другой вариант
        урегулирования ситуации — завод 2 акций АО ГМК «Норильский никель» (на
        28.09.2021 рыночная стоимость одной акции НорНикеля составила 26400
        руб.). 2*26400 = 52800.
      </p>

      <h2>Доходы по ценным бумагам, участие в корпоративных действиях</h2>
      <p>
        Если лица, имеющие право на получение от эмитента и (или) лица,
        выдавшего ценные бумаги, переданные по первой части сделки РЕПО, и (или)
        ценные бумаги, переданные в качестве маржинального взноса, денежных
        средств или иного имущества, в том числе в виде дивидендов или
        процентного дохода, определяются в период после передачи таких ценных
        бумаг одной стороной по сделке РЕПО и до их возврата другой стороной по
        сделке РЕПО, то при условии, что соответствующие денежные средства или
        иное имущество, в том числе в виде дивидендов или процентного дохода,
        выплачены (переданы) эмитентом или лицом, выдавшим ценные бумаги, суммы
        денежных средств, эквивалентные выплатам эмитента или лица, выдавшего
        ценные бумаги, и (или) иное имущество или его эквиваленту (далее — доход
        по ценным бумагам), должны быть переданы стороне, передавшей ценные
        бумаги в соответствии с сделкой РЕПО, в сроки и в порядке,
        предусмотренном сделкой РЕПО.
      </p>
      <p>
        Иными словами, покупатель ценных бумаг по первой части сделки РЕПО
        обязан возвратить продавцу ценных бумаг по первой части сделки РЕПО все
        начисления по данным бумагам, которые были произведены или начислены за
        период действия сделки РЕПО.
      </p>
      <p>
        Условиями сделки РЕПО может быть предусмотрено, что вместо передачи
        дохода по ценным бумагам покупателем по сделке РЕПО продавцу по сделке
        РЕПО стоимость ценных бумаг, подлежащих передаче во исполнение
        обязательств по второй части Договора РЕПО, уменьшается с учетом суммы
        дохода по ценным бумагам.
      </p>
      <br />
      <p>
        <b>Пример 1.5.</b>
      </p>
      <p>
        Согласно условий примера 1.1. 23.09.2021 по первой части сделки РЕПО
        клиентом было продано 1000 акций АО «Газпром». Напоминаем, сделка
        заключена на срок до 25.10.2021. 01.10.2021 Совет директоров АО
        «Газпром» принял решение о выплате дивидендов акционерам общества по
        итогам деятельности общества за первые полгода 2021 г. в размере 7,50
        руб. на одну обыкновенную акцию АО «Газпром». Список лиц, имеющих право
        на получение дивидендов, фиксируется на дату 12.10.2021.
      </p>
      <p>
        Таким образом, брокер «ABC» является лицом, имеющим право на получение
        от АО «Газпром» дивидендов в размере 7,5 * 1000 = 7500 руб. Согласно
        условиям сделки РЕПО, после получения дивидендов от эмитента брокер
        выплатит клиенту Алексееву сумму 7500 руб., или же, по соглашению
        сторон, сумма задолженности клиента Алексеева перед брокером уменьшится
        на эту сумму и составит 303160 – 7500 = 295660 руб.
      </p>

      <h2>РЕПО в России. Нормативная база</h2>
      <p>
        По российскому законодательству, договоры (сделки) РЕПО регулируются
        статьей 51.3 Федерального закона от 22.04.1996 N 39-ФЗ «О рынке ценных
        бумаг».
      </p>
      <p>
        Договоры РЕПО, подлежащие исполнению за счет физического лица, могут
        быть заключены, если одной из сторон по такому договору является брокер,
        дилер, депозитарий, управляющий, клиринговая организация или кредитная
        организация либо если указанный договор РЕПО заключен брокером за счет
        такого физического лица.
      </p>
      <p>
        Ценными бумагами по договору РЕПО могут быть эмиссионные ценные бумаги
        российского эмитента, инвестиционные паи паевого инвестиционного фонда,
        доверительное управление которым осуществляет российская управляющая
        компания, клиринговые сертификаты участия, акции или облигации
        иностранного эмитента, инвестиционные паи или акции иностранного
        инвестиционного фонда, ценные бумаги иностранного эмитента,
        удостоверяющие права в отношении ценных бумаг российского и (или)
        иностранного эмитента.
      </p>
      <p>
        Профессиональные стандарты сделок РЕПО в России в основном разрабатывают
        и согласуют в Центральном Банке России (ЦБ), Национальной Финансовой
        Ассоциации (НФА) и Национальной Ассоциации Участников Фондового Рынка
        (НАУФОР).
      </p>

      <div className="testing_divider" />
    </>
  )
}

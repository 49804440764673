import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { SET_CURRENT_PRODUCT_PATH } from 'reducers/global'
import { Button } from 'antd'
import notFound from '../../assets/not_found.svg'
import './index.less'

export const NotFound = () => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(SET_CURRENT_PRODUCT_PATH(''))
    if (typeof window !== 'undefined') window.scrollTo(0, 0)
  }, [])

  const handleButtonClick = React.useCallback(() => {
    dispatch(push('/'))
  }, [dispatch])

  return (
    <div className="not-found">
      <div className="not-found__image">
        <img
          src={notFound}
          alt="Страница не найдена"
          className="not-found__img"
        />
      </div>
      <div className="not-found__content">
        <h1 className="not-found__title">Что-то пошло не так</h1>
        <p className="not-found__text">
          Страница не найдена, либо ещё не создана
        </p>
        <Button
          type="primary"
          onClick={handleButtonClick}
          block
          className="not-found__button"
        >
          Перейти на главную
        </Button>
      </div>
    </div>
  )
}

import MobileImage from '../assets/start_saving/mobile.png'
import CollageImage from '../assets/start_saving/collage.png'
import ManagerImage from '../assets/start_saving/manager.png'

export const SLIDES = [
  {
    key: 1,
    label: 'Всегда под рукой',
    text: 'Удобное мобильное приложение',
    image: MobileImage,
  },
  {
    key: 2,
    label: 'Персональные предложения',
    text: 'Индивидуальные стратегии ваших накоплений',
    image: CollageImage,
  },
  {
    key: 3,
    label: 'Всегда с вами',
    text: 'Персональный менеджер',
    desc: 'Ответит на ваши вопросы в любое время. Онлайн и по телефону',
    image: ManagerImage,
  },
]

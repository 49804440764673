import { createSlice } from '@reduxjs/toolkit'
import { RESTORE_STEPS } from 'constants/SECURITY'
import { head } from 'ramda'

const initialState = {
  loading: false,
  token: null,
  secretQuestionList: [],
  restoreStep: RESTORE_STEPS.NEW,
  tryLoginData: {
    data: null,
    error: null,
    login: null,
  },
  sessionData: {
    data: null,
    error: null,
  },
  confirmOtpData: {
    data: null,
    error: null,
  },
  finalizeData: {
    data: null,
    error: null,
  },
  registrationData: {
    loading: false,
    form: {
      name: null,
      phone: null,
      email: null,
    },
    error: null,
  },
  invite: {
    loading: false,
    result: null,
    error: null,
  },
  restore: {
    data: null,
    error: null,
    success: false,
    otp: {
      data: null,
      error: null,
      success: false,
    },
  },
}

const security = createSlice({
  name: 'security',
  initialState,
  reducers: {
    saveSessionData: (state, action) => {
      const data = action.payload
      const { sessionList } = data
      localStorage.setItem('t', head(sessionList)?.sessionToken)
      state.sessionData.data = action.payload
    },
    saveSessionToken: (state, action) => {
      state.sessionData.data = {
        ...{},
        sessionList: [
          {
            sessionToken: localStorage.getItem('t'),
          },
        ],
      }
    },
    saveSessionError: (state, action) => {
      state.sesstionError = action.payload
    },

    saveTryLoginData: (state, action) => {
      state.tryLoginData.data = action.payload.data
      state.tryLoginData.login = action.payload.login
    },
    saveTryLoginError: (state, action) => {
      state.tryLoginData.error = action.payload
    },

    saveConfirmOtpData: (state, action) => {
      state.confirmOtpData.data = action.payload.data
    },
    saveConfirmOtpError: (state, action) => {
      state.confirmOtpData.error = action.payload
    },

    saveFinalizeData: (state, action) => {
      state.finalizeData.data = action.payload.data
    },
    saveFinalizeError: (state, action) => {
      state.finalizeData.error = action.payload
    },
    clearFinalizeData: (state, action) => {
      state.finalizeData.error = null
      state.finalizeData.data = null
    },
    securityLoading: (state, action) => {
      state.loading = action.payload
    },
    saveSecretQuestionList: (state, action) => {
      state.secretQuestionList = action.payload
    },
    saveSignupFinalize: (state, action) => {
      // state.data = action.payload.data
    },
    saveErrorSignupFinalize: (state, action) => {
      // state.data = action.payload.data
    },
    setRegistrationFormField: (state, action) => {
      const { key, value } = action.payload
      state.registrationData.form[key] = value
    },
    clearRegistrationData: (state, _action) => {
      state.registrationData = initialState.registrationData
    },
    registrationLoading: (state, action) => {
      state.registrationData.loading = action.payload
    },
    securityInviteLoading: (state, action) => {
      state.invite.loading = action.payload
    },
    securityInviteResult: (state, action) => {
      state.invite.result = action.payload
    },
    securityInviteError: (state, action) => {
      state.invite.error = action.payload
    },
    saveToken: (state, action) => {
      state.token = action.payload
    },
    clearToken: (state, _action) => {
      state.token = initialState.token
    },

    // Recover Password
    saveRestoreData: (state, action) => {
      state.restore.data = action.payload
    },
    saveRestoreError: (state, action) => {
      state.restore.error = action.payload
    },
    saveRestoreSuccess: (state, action) => {
      state.restore.success = action.payload
    },
    saveRestoreOTPData: (state, action) => {
      state.restore.otp.data = action.payload
    },
    saveRestoreOTPError: (state, action) => {
      state.restore.otp.error = action.payload
    },
  },
})

const { reducer, actions } = security

export const {
  saveSessionData,
  saveSessionError,
  saveTryLoginData,
  saveTryLoginError,
  saveConfirmOtpData,
  saveConfirmOtpError,
  saveFinalizeData,
  saveFinalizeError,
  clearFinalizeData,
  securityLoading,
  saveSecretQuestionList,
  saveSignupFinalize,
  saveErrorSignupFinalize,
  clearRegistrationData,
  registrationLoading,
  saveToken,
  securityInviteLoading,
  securityInviteResult,
  securityInviteError,
  saveRestoreData,
  saveRestoreError,
  saveRestoreSuccess,
  saveRestoreOTPData,
  saveRestoreOTPError,
} = actions

export default reducer

import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'

const IN_30_MINUTES = 1 / 48

const initialState = {
  login: '',
  authenticationData: null,
  authLoading: false,
  otpRequire: false,
  passwordData: null,
  passwordRequire: false,
  registrationRequire: false,
}

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveAuthenticationData: (state, action) => {
      state.authenticationData = action.payload
    },
    savePasswordData: (state, action) => {
      state.passwordData = action.payload
    },
    setLogin: (state, action) => {
      state.login = action.payload
    },
    setAuthLoading: (state, action) => {
      state.authLoading = action.payload
    },
    setPasswordRequired: (state, action) => {
      state.passwordRequire = action.payload
    },
    setRegistrationRequired: (state, action) => {
      state.registrationRequire = action.payload
    },
    setOtpRequired: (state, action) => {
      state.otpRequire = action.payload
    },
    saveSessionToken: (state, action) => {
      localStorage.setItem('t', action.payload)
    },
    saveRefreshToken: (state, action) => {
      Cookies.set('rt', action.payload, { expires: IN_30_MINUTES })
    },
    clearAuthenticationData: (state) => {
      state.login = initialState.login
      state.authenticationData = initialState.authenticationData
      state.authLoading = initialState.authLoading
      state.otpRequire = initialState.otpRequire
      state.passwordData = initialState.passwordData
      state.passwordRequire = initialState.passwordRequire
      state.registrationRequire = initialState.registrationRequire
    },
  },
})

const { reducer, actions } = auth

export const {
  saveAuthenticationData,
  savePasswordData,
  saveSessionToken,
  saveRefreshToken,
  setAuthLoading,
  setLogin,
  setPasswordRequired,
  setOtpRequired,
  setRegistrationRequired,
  clearAuthenticationData,
} = actions

export default reducer
